import get from 'lodash.get'
import cn from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import InputText from '../../../components/InputText'
import Checkbox from '../../../components/Checkbox'
import LoadingState from '../../../components/LoadingState'
import { ACTIVE_CURRENCIES, CURRENCY_USD, LOADING_STATE_LOADING } from '../../../constants'
import styles from './ProfileCard.module.scss'
import BigPopup from '../../../components/BigPopup'
import AvatarSettings from '../AvatarSettings/AvatarSettings'
import Icon from '../../../components/Icon/Icon'
import DEFAULT_AVATAR from '../../../images/default_avatar.svg'
import CustomSelect from '../../../components/Select/CustomSelect'
import PaymentAssetsTable from './PaymentAssetsTable'
import packageJson from '../../../../package.json'

class ProfileCardView extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      isAvatarPopupShow: false,
    }
  }

  static propTypes = {
    onFormatImage: PropTypes.func,
    onUpdateProfile: PropTypes.func,
    loadingState: PropTypes.object,
    avatar: PropTypes.string,
    isProfileSent: PropTypes.bool,
    avatarLoading: PropTypes.bool,
    profileInitialValues: PropTypes.object,
    profileValidationSchema: PropTypes.object,
    profile: PropTypes.object,
    bindSubmitForm: PropTypes.func,
  }

  toddleAvatarPopup = () => {
    this.setState((prevState) => {
      return {
        isAvatarPopupShow: !prevState.isAvatarPopupShow,
      }
    })
  }

  renderMainBlock = (formikProps) => {

    const {
      errors,
      touched,
      values,
      handleChange,
      handleBlur,
      setFieldValue,
      submitForm,
    } = formikProps

    const onFormatImage = (value) => {
      if (value === null) {
        setFieldValue('avatar', null)
        this.props.onFormatImage(DEFAULT_AVATAR)
      } else {
        const arr = value.split(','), mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        const type = mime.split('/')
        const fileObj = new File([u8arr], 'avatar.' + type[type.length - 1], { type: mime })
        setFieldValue('avatar', fileObj)
        this.props.onFormatImage(value)
      }
    }

    const { profile, bindSubmitForm } = this.props

    bindSubmitForm((e) => {
      submitForm(e)
        .then(() => {
          const defaulAccounts = values.defaultAccounts.filter((value) => !!value.blockchain && !!value.account)
          setFieldValue(
            'defaultAccounts',
            defaulAccounts.length > 0 ? defaulAccounts : [{
              blockchain: null,
              account: null,
            }],
          )
        })
    })

    const style = {}
    if (this.props.avatar || get(this.props, 'profile.avatar.url', null)) {
      style.backgroundImage = `url(${this.props.avatar || get(this.props, 'profile.avatar.url', null)})`
      style.backgroundSize = 'cover'
    }
    const fromDescription = values.fromDescription
      .split('\n')
      .slice(0, 4)

    return (
      <Form>
        {this.props.loadingState.loadingState === LOADING_STATE_LOADING
          ? (
            <div className={styles.loaderWrapper}>
              <LoadingState loadingState={this.props.loadingState.loadingState} />
            </div>
          )
          : <>
            <div className={styles.block}>
              <div className={styles.avatarBlock}>
                <div className={styles.avatarWrapper} style={style}>
                  <button
                    type='button'
                    className={styles.avatarIcon}
                    onClick={this.toddleAvatarPopup}
                    data-selenium-id='avatarButton'>
                    <Icon type='edit-square' colorType='blue' />
                  </button>
                </div>

                {profile.firstName || profile.lastName ? (
                  <div className='h5 gray-70'>{[profile.firstName, profile.lastName].join(' ')}</div>
                ) : null}
                <div className='capture gray-30'>{profile.email}</div>
                <div className={cn(styles.version, 'capture', 'gray-50')}>v{packageJson.version}</div>
              </div>
            </div>
            <div className={styles.block}>
              <div className={styles.rowWrapper}>
                <div className={styles.rowTitle + ' h5 gray-70'}>Personal information</div>
                <div className={styles.line}>
                  <span className='capture gray-70'>First name</span>
                  <InputText
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.firstName && touched.firstName}
                    value={values.firstName}
                    name='firstName'
                    data-selenium-id='firstNameInput'
                  />
                </div>
                <div className={styles.line}>
                  <span className='capture gray-70'>Last name</span>
                  <InputText
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.lastName && touched.lastName}
                    value={values.lastName}
                    name='lastName'
                    data-selenium-id='lastNameInput'
                  />
                </div>
                <div className={styles.line}>
                  <span className='capture gray-70'>Email address</span>
                  <InputText
                    onChange={handleChange}
                    disabled
                    onBlur={handleBlur}
                    error={errors.email && touched.email}
                    value={values.email}
                    name='email'
                    data-selenium-id='emailInput'
                    type='email'
                  />
                </div>
              </div>
            </div>
            <div className={styles.block}>
              <div className={styles.rowWrapper}>
                <div className={styles.rowTitle + ' h5 gray-70'}>Primary asset</div>
                <div className={styles.line}>
                  <span className='capture gray-70'>Currency</span>
                  <CustomSelect
                    onChange={(option) => {
                      setFieldValue('primaryAsset', option.value)
                    }}
                    defaultValue={ACTIVE_CURRENCIES[values.primaryAsset] || ACTIVE_CURRENCIES[CURRENCY_USD]}
                    name='primaryAsset'
                    options={Object.values(ACTIVE_CURRENCIES)}
                  />
                </div>
              </div>
            </div>
            <div className={styles.block}>
              <div className={styles.rowWrapper}>
                <div className={styles.rowTitle + ' h5 gray-70'}>Payment assets</div>
                <div>
                  <PaymentAssetsTable
                    formikProps={formikProps}
                  />
                </div>
              </div>
            </div>
            <div className={styles.block}>
              <div className={styles.rowWrapper}>
                <div className={styles.rowTitle + ' h5 gray-70'}>Receiving an invoice</div>
                <div className={cn(styles.line, styles.checkboxLine)}>
                  <div className={styles.checkboxContainer}>
                    <Checkbox
                      onClick={() => {
                        setFieldValue('receivePdf', !values.receivePdf)
                      }}
                      error={errors.receivePdf && touched.receivePdf}
                      value={values.receivePdf}
                      name='receivePdf'
                      data-selenium-id='receivePdfCheckbox'
                      type='receivePdf'
                      iconClassName={styles.checkbox16}
                    />
                  </div>
                  <div className={styles.checkboxDescriptionContainer}>
                    <div>
                      Receive a PDF copy of an invoice
                    </div>
                    <div className='gray-50'>
                      Email me a copy of an invoice in PDF when someone is invoicing me
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.block + ' ' + styles.descriptionBlock}>
              <div className={styles.rowWrapper}>
                <div className={styles.rowTitle + ' h5 gray-70'}>Billing info</div>
              </div>
              <div className={styles.rowWrapper}>
                <div className={cn(styles.line, styles.singleLine)}>
                  <InputText
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.fromDescription && touched.fromDescription}
                    errorText={errors.fromDescription}
                    value={values.fromDescription}
                    name='fromDescription'
                    data-selenium-id='fromDescriptionInput'
                    textarea
                    placeholder='Add an optional description such as home address, job title or phone. This text will be displayed along with your name and email address in your invoices. Please don’t put your name or email here, or it will be duplicated.'
                  />
                </div>
                <div className={styles.hintContainer}>
                  <div className={cn(styles.hintTitle, 'capture', 'gray-70')}>Invoice preview</div>
                  <div className={styles.hintBody}>
                    <div className={cn(styles.hintLogo, 'h5')}>Your logo here</div>
                    <div className={styles.hintInfo}>
                      {values.firstName || values.lastName
                        ? (<div>{`${values.firstName} ${values.lastName}\n`}</div>)
                        : null}
                      {fromDescription
                        .map((row, i) => {
                          if (row.length > 40) {
                            row = row.slice(0, 40) + '...'
                          }
                          const br = fromDescription.length > 1 ? <br /> : null
                          return <div key={i}>{row || br}</div>
                        })}
                      <div>{values.email}</div>
                    </div>
                    <div className={styles.hintInvoiceNumber}>Invoice #001</div>
                  </div>
                </div>
              </div>
            </div>
          </>}
        {this.state.isAvatarPopupShow && (
          <BigPopup onClose={this.toddleAvatarPopup} smallMode>
            <AvatarSettings
              avatar={this.props.avatar || get(this.props, 'profile.avatar.url', null)}
              onSave={onFormatImage}
            />
          </BigPopup>
        )}
      </Form>
    )
  }

  render () {
    return (
      <Formik
        initialValues={this.props.profileInitialValues}
        validationSchema={this.props.profileValidationSchema}
        onSubmit={this.props.onUpdateProfile}
        render={this.renderMainBlock}
      />
    )
  }
}

export default ProfileCardView
