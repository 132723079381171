//@flow
export type convertProfileValuesToRequestInfoType = {
  firstName: string,
  lastName: string,
  email: string,
  name: string,
  avatar: any,
  loadedAvatar: any,
  fromDescription: string,
  primaryAsset: string,
  receivePdf: boolean,
}

export const convertProfileValuesToRequestInfo = (values: convertProfileValuesToRequestInfoType) => {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    name: values.email,
    avatar: values.loadedAvatar,
    fromDescription: values.fromDescription,
    primaryAsset: values.primaryAsset,
    receivePdf: values.receivePdf,
  }
}
