//@flow
import moment from 'moment'
import { RestService } from '../../../services/RestService'
import { payrollUpdatePayrollInfo, removePayrollInfo, setCreatePayrollLoadingState } from '../actions'
import PxError, { ERROR_UNKNOWN_DESCRIPTION } from '../../../errors/PxError'
import { LOADING_STATE_ERROR, LOADING_STATE_LOADED, LOADING_STATE_LOADING } from '../../../constants'
import { myTeamsSelector } from '../../teams/selectors'
import { createTeam, loadMyTeams } from '../../teams/thunks'
import { getPayrollDateRange } from '../utils'
import { isResponseValid } from '../../../utils/general'
import { Dispatch } from 'redux'

import type { CreatePayrollValues, UpdatePayrollValues } from '../models'
import type { getStateType } from '../../accounts/models'
import Logger from '../../../services/Logger'

export { selectRequestItemThunk, updatePayrollSettings } from './runPayrollThunks'
export { runPayroll } from './runPayrollThunks'

export const createPayroll = (values: CreatePayrollValues) => async (dispatch: Dispatch, getState: getStateType) => {
  try {
    dispatch(setCreatePayrollLoadingState(LOADING_STATE_LOADING))

    let myTeam = myTeamsSelector()(getState())
    if (myTeam.length < 1) {
      myTeam = await dispatch(createTeam())
    } else {
      myTeam = myTeam[0]
    }

    await new Promise((resolve) => {
      setTimeout(() => resolve(), 500)
    })

    const payload = {
      teamId: myTeam.teamId,
      frequency: {
        period: values.paymentPeriod.toLowerCase(),
        days: getPayrollDateRange(values.firstPayDate, values.paymentPeriod),
      },
      activityStart: moment(values.firstPayDate).format('YYYY-MM-DD'),
      activityEnd: moment().add(99, 'year').format('YYYY-MM-DD'),
      generateInvoices: false,
      assets: [],
    }
    const { data } = await RestService.request('payrollService::createPayroll', payload)

    dispatch(payrollUpdatePayrollInfo(myTeam.teamId, data.result))
    dispatch(loadMyTeams())
    dispatch(setCreatePayrollLoadingState(LOADING_STATE_LOADED))

    return true
  } catch (e) {
    Logger.error(e, values)
    const errorText = e instanceof PxError ? e.getErrorDescription() : ERROR_UNKNOWN_DESCRIPTION
    dispatch(setCreatePayrollLoadingState(LOADING_STATE_ERROR, errorText))

    return false
  }
}

export const updatePayroll = (values: UpdatePayrollValues) => async (dispatch: Dispatch) => {
  try {
    dispatch(setCreatePayrollLoadingState(LOADING_STATE_LOADING))

    await new Promise((resolve) => {
      setTimeout(() => resolve(), 1000)
    })

    const payload = {
      teamId: values.teamId,
      frequency: {
        period: values.paymentPeriod.toLowerCase(),
        days: getPayrollDateRange(values.firstPayDate, values.paymentPeriod),
      },
      activityStart: values.firstPayDate,
      activityEnd: moment().add(99, 'year').format('YYYY-MM-DD'),
      generateInvoices: false,
      assets: [],
    }
    const { data } = await RestService.request('payrollService::updatePayroll', payload, values.payrollId)
    if (!isResponseValid(data.status)) {
      throw new Error(data.error)
    }

    dispatch(payrollUpdatePayrollInfo(values.teamId, data.result))
    dispatch(setCreatePayrollLoadingState(LOADING_STATE_LOADED))

    return true
  } catch (e) {
    Logger.error(e, values)
    const errorText = e instanceof PxError ? e.getErrorDescription() : ERROR_UNKNOWN_DESCRIPTION
    dispatch(setCreatePayrollLoadingState(LOADING_STATE_ERROR, errorText))

    return false
  }
}

export const deleteTeamAndPayroll = (teamId: string, payrollId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(setCreatePayrollLoadingState(LOADING_STATE_LOADING))

    await new Promise((resolve) => {
      setTimeout(() => resolve(), 1000)
    })

    const payrollResult = await RestService.request('payrollService::deletePayroll', payrollId)
    if (!isResponseValid(payrollResult.data.status)) {
      throw new Error()
    }

    const teamResult = await RestService.request('teamService::deleteTeam', teamId)
    if (!isResponseValid(teamResult.data.status)) {
      throw new Error(teamResult.data.error)
    }

    dispatch(loadMyTeams())
    dispatch(removePayrollInfo(teamId))
    dispatch(setCreatePayrollLoadingState(LOADING_STATE_LOADED))

    return true
  } catch (e) {
    Logger.error(e)
    const errorText = e instanceof PxError ? e.getErrorDescription() : ERROR_UNKNOWN_DESCRIPTION
    dispatch(setCreatePayrollLoadingState(LOADING_STATE_ERROR, errorText))

    return false
  }
}
