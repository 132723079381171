import * as Yup from 'yup'
import React from 'react'
import { connect } from 'react-redux'
import bip39 from 'bip39'
import { AccountModel } from '../../models/Accounts/AccountModel'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import InputText from '../../components/InputText'
import { Button } from '../../components/Button'
import { getAddressFromMnemonic } from '../../ducks/accounts/utils'
import { recoverAccountPassword } from '../../ducks/accounts/thunks'
import { accountByIdSelector, accountLoadingStateSelector } from '../../ducks/accounts/selectors'
import { profileInfoSelector } from '../../ducks/profile/selectors'
import { LOADING_STATE_LOADED, LOADING_STATE_ERROR, LOADING_STATE_LOADING } from '../../constants'
import globalStyles from './styles.module.scss'
import Icon from '../../components/Icon/Icon'
import styles from './RecoverPasswordForm.module.scss'
import LoadingState from '../../components/LoadingState'


const STEP_MNEMONIC = 'mnemonic'
const STEP_PASSWORD = 'password'

class RecoverPasswordForm extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      isSent: false,
      accountId: null,
      step: STEP_MNEMONIC,
      mnemonic: null,
    }
  }

  mnemonicValidationSchema = Yup.object().shape({
    mnemonic: Yup.mixed()
      .required('Recovery phrase is required')
      .test(
        'mnemonic',
        'Incorrect recovery phrase or wallet with this recovery phrase does not exist',
        (value) => {
          return bip39.validateMnemonic(value.toLowerCase().trim())
        },
      )
      .test(
        'mnemonic',
        'Incorrect recovery phrase or wallet with this recovery phrase does not exist',
        (value) => {
          if (this.props.account.address) {
            return this.props.account.address === getAddressFromMnemonic(this.props.account, value.toLowerCase().trim())
          }
          return true
        }),
  })

  passwordValidationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Minimum 8 symbols'),
    confirmPassword: Yup.mixed()
      .required('Confirm of password is required')
      .oneOf(
        [Yup.ref('password', null)],
        'Confirm of password must be equal with new password',
      )
      .test('confirmPassword', 'Minimum 8 symbols', (value) => value && value.length >= 8),
  })

  renderStep () {
    const handleSubmit = async (values) => {
      this.props.recoverAccountPassword({ ...values }, this.props.account)
      this.setState({
        isSent: true,
      })
    }

    switch (this.state.step) {
      case STEP_MNEMONIC:
        return (
          <Formik
            key='mnemonicForm'
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              mnemonic: '',
            }}
            validationSchema={this.mnemonicValidationSchema}
            onSubmit={(values) => {
              this.setState({
                step: STEP_PASSWORD,
                mnemonic: values.mnemonic,
              })
            }}
          >
            {(formikProps) => {
              const {
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
                setFieldValue,
                handleSubmit,
              } = formikProps

              return (
                <Form className={styles.form} name='mnemonicForm' onSubmit={handleSubmit}>
                  <div className={styles.title + ' capture gray-70'}>
                    Please type 12 words of recovery phrase for this<br />wallet to reset the password. Use space to separate words.
                  </div>
                  <div className={styles.input}>
                    <InputText
                      name='mnemonic'
                      textarea
                      onChange={handleChange}
                      onBlur={(e) => {
                        const trimmed = (e.target.value || '').toLowerCase().trim()
                        setFieldValue('mnemonic', trimmed)
                        handleBlur(e)
                      }}
                      error={!!errors.mnemonic && touched.mnemonic}
                      value={values.mnemonic}
                      errorText={errors.mnemonic}
                    />
                  </div>
                  <div className={styles.action}>
                    <Button
                      colorType='new-border'
                      disabled={!values.mnemonic}
                      type='submit'
                    >Reset password</Button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        )
      case STEP_PASSWORD:
        return (
          <Formik
            key='passwordForm'
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              mnemonic: this.state.mnemonic,
              password: '',
              confirmPassword: '',
            }}
            validationSchema={this.passwordValidationSchema}
            onSubmit={handleSubmit}
          >
            {(formikProps) => {
              const {
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
                handleSubmit,
              } = formikProps
              return (
                <Form className={styles.form} name='passwordForm' onSubmit={handleSubmit}>
                  <div className={styles.title + ' capture gray-70'}>
                    Enter new password.
                  </div>
                  <div className={styles.input}>
                    <InputText
                      name='password'
                      type='password'
                      placeholder='Enter wallet password'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!errors.password && touched.password}
                      value={values.password}
                    />
                  </div>
                  <div className={styles.input}>
                    <InputText
                      name='confirmPassword'
                      type='password'
                      placeholder='Enter password confimration'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!errors.confirmPassword && touched.confirmPassword}
                      value={values.confirmPassword}
                    />
                  </div>
                  <div className={styles.action}>
                    <Button
                      colorType='new-border'
                      disabled={!values.password && !values.confirmPassword}
                      type='submit'
                    >Reset password</Button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        )

      default:
        return
    }
  }

  render () {

    return (
      <div className={globalStyles.messageContainer}>
        <div className={globalStyles.messageHeader}>
          <button onClick={this.props.onClose}><Icon scale='1.5' type='close' colorType='gray-50' /></button>
          <div className='h4 gray-70'>Reset wallet password</div>
        </div>
        {!this.state.isSent && (
          <div className={styles.content}>
            {this.renderStep()}
          </div>
        )}

        {this.state.isSent && this.props.loadingState === LOADING_STATE_LOADING && (
          <LoadingState loadingState='loading' />
        )}

        {this.state.isSent && this.props.loadingState === LOADING_STATE_ERROR && (
          <div className={globalStyles.messageContent}>
            <Icon type='attention' colorType='red' />
            <div className='h5 gray-30'>Failed</div>
            <div className='capture gray-30'>Unable to reset password.<br />
              Please try again. Contact support if the issue still persists.
            </div>
            <div className={globalStyles.messageActions}>
              <Button
                type='button'
                onClick={this.props.onClose}
                colorType='new-border'>CLOSE</Button>
            </div>
          </div>
        )}

        {this.state.isSent && this.props.loadingState === LOADING_STATE_LOADED && (
          <div className={globalStyles.messageContent}>
            <Icon type='checkmark-circle' colorType='green' />
            <div className='h5 gray-30'>Wallet password successfully reset</div>
            <div className='capture gray-30'>You can send payments</div>
            <div className={globalStyles.messageActions}>
              <Button
                type='button'
                onClick={this.props.onClose}
                colorType='new-border'>CLOSE</Button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

RecoverPasswordForm.propTypes = {
  account: PropTypes.instanceOf(AccountModel),
  recoverAccountPassword: PropTypes.func,
  profile: PropTypes.object,
  loadingState: PropTypes.string,
  addAccount: PropTypes.func,
  onClose: PropTypes.func,
}

function mapStateToProps (state, props) {
  return {
    account: accountByIdSelector(props.accountId)(state),
    loadingState: accountLoadingStateSelector(state),
    profile: profileInfoSelector(state),
  }
}

function mapDispatchToProps (dispatch) {
  return {
    recoverAccountPassword: (values, account) => dispatch(recoverAccountPassword(values, account)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPasswordForm)