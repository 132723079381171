import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './Icon.module.scss'

export default class Icon extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string,
    colorType: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    scale: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }

  static defaultProps = {
    colorType: 'blue',
    onClick: () => {
    },
  }

  render () {
    const className = [styles.styledIcon]
    if (this.props.className) {
      className.push(this.props.className)
    }
    if (this.props.type) {
      className.push(styles[`icon-${this.props.type}`])
    }
    if (this.props.colorType) {
      className.push(styles[this.props.colorType])
    }
    if (this.props.scale) {
      className.push(styles[`scale-${this.props.scale*100}`])
    }

    return <div
      className={className.join(' ')}
      onClick={this.props.onClick}
    />
  }
}
