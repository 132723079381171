import PropTypes from 'prop-types'
import QRCode from 'qrcode'
import cn from 'classnames'
import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import styles from './AddPaymentRequestSidePanel.module.scss'
import { ratesSymbolMappedListSelector } from '../../ducks/rates/selectors'
import get from 'lodash.get'
import BigNumber from 'bignumber.js'
import { currentCurrencySelector, profileInfoSelector } from '../../ducks/profile/selectors'
import { ACCOUNTS_ACTIVE_BLOCKCHAINS, FORMAT_FIAT_NUMBERS } from '../../ducks/accounts/constants'
import { accountByIdSelector } from '../../ducks/accounts/selectors'
import Icon from '../Icon/Icon'
import CopyButton from '../Button/CopyButton'
import Button from '../Button/Button'
import InputText from '../InputText'
import { LOADING_STATE_LOADING } from '../../constants'
import LoadingState from '../LoadingState'
import { createPaymentLink } from "../../ducks/accounts/utils"
import { BLOCKCHAIN_BITCOIN, BLOCKCHAIN_BITCOIN_TESTNET } from "../../ducks/bitcoin/constants"
import { BLOCKCHAIN_ETHEREUM, BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY } from "../../ducks/ethereum/constants"

function SecondStep ({ rates, accounts, formikProps, loadingState }) {
  const {
    handleChange,
    handleBlur,
    errors,
    values,
  } = formikProps

  const [qrUrl, setQrUrl] = useState(null)
  let accountName, accountAddress = null
  const blockchainInfo = ACCOUNTS_ACTIVE_BLOCKCHAINS.find((b) => b.value === values.blockchain)

  if (values.isNewAddress) {
    accountAddress = values.accountId
  } else {
    const account = accounts.find((account) => values.accountId === account.id)
    accountName = account.name
    accountAddress = account.address
  }

  const [isEmailFieldShow, setIsEmailFieldShow] = useState(false)

  const message = values.message ? `&message=${encodeURI(values.message)}` : ''
  const str = createPaymentLink(values.blockchain, accountAddress, values.cryptoAmount, message)

  useEffect(() => {
    QRCode.toDataURL(str, { margin: 0, width: 180, height: 180, errorCorrectionLevel: 'H' })
      .then((url) => {
        setQrUrl(url)
      })
  }, [str])

  const rate = get(rates, `${blockchainInfo.symbol}.${values.currency}`, '--')
  const handleToggleEmailField = () => {
    setIsEmailFieldShow(!isEmailFieldShow)
  }

  return (
    <div className={styles.secondStepWrapper}>
      <div className={cn(styles.resultTable, 'capture', 'gray-70')}>
        <div className='gray-50'>Receive payment to:</div>
        <div className={styles.accountRow}>
          <Icon
            type={blockchainInfo.symbol}
            colorType={blockchainInfo.isTestnet ? 'gray-30' : 'blue'}
          />
          <div>
            {accountName && <div>{accountName}</div>}
            <div className='gray-50'>{accountAddress}</div>
          </div>
        </div>
        <div className='gray-50'>Amount:</div>
        <div>{values.fiatAmount} {values.currency} = {values.cryptoAmount} {blockchainInfo.symbol}</div>
        <div className='gray-50'>Exchange rate:</div>
        <div>{new BigNumber(rate).toFormat(2, FORMAT_FIAT_NUMBERS)} {values.currency}/{blockchainInfo.symbol}</div>
        <div className='gray-50'>Message:</div>
        <div>{values.message}</div>
      </div>

      <div className={styles.qrCodeWrapper}>
        {qrUrl && <img alt='Payment request QR code' src={qrUrl} />}
      </div>

      {[BLOCKCHAIN_BITCOIN, BLOCKCHAIN_BITCOIN_TESTNET].includes(values.blockchain) && (
        <div className={cn(styles.warning, 'capture-small', 'gray-30')}>Link is generated according to BIP_0021</div>
      )}
      {[BLOCKCHAIN_ETHEREUM, BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY].includes(values.blockchain) && (
        <div className={cn(styles.warning, 'capture-small', 'gray-30')}>Link is generated according to EIP 681</div>
      )}

      <div className={styles.copyButtonWrapper}>
        <CopyButton copyString={str}>
          <div className={styles.actionButton}>
            <Icon type='copy-2' colorType='blue' />
            <div className='capture-small blue-bk'>Copy payment link</div>
          </div>
        </CopyButton>
      </div>


      {loadingState === LOADING_STATE_LOADING
        ? (
          <div className={styles.sendEmailFormLoadingWrapper}>
            <LoadingState loadingState={LOADING_STATE_LOADING} />
          </div>
        ) : (
          <div className={styles.sendToEmailWrapper}>
            {isEmailFieldShow ? (
              <div>
                <InputText
                  name='email'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.email}
                  value={values.email}
                  errorText={errors.email}
                  placeholder='Enter email'
                />
                <Button type='submit'>Send</Button>
              </div>
            ) : (
              <button
                type='button'
                onClick={handleToggleEmailField}
                className={styles.actionButton}
              >
                <Icon type='mail' colorType='blue' />
                <div className='capture-small blue-bk'>Send to email</div>
              </button>
            )}
          </div>
        )}
    </div>
  )
}

SecondStep.propTypes = {
  rates: PropTypes.any,
  formikProps: PropTypes.object,
  accounts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    isTestnet: PropTypes.bool,
    blockchain: PropTypes.string,
    defaultAccount: PropTypes.bool,
    symbol: PropTypes.string,
  })),
  loadingState: PropTypes.string,
}

function mapStateToProps (state, props) {
  return {
    account: accountByIdSelector(props.accountId)(state),
    profile: profileInfoSelector(state),
    rates: ratesSymbolMappedListSelector(state),
    currentCurrency: currentCurrencySelector(state),
  }
}

export default connect(mapStateToProps, null)(SecondStep)
