import escapeRegExp from 'lodash.escaperegexp'
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styles from '../AddInvoiceCard.module.scss'
import CustomSelect from '../../../components/Select/CustomSelect'
import { allRequestsSelector } from '../../../ducks/paymentRequests/selectors'
import moment from 'moment'
import get from 'lodash.get'
import Icon from '../../../components/Icon/Icon'
import { PAYMENT_REQUEST_STATUS_DRAFT } from '../../../ducks/paymentRequests/constants'
import { PaymentRequestModel } from '../../../models/PaymentRequests/PaymentRequestModel'
import { amplitudeSendEvent } from "../../../utils/analytics"

const TemplateSelector = ({ requests, handleSetTemplate }) => {


  return (
    <div className={styles.templateSelectorWrapper}>
      <CustomSelect
        label='Load from drafts'
        onChange={({ value }) => {
          amplitudeSendEvent('Draft loaded', {})
          const request = requests.find((item) => item.requestId === value)
          handleSetTemplate(request)
        }}
        styles={{
          singleValue: (defaultStyles) => {
            return {
              ...defaultStyles,
              ['.' + styles.listOption]: {
                display: 'none',
              },
              ['.' + styles.singleOption]: {
                display: 'block',
              },
            }
          },
        }}
        name='template'
        placeholder='Select draft'
        formatOptionLabel={(elem, context) => {
          const safetyQuery = escapeRegExp(context.inputValue)
          const reg = new RegExp(safetyQuery, 'i')
          const replace = (string) => {
            if (context.inputValue) {
              return `<div>${string.replace(reg, (match) => {
                return `<span class='gray'>${match}</span>`
              })}</div>`
            }
            return `<div>${string}</div>`
          }

          return (
            <div>
              <div className={styles.singleOption + ' capture gray-70'}>{elem.title}</div>
              <div className={styles.listOption}>
                <div className={`${styles.billToOption} capture gray-50`}>
                  <div className={styles.billToOptionIcon}>
                    <div className={styles.avatarWrapper}>
                      <Icon type='draft' colorType='gray-70' />
                    </div>
                  </div>
                  <div
                    className={styles.billToOptionName}
                    dangerouslySetInnerHTML={{ __html: replace(elem.title) }}
                  />
                  <div
                    className={styles.billToOptionEmail + ' gray-30'}
                    dangerouslySetInnerHTML={{ __html: replace(elem.date) }}
                  />
                </div>
              </div>
            </div>
          )
        }}
        options={requests
          .reduce((acc, item) => {
            if (item.status === PAYMENT_REQUEST_STATUS_DRAFT) {
              const date = moment(item.createdAt).format('hh:mm · MMM DD,YYYY')
              const title = get(item, 'invoice.title', '')
              acc.push({
                label: `${date} ${title || 'no title'}`,
                date,
                title: title || 'no title',
                value: item.requestId,
              })
            }
            return acc
          }, [])}
      />
    </div>
  )
}


TemplateSelector.propTypes = {
  handleSetTemplate: PropTypes.func,
  requests: PropTypes.arrayOf(PropTypes.instanceOf(PaymentRequestModel)),
}

const mapStateToProps = (state) => {
  return {
    requests: allRequestsSelector(state),
  }
}

export default connect(mapStateToProps)(TemplateSelector)
