import { createSelector } from 'reselect'
import groupBy from 'lodash.groupby'
import get from 'lodash.get'
import config from '../../config/getConfig'
import {
  PAYMENT_REQUEST_STATUS_PAID,
  PAYMENT_REQUEST_COLLECTION_DRAFT,
  PAYMENT_REQUEST_COLLECTION_PENDING,
  PAYMENT_REQUEST_STATUS_CANCELLED, PAYMENT_REQUEST_STATUS_DELETED,
  PAYMENT_REQUEST_STATUS_DRAFT,
  PAYMENT_REQUESTS_DUCK,
} from './constants'
import { isPendingRequest } from './utils'
import BigNumber from 'bignumber.js'
import { allMembersListSelector } from '../teams/selectors'
import { profileInfoSelector } from '../profile/selectors'
import moment from 'moment'
import { BLOCKCHAIN_BITCOIN_TESTNET } from '../bitcoin/constants'
import { FORMAT_FIAT_NUMBERS } from '../accounts/constants'
import { TYPE_AMOUNT } from "../../partials/AddInvoiceCard/constants"

export const paymentRequestDuckSelector = (state) => {
  return state[PAYMENT_REQUESTS_DUCK]
}

export const requestsSelector = createSelector(
  paymentRequestDuckSelector,
  ({ requests }) => requests,
)

export const requestsLoadingStateSelector = createSelector(
  requestsSelector,
  (requests) => {
    return requests.loadingState
  },
)

export const requestByIdSelector = (requestId) => createSelector(
  requestsSelector,
  ({ requestList }) => requestList.find((request) => request.requestId === requestId),
)

export const allRequestsSelector = createSelector(
  requestsSelector,
  ({ requestList }) => requestList,
)

export const requestsByIdsSelector = (paymentRequestIds: Array<string>) => createSelector(
  allRequestsSelector,
  (requests) => {
    const requestList = []
    paymentRequestIds.forEach((requestId) => {
      const searchedRequest = requests.find((r) => r.requestId === requestId)
      if (searchedRequest) {
        requestList.push(searchedRequest)
      }
    })
    return requestList
  },
)

const sortByCollectionDate = (a, b) => {
  if ((a.collectionDate || a) > (b.collectionDate || b)) {
    return -1
  }
  if ((a.collectionDate || a) < (b.collectionDate || b)) {
    return 1
  }
  return 0
}

export const activityListSelector = createSelector(
  requestsSelector,
  allMembersListSelector,
  profileInfoSelector,
  ({ requestList }, teamMembers, profile) => {
    const formattedList = requestList.map((item) => {
      const preSymbol = item.fromUserId === profile.id ? '+' : '-'

      const name = item.fromUserId === profile.id
        ? {
          name: item.invoice.payerName || item.invoice.payerAddress,
          email: item.invoice.payerAddress,
        }
        : {
          name: item.invoice.payeeName || item.invoice.payeeAddress || '--',
          email: item.invoice.payeeAddress || '--',
        }

      let resName = item.isPaid ? name.name : name.email

      if (!resName) {
        resName = '--'
      }

      // filter cancelled invoices in case of WS update
      if (item.status === PAYMENT_REQUEST_STATUS_CANCELLED && item.fromUserId !== profile.id) {
        return null
      }

      const getCollectionDate = (request) => {
        if (request.status === PAYMENT_REQUEST_STATUS_DRAFT) {
          return PAYMENT_REQUEST_COLLECTION_DRAFT
        }
        if (!isPendingRequest(request.status) || moment(request.invoice.due) < moment()) {
          return moment(request.statusHistory[request.statusHistory.length - 1].date)
            .format('DD.MM.YYYY')
        }
        return PAYMENT_REQUEST_COLLECTION_PENDING
      }

      let cryptoAmount = new BigNumber(0)
      item.invoice.transactions.forEach((t) => {
        cryptoAmount = cryptoAmount.plus(t.amount ? t.amount : new BigNumber(item.settlementAmount).dividedBy(t.xrate))
      })
      const invoiceNumber = get(item, 'invoice.number', null)
      const invoiceVersion = get(item, 'invoice.version', null)
      const invoiceTitle = get(item, 'invoice.title', null)
      const paymentAsset = item.paymentAsset
        ? `${item.paymentAsset}${item.paymentBlockchain === BLOCKCHAIN_BITCOIN_TESTNET ? ' Testnet' : ''}`
        : '--'
      const amount = `${preSymbol} ${item.settlementAsset} ${new BigNumber(item.settlementAmount || 0).toFormat(2, FORMAT_FIAT_NUMBERS)}`

      return {
        invoiceNumber: `#${(invoiceVersion ? `${invoiceNumber}.${invoiceVersion}` : invoiceNumber) || ` --`}`,
        invoiceTitle: invoiceTitle || 'Draft',
        isFromMe: item.fromUserId === profile.id,
        requestId: item.requestId,
        name: resName,
        email: name.email,
        isContact: item.isPaid,
        status: item.status,
        createdAt: moment(item.createdAt).format('MMM DD, YYYY'),
        updatedAt: moment(item.updatedAt),
        createdAtTime: moment(item.createdAt).format('HH:mm'),
        preSymbol,
        collectionDate: getCollectionDate(item),
        amount,
        amountCrypto: !cryptoAmount.isZero() ? preSymbol + ' ' + item.paymentAsset + ' ' + cryptoAmount.toFixed(config.getConfig('bitcoinDecimalLength')) : '',
        paymentAsset,
        paymentBlockchain: item.paymentBlockchain,
      }
    })
      .filter((i) => i)

    return Object.entries(groupBy(formattedList.sort(sortByCollectionDate),
      (request) => request.collectionDate))
      .map(([dateOrType, dateRequests]) => {
        const d = moment(dateOrType, 'DD.MM.YYYY')
        return {
          collection: {
            dateValue: d.isValid() ? d.format('x') : dateOrType,
            date: d.isValid() ? dateOrType : dateOrType,
          },
          requests: dateRequests.filter((i) => i.status !== PAYMENT_REQUEST_STATUS_DELETED),
        }
      })
      .sort((a, b) => {
        const aValue = a.collection.dateValue
        const bValue = b.collection.dateValue
        if (!aValue) {
          return -1
        }
        if (!bValue) {
          return 1
        }

        if (aValue > bValue) {
          return -1
        }
        if (aValue < bValue) {
          return 1
        }
        return 0
      })
  },
)

export const formLoadingStateSelector = (formName) => createSelector(
  paymentRequestDuckSelector,
  ({ forms }) => {
    return forms[formName]
  },
)

export const usersHintSelector = createSelector(
  paymentRequestDuckSelector,
  ({ usersHint }) => {
    return usersHint
  },
)

export const requestsPreparedToExportToScvSelector = createSelector(
  requestsSelector,
  ({ requestList }) => {
    const fields = [
      {
        keyInModel: 'requestId',
        keyInCSV: 'id',
      },
      {
        keyInModel: 'invoice.number',
        keyInCSV: 'number',
        formatCallback: (value, request) => {
          const invoiceVersion = get(request, 'invoice.version', null)
          return `#${(invoiceVersion ? `${value}.${invoiceVersion}` : value) || ` --`}`
        },
      },
      {
        keyInModel: 'invoice.payerAddress',
        keyInCSV: 'payerAddress',
      },
      {
        keyInModel: 'invoice.payerName',
        keyInCSV: 'payerName',
      },
      {
        keyInModel: 'invoice.payeeAddress',
        keyInCSV: 'payeeAddress',
      },
      {
        keyInModel: 'invoice.payeeName',
        keyInCSV: 'payeeName',
      },
      {
        keyInModel: 'invoice.title',
        keyInCSV: 'title',
      },
      {
        keyInModel: 'invoice.due',
        keyInCSV: 'due',
        formatCallback: (value) => {
          return value ? moment(value).format('YYYY-MM-DD') : ''
        },
      },
      {
        keyInModel: 'invoice.billFrom',
        keyInCSV: 'billFrom',
      },
      {
        keyInModel: 'settlementAsset',
        keyInCSV: 'settlementAsset',
      },
      {
        keyInModel: 'settlementAmount',
        keyInCSV: 'settlementAmount',
        formatCallback: (value) => {
          return parseFloat(value).toFixed(2)
        },
      },
      {
        keyInModel: 'paymentAsset',
        keyInCSV: 'paymentAsset',
      },
      {
        keyInModel: 'paymentWallet',
        keyInCSV: 'paymentWallet',
      },
      {
        keyInModel: 'status',
        keyInCSV: 'status',
      },
      {
        keyInModel: (request) => {
          if(request.status === PAYMENT_REQUEST_STATUS_PAID) {
            const txs = request.invoice.transactions
            return txs[txs.length - 1].hash
          } else {
            return ''
          }
        },
        keyInCSV: 'transactionHash',
      },
      {
        keyInModel: (request) => {
          if(request.status === PAYMENT_REQUEST_STATUS_PAID) {
            const txs = request.invoice.transactions
            return txs[txs.length - 1].xrate.toFixed(2)
          } else {
            return ''
          }
        },
        keyInCSV: 'exchangeRate',
      },
      {
        keyInModel: 'notes',
        keyInCSV: 'notes',
      },
    ]

    const itemsFields = [
      {
        keyInModel: 'type',
        keyInCSV: 'ItemType',
        formatCallback: (value) => {
          return value ? `${value}` : ''
        },
      },
      {
        keyInModel: 'description',
        keyInCSV: 'ItemDescription',
        formatCallback: (value) => {
          return value ? `${value}` : ''
        },
      },
      {
        keyInModel: 'count',
        keyInCSV: 'ItemCount',
      },
      {
        keyInModel: (item) => {
          if (item.type === TYPE_AMOUNT) {
            return item.amount
          }
          return item.rate
        },
        keyInCSV: 'ItemRate',
        formatCallback: (value) => {
          return parseFloat(value).toFixed(2)
        },
      },
    ]

    const fakeItemsFields = fields.reduce((acc, { keyInCSV, keyInModel }) => {
      if (keyInModel !== 'requestId') {
        acc[keyInCSV] = ''
      }
      return acc
    }, {})

    const items = requestList.reduce((accumulator, request) => {
      if ([PAYMENT_REQUEST_STATUS_DRAFT].includes(request.status)) {
        return accumulator
      }

      const mainColumns = fields.reduce((acc, field) => {
        const value = typeof field.keyInModel === 'function'
          ? field.keyInModel(request)
          : get(request, field.keyInModel, '')

        acc[field.keyInCSV] = typeof field.formatCallback === 'function' ? field.formatCallback(value, request) : value
        return acc
      }, {})

      request.invoice.items.forEach((item, i) => {
        const itemsColumns = itemsFields.reduce((acc, field) => {
          const value = typeof field.keyInModel === 'function'
            ? field.keyInModel(item)
            : get(item, field.keyInModel, '')

          acc[field.keyInCSV] = typeof field.formatCallback === 'function' ? field.formatCallback(value) : value
          return acc
        }, {})
        if (i === 0) {
          accumulator.push({
            ...mainColumns,
            ...itemsColumns,
          })
        } else {
          accumulator.push({
            [fields[0].keyInCSV]: get(request, fields[0].keyInModel, ''), // TODO fix this crutch somehow
            ...fakeItemsFields,
            ...itemsColumns,
          })

        }
      })
      return accumulator
    }, [])

    return items
  },
)

export const isExportRequestsExistedSelector = createSelector(
  requestsSelector,
  ({ requestList }) => {

    const list = requestList.filter((request) => ![PAYMENT_REQUEST_STATUS_DRAFT].includes(request.status))

    return list.length > 0
  },
)

export const requestsMappedByIdSelector = createSelector(
  requestsSelector,
  ({ requestList }) => {
    return requestList.reduce((acc, item) => {
      acc[item.requestId] = item
      return acc
    }, {})
  },
)

