/**
 * This function is used for swipe detection of a panel on mobile version
 * @param {Object} el - element to watch.
 */

const TAP_EVENT_BORDER_DISTANCE = 5

export default class Swipe {

  constructor (element) {
    const params = {
      elem: element,            // swipe panel element
      direction: null,            // swipe direction (horizontal, vertical)
      swipeType: null,            // тип свайпа (up, down, left, right)
      dist: null,                 // swipe distance
      disabledByScroll: null,     //
      lastScrollEventTime: 0,  // time of last scroll event to prevent conflict between swipe and scroll
      isMouse: false,      // mouse support (not used for touch events)
      isMouseDown: false,  // mouse usage when mouse is pressed down (not used for touch events)
      startX: 0,           // origin along the x axis (pageX)
      distX: 0,            // swipe distance on axis X
      startY: 0,           // origin along the Y axis (pageY)
      distY: 0,            // swipe distance on axis Y
      startTime: 0,        // first touch time
      adjustMoveParams: false,
      support: {           // supported browser event types
        pointer: !!('PointerEvent' in window || ('msPointerEnabled' in window.navigator)),
        touch: !!(typeof window.orientation !== 'undefined' || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || 'ontouchstart' in window || navigator.msMaxTouchPoints || 'maxTouchPoints' in window.navigator > 1 || 'msMaxTouchPoints' in window.navigator > 1),
      },
    }

    Object.entries(params).forEach(([key, value]) => {
      this[key] = value
    })
  }

  /**
   * Available types of event: pointer, touch and mouse.
   * @returns {Object} - returns an object with available type of event.
   */
  getSupportedEvents = () => {
    let events
    switch (true) {
      case this.support.pointer && 0: // temp disabled
        events = {
          type:   'pointer',
          start:  'PointerDown',
          move:   'PointerMove',
          end:    'PointerUp',
          cancel: 'PointerCancel',
          leave:  'PointerLeave',
        }
        // добавление префиксов для IE10
        // eslint-disable-next-line
        const ie10 = (window.navigator.msPointerEnabled && Function('/*@cc_on return document.documentMode===10@*/')())
        for (const value in events) {
          if (value === 'type') {
            continue
          }
          events[value] = (ie10) ? 'MS' + events[value] : events[value].toLowerCase()
        }
        break
      case this.support.touch:
        events = {
          type:   'touch',
          start:  'touchstart',
          move:   'touchmove',
          end:    'touchend',
          cancel: 'touchcancel',
        }
        break
      default:
        events = {
          type:  'mouse',
          start: 'mousedown',
          move:  'mousemove',
          end:   'mouseup',
          leave: 'mouseleave',
        }
        break
    }
    return events
  }


  /**
   * Merge events mouse/pointer and touch.
   * @param e {Event} - event object.
   * @returns {TouchList|Event} - returns either TouchList, or an event without changes.
   */
  eventsUnify = (e) => {
    return e.changedTouches ? e.changedTouches[0] : e
  }

  setDefault = () => {
    this.startX = 0           // origin along the x axis (pageX)
    this.distX = 0            // swipe distance on axis X
    this.startY = 0           // origin along the Y axis (pageY)
    this.distY = 0            // swipe distance on axis Y
    this.startTime = 0        // first touch time
    this.dist = 0
    this.disabledByScroll = null
  }

  findScroll = (event) => {
    let containerIndex = null
    let disabledByScroll = false
    const path = event.path || (event.composedPath && event.composedPath())
    path.reverse().forEach((element, index) => {
      if (containerIndex !== null && element.matches('.Swipe_scrollContainer > div') && element.scrollTop > 0) {
        disabledByScroll = true
      }

      if (!containerIndex && element === this.elem) {
        containerIndex = index
      }
    })

    return disabledByScroll
  }

  /**
   * First touch event handler.
   * @param e {Event} - event object.
   */
  checkStart = (e) => {
    if (this.disabledByScroll === null) {
      this.disabledByScroll = this.findScroll(e)
    }

    const event = this.eventsUnify(e)
    if ((this.support.touch && typeof e.touches !== 'undefined' && e.touches.length !== 1) || this.disabledByScroll) {
      return
    }

    this.direction = 'none'
    this.swipeType = 'none'
    this.dist = 0
    this.distX = 0
    this.distY = 0
    this.startX = event.pageX
    this.startY = event.pageY
    this.startTime = new Date().getTime()
    this.adjustMoveParams = false
    if (this.isMouse) {
      this.isMouseDown = true
    }

    if (this.elem.classList.contains('closed')) {
      e.preventDefault()
    }
  }

  /**
   * Movement events handler.
   * @param e {Event} - object event.
   */
  checkMove = (e) => {
    if ((this.isMouse && !this.isMouseDown) || this.disabledByScroll) {
      return
    }

    const event = this.eventsUnify(e)
    if (!this.adjustMoveParams) {
      this.startX = event.pageX
      this.startY = event.pageY
      this.adjustMoveParams = true
    }

    this.distX = this.startX - event.pageX
    this.distY = this.startY - event.pageY
    // if movement is too small count it as a Tap event or scroll event is recently triggered
    if (!this.startX || !this.startY || !(Math.abs(this.distX) > TAP_EVENT_BORDER_DISTANCE || Math.abs(this.distY) > TAP_EVENT_BORDER_DISTANCE)) {
      return null
    }

    if (Math.abs(this.distX) > Math.abs(this.distY)) {
      this.direction = (this.distX < 0) ? 'left' : 'right'
    } else {
      this.direction = (this.distY < 0) ? 'down' : 'up'
    }

    const endTime = new Date().getTime()
    const time = endTime - this.startTime
    this.dist = (this.direction === 'left' || this.direction === 'right') ? Math.abs(this.distX) : Math.abs(this.distY) // опредление пройденной указателем дистанции

    const swipeEvent = new CustomEvent('swipeMove', {
      bubbles: true,
      cancelable: true,
      detail: {
        full: e,
        direction:  this.swipeType,
        dist: this.dist,
        distX: this.distX,
        distY: this.distY,
        time: time,
      },
    })
    this.elem.dispatchEvent(swipeEvent)
    // e.preventDefault()
  }

  /**
   * Release touch handler
   * @param e {Event} - event object.
   */
  checkEnd = (e) => {
    if ((this.isMouse && !this.isMouseDown) || !this.startX || !this.startY) { // exit if mouse isn't down
      this.isMouseDown = false
      this.disabledByScroll = null
      return
    }
    const endTime = new Date().getTime()
    const time = endTime - this.startTime
    this.swipeType = this.direction
    this.dist = (this.direction === 'left' || this.direction === 'right') ? Math.abs(this.distX) : Math.abs(this.distY) // опредление пройденной указателем дистанции

    if (this.swipeType !== 'none') {
      const swipeEvent = new CustomEvent('swipe', {
        bubbles: true,
        cancelable: true,
        detail: {
          full: e, // full Event object
          direction:  this.swipeType, // swipe direction
          dist: this.dist, // swipe distance
          distX: this.distX, // swipe distance X
          distY: this.distY, // swipe distance Y
          time: time, // swipe time
        },
      })
      this.elem.dispatchEvent(swipeEvent)
    } else if (!this.distX && !this.distY) {
      const tapEvent = new CustomEvent('tap', {
        bubbles: true,
        cancelable: true,
        detail: {
          full: e, // full Event object
          time: time, // swipe time
        },
      })
      this.elem.dispatchEvent(tapEvent)
    }
    if (this.elem.classList.contains('closed')) {
      e.preventDefault()
    }

    this.setDefault()
  }

  subscribe = () => {
    // add supported events
    const events = this.getSupportedEvents()

    // check is mouse is available
    if ((this.support.pointer && !this.support.touch) || events.type === 'mouse') {
      this.isMouse = true
    }

    this.elem.addEventListener(events.start, this.checkStart)
    this.elem.addEventListener(events.move, this.checkMove)
    this.elem.addEventListener(events.end, this.checkEnd)
    this.elem.addEventListener(events.leave, (e) => {
      // eslint-disable-next-line
      console.log('Leave: ', e)
    })
    this.elem.addEventListener(events.cancel, (e) => {
      // eslint-disable-next-line
      console.log('Cancel: ', e)
    })

  }
}

