import * as a from './constants'

export const setSidePanel = (panelName, panelProps) => {
  return {
    type: a.UI_SET_SIDE_PANEL,
    panelName,
    panelProps,
  }
}

export const setNotification = (notificationKey, notificationParam) => {
  return {
    type: a.UI_SET_NOTIFICATIONS,
    notificationKey,
    notificationParam,
  }
}

export const setErrorPageData = ({ title, subTitle }) => {
  return {
    type: a.UI_SET_ERROR_PAGE_DATA,
    title,
    subTitle,
  }
}

export const setConfirmPasswordCardParams = (params) => {
  return {
    type: a.UI_SET_CONFIRM_PASSWORD_CARD_PARAMS,
    params,
  }
}

export const setCustomLoadingState = (params) => {
  return {
    type: a.UI_SET_CUSTOM_LOADING_STATE,
    params,
  }
}

export const updateTransactionFormState = (sendingState) => {
  return {
    type: a.UI_UPDATE_TRANSACTION_FORM_STATE,
    sendingState,
  }
}

