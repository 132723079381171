import AbstractRESTService from './AbstractRESTService'

const URL_UPLOAD_IMAGE = '/api/v1/media/image/upload'
const URL_GET_IMAGE = '/api/v1/media/image/'

export default class MediaService extends AbstractRESTService {
  uploadImage (image) {
    return this.restService.request({
      method: 'POST',
      headers: this.getBearerHeaders(),
      url: URL_UPLOAD_IMAGE,
      data: image,
    })
  }

  getImage (imageId) {
    return this.restService.request({
      method: 'GET',
      headers: this.getBearerHeaders(),
      url: URL_GET_IMAGE + imageId,
    })
  }
}
