// @flow
import type { HotWallet } from '../../ducks/accounts/models'
import { connect } from 'react-redux'
import React from 'react'
import { accountLoadingStateSelector } from '../../ducks/accounts/selectors'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import InputText from '../../components/InputText'
import { Button } from '../../components/Button'
import { getMnemonicPhrase } from '../../ducks/accounts/utils'
import { updatePassword } from '../../ducks/accounts/thunks'
import { LOADING_STATE_LOADED, LOADING_STATE_ERROR, LOADING_STATE_LOADING } from '../../constants'
import LoadingState from '../../components/LoadingState'
import globalStyles from './styles.module.scss'
import styles from './ChangePasswordForm.module.scss'
import Icon from '../../components/Icon/Icon'
import { setSidePanel } from '../../ducks/ui/actions'

type State = {
  isSent: boolean,
}

type Props = {
  updatePassword: (account: HotWallet, oldPassword: string, newPassword: string) => void,
  loadingState: string,
  account: HotWallet,
  onClose: () => void,
  setSidePanel: (panelName: string, panelProps: any)=>void,
}

type FormValues = {
  oldPassword: string,
  newPassword: string,
  confirmPassword: string,
}

class ChangePasswordForm extends React.PureComponent<Props, State> {
  static propTypes = {
    account: PropTypes.object,
    updatePassword: PropTypes.func,
    loadingState: PropTypes.string,
    onClose: PropTypes.func,
    setSidePanel: PropTypes.func,
  }

  resetForm: () => void

  constructor (props) {
    super(props)

    this.state = {
      isSent: false,
    }
  }

  validationSchema = Yup.object().shape({
    oldPassword: Yup.mixed()
      .required('Old password is required')
      .test(
        'oldPassword',
        'Old password wrong',
        (value) => !!getMnemonicPhrase(this.props.account, value),
      ),
    newPassword: Yup.string()
      .required('New password is required')
      .min(8, 'Minimum 8 symbols'),
    confirmPassword: Yup.mixed()
      .required('Confirm of new password is required')
      .oneOf(
        [Yup.ref('newPassword', '')],
        'Confirm of new password must be equal with new password',
      )
      .test('confirmPassword', 'Minimum 8 symbols', (value) => value && value.length >= 8),
  })

  handleOpenResetPasswordForm = () => {
    this.props.onClose()
    this.props.setSidePanel('recoverAccountPasswordForm', {
      isOpen: true,
      accountId: this.props.account.id,
    })
  }


  render () {

    const handleSubmit = async (values: FormValues) => {
      this.props.updatePassword(this.props.account, values.oldPassword, values.newPassword)
      this.resetForm()
      this.setState({
        isSent: true,
      })
    }

    return (
      <Formik
        validateOnChange={false}
        initialValues={{
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
        }}
        validationSchema={this.validationSchema}
        onSubmit={handleSubmit}
      >
        {(formProps) => {
          const {
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            resetForm,
            touched,
          } = formProps

          this.resetForm = resetForm

          return (
            <>
              <div className={globalStyles.messageContainer}>
                <div className={globalStyles.messageHeader}>
                  <button onClick={this.props.onClose}><Icon scale='1.5' type='close' colorType='gray-50' /></button>
                  <div className='h4 gray-70'>Recover wallet from backup</div>
                </div>
                {!this.state.isSent && (
                  <div className={styles.content}>
                    <Form name='sendTokensForm' onSubmit={handleSubmit}>
                      <InputText
                        name='oldPassword'
                        type='password'
                        placeholder='Old password'
                        label='Old password'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.oldPassword && touched.oldPassword}
                        value={values.oldPassword}
                      />
                      <div
                        className={styles.resetPasswordButton}>
                        <button
                          type='button'
                          className='capture gray-50'
                          onClick={this.handleOpenResetPasswordForm}>
                          Forgot password?
                        </button>
                      </div>
                      <InputText
                        name='newPassword'
                        type='password'
                        placeholder='New password'
                        label='New password'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.newPassword && touched.newPassword}
                        value={values.newPassword}
                      />
                      <InputText
                        name='confirmPassword'
                        type='password'
                        placeholder='Confirm new password'
                        label='Confirm new password'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.confirmPassword && touched.confirmPassword}
                        value={values.confirmPassword}
                      />
                      <Button
                        disabled={!values.oldPassword || !values.newPassword || !values.confirmPassword}
                        colorType='new-border'
                        type='submit'
                      >Change</Button>
                    </Form>
                  </div>
                )}

                {this.state.isSent && this.props.loadingState === LOADING_STATE_LOADING && (
                  <LoadingState loadingState='loading' />
                )}

                {this.state.isSent && this.props.loadingState === LOADING_STATE_ERROR && (
                  <div className={globalStyles.messageContent}>
                    <Icon type='attention' colorType='red' />
                    <div className='h5 gray-30'>Failed</div>
                    <div className='capture gray-30'>Unable to change wallet password.<br />
                      Please try again. Contact support if the issue still persists.
                    </div>
                    <div className={globalStyles.messageActions}>
                      <Button
                        type='button'
                        onClick={this.props.onClose}
                        colorType='new-border'>CLOSE</Button>
                    </div>
                  </div>
                )}

                {this.state.isSent && this.props.loadingState === LOADING_STATE_LOADED && (
                  <div className={globalStyles.messageContent}>
                    <Icon type='checkmark-circle' colorType='green' />
                    <div className='h5 gray-30'>Password successfully changed</div>
                    <div className='capture gray-30'>All your funds are protected with the new password</div>
                    <div className={globalStyles.messageActions}>
                      <Button
                        type='button'
                        onClick={this.props.onClose}
                        colorType='new-border'>CLOSE</Button>
                    </div>
                  </div>
                )}
              </div>
            </>
          )
        }}
      </Formik>
    )
  }
}

function mapStateToProps (state) {
  return {
    loadingState: accountLoadingStateSelector(state),
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setSidePanel: (panelName, panelProps) => dispatch(setSidePanel(panelName, panelProps)),
    updatePassword: (account, oldPassword, newPassword) => dispatch(updatePassword(account, oldPassword, newPassword)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm)
