import * as a from './constants'
import type { AnyWallet } from './models'
import BigNumber from 'bignumber.js'

export const addAccount = (account: AnyWallet) => ({
  type: a.ACCOUNTS_ADD_ACCOUNT,
  account,
})

export const removeAccount = (accountId: string) => ({
  type: a.ACCOUNTS_REMOVE_ACCOUNT,
  accountId,
})

export const updateLoadingState = (loadingState: string) => ({
  type: a.ACCOUNTS_UPDATE_LOADING_STATE,
  loadingState,
})

export const updateAccountBalance = (accountId: string, balance: BigNumber, formattedBalance: BigNumber) => ({
  type: a.ACCOUNTS_UPDATE_BALANCE,
  accountId,
  balance,
  formattedBalance,
})

export const updateAccountBalanceError = (accountId: string) => ({
  type: a.ACCOUNTS_UPDATE_BALANCE_ERROR,
  accountId,
})

export const updateAccount = (account: AnyWallet) => ({
  type: a.ACCOUNTS_UPDATE,
  account,
})

export const increaseAccountBalance = (accountId: string, increaseBalance: BigNumber, increaseFormattedBalance: BigNumber) => ({
  type: a.ACCOUNTS_INCREASE_BALANCE,
  accountId,
  increaseBalance,
  increaseFormattedBalance,
})

export const updateSendFormSetting = (settings: any) => ({
  type: a.ACCOUNTS_UPDATE_SEND_FORM_SETTINGS,
  settings,
})
