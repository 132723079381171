import * as a from './constants'

export const updateBitcoinFeeRate = (blockchain, feeRate) => ({
  type: a.BITCOIN_UPDATE_FEE_RATE,
  blockchain,
  feeRate,
})

export const updateTransactionSendingState = (sendingState) => ({
  type: a.BITCOIN_UPDATE_TRANSACTION_STATE,
  sendingState,
})
