import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getPaymentRequests } from '../../ducks/paymentRequests/thunks'
import InvoiceCard from '../../partials/InvoiceCard'
import PaymentsTable from '../../components/PaymentsTable/PaymentsTable'
import { updateFormLoadingState } from '../../ducks/paymentRequests/actions'
import CreateAccountCard from '../../partials/CreateAccountCard'
import AccountViewCard from '../../partials/AccountViewCard'
import { setSidePanel } from '../../ducks/ui/actions'
import { sidePanelPropsSelector } from '../../ducks/ui/selectors'
import { removeAccount } from '../../ducks/accounts/actions'
import BigPopup from '../../components/BigPopup'
import { updateStep } from '../../ducks/payroll/actions'
import { PAYROLL_STEP_REQUEST_SETTINGS } from '../../ducks/payroll/constants'
import RecoverPasswordForm from '../../partials/AccountViewCard/RecoverPasswordForm'
import NotificationPopup from '../../components/NotificationPopup/NotificationPopup'
import { amplitudeSendEvent } from "../../utils/analytics"

class PaymentRequestsContainer extends React.PureComponent {
  static propTypes = {
    resetPayrollSteps: PropTypes.func,
    getPaymentRequests: PropTypes.func,
    setSidePanel: PropTypes.func,
    removeLoadingState: PropTypes.func,
    addInvoiceSidePanel: PropTypes.shape({
      isOpen: PropTypes.bool,
      requestId: PropTypes.string,
      templateRequestId: PropTypes.string,
    }),
    requestsSidePanel: PropTypes.shape({
      isOpen: PropTypes.bool,
      requestIds: PropTypes.arrayOf(PropTypes.string),
    }),
    invoiceSidePanel: PropTypes.shape({
      isOpen: PropTypes.bool,
      requestId: PropTypes.string,
    }),
    addAccountSidePanel: PropTypes.shape({
      isOpen: PropTypes.bool,
    }),
    accountSidePanel: PropTypes.shape({
      isOpen: PropTypes.bool,
      accountId: PropTypes.string,
    }),
    recoverAccountPasswordForm: PropTypes.shape({
      isOpen: PropTypes.bool,
      accountId: PropTypes.string,
    }),
    removeAccount: PropTypes.func,
  }

  openAddInvoiceCard = (requestId) => {
    this.props.setSidePanel('addInvoiceSidePanel', { isOpen: true, requestId })
  }

  openInvoiceCard = (requestId, item) => () => {
    if (item && !item.isFromMe && item.status === 'Unpaid') {
      amplitudeSendEvent('Invoice opened')
    }
    this.props.resetPayrollSteps()
    this.props.setSidePanel('invoiceSidePanel', { isOpen: true, requestId })
  }

  handleSetSidePanel = (panelName: string, props: any) => () => {
    this.props.setSidePanel(panelName, props)
  }

  handleOpenAccountViewPanel = (accountId) => {
    this.handleSetSidePanel('accountSidePanel', { 'isOpen': true, accountId })()
  }

  render () {

    return (
      <div>
        <PaymentsTable
          openInvoiceCard={this.openInvoiceCard}
          openAddInvoiceCard={this.openAddInvoiceCard}
        />

        {this.props.invoiceSidePanel.isOpen &&
          <BigPopup
            onClose={this.handleSetSidePanel('invoiceSidePanel', { isOpen: false, requestId: null })}
          >
            <InvoiceCard
              openAddInvoiceCard={this.openAddInvoiceCard}
              requestId={this.props.invoiceSidePanel.requestId}
              onClose={this.handleSetSidePanel('invoiceSidePanel', { isOpen: false, requestId: null })}
              handleCopyInvoice={this.handleCopyInvoice}
            />
          </BigPopup>
        }

        {this.props.accountSidePanel.isOpen && (
          <BigPopup onClose={this.handleSetSidePanel('accountSidePanel', { 'isOpen': false, accountId: null })}>
            <AccountViewCard
              accountId={this.props.accountSidePanel.accountId}
              removeAccountAndClose={this.props.removeAccount(this.props.accountSidePanel.accountId)}
            />
          </BigPopup>
        )}

        {this.props.addAccountSidePanel.isOpen && (
          <BigPopup onClose={this.handleSetSidePanel('addAccountSidePanel', { 'isOpen': false })}>
            <CreateAccountCard
              onOpenAccountViewPanel={this.handleOpenAccountViewPanel}
              onClose={this.handleSetSidePanel('addAccountSidePanel', { 'isOpen': false })}
            />
          </BigPopup>
        )}

        {this.props.recoverAccountPasswordForm.isOpen && (
          <BigPopup onClose={this.handleSetSidePanel('recoverAccountPasswordForm', { 'isOpen': false })} smallMode>
            <RecoverPasswordForm accountId={this.props.recoverAccountPasswordForm.accountId} />
          </BigPopup>
        )}

        <NotificationPopup />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    addInvoiceSidePanel: sidePanelPropsSelector('addInvoiceSidePanel')(state),
    invoiceSidePanel: sidePanelPropsSelector('invoiceSidePanel')(state),
    addAccountSidePanel: sidePanelPropsSelector('addAccountSidePanel')(state),
    accountSidePanel: sidePanelPropsSelector('accountSidePanel')(state),
    requestsSidePanel: sidePanelPropsSelector('requestsSidePanel')(state),
    recoverAccountPasswordForm: sidePanelPropsSelector('recoverAccountPasswordForm')(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  setSidePanel: (panelName, panelProps) => dispatch(setSidePanel(panelName, panelProps)),
  getPaymentRequests: () => dispatch(getPaymentRequests()),
  removeLoadingState: () => dispatch(updateFormLoadingState('newInvoiceForm', null)),
  removeAccount: (accountId) => () => dispatch(removeAccount(accountId)),
  resetPayrollSteps: () => dispatch(updateStep(PAYROLL_STEP_REQUEST_SETTINGS)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentRequestsContainer)
