import { BLOCKCHAIN_BITCOIN, BLOCKCHAIN_BITCOIN_TESTNET } from '../bitcoin/constants'
import { BLOCKCHAIN_ETHEREUM, BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY } from '../ethereum/constants'

export const ACCOUNTS_DUCK = 'accounts'

export const ACCOUNTS_ADD_ACCOUNT = 'accounts/ADD_ACCOUNT'
export const ACCOUNTS_REMOVE_ACCOUNT = 'accounts/ACCOUNTS_REMOVE_ACCOUNT'
export const ACCOUNTS_UPDATE = 'accounts/UPDATE'
export const ACCOUNTS_UPDATE_BALANCE = 'accounts/UPDATE_BALANCE'
export const ACCOUNTS_UPDATE_BALANCE_ERROR = 'accounts/UPDATE_BALANCE_ERROR'
export const ACCOUNTS_UPDATE_LOADING_STATE = 'accounts/UPDATE_LOADING_STATE'
export const ACCOUNTS_INCREASE_BALANCE = 'accounts/INCREASE_BALANCE'
export const ACCOUNTS_UPDATE_SEND_FORM_SETTINGS = 'accounts/UPDATE_SEND_FORM_SETTINGS'

export const ACCOUNTS_STORAGE_KEY = 'accounts'

export const ACCOUNTS_ACTIVE_BLOCKCHAINS = [
  { label: 'Bitcoin', shortLabel: 'BTC', value: BLOCKCHAIN_BITCOIN, symbol: 'BTC', isTestnet: false },
  { label: 'Bitcoin testnet', shortLabel: 'BTC testnet', value: BLOCKCHAIN_BITCOIN_TESTNET, symbol: 'BTC', isTestnet: true },
  { label: 'Ethereum', shortLabel: 'ETH', value: BLOCKCHAIN_ETHEREUM, symbol: 'ETH', isTestnet: false },
  { label: 'Ethereum (Rinkeby)', shortLabel: 'ETH RIN', value: BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY, symbol: 'ETH', isTestnet: true },
]

export const TRANSACTIONS_LIMIT = 10

export const ACCOUNT_TYPE_HOT = 'hot'
export const ACCOUNT_TYPE_COLD = 'cold'
export const ACCOUNT_TYPE_TREZOR = 'trezor'

export const TRANSACTION_ICON_NAME_PENDING = 'pending'
export const TRANSACTION_ICON_NAME_IN = 'in'
export const TRANSACTION_ICON_NAME_OUT = 'out'

export const UNKNOWN_ADDRESS = 'Unknown address'

// BigNumber format to show fiat numbers
export const FORMAT_FIAT_NUMBERS = {
  prefix: '',
  decimalSeparator: '.',
  groupSeparator: ' ',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: ' ',
  fractionGroupSize: 0,
  suffix: '',
}

// BigNumber format to show fiat numbers
export const FORMAT_RATE_NUMBERS = {
  prefix: '',
  decimalSeparator: '.',
  groupSeparator: '\'',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: ' ',
  fractionGroupSize: 0,
  suffix: '',
}

export const ACCOUNT_KEY_MAINNET = 'mainnet'
