import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import Icon from '../Icon/Icon'

import * as styles from './datepicker.module.scss'

export default class Datepicker extends React.PureComponent {
  static defaultProps = {
    fixedHeight: true,
  }

  static propTypes = {
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    minDate: PropTypes.instanceOf(Date),
    fixedHeight: PropTypes.bool,
    inline: PropTypes.bool,
    name: PropTypes.string,
    error: PropTypes.bool,
    popperModifiers: PropTypes.object,
  }

  handleChange = (dateValue) => {
    const { field, form } = this.props
    form.setFieldValue(field.name, dateValue, false)
  }

  handleBlur = (e) => {
    const { field, form } = this.props
    form.setFieldValue(field.name, moment(e.target.value, 'DD.MM.YYYY').toDate())
  }

  render () {
    const { field, form, minDate, inline, error, popperModifiers } = this.props
    const due = new Date()
    due.setDate(due.getDate() + 14)
    const value = moment(form.values[field.name]).isValid()
      ? moment(form.values[field.name]).toDate()
      : null

    return (
      <div className={styles.wrapper}>
        <DatePicker
          className={error ? 'error' : ''}
          id={field.name}
          inline={inline}
          selected={value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          dropdownMode='select'
          minDate={minDate}
          dateFormat='dd.MM.yyyy'
          popperModifiers={popperModifiers}
        />
        <label className={styles.iconWrapper} htmlFor={field.name}>
          <Icon type='calendar' colorType='gray-30' />
        </label>
      </div>
    )
  }
}
