import * as c from './constants'
import {
  CURRENCY_AUD,
  CURRENCY_EUR,
  CURRENCY_NZD,
  CURRENCY_RUB,
  CURRENCY_USD,
  CURRENCY_BTC,
  CURRENCY_ETH,
} from '../../constants'

const getInitialState = () => ({
  rates: [],
  tokens: [CURRENCY_BTC, CURRENCY_ETH, CURRENCY_USD, CURRENCY_RUB, CURRENCY_EUR, CURRENCY_NZD, CURRENCY_AUD],
  currencies: [CURRENCY_USD, CURRENCY_RUB, CURRENCY_EUR, CURRENCY_NZD, CURRENCY_AUD],
  intervalId: null,
  intervalTime: 300000,
})

const mutations = {
  [c.RATES_UPDATE_RATES]: (state, action) => {

    const newRates = []
    Object.entries(action.rates).forEach(([token, rates]) => {
      newRates.push({
        token,
        rates,
        updatedAt: new Date(),
      })
    })

    return {
      ...state,
      rates: [
        ...state.rates.filter((r) => !Object.keys(action.rates).includes(r.token)),
        ...newRates,
      ],
    }
  },
  [c.RATES_UPDATE_INTERVAL_ID]: (state, action) => {
    return {
      ...state,
      intervalId: action.intervalId,
    }
  },
}

export default (state = getInitialState(), { type, ...payload }) => {
  return (type in mutations)
    ? mutations[type](state, payload)
    : state
}
