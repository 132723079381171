// @flow
import get from 'lodash.get'
import CryptoJS from 'crypto-js'
import BigNumber from 'bignumber.js'
import config from '../../config/getConfig'
import { EthereumSubscribeService } from '../../services/EthereumSubscribeService'
import { convertToBigNumber, formatBalance, formatBlockExplorerTx, getChainOfAccount } from './utils'
import { RestService } from '../../services/RestService'
import {
  TRANSACTION_STATE_ERROR,
  TRANSACTION_STATE_SENDING,
  TRANSACTION_STATE_SENT,
} from '../../constants'

import type { AnyWallet, HotWallet } from '../accounts/models'
import type { TransactionSend, TransactionTo } from './models'
import type { Dispatch, GetState } from 'redux'
import { ACCOUNT_TYPE_TREZOR } from "../accounts/constants"
import Logger from '../../services/Logger'
import Web3 from 'web3'
import { getNetwork, getSigner, lastBlockSelector } from "./selectors"
import { updateEthereumLastBlock } from "./actions"
import {
  BLOCKCHAIN_ETHEREUM,
  BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY,
  ETHEREUM_ACCOUNT_KEY_MAINNET,
  ETHEREUM_ACCOUNT_KEY_TESTNET_RINKEBY,
} from "./constants"
import { Transaction as EthereumTx } from 'ethereumjs-tx'
import { updateTransactionFormState } from '../ui/actions'
import EthereumTrezorDevice from "../../signers/EthereumTrezorDevice"

export { formatBlockExplorerTx } from './utils'
export { ETHEREUM_DECIMALS as decimals } from './constants'

type sendTransactionType = {
  account: HotWallet,
  tx: TransactionSend,
  password: string,
  multiplier: number,
  isSendAll: boolean,
  reThrowError: boolean,
  returnValues?: boolean,
}

const getWeb3 = (network) => {
  const url = config.getConfig('urls.ethereum.' + network)
  const provider = new Web3.providers.HttpProvider(url)
  return new Web3(provider)
}

export const sendTransaction = (params: sendTransactionType) => async (dispatch: Dispatch): Promise<any | void> => {
  const { account, tx, password, isSendAll, multiplier, reThrowError, returnValues } = params
  console.log('sendTransaction ethereum multiplier', multiplier)
  try {

    dispatch(updateTransactionFormState(TRANSACTION_STATE_SENDING))
    const web3 = getWeb3(get(account, 'meta.network', null))
    const to: { address: string, value: BigNumber } = get(tx, 'to[0]', null)

    // const Contract = new web3.eth.Contract(ABI, CONTRACT_ADDRESS)

    const nonce = await web3.eth.getTransactionCount(account.address, 'pending')

    // const data = Contract.methods.createItem(name, price).encodeABI()

    let gasPrice = await web3.eth.getGasPrice()
    gasPrice = new BigNumber(gasPrice).multipliedBy(multiplier).toNumber()

    const transactionObject = {
      from: account.address,
      to: to.address,
    }
    console.log('estimateGas', transactionObject)
    const gasLimit = await web3.eth.estimateGas(transactionObject) // estimate the gas limit for this transaction

    const chain = getChainOfAccount(account)
    const rawTx = new EthereumTx({
      nonce: web3.utils.toHex(nonce),
      gasPrice: web3.utils.toHex(gasPrice),
      gasLimit: web3.utils.toHex(gasLimit),
      to: to.address,
      value: web3.utils.toHex(new BigNumber(to.value).toString()),
      data: null,
    }, { chain })

    const mnemonic = account.encryptedKey ? CryptoJS.AES.decrypt(account.encryptedKey, password) : null
    const signer = getSigner(mnemonic ? CryptoJS.enc.Utf8.stringify(mnemonic) : null)
    const signedTransaction = await signer.signTransaction(rawTx)
    const raw = '0x' + signedTransaction.serialize().toString('hex')
    const result = await web3.eth.sendSignedTransaction(raw)

    if (result.transactionHash) {
      dispatch(updateTransactionFormState(TRANSACTION_STATE_SENT))
      if (returnValues) {
        return {
          hash: result.transactionHash,
          value: to.value,
          fee: gasLimit * gasPrice,
        }
      }
      return result.transactionHash
    } else {
      throw new Error('Transaction failed')
    }
  } catch (e) {
    Logger.error(e, { account: { ...account, encryptedKey: null }, tx, isSendAll, reThrowError, returnValues })
    dispatch(updateTransactionFormState(TRANSACTION_STATE_ERROR))
    if (reThrowError) throw new Error(e)
  }
}

export const signTransaction = ({ account, tx, password, multiplier }: sendTransactionType) => async (/*dispatch: Dispatch*/): Promise<any | void> => {
  console.log('signTransaction ethereum multiplier', multiplier)
  const to = get(tx, 'to[0]', null)
  if ([ACCOUNT_TYPE_TREZOR].includes(account.type)) {
    const network = getNetwork(account.blockchain)
    const web3 = getWeb3(network)
    const signer = new EthereumTrezorDevice()
    const nonce = await web3.eth.getTransactionCount(account.address, 'pending')
    let gasPrice = await web3.eth.getGasPrice()
    gasPrice = new BigNumber(gasPrice).multipliedBy(multiplier).toNumber()
    const transactionObject = {
      from: account.address,
      to: to.address,
    }
    console.log('estimateGas', transactionObject)

    const gasLimit = await web3.eth.estimateGas(transactionObject) // estimate the gas limit for this transaction

    const chain = getChainOfAccount(account)
    const tx = {
      nonce: web3.utils.toHex(nonce),
      gasPrice: web3.utils.toHex(gasPrice),
      gasLimit: web3.utils.toHex(gasLimit),
      to: to.address,
      value: web3.utils.toHex(to.value),
      data: '',
    }

    const { payload, success } = await signer.composeTransaction(tx, chain)

    if (!success) {
      throw new Error(payload.error)
    }
    const rawTx = new EthereumTx({
      ...tx,
      data: null,
      v: payload.v,
      r: payload.r,
      s: payload.s,
    }, { chain })

    return {
      tx: '0x' + rawTx.serialize().toString('hex'),
      fee: gasPrice * multiplier * gasLimit,
      value: to.value,
    }
  } else {
    const web3 = getWeb3(get(account, 'meta.network', null))
    // const Contract = new web3.eth.Contract(ABI, CONTRACT_ADDRESS)
    const nonce = await web3.eth.getTransactionCount(account.address, 'pending')
    // const data = Contract.methods.createItem(name, price).encodeABI()
    let gasPrice = await web3.eth.getGasPrice()

    gasPrice = new BigNumber(gasPrice).multipliedBy(multiplier).toNumber()

    const transactionObject = {
      from: account.address,
      to: to.address,
    }

    console.log('estimateGas', transactionObject)
    const gasLimit = await web3.eth.estimateGas(transactionObject) // estimate the gas limit for this transaction

    const chain = getChainOfAccount(account)
    const rawTx = new EthereumTx({
      nonce: web3.utils.toHex(nonce),
      gasPrice: web3.utils.toHex(gasPrice),
      gasLimit: web3.utils.toHex(gasLimit),
      to: to.address,
      value: web3.utils.toHex(to.value.toString()),
      data: null,
    }, { chain })

    const mnemonic = account.encryptedKey ? CryptoJS.AES.decrypt(account.encryptedKey, password) : null
    const signer = getSigner(mnemonic ? CryptoJS.enc.Utf8.stringify(mnemonic) : null)
    const signedTransaction = await signer.signTransaction(rawTx)

    const raw = '0x' + signedTransaction.serialize().toString('hex')

    return {
      tx: raw,
      fee: gasPrice * multiplier * gasLimit,
      value: to.value,
    }
  }
}

export const calculateFee = (account: AnyWallet, to: Array<TransactionTo> | string, multipliers: Array<string | number>, isSendAll: boolean) => async () => {
  try {
    const web3 = getWeb3(get(account, 'meta.network', null))
    if (!web3) {
      Logger.error(new Error('Wrong web3 instance'), { account: { account, 'encryptedKey': null } })
      return null
    }

    const gasPrice = await web3.eth.getGasPrice()
    let toFormatted = Array.isArray(to) ? get(to, '[0].address') : to
    if (!toFormatted) {
      toFormatted = account.address
    }
    const transactionObject = {
      from: account.address,
      to: toFormatted,
    }
    console.log('estimateGas', transactionObject)
    const gasLimit = await web3.eth.estimateGas(transactionObject) // estimate the gas limit for this transaction
    const rateResult = []
    multipliers.forEach((multiplier: string | number) => {
      rateResult.push(convertToBigNumber(gasPrice * parseFloat(multiplier) * gasLimit))
    })

    return rateResult

  } catch (e) {
    Logger.error(e, { from: account.address, to, blockchain: account.blockchain, multipliers, isSendAll })
    throw e
  }
}

export const getUtxoByAddress = async (address: string, blockchain: string) => {
  try {
    const { data } = await RestService.request('insightService::getUtxo', blockchain, address)
    return data
  } catch (e) {
    Logger.error(e, { address, blockchain })
    throw e
  }
}

export const getAddressTransactions = async (account: AnyWallet, prevLastBlock: number, limit: number) => {
  const address = account.address.toLocaleLowerCase()
  const web3 = getWeb3(get(account, 'meta.network', null))
  if (prevLastBlock === 0) {
    prevLastBlock = await web3.eth.getBlockNumber()
  }

  const finishBlockNumber = prevLastBlock - 100 > 1 ? prevLastBlock - 100 : 1
  let items = []
  const promises = []

  const blockCallback = (blockNumber) => (resolve) => {
    web3.eth.getBlock(blockNumber, true)
      .then((block) => {
        if (block != null && block.transactions != null) {
          block.transactions.forEach(function (e) {
            if ((e.from && address === e.from.toLocaleLowerCase()) || (e.to && address === e.to.toLocaleLowerCase())) {
              items.push(formatBlockExplorerTx({
                ...e,
                blockTime: block.timestamp,
              }, address))
            }
          })
        }
        resolve()
      })
  }
  if (prevLastBlock && finishBlockNumber) {
    for (let block = prevLastBlock; block >= finishBlockNumber; block--) {
      promises.push(new Promise(blockCallback(block)))
    }
  }
  await Promise.all(promises)

  items = items
    .sort((a: { block: number | null }, b: { block: number | null }) => {
      if (a.block && b.block && a.block > b.block) return -1
      if (a.block && b.block && b.block > a.block) return 1
      return 0
    })
    .slice(0, limit)

  const lastItem = items[items.length - 1]

  return {
    items: items
      .reduce((acc, item) => {
        acc[item.txId] = item
        return acc
      }, {}),
    hasMore: lastItem ? (lastItem.block || 0) > 1 : finishBlockNumber > 1,
    lastBlock: lastItem ? lastItem.block : finishBlockNumber,
  }
}

export const getBalance = async (account: AnyWallet): Promise<any> => {
  try {
    const web3 = getWeb3(get(account, 'meta.network', null))
    const balance = await web3.eth.getBalance(account.address)
    return formatBalance(balance)
  } catch (e) {
    return { error: e }
  }
}

export const subscribeAccount = (account: AnyWallet, subscribeTxCallback: (data: any)=>Promise<any>, subscribeConfirmationCallback?: (data: any)=> void) => () => {
  EthereumSubscribeService.addSubscription(EthereumSubscribeService.getSubscriptionKey({
    address: account.address,
    meta: {
      network: account.meta.network,
    },
  }), () => {
    EthereumSubscribeService.subscribeAccount(account, subscribeTxCallback, subscribeConfirmationCallback)
  })
}

export const getEthereumLastBlocks = () => async (dispatch: Dispatch, getState: GetState) => {
  const promises = [];
  [
    {
      blockchain: BLOCKCHAIN_ETHEREUM,
      network: ETHEREUM_ACCOUNT_KEY_MAINNET,
    },
    {
      blockchain: BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY,
      network: ETHEREUM_ACCOUNT_KEY_TESTNET_RINKEBY,
    },
  ].forEach(({ blockchain, network }) => {
    promises.push(new Promise((resolve, reject) => {
      const web3 = getWeb3(network)
      const lastBlock = lastBlockSelector(blockchain)(getState())
      web3.eth.getBlock('latest')
        .then((block) => {
          if (!lastBlock || block.number > lastBlock.number) {
            dispatch(updateEthereumLastBlock(blockchain, block))
          }
          resolve()
        }, (e) => {
          reject(e)
        })
    }))
  })
  await Promise.all(promises)
}

export const subscribeToLastBlock = () => (dispatch: Dispatch, getState: GetState) => {
  EthereumSubscribeService.subscribeToLastBlock(({ blockchain, block }) => {
    const lastBlock = lastBlockSelector(blockchain)(getState())
    if (!lastBlock || block.number > lastBlock.number) {
      dispatch(updateEthereumLastBlock(blockchain, block))
    }
  })
}

export const getTrezorWalletAddress = async (blockchain: string) => {
  try {
    const signer = new EthereumTrezorDevice()

    const { success, payload: accountInfo } = await signer.getAccount()
    if (!success) {
      throw new Error(accountInfo.error)
    }

    return accountInfo.address

  } catch (e) {
    Logger.error(e, { blockchain })
  }
}
