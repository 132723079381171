import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Icon from '../Icon/Icon'

import * as styles from './index.module.scss'

export default class InputText extends React.PureComponent {
  static propTypes = {
    ref: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    additionalType: PropTypes.string,
    placeholder: PropTypes.string,
    postContent: PropTypes.string,
    preContent: PropTypes.string,
    postPadding: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onKeyPress: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    textarea: PropTypes.bool,
    label: PropTypes.string,
    autoFocus: PropTypes.bool,
    error: PropTypes.bool,
    errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    errorWithIcon: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    rows: PropTypes.number,
    cols: PropTypes.number,
    'data-selenium-id': PropTypes.string,
    decriseFontOptions: PropTypes.shape({
      min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    errorColor: PropTypes.string,
  }

  static defaultProps = {
    type: 'text',
    placeholder: '',
    additionalType: null,
    errorColor: 'default',
  }

  constructor (props) {
    super(props)
    this.state = {
      type: props.type,
    }
  }

  handleShowPassword = () => {
    const types = {
      'password': 'text',
      'text': 'password',
    }
    this.setState((prevState) => ({ type: types[prevState.type] || 'text' }))
  }

  handleChange = (e) => {
    const input = e.target
    if (this.props.decriseFontOptions) {
      const { min, max } = this.props.decriseFontOptions
      input.style.fontSize = ''
      if (input.value.length > min) {
        const currentFontSize = window.getComputedStyle(input, null).getPropertyValue('font-size')
        const minus = input.value.length < max ? input.value.length - min : max - min
        input.style.fontSize = (parseInt(currentFontSize) - minus) + 'px'
      }
    }
    this.props.onChange(e)
  }

  render () {

    const inputClassName = this.props.textarea ? [styles.textarea] : [styles.styledInput]
    if (this.props.error) {
      inputClassName.push(`error-${this.props.errorColor}`)
    }
    if (this.props.type === 'password' || this.props.postPadding === '60px') {
      inputClassName.push(styles.paddingRight60)
    }

    return (
      <div className={styles.container}>
        <div className={styles.inputContainer + ' ' + cn(this.props.className)}>
          {this.props.additionalType === 'datepicker' && <div className={styles.datepickerIcon} />}
          {this.props.label ? <label>{this.props.label}</label> : ''}
          {this.props.textarea
            ? (
              <textarea
                id={'inputId_' + this.props.name}
                rows={this.props.rows || '4'}
                cols={this.props.cols || '45'}
                onKeyPress={this.props.onKeyPress}
                className={inputClassName.join(' ')}
                name={this.props.name}
                placeholder={this.props.placeholder}
                value={this.props.value}
                disabled={this.props.disabled}
                autoFocus={this.props.autoFocus}
                onChange={this.props.onChange}
                onFocus={this.props.onFocus}
                onBlur={this.props.onBlur}
              />
            ) : (
              <div className={styles.inputWrapper}>
                {this.props.preContent ? <p className={styles.preParagraph}>{this.props.preContent}</p> : ''}
                <input
                  id={'inputId_' + this.props.name}
                  onKeyPress={this.props.onKeyPress}
                  className={inputClassName.join(' ')}
                  type={this.state.type}
                  name={this.props.name}
                  placeholder={this.props.placeholder}
                  value={this.props.value}
                  disabled={this.props.disabled}
                  onChange={this.handleChange}
                  onFocus={this.props.onFocus}
                  onBlur={this.props.onBlur}
                  autoFocus={this.props.autoFocus}
                  data-selenium-id={this.props['data-selenium-id']}
                  onClick={this.props.onClick}
                />
                {this.props.postContent ? <p className={styles.postParagraph}>{this.props.postContent}</p> : ''}
                {this.props.type === 'password' ? <div className={styles.eye} onClick={this.handleShowPassword}>
                  <Icon type={this.state.type === 'text' ? 'eye' : 'eye-slash'} colorType='gray-30' />
                </div> : ''}
              </div>
            )
          }
          {this.props.errorText ? <div className={styles.errorText + ' capture red'}>
            {this.props.errorWithIcon && <Icon type='attention' colorType='red' />}
            {this.props.errorText}
          </div> : ''}
        </div>
      </div>
    )
  }
}
