import { connect } from 'react-redux'
import QRCode from 'qrcode'
import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import CopyButton from '../../components/Button/CopyButton'
import Icon from '../../components/Icon/Icon'
import { setSidePanel } from '../../ducks/ui/actions'

class Receive extends React.PureComponent {

  static propTypes = {
    account: PropTypes.object,
    handleOpenPaymentSidePanel: PropTypes.func,
  }

  constructor (props) {
    super(props)
    this.state = {
      QRCode: null,
    }
  }


  componentDidMount () {
    this.getQRCode(this.props)
  }

  componentDidUpdate () {
    this.getQRCode(this.props)
  }

  getQRCode = (props) => {

    if (props.account && (props.account.address || props.account.unusedAddresses[0])) {
      QRCode.toDataURL(props.account.address || props.account.unusedAddresses[0], { errorCorrectionLevel: 'H' })
        .then((url) => {
          this.setState({
            QRCode: url,
          })
        })
    }
  }

  render () {
    const { account } = this.props
    const { QRCode } = this.state

    let blockchain = account.blockchain
    blockchain = blockchain.charAt(0).toUpperCase() + blockchain.substring(1).replace('_', ' ')
    const address = account.address || account.unusedAddresses[0]
    return (
      <div className={styles.receiveContainer}>
        <div className={styles.tabTitle + ' h4 gray-70'}>Receive</div>
        <div className={styles.receiveContent}>
          <div className='capture gray-50'>Your {blockchain} wallet address:</div>
          <div className={styles.receiveAddress}>
            <span className='capture gray-70'>{address}</span>
            <CopyButton copyString={address} />
          </div>
          <div className={styles.receiveQRCodeWrapper}>
            {QRCode && <img src={QRCode} alt={address} />}
          </div>
          <button
            onClick={this.props.handleOpenPaymentSidePanel(account.id)}
            className={styles.sendEmailWrapper}
          >
            <div className={styles.sendEmailGrid}>
              <Icon type='mail' colorType='blue' />
              <div className='capture blue-bk'>Send payment request to email</div>
            </div>
          </button>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  handleOpenPaymentSidePanel: (accountId) => () => dispatch(setSidePanel('addPaymentRequestSidePanel',
    {
      isOpen: true,
      accountId,
    })),
})

export default connect(null, mapDispatchToProps)(Receive)
