import { TYPE_AMOUNT, TYPE_QUANTITY, TYPE_HOURS } from '../constants'
import BigNumber from 'bignumber.js'
import { getBase64 } from '../../../ducks/paymentRequests/utils'
import get from 'lodash.get'

export function handleClickEnter (formikProps, key) {
  return (e) => {
    if ((e.keyCode || e.which) === 13) { //13 is the enter keycode
      e.preventDefault()
      e.stopPropagation()
      const nextKey = key + 1
      if (formikProps.values.items[nextKey]) {
        // set focus for next item
        document.getElementById(`inputId_items[${nextKey}].description`).focus()
      } else {
        this.handlers.handleAddItem(formikProps)(e)
        setTimeout(() => {
          // set focus for new item
          document.getElementById(`inputId_items[${nextKey}].description`).focus()
        }, 100)
      }
    }
  }
}

export function handleAddItem (formikProps) {
  return () => {
    const { values, setFieldValue } = formikProps

    const newItem = this.props.emptyItems[values.itemsType]
      .reduce((acc, fieldName) => {
        return {
          ...acc,
          [fieldName]: '',
        }
      }, {})
    setFieldValue('items', [
      ...values.items,
      newItem,
    ])
  }
}

export function handleRemoveItem (formikProps, itemKey) {
  return (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { setFieldValue, values } = formikProps
    if (values.items.length <= 1) {
      setFieldValue('items', values.items.map(() => {
        return this.props.emptyItems[values.itemsType]
          .reduce((acc, fieldName) => {
            return {
              ...acc,
              [fieldName]: '',
            }
          }, {})
      }), true)
    } else {
      setFieldValue('items', values.items.filter((item, key) => {
        return key !== itemKey
      }), true)
    }
  }
}

export function handleChangeItemsType (formikProps) {
  return ({ value }) => {
    const { values, setFieldValue } = formikProps
    setFieldValue('itemsType', value)
    setFieldValue('items', values.items.map((item) => {
      switch (value) {
        case TYPE_AMOUNT: {
          const amount = new BigNumber(item.rate).multipliedBy(item.count)
          return {
            description: item.description,
            amount: amount.isNaN() ? 1 : amount.toFixed(2),
          }
        }
        case TYPE_QUANTITY:
        case TYPE_HOURS:
          return {
            description: item.description,
            count: item.count || 1,
            rate: item.rate || item.amount || '1.00',
          }
        default:
          return null
      }
    }))
  }
}

export function handleAddLogo (formikProps) {
  return (fileOrFiles) => {
    const file = fileOrFiles[0] || fileOrFiles

    const { setFieldValue } = formikProps

    file.id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10)
    file.isLoading = true

    setFieldValue('logo', file)
    new Promise((resolve) => {
      if (file && file.data) {
        resolve({
          'id': file.id,
          'name': file.name,
          'extension': file.extension || file.type || '.' + file.name.split('.').pop(),
          'data': file.data,
        })
      } else {
        getBase64(file)
          .then((fileBase64) => {
            resolve({
              'id': file.id,
              'name': file.name,
              'extension': file.extension || file.type || '.' + file.name.split('.').pop(),
              'data': fileBase64,
            })

          }, () => {
            file.isLoading = false
            file.isErrored = true
            resolve(file)
          })
      }
    })
      .then((file) => {
        if (file.isErrored) {
          return file
        }
        setFieldValue('logo', {
          'name': file.name,
          'extension': file.extension,
          'data': file.data,
        })
      })
  }
}

export function handleAddFile (formikProps) {
  return (files) => {
    const { setFieldValue, values } = formikProps
    //eslint-disable-next-line
    console.log('handleAddFile files: ', files)

    const fileList = []
    const promisesList = []
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      file.id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10)
      file.isLoading = true
      fileList.push(file)
      promisesList.push(new Promise((resolve) => {
        if (file && file.data) {
          resolve({
            'id': file.id,
            'name': file.name,
            'extension': file.extension || file.type || '.' + file.name.split('.').pop(),
            'data': file.data,
          })
        } else {
          getBase64(file)
            .then((fileBase64) => {
              resolve({
                'id': file.id,
                'name': file.name,
                'extension': file.extension || file.type || '.' + file.name.split('.').pop(),
                'data': fileBase64,
              })
            }, () => {
              file.isLoading = false
              file.isErrored = true
              resolve(file)

            })
        }
      }))
    }
    let resList = [
      ...values.attachments,
      ...fileList,
    ]
    setFieldValue('attachments', resList)
    promisesList.forEach((promise) => {
      promise
        .then((file) => {
          resList = resList.map((loadedFile) => {
            if (loadedFile.id === file.id) {
              if (file.isErrored) {
                return file
              }
              return {
                'name': file.name,
                'extension': file.extension,
                'data': file.data,
              }
            }
            return loadedFile
          })
          setFieldValue('attachments', resList)
        })
    })
  }
}

export function handleToggleAdditionalFields (formikProps) {
  return (field, value) => () => {
    if (value) {
      formikProps.setFieldValue(field, '')
    } else {
      if (field === 'billFrom') { // uses only for fromDescription in profile
        const valueFromRequest = get(this, 'props.request.invoice.billFrom', '')
        const valueFromProfile = get(this, 'props.profile.fromDescription', '')
        formikProps.setFieldValue(field, valueFromRequest || valueFromProfile)
      }
      if (field === 'subtitle') { // uses only for fromDescription in profile
        const newValue = get(this, 'props.request.invoice.title', '')
        formikProps.setFieldValue(field, newValue)
      }
    }

    this.setState((oldState) => ({
      showedFields: {
        ...oldState.showedFields,
        [field]: !value,
      },
    }))
  }
}

export function handleSetTemplate (formikProps) {
  return (request) => {
    const newValues = this.props.getInitialValues(request)
    const due = new Date()
    due.setDate(due.getDate() + 14)
    formikProps.setValues({
      ...newValues,
      due,
    })
    this.setState({
      showedFields: {
        billFrom: !!get(newValues, 'billFrom', false),
        subtitle: !!get(newValues, 'subtitle', false),
      },
    })
    this.handlers.handleGetRequestLogo(formikProps, request)
  }
}

export function handleToggleDeletePopup () {
  this.setState((prevState) => {
    return {
      isDeletePopupOpen: !prevState.isDeletePopupOpen,
    }
  })
}

export function handleGetRequestLogo (formikProps, request) {
  if (get(request, 'invoice.logo.attachmentId', false)) {
    formikProps.setFieldValue('logo', { isLoading: true })
    this.props.getRequestLogo(request.requestId)
      .then((res) => {
        const logo = { ...res }
        delete logo.attachmentId
        formikProps.setFieldValue('logo', { ...logo })
      })
  }
}

export function handleSetTrezorWallet (formikProps) {
  return (walletData) => {
    if (!walletData.blockchain) {
      return null
    }
    const oldData = get(this, `state.trezorAccounts.${walletData.blockchain}`, {})
    const wallet = {
      ...oldData,
      ...walletData,
    }
    this.setState({
      trezorAccounts: {
        ...this.state.trezorAccounts,
        [walletData.blockchain]: wallet,
      },
    })
    if (wallet.address) {
      const { setFieldValue, setTouched, touched } = formikProps
      setFieldValue('account', wallet)
      setTouched({
        ...(touched || {}),
        account: true,
      })
    }
  }
}
