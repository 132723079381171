//@flow

import AbstractRESTService from './AbstractRESTService'

const URL_TEAMS_MY = '/teams/my'
const URL_TEAMS = '/teams'
const URL_TEAM_MEMBERS = '/team-members'
const URL_TEAM_MEMBERS_ADD = '/team-members/add'
const URL_TEAM_MEMBERS_ACTIVATE = '/team-members/activate'

export default class TeamService extends AbstractRESTService {

  /**
   * Getting contact list. You can get input param via ...params
   */
  getMyTeams (withMembers: boolean = true) {
    const withMembersString = withMembers ? 'true' : 'false'
    return this.restService.request({
      method: 'GET',
      url: URL_TEAMS_MY + '?members=' + withMembersString,
      headers: this.getBearerHeaders(),
    })
  }

  /**
   * Getting team members
   */
  getTeamMembers (teamId: string, limit: string, offset: string) {
    return this.restService.request({
      method: 'GET',
      url: URL_TEAM_MEMBERS + `?limit=${limit}&offset=${offset}&team-id=${teamId}`,
      headers: this.getBearerHeaders(),
    })
  }

  /**
   * Create team
   */
  createTeam (payload: {}) {
    return this.restService.request({
      method: 'POST',
      url: URL_TEAMS,
      headers: this.getBearerHeaders(),
      data: { payload },
    })
  }

  /**
   * Delete team
   */
  deleteTeam (teamId: string) {
    return this.restService.request({
      method: 'DELETE',
      url: URL_TEAMS,
      headers: this.getBearerHeaders(),
      data: { teamId },
    })
  }

  /**
   * Create new team member
   */
  addNewMember (payload: {}) {
    return this.restService.request({
      method: 'POST',
      url: URL_TEAM_MEMBERS_ADD,
      headers: this.getBearerHeaders(),
      data: { payload },
    })
  }

  /**
   * Remove team member
   */
  removeMember (payload: any) {
    return this.restService.request({
      method: 'DELETE',
      url: URL_TEAM_MEMBERS,
      headers: this.getBearerHeaders(),
      data: { payload },
    })
  }

  /**
   * Update team member
   */
  updateMember (memberId: string, payload: {}) {
    return this.restService.request({
      method: 'PUT',
      url: URL_TEAM_MEMBERS,
      headers: this.getBearerHeaders(),
      data: { memberId, payload },
    })
  }

  /**
   * Update team member
   */
  activateMember (payload: {}) {
    return this.restService.request({
      method: 'POST',
      url: URL_TEAM_MEMBERS_ACTIVATE,
      headers: this.getBearerHeaders(),
      data: { payload },
    })
  }
}
