import { createStore, applyMiddleware, compose } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import { createLogger } from 'redux-logger'
import appReducer from './ducks'
import { SESSION_DESTROY } from './constants'
import Logger from './services/Logger'

export const history = createBrowserHistory()

const initialState = {}
const enhancers = []

const logger = createLogger({
  collapsed: true,
  predicate: (getState, action) => action.type !== 'profile/updateLastActivity',
})

Logger.init()

const middleware = [
  thunk,
  routerMiddleware(history),
]
if (process.env.NODE_ENV === 'development') {
  middleware.push(logger)
}

const rootReducer = (state, action) => {

  if (action.type === SESSION_DESTROY) {
    state = {}
  }
  return appReducer(state, action)
}

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers,
)

export const store = createStore(connectRouter(history)(rootReducer), initialState, composedEnhancers)
