// @flow
import get from 'lodash.get'
import { deleteCookie, getCookie, setCookie } from '../utils/auth'
import { history, store } from '../store'
import servicesConfig from './config'
import { AbstractRestService } from './AbstractRESTService'
import { isResponseValid } from '../utils/general'
import { InsightSocketService } from './InsightSocketService'
import Logger from './Logger'

const TIME_TO_REFRESH_TOKEN = 5 * 60 * 1000 // 5 min

class RestServiceClass {
  authTokenValue: string | null
  refreshTokenValue: string | null
  //eslint-disable-next-line
  refreshTimeoutId: TimeoutID | null
  instances: { [name: string]: AbstractRestService }

  constructor () {
    this.authTokenValue = null
    this.refreshTokenValue = null
    this.refreshTimeoutId = null
    this.instances = {}

    this.initialization()
  }

  get authToken () {
    const authToken = getCookie('authToken')
    if (authToken) {
      this.authTokenValue = authToken
    }
    return this.authTokenValue
  }

  set authToken (token: string) {
    setCookie('authToken', token, { expires: 60 * 60 }) // one hour
    return token
  }

  get refreshToken () {
    const refreshToken = getCookie('refreshToken')
    if (refreshToken) {
      this.refreshTokenValue = refreshToken
    }
    return this.refreshTokenValue
  }

  erroredLogout = () => {
    store.dispatch({ type: 'session/DESTROY' })
    this.deleteAuthToken()
    this.deleteRefreshToken()
    InsightSocketService.disconnect(true)
    history.push('/auth')
  }

  set refreshToken (token: string | null) {
    setCookie('refreshToken', token, { expires: 60 * 60 }) // one hour
    this.refreshTokenValue = token
    return token
  }

  handleRefreshToken () {
    this.refreshTimeoutId = setTimeout(() => {
      this.request('authService::refreshToken', this.refreshToken)
        .then((resp) => {
          const status = get(resp, 'data.status', null)
          if (isResponseValid(status)) {
            const token = get(resp, 'data.result.token', null)
            const refreshToken = get(resp, 'data.result.refreshToken', null)
            RestService.authToken = token
            RestService.refreshToken = refreshToken

            InsightSocketService.disconnect()
            InsightSocketService.connect(token)

            this.handleRefreshToken()
          } else {
            this.erroredLogout()
          }
        })
        .catch(() => {
          this.erroredLogout()
        })
    }, TIME_TO_REFRESH_TOKEN)
  }

  isAuthenticated () {
    return !!this.authToken
  }

  getAuthToken () {
    return this.authToken
  }

  /**
   * List of services are going to be used. @see request method
   */
  initialization () {
    servicesConfig.forEach((service) => {
      try {
        this.instances[service.name] = new service.class({
          restOptions: { baseURL: service.baseURL, ...(service.options || {}) },
          getAuthToken: this.getAuthToken.bind(this),
        })
      } catch (e) {
        Logger.error(e)
      }
    })
  }

  deleteAuthToken = () => {
    this.authTokenValue = null
    deleteCookie('authToken')
  }

  deleteRefreshToken = () => {
    this.refreshToken = null
    clearTimeout(this.refreshTimeoutId)
    this.refreshTimeoutId = null

    deleteCookie('refreshToken')
  }

  /**
   * To make any rest request to our services use this method
   * @param serviceMethod example: contactService::getContactList
   * @param params rest of params that will be provided to service method
   */
  async request (serviceMethod: string, ...params: Object): Promise<any> {
    const attemptLimit = 3
    let attempt = 0
    const executeRequest = async () => {
      try {
        attempt++
        const [service, method] = serviceMethod.split('::')
        if (!service || !method || !this.instances[service][method]) {
          throw new Error('Can\'t find service or method. Wrong serviceMethod parameter?')
        }

        return await this.instances[service][method](...params)
      } catch (e) {
        // if this is an authentication error, we need to update a token. It's convenient to catch all errors in one place
        // do some stuff to update token
        const error = get(e, 'response', {})
        if ((error.status === 500 && typeof error.data.error === 'string')) {
          const [status] = error.data.error.split(' - ')
          if (parseInt(status) === 400) {
            this.erroredLogout()
          }
        }
        if (error.status === 401) {
          this.erroredLogout()
        }
        // Api overload error. Trying to execute in a second
        if (error.status === 429 && attempt < attemptLimit) {
          await new Promise((resolve) => {
            setTimeout(() => resolve(), 1000)
          })
          return executeRequest()
        }
        throw e
      }
    }

    return executeRequest()
  }
}

export const RestService = new RestServiceClass()
