
export const ERROR_UNKNOWN_DESCRIPTION = 'Unknown error. Try again later'

export const ERROR_CODE_NO_ACCOUNTS = 5001

const codeDescriptions = {
  [ERROR_CODE_NO_ACCOUNTS]: 'You must create at least one account first',
}

export default class PxError extends Error {
  constructor (message, code) {
    super(message)
    this.code = code
  }

  getErrorDescription = () => {
    return codeDescriptions[this.code] ? codeDescriptions[this.code] : 'Unknown error'
  }
}
