// @flow
import get from 'lodash.get'
import * as a from './actions'
import { ProfileModel } from '../../models/Contacts/ProfileModel'
import { RestService } from '../../services/RestService'
import {
  LOADING_STATE_LOADED,
  LOADING_STATE_LOADING,
  LOADING_STATE_ERROR,
  CURRENCY_USD,
} from '../../constants'
import config from '../../config/getConfig'
import { convertProfileValuesToRequestInfo } from './utils'
import {
  profileInfoSelector,
  lastActivitySelector,
  showLastActivityPopupSelector,
  getActivityTimerIdsSelector,
} from './selectors'
import { Dispatch } from 'redux'
import AuthError from '../../errors/AuthError'
import { isResponseValid } from '../../utils/general'
import { getUsersHint, loadAndUpdateRequest, updateRequestLocal } from '../paymentRequests/thunks'
import { PAYMENT_REQUEST_STATUS_CANCELLED } from '../paymentRequests/constants'
import { InsightSocketService } from '../../services/InsightSocketService'
import { setDefaultAccounts } from '../accounts/thunks'
import type { defaultAccountType } from '../accounts/thunks'

import type { getStateType } from '../accounts/models'

export const getProfile = () => async (dispatch: Dispatch): Promise<any> => {
  try {
    dispatch(a.updateProfileStatus(LOADING_STATE_LOADING))
    const { data } = await RestService.request('profileService::getProfile')
    const isConfirmed = get(data, 'resuslt.profile.isConfirmed', false)
    if (isConfirmed) {
      throw new AuthError('Not confirmed e-mail', 401)
    }
    const profileData = data.result
    profileData.id = profileData.profileId
    const profile = new ProfileModel({ ...profileData, primaryAsset: profileData.primaryAsset || CURRENCY_USD })
    dispatch(a.updateProfile(profile))
    dispatch(a.updateProfileStatus(LOADING_STATE_LOADED, undefined, undefined))
    return profileData
  } catch (e) {
    const errorStatus = get(e, 'code', 400)
    const message = get(e, 'message', 'Unknown error')
    dispatch(a.updateProfileStatus(LOADING_STATE_ERROR, message, errorStatus))
    throw e
  }
}

export const userUploadImageRequest = (image: { name: string }) => async (resolve: (any) => void) => {
  try {
    const formData = new FormData()
    // $flow-disable-line
    formData.append('image', image, image.name)
    const resp = await RestService.request('mediaService::uploadImage', formData)
    const status = get(resp, 'data.status', null)
    const { id, url } = get(resp, 'data.result', {})

    if (status === 200) {
      resolve({
        id,
        url,
      })
    }
    resolve(false)
  } catch (e) {
    resolve(false)
  }
}

export type updateProfileValuesType = {
  primaryAsset: string,
  firstName: string,
  lastName: string,
  email: string,
  name: string,
  avatar: any,
  receivePdf: boolean,
  loadedAvatar: any,
  fromDescription: string,
  defaultAccounts: Array<defaultAccountType>
}

export const updateProfile = (values: updateProfileValuesType) => async (dispatch: Dispatch, getState: getStateType) => {
  try {
    dispatch(a.updateProfileStatus(LOADING_STATE_LOADING))
    const oldProfileInfo = profileInfoSelector(getState())

    const oldEmail = get(oldProfileInfo, 'email', null)
    const isEmailChanged = !!values.email && oldEmail && oldEmail !== values.email

    if (isEmailChanged) {
      // need to check an email in profile service
      try {
        const { data } = await RestService.request('authService::isContactExists', values.email)
        const { result, status } = data
        if (status === 200 && result.exist) {
          // return an error
          dispatch(a.updateProfileStatus(LOADING_STATE_ERROR, 'Wrong email.', 'error'))
          return
        }
      } catch (e) {
        // return an error too
        dispatch(a.updateProfileStatus(LOADING_STATE_ERROR, 'Something went wrong.', 'error'))
        return
      }
    }

    if (values.avatar === null) {
      values.loadedAvatar = null
    } else if (values.avatar) {
      values.loadedAvatar = await new Promise(userUploadImageRequest(values.avatar))
    } else {
      values.loadedAvatar = oldProfileInfo.avatar
    }

    const info = convertProfileValuesToRequestInfo(values)
    const { data } = await RestService.request('profileService::updateProfile', info)

    if (!isResponseValid(data.status)) {
      dispatch(a.updateProfileStatus(LOADING_STATE_ERROR, data.error, data.status))
      return
    }

    const profileData = data.result
    profileData.id = profileData.profileId
    const profile = new ProfileModel({ ...profileData, primaryAsset: profileData.primaryAsset || CURRENCY_USD })
    dispatch(a.updateProfile(profile))

    /**
     * update default accounts
     */
    await dispatch(setDefaultAccounts(values.defaultAccounts))

    dispatch(a.updateProfileStatus(LOADING_STATE_LOADED, null, 200))
  } catch (e) {
    const error = get(e, 'response.data', { error: 'Something went wrong.', status: 'error' })
    dispatch(a.updateProfileStatus(LOADING_STATE_ERROR, error.error, error.status))
  }
}

export const updatePassword = (values: { oldPassword: string, password: string, }) => async (dispatch: Dispatch) => {
  try {
    dispatch(a.updatePasswordStatus(LOADING_STATE_LOADING))
    const { data } = await RestService.request('authService::updatePassword', values.oldPassword, values.password)
    if (!isResponseValid(data.status)) {
      const message = get(data, 'error', 'Something went wrong.')
      throw new AuthError(message, data.status)
    }
    dispatch(a.updatePasswordStatus(LOADING_STATE_LOADED))
    return true
  } catch (e) {
    const code = get(e, 'code', 400)
    const message = get(e, 'message', 'Something went wrong.')
    dispatch(a.updatePasswordStatus(LOADING_STATE_ERROR, message, code))
    return false
  }
}

export const subscribeToUserEvents = (profileId: string) => async (dispatch: Dispatch) => {

  const eventCallback = async (data) => {
    if (!data.requestId) {
      return null
    }

    dispatch(loadAndUpdateRequest(data.requestId))
    dispatch(getUsersHint())
  }

  const eventEditCallback = async (data) => {
    if (!data.requestId) {
      return null
    }

    dispatch(updateRequestLocal(data.old.requestId, { status: PAYMENT_REQUEST_STATUS_CANCELLED }))
    dispatch(loadAndUpdateRequest(data.requestId))
    dispatch(getUsersHint())
  }

  InsightSocketService.addSubscription(`events:${profileId}`, () => {
    // $flow-disable-line
    InsightSocketService.subscribeProfileId(profileId, eventCallback, eventEditCallback)
  })
}

export const updateLastActivityThunk = () => async (dispatch: Dispatch) => {
  dispatch(a.updateLastActivity())
}

export const startLastActivityTimer = () => async (dispatch: Dispatch, getState: getStateType) => {
  const intervalId = setInterval(() => {
    const lastActivity = lastActivitySelector(getState())
    const isWarningActive = showLastActivityPopupSelector(getState())
    if (!isWarningActive && Date.now() - lastActivity.getTime() >= config.getConfig('inactivityIntervalWarning')) {
      dispatch(a.showLastActivityPopup())
    }
  }, 1000)
  if (typeof intervalId === 'number') {
    dispatch(a.updateLastActivityTimerIds(intervalId))
  }
}

export const hideLastActivityPopupThunk = () => async (dispatch: Dispatch) => {
  dispatch(a.hideLastActivityPopup())
}

export const clearLastActivityIntervals = () => async (dispatch: Dispatch, getState: getStateType) => {
  const { lastActivityTimerId } = getActivityTimerIdsSelector(getState())
  if (lastActivityTimerId) {
    clearInterval(lastActivityTimerId)
  }
  dispatch(a.hideLastActivityPopup())
}

