import * as Yup from 'yup'
import { SECOND_STEP } from './AddPaymentRequestSidePanel'
import { validateAddress } from '../../ducks/accounts/utils'

export const validation = (values) => {

  const errors = {}

  try {
    Yup.number()
      .typeError('Amount must be a number')
      .min(1, 'Min amount is 1')
      .required('Amount is required')
      .validateSync(values.fiatAmount)
  } catch (error) {
    errors.fiatAmount = error.message
  }

  try {
    Yup.number()
      .typeError('Amount must be a number')
      .min(0, 'Amount must be positive')
      .required('Amount is required')
      .validateSync(values.cryptoAmount)
  } catch (error) {
    errors.cryptoAmount = error.message
  }

  try {
    Yup.string()
      .required('Account must be selected')
      .validateSync(values.accountId)

    if (values.isNewAddress) {
      const error = validateAddress(values.blockchain, values.accountId)
      if (!error) {
        throw new Error('Address is invalid')
      }
    }
  } catch (error) {
    errors.accountId = error.message
  }

  if (values.step === SECOND_STEP) {
    try {
      Yup.string()
        .email('Please enter valid email address')
        .required('Email is required')
        .validateSync(values.email)
    } catch (error) {
      errors.email = error.email
    }
  }


  return errors
}
