import get from 'lodash.get'
import BigNumber from 'bignumber.js'
import { createSelector } from 'reselect'
import { ACCOUNT_TYPE_HOT, ACCOUNT_TYPE_TREZOR, ACCOUNTS_DUCK } from './constants'
import { ratesSymbolMappedListSelector } from '../rates/selectors'
import { currentCurrencySelector } from '../profile/selectors'
import { isTestnetAccount } from './utils'
import type { HotWallet, ColdWallet } from './models'
import { LOADING_STATE_LOADED } from '../../constants'
import { BLOCKCHAIN_BITCOIN, BLOCKCHAIN_BITCOIN_TESTNET } from '../bitcoin/constants'
import { BLOCKCHAIN_ETHEREUM, BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY } from '../ethereum/constants'
import * as ETH from '../ethereum/selectors'
import * as BTC from '../bitcoin/selectors'

const getSelector = (blockchain) => {
  switch (blockchain) {
    case BLOCKCHAIN_ETHEREUM:
    case BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY:
      return ETH
    case BLOCKCHAIN_BITCOIN:
    case BLOCKCHAIN_BITCOIN_TESTNET:
    default:
      return BTC
  }
}

export const accountsDuckSelector = (state) => {
  return state[ACCOUNTS_DUCK]
}

export const accountsSelector = createSelector(
  accountsDuckSelector,
  ({ list }) => list,
)

export const accountLoadingStateSelector = createSelector(
  accountsDuckSelector,
  ({ loadingState }) => loadingState,
)

export const accountNamesSelector = createSelector(
  accountsSelector,
  (list) => list.map((account) => account.name),
)

export const accountBlockchainAddressSelector = createSelector(
  accountsSelector,
  (list) => list.map((account) => `${account.blockchain}_${account.address}`.toLocaleLowerCase()),
)
export const sendFormSettingSelector = createSelector(
  accountsDuckSelector,
  ({ sendFormSettings }) => sendFormSettings,
)

export const defaultAccountSelector = (blockchain) => createSelector(
  accountsSelector,
  (list) => list.find((account) => {
    return account.defaultAccount && account.blockchain === blockchain
  }),
)

export const walletAddressAccountSelector = (walletAddress) => createSelector(
  accountsSelector,
  (list) => {
    const account = list.find((account) => account.address === walletAddress)
    return account ? account.name : null
  },
)

export const defaultAccountsSelector = createSelector(
  accountsSelector,
  (list) => {
    return list.filter((account) => {
      return !!account.defaultAccount
    })
  },
)

export const selectTypeAccountsSelector = (blockchainType: string, type: string = ACCOUNT_TYPE_HOT, isAddHardwareWallets: boolean = true) => createSelector(
  accountsSelector,
  (list) => list.filter((a) => {
    if (a.blockchain === blockchainType) {
      return a.type === type || (isAddHardwareWallets && [ACCOUNT_TYPE_TREZOR].includes(a.type))
    }
    return false
  }),
)

export const anyHotAccountsSelector = createSelector(
  accountsSelector,
  (accounts) => {
    return accounts.reduce((acc, account) => {
      if (!acc[account.blockchain] && account.type === ACCOUNT_TYPE_HOT) {
        acc[account.blockchain] = account
      }
      return acc
    }, {})
  },
)

export const accountByIdSelector = (id) => createSelector(
  accountsDuckSelector,
  ({ list }) => list.find((account) => account.id === id),
)

export const accountByAddressSelector = (address) => createSelector(
  accountsDuckSelector,
  ({ list }) => list.find((account) => account.address === address),
)


export const totalFiatBalanceSelector = createSelector(
  accountsSelector,
  ratesSymbolMappedListSelector,
  currentCurrencySelector,
  (list, rates, currency) => {
    const accountBalances = {}
    const totalBalance: BigNumber = list.reduce((accumulator, account) => {
      const rate = get(rates, `${account.symbol}.${currency}`, null)
      const balanceFiat = account.formattedBalance && rate ? account.formattedBalance.multipliedBy(rate).decimalPlaces(2) : null
      accountBalances[account.id] = balanceFiat

      if (isTestnetAccount(account)) {
        return accumulator
      }

      return balanceFiat ? balanceFiat.plus(accumulator || 0) : accumulator
    }, new BigNumber(0))

    return {
      accountBalances,
      totalBalance,
    }
  },
)

export const isAllBalancesLoadedSelector = createSelector(
  accountsSelector,
  (list: { list: Array<HotWallet | ColdWallet> }) => {
    return list
      .filter((account: HotWallet | ColdWallet) => account.type === ACCOUNT_TYPE_HOT)
      .every((account: HotWallet | ColdWallet) => account.balanceLoadingState === LOADING_STATE_LOADED)
  },
)

export const transactionByIdSelector = (accountId, txId) => createSelector(
  accountByIdSelector(accountId),
  (account) => {
    const transactions = get(account, 'transactions', null)
    return (transactions || { list: {} }).list[txId]
  },
)

export const walletsMappedByAddressAndBlockchainSelector = createSelector(
  accountsSelector,
  (list) => {
    return list.reduce((acc, account) => {
      acc[`${account.blockchain}-${account.address}`] = {
        name: account.name,
        blockchain: account.blockchain,
        address: account.address,
      }
      return acc
    }, {})
  },
)

export const getSigner = (blockchain, ...props) => {
  const selectors = getSelector(blockchain)
  return selectors.getSigner(...props)
}

export const getAccountNetwork = (blockchain) => {
  const selectors = getSelector(blockchain)
  return selectors.getNetwork(blockchain)
}

export const getRemoteBlockchain = (blockchain) => {
  const selectors = getSelector(blockchain)
  return selectors.getRemoteBlockchain(blockchain)
}

export const lastBlockSelector = (blockchain) => {
  const selectors = getSelector(blockchain)
  return selectors.lastBlockSelector(blockchain)
}
