import React from 'react'
import PropTypes from 'prop-types'
import {Button} from '../../components/Button'
import styles from './Message.module.scss'
import Icon from '../../components/Icon/Icon'

const Message = (props) => {
  const {onClose, onCloseText, isSuccess, title, subTitle, onGoToWallet, customActions} = props
  return (
    <div className={styles.messageContainer}>
      <div className={styles.messageContent}>
        {isSuccess
          ? <>
            <Icon type='checkmark-circle' colorType='green' />
          </>
          : <>
            <Icon type='attention' colorType='red' />
          </>
        }

        {title && <div className='h5 gray-30'>{title}</div>}
        {subTitle && <div className='capture gray-30'>{subTitle}</div>}

        <div className={styles.messageActions}>
          {customActions || (
            <>
              {isSuccess && <Button type='button' onClick={onClose}>Ok</Button>}
              <Button type='button' onClick={onGoToWallet || onClose} colorType='new-border'>{
                onCloseText
              }</Button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
Message.propTypes = {
  onClose: PropTypes.func,
  onGoToWallet: PropTypes.func,
  onCloseText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isSuccess: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  customActions: PropTypes.node,
}

export default Message
