import BigNumber from 'bignumber.js'
import moment from 'moment'
import get from 'lodash.get'
import {
  PAYMENT_REQUEST_MAINNET_NETWORK_KEY, PAYMENT_REQUEST_RINKEBY_NETWORK_KEY,
  PAYMENT_REQUEST_STATUS_APPROVED,
  PAYMENT_REQUEST_STATUS_DRAFT,
  PAYMENT_REQUEST_STATUS_FAILED,
  PAYMENT_REQUEST_STATUS_IN_PROGRESS,
  PAYMENT_REQUEST_STATUS_IN_PROGRESS_FORMATTED,
  PAYMENT_REQUEST_STATUS_PAID,
  PAYMENT_REQUEST_STATUS_PENDING_APPROVAL,
  PAYMENT_REQUEST_STATUS_PENDING_APPROVAL_FORMAT,
  PAYMENT_REQUEST_STATUS_UNPAID, PAYMENT_REQUEST_TESTNET_NETWORK_KEY,
} from './constants'
import { checkNetworkOfAddress } from '../bitcoin/utils'
import {
  BITCOIN_SYMBOL,
  BLOCKCHAIN_BITCOIN,
  BLOCKCHAIN_BITCOIN_TESTNET,
} from '../bitcoin/constants'
import { PaymentRequestModel } from '../../models/PaymentRequests/PaymentRequestModel'
import { LOADING_STATE_LOADED } from '../../constants'
import {
  ETHEREUM_SYMBOL,
  BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY,
  BLOCKCHAIN_ETHEREUM,
} from '../ethereum/constants'
import { getAccountNetwork, getRemoteBlockchain } from '../accounts/selectors'

export const formatValues = async (values, profile, account, oldRequest) => {
  let amount = new BigNumber(0)
  values.items = values.items.map((item) => {
    const formattedItem = {
      type: values.itemsType,
      description: item.description,
    }
    if (item.count && item.rate) {
      amount = amount.plus(new BigNumber(item.count).multipliedBy(item.rate))
      formattedItem.count = parseFloat(item.count)
      formattedItem.rate = parseFloat(item.rate)
    } else {
      amount = amount.plus(item.amount)
      formattedItem.amount = parseFloat(item.amount)
    }

    return formattedItem
  })
  const name = profile.firstName && profile.lastName ? profile.firstName + ' ' + profile.lastName : profile.email

  const logo = values.logo
  if (logo) {
    delete logo.loadingState
    delete logo.size
    // if (get(values, 'logo.attachmentId', null) && values.statusText) {
    //   delete logo.data
    // }
  }

  const res = {
    type: 'request',
    userEmail: values.billTo,
    userTag: values.billTo,
    settlementAsset: values.currency,
    settlementAmount: amount.toNumber(),
    paymentAsset: account.symbol,
    paymentWallet: account.address,
    status: values.statusText || PAYMENT_REQUEST_STATUS_UNPAID,
    notes: values.comment,
    checkoutUrl: '',
    paymentBlockchain: getRemoteBlockchain(values.blockchain),
    paymentNetwork: getAccountNetwork(values.blockchain),
    invoice: {
      logo: logo,
      number: values.invoiceNumber,
      payerAddress: values.billTo,
      payerName: values.billTo,
      payeeName: name,
      payeeAddress: profile.email,
      title: values.subtitle,
      due: moment(values.due).format('YYYY-MM-DD'),
      items: values.items,
      attachments: values.attachments,
      billFrom: values.billFrom,
    },
  }

  if (
    values.requestId &&
    oldRequest &&
    oldRequest.status === PAYMENT_REQUEST_STATUS_DRAFT &&
    values.statusText === PAYMENT_REQUEST_STATUS_UNPAID
  ) {
    res.fromDraftId = values.requestId
  }

  return res
}

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export const getBlockchainFromRemoteInvoice = (paymentBlockchain: string, paymentNetwork: string): string => {
  switch (true) {
    case [BLOCKCHAIN_BITCOIN].includes(paymentBlockchain) && paymentNetwork === PAYMENT_REQUEST_MAINNET_NETWORK_KEY:
      return BLOCKCHAIN_BITCOIN

    case [BLOCKCHAIN_BITCOIN, BLOCKCHAIN_BITCOIN_TESTNET].includes(paymentBlockchain) &&
    paymentNetwork === PAYMENT_REQUEST_TESTNET_NETWORK_KEY:
      return BLOCKCHAIN_BITCOIN_TESTNET

    case [BLOCKCHAIN_ETHEREUM].includes(paymentBlockchain) && paymentNetwork === PAYMENT_REQUEST_MAINNET_NETWORK_KEY:
      return BLOCKCHAIN_ETHEREUM

    case [BLOCKCHAIN_ETHEREUM, BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY].includes(paymentBlockchain) &&
    paymentNetwork === PAYMENT_REQUEST_RINKEBY_NETWORK_KEY:
      return BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY

    default:
      return null
  }
}

export const getRequestNetwork = (request) => {
  switch (request.paymentAsset) {
    case BITCOIN_SYMBOL:
      return checkNetworkOfAddress(request.paymentWallet)
    case ETHEREUM_SYMBOL:
      return BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY
    default:
      return null
  }
}

export const getFormattedInvoiceStatus = (status: string, isItMyRequest: boolean) => {
  if (status === PAYMENT_REQUEST_STATUS_PAID) {
    return isItMyRequest ? 'Received' : 'Sent'
  } else if (status === PAYMENT_REQUEST_STATUS_PENDING_APPROVAL) {
    return PAYMENT_REQUEST_STATUS_PENDING_APPROVAL_FORMAT
  } else if (status === PAYMENT_REQUEST_STATUS_IN_PROGRESS) {
    return PAYMENT_REQUEST_STATUS_IN_PROGRESS_FORMATTED
  } else {
    return status
  }
}

export const isPendingRequest = (requestStatus: string) => {
  const pendingStatuses = [
    PAYMENT_REQUEST_STATUS_PENDING_APPROVAL,
    PAYMENT_REQUEST_STATUS_UNPAID,
    PAYMENT_REQUEST_STATUS_IN_PROGRESS,
    PAYMENT_REQUEST_STATUS_FAILED,
    PAYMENT_REQUEST_STATUS_APPROVED,
  ]
  return pendingStatuses.includes(requestStatus)
}

export const formatRawRequest = (request, requestsMap) => {
  const version = request.invoice.updateNumber || request.invoice.version || 0
  // we need this because the logo can be loaded already
  const logo = get(requestsMap, `[${request.requestId}.invoice.logo]`, request.invoice.logo)

  const paymentBlockchain = getBlockchainFromRemoteInvoice(request.paymentBlockchain, request.paymentNetwork)
  if (request.status !== PAYMENT_REQUEST_STATUS_DRAFT && !paymentBlockchain) {
    throw new Error('paymentBlockchain is required')
  }
  return new PaymentRequestModel({
    loadingState: LOADING_STATE_LOADED,
    ...request,
    invoice: {
      ...request.invoice,
      logo,
      version,
      totalNumber: request.invoice.number + (version ? '.' + version : ''),
      items: request.invoice.items.map((i) => {
        return {
          ...i,
          type: i.type && i.type.toLowerCase(),
        }
      }),
    },
    userTag: request.userEmail || request.userTag,
    paymentBlockchain,
  })
}
