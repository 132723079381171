import React from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import InputText from '../../../components/InputText'
import { Formik } from 'formik'
import { Button } from '../../../components/Button'
import NewSelect from '../../../components/Select/newSelect'
import { ACCOUNTS_ACTIVE_BLOCKCHAINS } from '../../../ducks/accounts/constants'
import styles from './CreateWallet.module.scss'

const CreateWalletName = ({ handleSubmit, onBack, accountList, initialValues, isRecover }) => {
  const validation = (values) => {
    const errors = {}

    try {
      Yup.mixed()
        .test('Wallet name is required', (value) => {
          return !!value
        })
        .test('Wallet name is invalid', (value) => {
          return !!value.trim()
        })
        .test(
          'You already have an account with this name',
          (value) => {
            return !accountList.includes(value.trim())
          },
        )
        .validateSync(values.walletName)
    } catch (error) {
      errors.walletName = error.type
    }

    return errors
  }

  return (
    <div className={styles.formWrapper}>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          blockchain: initialValues.blockchain,
          walletName: initialValues.walletName,
        }}
        validate={validation}
        onSubmit={handleSubmit}
      >
        {(formikProps) => {
          const {
            handleChange,
            submitForm,
            handleBlur,
            values,
            errors,
            setFieldValue,
          } = formikProps
          return (
            <form name='createNameForm'>
              <div className={styles.line}>
                <NewSelect
                  label='Select coin'
                  name='blockchain'
                  value={values.blockchain}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {ACCOUNTS_ACTIVE_BLOCKCHAINS.map((blockchain) => {
                    return (
                      <option key={blockchain.value} value={blockchain.value}>{blockchain.label}</option>
                    )
                  })}
                </NewSelect>
              </div>
              <div className={styles.line}>
                <InputText
                  value={values.walletName}
                  onChange={handleChange}
                  onBlur={(e) => {
                    setFieldValue('walletName', e.target.value.trim())
                  }}
                  label='Wallet name'
                  errorText={errors['walletName']}
                  error={!!errors['walletName']}
                  name='walletName'
                />
              </div>
              <div className={styles.actions}>
                <Button
                  type='button'
                  onClick={onBack}
                  colorType='new-border'
                >cancel</Button>
                <Button
                  onClick={submitForm}
                  type='submit'
                  colorType='new-filled'>{isRecover ? 'RECOVER' : 'CREATE'} WALLET</Button>
              </div>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}
CreateWalletName.propTypes = {
  handleSubmit: PropTypes.func,
  accountList: PropTypes.arrayOf(PropTypes.string),
  onBack: PropTypes.func,
  isRecover: PropTypes.bool,
  initialValues: PropTypes.shape({
    walletName: PropTypes.string,
    blockchain: PropTypes.string,
  }),
}


export default CreateWalletName
