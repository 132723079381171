import * as c from './constants'
import { PaymentRequestModel } from '../../models/PaymentRequests/PaymentRequestModel'

export const setPaymentRequestsList = (requestsData) => {
  return {
    type: c.PAYMENT_REQUESTS_SET_LIST,
    ...requestsData,
  }
}

export const setPaymentRequestsLoadingState = (loadingState) => {
  return {
    type: c.PAYMENT_REQUESTS_SET_LOADING_STATE,
    ...loadingState,
  }
}

export const updatePaymentRequest = (paymentRequest) => {
  return {
    type: c.PAYMENT_REQUESTS_UPDATE,
    paymentRequest,
  }
}
export const removePaymentRequestLocal = (requestId) => {
  return {
    type: c.PAYMENT_REQUESTS_DELETE,
    requestId,
  }
}

export const updatePaymentRequests = (requests: { [requestId: string]: PaymentRequestModel }) => {
  return {
    type: c.PAYMENT_REQUESTS_MULTIPLE_UPDATE,
    requests,
  }
}

export const updateFormLoadingState = (formName, loadingState) => {
  return {
    type: c.PAYMENT_REQUESTS_UPDATE_FORM_LOADING_STATE,
    formName,
    loadingState,
  }
}

export const updateUsersHint = (list) => {
  return {
    type: c.PAYMENT_REQUESTS_UPDATE_USERS_HINT,
    list,
  }
}
