import PropTypes from 'prop-types'
import { setSidePanel } from '../../ducks/ui/actions'
import { connect } from 'react-redux'
import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import styles from './CreateInvoiceButton.module.scss'
import Button from '../Button/Button'
import Icon from '../Icon/Icon'
import { amplitudeSendEvent } from '../../utils/analytics'

function CreateInvoiceButton ({ handleOpenAddInvoiceSidePanel, handleOpenPaymentSidePanel }) {

  const [isMenuShown, setIsMenuShown] = useState(false)

  //Replaces componentDidMount and componentWillUnmount
  const closeMenu = function () {
    setIsMenuShown(false)
  }
  useEffect(() => {
    window.addEventListener('click', closeMenu)
  }, [])
  useEffect(() => {
    return () => {
      window.removeEventListener('click', closeMenu)
    }
  }, [])

  const handleClickWithoutEvents = (callback) => (e) => {
    e.stopPropagation()
    e.preventDefault()
    callback(e)
  }

  const showDropDownMenu = () => {
    setIsMenuShown(!isMenuShown)
  }

  const handleOpenRequestForm = (e) => {
    e.stopPropagation()
    e.preventDefault()
    amplitudeSendEvent('Payment request initiated', {})
    handleOpenPaymentSidePanel()
    setIsMenuShown(false)
  }

  return (
    <div className={styles.container}>
      <div className={styles.buttonWrapper}>
        <Button
          id='openAddInvoicePanelButton'
          onClick={handleClickWithoutEvents(handleOpenAddInvoiceSidePanel)}
          data-selenium-id='add'>Create Invoice</Button>
        <Button
          onClick={handleClickWithoutEvents(showDropDownMenu)}
          className={cn(styles.dropDownButton, { [styles.activeButton]: isMenuShown })}
        >
          <Icon
            type={isMenuShown ? 'chevron-up' : 'chevron-down'}
            colorType='white'
          />
        </Button>
      </div>
      <div className={cn(styles.dropDownMenu, { [styles.active]: isMenuShown })}>
        <button
          className='capture gray-70'
          onClick={handleOpenRequestForm}
        >Payment request
        </button>
      </div>
    </div>
  )
}

CreateInvoiceButton.propTypes = {
  handleOpenAddInvoiceSidePanel: PropTypes.func,
  handleOpenPaymentSidePanel: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => ({
  handleOpenAddInvoiceSidePanel: () => {
    amplitudeSendEvent('Invoicing initiated', {})
    return dispatch(setSidePanel('addInvoiceSidePanel', { isOpen: true, requestId: null }))
  },
  handleOpenPaymentSidePanel: () => dispatch(setSidePanel('addPaymentRequestSidePanel',
    {
      isOpen: true,
      accountId: null,
    })),
})

export default connect(null, mapDispatchToProps)(CreateInvoiceButton)

