import React from 'react'
import PropTypes from 'prop-types'
import styles from './Label.module.scss'
import Button from '../Button/Button'

class Label extends React.Component {
  constructor (props) {
    super(props)
    this.labelRef = React.createRef()
    this.state = {
      x: null,
      y: null,
      arrowX: null,
    }

    this.dropRef = React.createRef()
  }

  componentDidMount () {
    if (this.props.isOpen) {
      this.onMouseOver({ target: this.dropRef.current })
    }
  }

  onMouseOver = (e) => {
    const { x, y, height, width } = e.target.getBoundingClientRect()
    const { height: labelHeight, width: labelWidth } = this.labelRef.current.getBoundingClientRect()
    let xRes = x + width / 2 - labelWidth / 2
    const yRes = y - height / 2 - labelHeight
    const documentWidth = document.body.offsetWidth
    let arrowX = null

    if (xRes + labelWidth > documentWidth) {
      arrowX = `calc(50% - 4px + ${Math.abs((documentWidth - (xRes + labelWidth)))}px)`
      xRes = xRes + (documentWidth - (xRes + labelWidth) - 1)
    }
    if (xRes < 0) {
      arrowX = `calc(50% - 6px + ${xRes}px)`
    }
    this.setState({
      x: xRes > 0 ? xRes : 1,
      y: yRes,
      arrowX,
    })
  }

  onMouseLeave = () => {
    this.setState({
      x: null,
      y: null,
      arrowX: null,
    })
  }

  handleButtonClick = async () => {
    await this.props.onButtonClick()
    this.setState({
      x: null,
      y: null,
      arrowX: null,
    })
  }

  render () {
    const { children, label } = this.props
    const { x, y, arrowX } = this.state
    let style = {}
    const arrowStyle = {}
    if (y && x) {
      style = {
        top: y,
        left: x,
        opacity: 1,
        zIndex: 1,
      }
      if (arrowX) {
        arrowStyle.left = arrowX
      }
    }
    return (
      <>
        <span
          ref={this.dropRef}
          onMouseOver={!this.props.isOpen ? this.onMouseOver : null}
          onMouseLeave={this.props.withButton ? null : this.onMouseLeave}
          className={this.props.className}
        >{children}</span>
        <span ref={this.labelRef} style={style} className={styles.label + ' ' + this.props.labelClassName}>
          {label}
          {this.props.withButton && <span className={styles.buttonWrapper}>
            <Button
              colorType='new-filled'
              onClick={this.handleButtonClick}>Ok</Button>
          </span>}
          <span className={styles.arrow} style={arrowStyle}></span>
        </span>
      </>
    )
  }
}

Label.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.number, PropTypes.string]),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.number, PropTypes.string]),
  withButton: PropTypes.bool,
  onButtonClick: PropTypes.func,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
}

export default Label
