//@flow
import { connect } from 'react-redux'
import React from 'react'
import BigNumber from 'bignumber.js'
import PropTypes from 'prop-types'
// import { Scrollbars } from 'react-custom-scrollbars'
import Wallet from '../../components/Wallet'
import { setSidePanel } from '../../ducks/ui/actions'
import type { AnyWallet } from '../../ducks/accounts/models'
import Icon from '../../components/Icon/Icon'
import { FORMAT_FIAT_NUMBERS } from '../../ducks/accounts/constants'
import LoadingState from '../../components/LoadingState'
import { LOADING_STATE_LOADING } from '../../constants'
import CreateInvoiceButton from '../../components/CreateInvoiceButton/CreateInvoiceButton'

import styles from './AccountCard.module.scss'
import '../../components/Datepicker/datepicker.css'
import {Scrollbars} from 'react-custom-scrollbars'
import { amplitudeSendEvent } from "../../utils/analytics"

type Props = {
  accounts: Array<AnyWallet>,
  handleSubmit: () => {},
  totalFiatBalance: BigNumber,
  primaryAsset: string,
  isBalanceLoaded: boolean,
  setSidePanel: (panelName: string, panelProps: any) => void;
}

class AccountCardComponent extends React.PureComponent<Props> {
  static propTypes = {
    handleSubmit: PropTypes.func,
    accounts: PropTypes.arrayOf(PropTypes.object),
    totalFiatBalance: PropTypes.instanceOf(BigNumber),
    isBalanceLoaded: PropTypes.bool,
    setSidePanel: PropTypes.func,
    primaryAsset: PropTypes.string,
  }

  handleOpenAddAccountPanel = () => {
    amplitudeSendEvent('Adding wallet initiated', { })
    this.props.setSidePanel('addAccountSidePanel', { isOpen: true })
  }

  renderWalletsTitle = (hideDesktop = false) => {
    return (
      <div className={styles.walletsTitle + (hideDesktop ? ' hide-desktop' : ' hide-mobile')}>
        <div className={styles.title}>Wallets</div>
        <button onClick={this.handleOpenAddAccountPanel}>
          <Icon type='plus-circle' colorType='blue' />
        </button>
      </div>
    )
  }

  render () {
    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <CreateInvoiceButton />
        </div>
        {this.props.accounts.length > 0
          ? (
            <div className={styles.accountsContainer}>
              <div className={styles.totalRow + ' hide-mobile'}>
                <div className={styles.totalTitle}>Total</div>
                <div className={styles.summ}>{this.props.isBalanceLoaded
                  ?
                  <b>
                    <span>
                      {this.props.totalFiatBalance
                        .toFormat(2, FORMAT_FIAT_NUMBERS)
                        .toString()}
                    </span>
                    <span className={styles.currency}> {this.props.primaryAsset}</span>
                  </b>
                  : <LoadingState loadingState={LOADING_STATE_LOADING} loaderClassName={styles.totalLoader} />
                }</div>
              </div>
              {this.renderWalletsTitle()}
              <div className={styles.walletsMobile + ' hide-desktop'}>
                <div className={styles.mobileTotalBalance}><span>Total</span><span className={styles.totalAmount}>{this.props.totalFiatBalance
                  .toFormat(2, FORMAT_FIAT_NUMBERS)
                  .toString()}
                <span className={styles.currency}> {this.props.primaryAsset}</span>
                </span>
                </div>
              </div>
              {this.renderWalletsTitle(true)}
              <Scrollbars className={styles.walletListContainer+ ' Swipe_scrollContainer'}>
                {this.props.accounts.map((account) => {
                  return <Wallet
                    key={account.id}
                    accountId={account.id}
                  />
                })}
              </Scrollbars>
            </div>
          )
          : (
            <div className={styles.emptyWalletsContainer}>
              <div className={styles.addWalletLine}>
                <button onClick={this.handleOpenAddAccountPanel} className='capture blue-bk cursor-pointer'>
                  <Icon type='plus-circle' colorType='blue' />
                  Add new wallet
                </button>
              </div>
              <div className={styles.emptyMessage}>
                <Icon type='wallet' colorType='gray-30' />
                <div className='h5 gray-30'>You don’t have any<br />wallets yet</div>
              </div>
            </div>
          )}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  setSidePanel: (panelName, panelProps) => dispatch(setSidePanel(panelName, panelProps)),
})

export default connect(null, mapDispatchToProps)(AccountCardComponent)
