import * as c from './constants'

export const selectRequestUpdateList = (selectedRequestIds) => {
  return {
    type: c.REQUEST_ITEM_UPDATE_LIST,
    selectedRequestIds,
  }
}
export const updatePayrollTransactions = (transactions) => {
  return {
    type: c.UPDATE_PAYROLL_TRANSACTIONS,
    transactions,
  }
}
export const selectRequestItem = (requestId) => {
  return {
    type: c.REQUEST_ITEM_SELECT,
    requestId,
  }
}
export const selectRequestPreviewItem = (requestId) => {
  return {
    type: c.REQUEST_ITEM_SELECT_PREVIEW_INVOICE,
    requestId,
  }
}
export const clearRequestPreviewItem = () => {
  return {
    type: c.REQUEST_ITEM_CLEAR_PREVIEW_INVOICE,
  }
}
export const removeRequestItem = (requestId) => {
  return {
    type: c.REQUEST_ITEM_REMOVE,
    requestId,
  }
}
export const updateStep = (step) => {
  return {
    type: c.PAYROLL_UPDATE_STEP,
    step,
  }
}
export const clearRequestItemSet = () => {
  return {
    type: c.REQUEST_ITEM_CLEAR_SET,
  }
}

export const updatePayrollSettingsAction = (accounts) => {
  return {
    type: c.PAYROLL_UPDATE_SETTINGS,
    accounts,
  }
}
export const updatePayrollSettingsLoadingState = (loadingState) => {
  return {
    type: c.PAYROLL_UPDATE_SETTINGS_LOADING_STATE,
    loadingState,
  }
}

export const updatePayrollSettingsBlockchain = (settings: any) => {
  return {
    type: c.PAYROLL_UPDATE_SETTINGS_BLOCKCHAIN,
    ...settings,
  }
}

export const setCreatePayrollLoadingState = (createPayrollLoadingState, createPayrollErrorMessage = null) => {
  return {
    type: c.PAYROLL_CREATE_SET_LOADING_STATE,
    createPayrollLoadingState,
    createPayrollErrorMessage,
  }
}

export const updatePayrollStep = (step) => {
  return {
    type: c.PAYROLL_SET_STEP,
    step,
  }
}

export const removePayrollInfo = (teamId) => {
  return {
    type: c.PAYROLL_REMOVE_PAYROLL_INFO,
    teamId,
  }
}

export const payrollUpdatePayrollInfo = (teamId, payrollInfo) => {
  return {
    type: c.PAYROLL_UPDATE_PAYROLL_INFO,
    teamId,
    payrollInfo,
  }
}
