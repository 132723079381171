// @flow
import AbstractRESTService from './AbstractRESTService'

const URL_GET_PROFILE = '/profile/me'
const URL_UPDATE_PROFILE = '/profile'

export default class ProfileService extends AbstractRESTService {
  getProfile () {
    return this.restService.request({
      method: 'GET',
      headers: this.getBearerHeaders(),
      url: URL_GET_PROFILE,
      data: {},
    })
  }

  updateProfile (profile: any) {
    return this.restService.request({
      method: 'POST',
      headers: this.getBearerHeaders(),
      url: URL_UPDATE_PROFILE,
      data: {
        ...profile,
      },
    })
  }
}
