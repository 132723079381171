import { connect } from 'react-redux'
import { addPublicAddress, checkIsSimilarAccountExisted } from '../../ducks/accounts/thunks'
import React from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import InputText from '../../components/InputText'
import { Formik } from 'formik'
import { Button } from '../../components/Button'
import NewSelect from '../../components/Select/newSelect'
import { ACCOUNTS_ACTIVE_BLOCKCHAINS } from '../../ducks/accounts/constants'
import { BLOCKCHAIN_BITCOIN } from '../../ducks/bitcoin/constants'
import styles from './AddPublicAddress.module.scss'
import {
  accountBlockchainAddressSelector,
  accountLoadingStateSelector,
  accountNamesSelector,
} from '../../ducks/accounts/selectors'
import { AccountModel } from '../../models/Accounts/AccountModel'
import LoadingState from '../../components/LoadingState'
import {
  LOADING_STATE_ERROR,
  LOADING_STATE_LOADED,
  LOADING_STATE_LOADING,
} from '../../constants'
import Message from './Message'
import { validateAddress } from '../../ducks/accounts/utils'

class AddPublicAddress extends React.PureComponent {

  static propTypes = {
    onClose: PropTypes.func,
    accountList: PropTypes.arrayOf(PropTypes.string),
    addresses: PropTypes.arrayOf(PropTypes.string),
    addPublicAddress: PropTypes.func,
    loadingState: PropTypes.string,
    openViewCard: PropTypes.func,
    onBack: PropTypes.func,
    checkIsSimilarAccountExisted: PropTypes.func,
  }

  constructor (props) {
    super(props)
    this.state = {
      isSent: false,
      accountId: null,
      similarAccountAddress: null,
    }
  }

  validation = (values) => {
    const errors = {}

    try {
      Yup.mixed()
        .test('Wallet name is required', (value) => {
          return !!value
        })
        .test('Wallet name is invalid', (value) => {
          return !!value.trim()
        })
        .test(
          'You already have an account with this name',
          (value) => {
            return !this.props.accountList.includes(value.trim())
          },
        )
        .validateSync(values.walletName)
    } catch (error) {
      errors.walletName = error.type
    }

    try {
      Yup.mixed()
        .test('Wallet address is required', (value) => {
          return !!value
        })
        .test(
          'Address is incorrect',
          (address) => {
            if (address) {
              return validateAddress(values.blockchain, address)
            }
          },
        )
        .test(
          'You already have an account with this address',
          (value) => {
            return !this.props.addresses.includes(`${values.blockchain}_${value.trim()}`.toLocaleLowerCase())
          },
        )
        .validateSync(values.walletAddress.trim())
    } catch (error) {
      errors.walletAddress = error.type
    }

    return errors
  }

  handleGoToWallet = () => {
    if (this.state.accountId) {
      this.props.onClose()
      this.props.openViewCard(this.state.accountId)
    }
  }

  render () {
    const handleSubmit = async (values) => {
      const existedAddress = this.props.checkIsSimilarAccountExisted({
        address: values.walletAddress.trim(),
        blockchain: values.blockchain,
      })
      if (!existedAddress) {
        this.setState({
          isSent: true,
        })
        const accountId = await this.props.addPublicAddress(new AccountModel({
          name: values.walletName.trim(),
          blockchain: values.blockchain,
          address: values.walletAddress.trim(),
        }))
        this.setState({
          accountId,
        })
      } else {
        this.setState({
          similarAccountAddress: existedAddress,
        })
      }
    }

    return (
      <div className={styles.formWrapper}>
        {this.props.loadingState === LOADING_STATE_LOADING && (
          <div className={styles.loaderWrapper}>
            <LoadingState loadingState={this.props.loadingState} />
          </div>
        )}
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            walletAddress: '',
            blockchain: BLOCKCHAIN_BITCOIN,
            walletName: '',
          }}
          validate={this.validation}
          onSubmit={handleSubmit}
        >
          {(formikProps) => {
            const {
              handleChange,
              submitForm,
              handleBlur,
              values,
              errors,
              setFieldValue,
            } = formikProps
            if (this.state.similarAccountAddress) {
              return (
                <Message
                  onClose={() => {
                    this.setState({
                      similarAccountAddress: null,
                    })
                  }}
                  onCloseText='CLOSE'
                  isSuccess={false}
                  title='Wallet exists'
                  subTitle={<>Can’t add this wallet.<br />You already have a wallet with the same address
                    - {this.state.similarAccountAddress}.</>}
                />
              )
            }
            if (this.state.isSent && this.props.loadingState === LOADING_STATE_LOADED) {
              return (
                <Message
                  onClose={this.props.onClose}
                  onGoToWallet={this.handleGoToWallet}
                  onCloseText='GO TO WALLET'
                  isSuccess
                  title='Public wallet address successfully added'
                />
              )
            }
            if (this.state.isSent && this.props.loadingState === LOADING_STATE_ERROR) {
              return (
                <Message
                  onClose={() => {
                    this.setState({
                      isSent: false,
                    })
                  }}
                  onCloseText='CLOSE'
                  isSuccess={false}
                  title='Failed'
                  subTitle={<>Unable to add public address.<br />Please try again. Contact support if the issue still
                    persists.</>}
                />
              )
            }
            return (
              <form name='addPublicAddressForm'>
                <div className={styles.line}>
                  <NewSelect
                    label='Select coin'
                    name='blockchain'
                    value={values.blockchain}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {ACCOUNTS_ACTIVE_BLOCKCHAINS.map((blockchain) => {
                      return (
                        <option key={blockchain.value} value={blockchain.value}>{blockchain.label}</option>
                      )
                    })}
                  </NewSelect>
                </div>
                <div className={styles.line}>
                  <InputText
                    value={values.walletAddress}
                    onChange={handleChange}
                    onBlur={(e) => {
                      setFieldValue('walletAddress', e.target.value.trim())
                    }}
                    errorText={errors['walletAddress']}
                    error={!!errors['walletAddress']}
                    label='Please type address'
                    name='walletAddress'
                  />
                </div>
                <div className={styles.line}>
                  <InputText
                    value={values.walletName}
                    onChange={handleChange}
                    onBlur={(e) => {
                      setFieldValue('walletName', e.target.value.trim())
                    }}
                    label='Wallet name'
                    errorText={errors['walletName']}
                    error={!!errors['walletName']}
                    name='walletName'
                  />
                </div>
                <div className={styles.actions}>
                  <Button
                    type='button'
                    onClick={this.props.onBack}
                    colorType='new-border'
                  >back</Button>
                  <Button
                    onClick={submitForm}
                    type='submit'
                    colorType='new-filled'>add wallet</Button>
                </div>
              </form>
            )
          }}
        </Formik>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    loadingState: accountLoadingStateSelector(state),
    accountList: accountNamesSelector(state),
    addresses: accountBlockchainAddressSelector(state),
  }
}

function mapDispatchToProps (dispatch) {
  return {
    addPublicAddress: (accountData) => dispatch(addPublicAddress(accountData)),
    checkIsSimilarAccountExisted: (accountData, mnemonic) => dispatch(checkIsSimilarAccountExisted(accountData, mnemonic)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPublicAddress)
