import React from 'react'
import bip39 from 'bip39'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import InputText from '../../../components/InputText'
import { Formik } from 'formik'
import { Button } from '../../../components/Button'
import styles from './CreateWallet.module.scss'

const RecoverMnemonic = ({ handleSubmit, onBack, initialValues }) => {

  const validation = (values) => {
    const errors = {}

    try {
      Yup.mixed()
        .test('Recovery phrase is required',
          (value) => {
            return !!value
          })
        .test(
          'Incorrect recovery phrase',
          (value) => {
            return bip39.validateMnemonic(value.toLowerCase().trim())
          },
        )
        .validateSync(values.mnemonic)
    } catch (error) {
      errors.mnemonic = error.type
    }

    return errors
  }

  return (
    <div className={styles.formWrapper}>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          mnemonic: initialValues.mnemonic,
        }}
        validate={validation}
        onSubmit={handleSubmit}
      >
        {(formikProps) => {
          const {
            handleChange,
            submitForm,
            handleBlur,
            values,
            errors,
          } = formikProps
          return (
            <form name='recoverMnemonic'>
              <div className={styles.line + ' capture gray-70'}>Please type 12 words of recovery phrase for this <br />
                wallet. Use space to separate words
              </div>
              <div className={styles.line}>
                <InputText
                  value={values.mnemonic}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  textarea
                  errorText={errors['mnemonic']}
                  error={!!errors['mnemonic']}
                  name='mnemonic'
                />
              </div>
              <div className={styles.actions}>
                <Button
                  type='button'
                  onClick={onBack}
                  colorType='new-border'
                >back</Button>
                <Button
                  onClick={submitForm}
                  type='submit'
                  colorType='new-filled'>NEXT</Button>
              </div>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}
RecoverMnemonic.propTypes = {
  handleSubmit: PropTypes.func,
  onBack: PropTypes.func,
  initialValues: PropTypes.shape({
    mnemonic: PropTypes.string,
  }),
}


export default RecoverMnemonic
