import BitcoinMemoryDevice from '../../signers/BitcoinMemoryDevice'
import { createSelector } from 'reselect'
import {
  BITCOIN_DUCK,
  BITCOIN_ACCOUNT_KEY_MAINNET,
  BITCOIN_ACCOUNT_KEY_TESTNET,
  BLOCKCHAIN_BITCOIN,
  BLOCKCHAIN_BITCOIN_TESTNET,
} from './constants'
import { ACCOUNT_TYPE_TREZOR } from "../accounts/constants"
import BitcoinTrezorDevice from "../../signers/BitcoinTrezorDevice"

export const getBitcoinSigner = (mnemonic, network, coinType, type) => {
  switch (type) {
    case ACCOUNT_TYPE_TREZOR:
      return new BitcoinTrezorDevice(network, coinType)
    default:
      return new BitcoinMemoryDevice(mnemonic, network, coinType)
  }
}
export const getSigner = getBitcoinSigner

export const getNetwork = (blockchain) => {
  switch (blockchain) {
    case BLOCKCHAIN_BITCOIN_TESTNET:
      return BITCOIN_ACCOUNT_KEY_TESTNET
    case BLOCKCHAIN_BITCOIN:
    default:
      return BITCOIN_ACCOUNT_KEY_MAINNET
  }
}

export const getRemoteBlockchain = (blockchain: string): string => {
  switch (blockchain) {
    case BLOCKCHAIN_BITCOIN:
    case BLOCKCHAIN_BITCOIN_TESTNET:
      return BLOCKCHAIN_BITCOIN
    default:
      return null
  }
}

export const bitcoinDuckSelector = (state) => {
  return state[BITCOIN_DUCK]
}

export const feeRateSelector = (state) => {
  const { feeRates } = bitcoinDuckSelector(state)
  return feeRates
}

export const bitcoinTransactionSendingStateSelector = (state) => {
  const { transactionSendingState } = bitcoinDuckSelector(state)
  return transactionSendingState
}

export const feeRateByBlockchainSelector = (blockchain) => createSelector(
  feeRateSelector,
  (feeRates) => feeRates[blockchain],
)

export const lastBlockSelector = (blockchain) => createSelector(
  bitcoinDuckSelector,
  ({ lastBlock }) => lastBlock ? lastBlock[blockchain] : null,
)
