import Joi from 'joi'
import AbstractModel from '../Core/AbstractModel'

const schemaFactory = () => ({
  id: Joi.string(),
  name: Joi.string(),
  usedForPayroll: Joi.boolean(),
  defaultAccount: Joi.boolean(),
  blockchain: Joi.string(),
  type: Joi.string(),
  symbol: Joi.string(),
  network: Joi.string(),
  address: Joi.string(),
  _address: Joi.string().allow([null, '']),
  xpub: Joi.string().allow([null, '']),
  balance: Joi.object().allow(null),
  formattedBalance: Joi.object().allow(null),
  balanceLoadingState: Joi.string().allow(null),
  hash: Joi.string(),
  encryptedKey: Joi.string(),
  loadingState: Joi.string().allow(null),
  transactions: Joi.object().keys({
    loadingState: Joi.string().allow([null, '']),
    limit: Joi.number().allow([null]),
    list: Joi.any(),
    loadMore: Joi.boolean().allow([null]),
    lastBlock: Joi.number().allow([null]),
  }),
  usedAddresses: Joi.array().items(Joi.object().keys({
    address: Joi.string().allow([null, '']),
    received: Joi.number().allow([null]),
  })).allow([null, []]),
  unusedAddresses: Joi.array().items(Joi.string()),
  meta: {
    symbol: Joi.string(),
    payDefault: Joi.boolean(),
    recieveDefault: Joi.boolean(),
    network: Joi.string(),
    isTestnet: Joi.boolean(),
  },
})

export class AccountModel extends AbstractModel {
  constructor (data, options = {}) {
    data._address = data.address || data._address

    super(data, schemaFactory, { ...options, stripUnknown: true })
    Object.assign(this, data)
    Object.freeze(this)
  }

  get address () {
    if (this.unusedAddresses) {
      return this.unusedAddresses[0]
    }
    return this._address
  }

  set address (address) {
    this._address = address
  }
}
