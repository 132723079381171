import { connect } from 'react-redux'
import { addAccount, checkIsSimilarAccountExisted } from '../../../ducks/accounts/thunks'
import React from 'react'
import PropTypes from 'prop-types'
import styles from './CreateWallet.module.scss'
import { accountLoadingStateSelector, accountNamesSelector } from '../../../ducks/accounts/selectors'
import LoadingState from '../../../components/LoadingState'
import { LOADING_STATE_ERROR, LOADING_STATE_LOADED, LOADING_STATE_LOADING } from '../../../constants'
import Message from '../Message'
import CreateWalletName from './CreateWalletName'
import CreatePassword from './CreatePassword'
import { BLOCKCHAIN_BITCOIN } from '../../../ducks/bitcoin/constants'
import RecoverMnemonic from './RecoverMnemonic'

const STEP_CREATE_WALLET_NAME = 'createWalletName'
const STEP_SET_PASSWORD = 'setPassword'
const STEP_RECOVER_MNEMONIC = 'recoverMnemonic'

const stepOrderedList = [
  STEP_CREATE_WALLET_NAME,
  STEP_SET_PASSWORD,
  STEP_RECOVER_MNEMONIC,
]

class RecoverWallet extends React.PureComponent {

  static propTypes = {
    onClose: PropTypes.func,
    accountList: PropTypes.arrayOf(PropTypes.string),
    saveAccount: PropTypes.func,
    loadingState: PropTypes.string,
    openViewCard: PropTypes.func,
    onBack: PropTypes.func,
    checkIsSimilarAccountExisted: PropTypes.func,
  }

  constructor (props) {
    super(props)
    this.state = {
      currentStep: stepOrderedList[0],
      wallet: {
        mnemonic: '',
        blockchain: BLOCKCHAIN_BITCOIN,
        password: '',
        confirmPassword: '',
        name: '',
      },
      isSent: false,
      accountId: null,
      similarAccountAddress: null,
    }
  }

  handleGoToWallet = () => {
    if (this.state.accountId) {
      this.props.onClose()
      this.props.openViewCard(this.state.accountId)
    }
  }

  handleCreateWalletName = (values) => {
    this.setState((oldState) => ({
      wallet: {
        ...oldState.wallet,
        name: values.walletName.trim(),
        blockchain: values.blockchain,
      },
      currentStep: STEP_SET_PASSWORD,
    }))
  }

  handleCreatePassword = (values) => {
    this.setState((oldState) => ({
      wallet: {
        ...oldState.wallet,
        password: values.password,
      },
      currentStep: STEP_RECOVER_MNEMONIC,
    }))
  }

  handleConfirmMnemonic = async ({ mnemonic }) => {
    const { wallet } = this.state
    const existedAddress = this.props.checkIsSimilarAccountExisted({
      name: wallet.name,
      blockchain: wallet.blockchain,
    }, mnemonic.trim())
    if (!existedAddress) {
      this.setState({
        isSent: true,
      })
      const accountId = await this.props.saveAccount(
        {
          name: wallet.name,
          blockchain: wallet.blockchain,
        },
        mnemonic.trim(),
        wallet.password,
      )
      this.setState({
        accountId,
      })
    } else {
      this.setState({
        similarAccountAddress: existedAddress,
      })
    }
  }

  handleBack = (e) => {
    const index = stepOrderedList.findIndex((step) => step === this.state.currentStep)
    if (index === 0) {
      this.props.onBack(e)
    }
    if (index > 0) {
      this.setState({
        currentStep: stepOrderedList[index - 1],
      })
    }
  }

  render () {
    if (this.props.loadingState === LOADING_STATE_LOADING) {
      return (
        <div className={styles.confirmLoaderWrapper}>
          <LoadingState loadingState={this.props.loadingState} />
        </div>
      )
    }
    if (this.state.similarAccountAddress) {
      return (
        <div className={styles.messageContainer}>
          <Message
            onClose={() => {
              this.setState({
                similarAccountAddress: null,
              })
            }}
            onCloseText='CLOSE'
            isSuccess={false}
            title='Wallet exists'
            subTitle={<>Can’t add this wallet.<br />You already have a wallet with the same address - {this.state.similarAccountAddress}.</>}
          />
        </div>
      )
    }
    if (this.state.isSent && this.props.loadingState === LOADING_STATE_LOADED) {
      return (
        <div className={styles.messageContainer}>
          <Message
            onClose={this.props.onClose}
            onGoToWallet={this.handleGoToWallet}
            onCloseText='GO TO WALLET'
            isSuccess
            title='New wallet successfully recovered'
            subTitle='All your funds are protected with the recovery phrase'
          />
        </div>
      )
    }
    if (this.state.isSent && this.props.loadingState === LOADING_STATE_ERROR) {
      return (
        <div className={styles.messageContainer}>
          <Message
            onClose={() => {
              this.setState({
                isSent: false,
                currentStep: STEP_CREATE_WALLET_NAME,
              })
            }}
            onCloseText='CLOSE'
            isSuccess={false}
            title='Failed'
            subTitle={<>Unable to recover wallet.<br />Please try again. Contact support if the issue still
              persists.</>}
          />
        </div>
      )
    }

    switch (this.state.currentStep) {
      case STEP_CREATE_WALLET_NAME:
        return <CreateWalletName
          handleSubmit={this.handleCreateWalletName}
          accountList={this.props.accountList}
          isRecover
          onBack={this.handleBack}
          initialValues={{
            blockchain: this.state.wallet.blockchain,
            walletName: this.state.wallet.name,
          }}
        />
      case STEP_SET_PASSWORD:
        return <CreatePassword
          handleSubmit={this.handleCreatePassword}
          onBack={this.handleBack}
          initialValues={{
            password: this.state.wallet.password,
            confirmPassword: this.state.wallet.password,
          }}
        />
      case STEP_RECOVER_MNEMONIC:
        return <RecoverMnemonic
          handleSubmit={this.handleConfirmMnemonic}
          onBack={this.handleBack}
          initialValues={{
            mnemonic: this.state.wallet.mnemonic,
          }}
        />
      default:
        return null
    }
  }
}

function mapStateToProps (state) {
  return {
    loadingState: accountLoadingStateSelector(state),
    accountList: accountNamesSelector(state),
  }
}

function mapDispatchToProps (dispatch) {
  return {
    saveAccount: (accountData, mnemonic, passPhrase) => dispatch(addAccount(accountData, mnemonic, passPhrase)),
    checkIsSimilarAccountExisted: (accountData, mnemonic, ) => dispatch(checkIsSimilarAccountExisted(accountData, mnemonic)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecoverWallet)
