import Joi from 'joi'
import AbstractModel from '../Core/AbstractModel'
import { PAYMENT_REQUEST_STATUS_DELETED, PAYMENT_REQUEST_STATUS_DRAFT } from '../../ducks/paymentRequests/constants'

const schemaFactory = () => ({
  requestId: Joi.string().allow([null, '']),
  url: Joi.string().allow([null, '']),
  name: Joi.string().allow([null, '']),
  mimetype: Joi.string().allow([null, '']),
  size: Joi.number(),
  type: Joi.string().allow(['request', 'invoice']),
  userId: Joi.string().allow([null, '']),
  userEmail: Joi.string().required(),
  userTag: Joi.string().required(),
  settlementAsset: Joi.string().required(),
  settlementAmount: Joi.number().required(),
  paymentAsset: Joi.string().required(),
  paymentBlockchain: Joi.string().allow([null, '']),
  paymentNetwork: Joi.string().allow([null, '']),
  isPaid: Joi.bool().required(),
  paymentWallet: Joi.string().required(),
  status: Joi.string().allow(['Draft', 'Pending', 'Approved', 'Declined', 'Overdue', 'Overpaid', 'Underpaid', 'Unpaid', 'InProgress', 'Paid', 'Cancelled']),
  notes: Joi.string().allow([null, '']),
  checkoutUrl: Joi.string().allow([null, '']), // '' for mvp
  additionalAmount: Joi.alternatives(Joi.string().allow([null, '']), Joi.number()),
  virtual: Joi.bool(),
  statusHistory: Joi.array().items(Joi.object().keys({
    date: Joi.string().allow([null, '']),
    status: Joi.string().allow([null, '']),
  })).allow([null, []]),
  fromUserId: Joi.string().allow([null, '']),
  createdAt: Joi.string().allow([null, '']),
  updatedAt: Joi.string().allow([null, '']),
  invoice: {
    logo: Joi.object().keys({
      attachmentId: Joi.string().allow([null, '']),
      createdAt: Joi.string().allow([null, '']),
      data: Joi.string().allow([null, '']),
      size: Joi.number().allow([null]),
      extension: Joi.string().allow([null, '']),
      name: Joi.string().allow([null, '']),
      loadingState: Joi.string().allow([null]),
    }).allow(null),
    version: Joi.number().allow([null, '']),
    number: Joi.string().required(),
    totalNumber: Joi.string().required(),
    payerAddress: Joi.string().allow([null, '']), // team address| custom address string
    payerName: Joi.string().allow([null, '']), //team name|custom name
    payeeAddress: Joi.string().allow([null, '']), // team address| custom address string
    payeeName: Joi.string().allow([null, '']), // team name|custom name
    from: Joi.string().allow([null, '']),
    billFrom: Joi.string().allow([null, '']),
    title: Joi.string().allow([null, '']),
    due: Joi.string().required(),
    related: Joi.string().allow([null, '']), // null for new payment request
    history: Joi.array().items(Joi.object().keys({
      date: Joi.string(),
      status: Joi.string(),
    })).allow([null, []]),
    items: Joi.array(),
    transactions: Joi.array().items(Joi.object().keys({
      description: Joi.string().allow([null, '']),
      hash: Joi.string(),
      createdAt: Joi.string().allow([null, '']),
      requestId: Joi.string().allow([null, '']),
      transactionId: Joi.string().allow([null, '']),
      blockTime: Joi.string().allow([null, '']),
      blockTimeNormalized: Joi.string().allow([null, '']),
      fee: Joi.number().allow([null]),
      value: Joi.number().allow([null]),
      confirmations: Joi.number().allow([null]),
      inputs: Joi.array().allow([null]),
      outputs: Joi.array().allow([null]),
      blockchain: Joi.string().allow([null, '']),
      asset: Joi.string().allow([null, '']),
      xrate: Joi.number().allow([null]),
      xrateSource: Joi.string().allow([null, '']),
      xrateDate: Joi.string().allow([null, '']),
    })),
    attachments: Joi.array().items(Joi.object().keys({
      attachmentId: Joi.string().allow([null, '']),
      createdAt: Joi.string().allow([null, '']),
      data: Joi.string().allow([null, '']),
      size: Joi.number().allow([null]),
      extension: Joi.string().allow([null, '']),
      name: Joi.string().allow([null, '']),
      requestId: Joi.string().allow([null, '']),
    })).allow([null, []]),
  },
  loadingState: Joi.string().allow([null]),
})

const draftSchemaFactory = () => ({
  requestId: Joi.string().allow([null, '']),
  url: Joi.string().allow([null, '']),
  name: Joi.string().allow([null, '']),
  mimetype: Joi.string().allow([null, '']),
  size: Joi.number(),
  type: Joi.string().allow(['request', 'invoice']),
  userId: Joi.string().allow([null, '']),
  userEmail: Joi.string().allow([null, '']),
  userTag: Joi.string().allow([null, '']),
  settlementAsset: Joi.string().allow([null, '']),
  settlementAmount: Joi.number().allow([null, '']),
  paymentAsset: Joi.string().allow([null, '']),
  paymentBlockchain: Joi.string().allow([null, '']),
  isPaid: Joi.bool().allow([null, '']),
  paymentWallet: Joi.string().allow([null, '']),
  status: Joi.string().allow(['Draft', 'Pending', 'Approved', 'Declined', 'Overdue', 'Overpaid', 'Underpaid', 'Unpaid', 'InProgress', 'Paid', 'Cancelled']),
  notes: Joi.string().allow([null, '']),
  checkoutUrl: Joi.string().allow([null, '']), // '' for mvp
  additionalAmount: Joi.alternatives(Joi.string().allow([null, '']), Joi.number()),
  virtual: Joi.bool(),
  statusHistory: Joi.array().items(Joi.object().keys({
    date: Joi.string().allow([null, '']),
    status: Joi.string().allow([null, '']),
  })).allow([null, []]),
  fromUserId: Joi.string().allow([null, '']),
  createdAt: Joi.string().allow([null, '']),
  updatedAt: Joi.string().allow([null, '']),
  invoice: {
    logo: Joi.object().keys({
      attachmentId: Joi.string().allow([null, '']),
      createdAt: Joi.string().allow([null, '']),
      data: Joi.string().allow([null, '']),
      size: Joi.number().allow([null]),
      extension: Joi.string().allow([null, '']),
      name: Joi.string().allow([null, '']),
      loadingState: Joi.string().allow([null]),
    }).allow(null),
    version: Joi.number().allow([null, '']),
    number: Joi.string().allow([null, '']),
    totalNumber: Joi.string().allow([null, '']),
    payerAddress: Joi.string().allow([null, '']), // team address| custom address string
    payerName: Joi.string().allow([null, '']), //team name|custom name
    payeeAddress: Joi.string().allow([null, '']), // team address| custom address string
    payeeName: Joi.string().allow([null, '']), // team name|custom name
    from: Joi.string().allow([null, '']),
    billFrom: Joi.string().allow([null, '']),
    title: Joi.string().allow([null, '']),
    due: Joi.string().allow([null, '']),
    related: Joi.string().allow([null, '']), // null for new payment request
    history: Joi.array().items(Joi.object().keys({
      date: Joi.string(),
      status: Joi.string(),
    })).allow([null, []]),
    items: Joi.array(),
    transactions: Joi.array().items(Joi.object().keys({
      description: Joi.string().allow([null, '']),
      hash: Joi.string(),
      createdAt: Joi.string().allow([null, '']),
      requestId: Joi.string().allow([null, '']),
      transactionId: Joi.string().allow([null, '']),
      blockTime: Joi.string().allow([null, '']),
      blockTimeNormalized: Joi.string().allow([null, '']),
      fee: Joi.number().allow([null]),
      value: Joi.number().allow([null]),
      confirmations: Joi.number().allow([null]),
      inputs: Joi.array().allow([null]),
      outputs: Joi.array().allow([null]),
      blockchain: Joi.string().allow([null, '']),
      asset: Joi.string().allow([null, '']),
      xrate: Joi.number().allow([null]),
      xrateSource: Joi.string().allow([null, '']),
      xrateDate: Joi.string().allow([null, '']),
    })),
    attachments: Joi.array().items(Joi.object().keys({
      attachmentId: Joi.string().allow([null, '']),
      createdAt: Joi.string().allow([null, '']),
      data: Joi.string().allow([null, '']),
      size: Joi.number().allow([null]),
      extension: Joi.string().allow([null, '']),
      name: Joi.string().allow([null, '']),
      requestId: Joi.string().allow([null, '']),
    })).allow([null, []]),
  },
  loadingState: Joi.string().allow([null]),
})

export class PaymentRequestModel extends AbstractModel {
  constructor (data, options) {
    super(
      data,
      [PAYMENT_REQUEST_STATUS_DRAFT, PAYMENT_REQUEST_STATUS_DELETED].includes(data.status) ? draftSchemaFactory : schemaFactory,
      {
        ...options,
        stripUnknown: true,
      })
    Object.freeze(this)
  }
}
