import Joi from 'joi'
import AbstractModel from '../Core/AbstractModel'

const schemaFactory = () => ({
  address: Joi.string().allow([null, '']),
  addressAttachments: Joi.array().allow([null, '']),
  attachments: Joi.array().allow([null, '']),
  avatar: Joi.object().keys({
    id: Joi.string(),
    url: Joi.string(),
  }).allow(null),
  birthDate: Joi.string().allow([null, '']),
  company: Joi.string().allow([null, '']),
  email: Joi.string(),
  expirationDate: Joi.string().allow([null, '']),
  firstName: Joi.string().allow([null, '']),
  ipfsHash: Joi.string().allow([null, '']),
  lastName: Joi.string().allow([null, '']),
  name: Joi.string().allow([null, '']),
  passport: Joi.string().allow([null, '']),
  profileId: Joi.string(),
  referred: Joi.string().allow([null, '']),
  referredBy: Joi.string().allow([null, '']),
  website: Joi.string().allow([null, '']),
  canPaySeparateTransactions: Joi.boolean(),
  fromDescription: Joi.string().allow([null, '']),
  primaryAsset: Joi.string().allow([null, '']),
  receivePdf: Joi.boolean(),
})


export class ProfileModel extends AbstractModel {
  constructor (data, options = {}) {
    super(data, schemaFactory, { ...options, stripUnknown: true })
    Object.assign(this, data)
    Object.freeze(this)
  }

  set id (value) {
    this._id = value
    return this._id
  }

  get id () {
    return this._id || this.profileId
  }

  get fullName () {
    return this.firstName + ' ' + this.lastName
  }
}
