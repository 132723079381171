import React from 'react'
import styles from '../AddInvoiceCard.module.scss'
import PropTypes from 'prop-types'
import DragAndDrop from '../../../components/DragAndDrop/DragAndDrop'
import Icon from '../../../components/Icon/Icon'

const AttachmentsArea = ({ formikProps, handleAddFile }) => {
  const {
    errors,
    values,
    setFieldValue,
  } = formikProps

  return (
    <>
      <input
        className={styles.fileInput}
        name='attachments'
        id='attachments'
        multiple
        type='file'
        onChange={(event) => {
          if (event.currentTarget.files.length > 0) {
            handleAddFile(event.currentTarget.files)
          }
        }}
      />
      <DragAndDrop handleDrop={handleAddFile}>
        <div className={styles.attachmentArea}>
          <label htmlFor='attachments'><Icon type='cloud-upload' colorType='blue' /> <span
            className='capture blue-bk'>Choose a file or drag it here</span>
          </label>
        </div>
      </DragAndDrop>
      <div className={styles.files}>
        {values.attachments.map((file, i) => {
          return (
            <div key={`${file.name}-${i}`}>
              {file.isLoading
                ? <div className={styles.fileLoader} />
                : <Icon {...(file.isErrored || (errors.attachments && errors.attachments[i])
                  ? { type: 'attention', colorType: 'red' }
                  : { type: 'file-empty', colorType: 'gray-70' })} />
              }
              <div
                className={file.isLoading ? 'capture gray-50' : 'capture gray-70'}>
                {(file.isErrored || (errors.attachments && errors.attachments[i])) &&
                <span className='gray-50'>{
                  errors.attachments && errors.attachments[i]
                    ? errors.attachments[i]
                    : 'Unable to download'
                }&nbsp;</span>}


                {file.name}</div>
              <button onClick={() => {
                setFieldValue('attachments', values.attachments.filter((file, index) => index !== i))
              }}>
                <Icon type='close' colorType='gray-30' />
              </button>
            </div>
          )
        })}
      </div>
    </>
  )
}

AttachmentsArea.propTypes = {
  handleAddFile: PropTypes.func,
  formikProps: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    values: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    submitForm: PropTypes.func,
    setFieldValue: PropTypes.func,
    submitCount: PropTypes.number,
  }),
}

export default AttachmentsArea
