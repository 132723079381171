// @flow
import AbstractRESTService from './AbstractRESTService'
import config from '../config'

const LOGIN_TYPE = 'code'
const URL_SIGN_UP = '/sign-up'
const URL_SIGN_IN = '/sign-in'
const URL_CHECK_PROFILE = '/profile/check'
const URL_PROFILE = '/profile/me'
const URL_UPDATE_PASSWORD = '/profile/update_password'
const URL_RESET_PASSWORD = '/profile/reset_password'
const URL_REFRESH_TOKEN = '/sign-in/refresh'
const URL_CONFIG = '/config'
const URL_CONFIRM_CODE = '/code/check'
const URL_SEND_CODE = '/code/send'

export default class AuthService extends AbstractRESTService {
  signUp ({ email, password, secret }: { email: string, password: string, secret?: string | null }) {
    return this.restService.request({
      method: 'POST',
      url: URL_SIGN_UP,
      data: {
        login: email,
        password,
        secret,
        loginType: LOGIN_TYPE,
        scope: config.authScope,
      },
    })
  }

  confirmCode (email: string, code: string) {
    return this.restService.request({
      method: 'POST',
      url: URL_CONFIRM_CODE,
      data: {
        email,
        code,
      },
    })
  }

  sendCode (props: { email: string, isForce: boolean, isRegisterCode: boolean }) {
    const { email, isForce, isRegisterCode } = props
    return this.restService.request({
      method: 'POST',
      url: URL_SEND_CODE,
      data: {
        scope: config.authScope,
        email,
        reset: !!isForce,
        type: isRegisterCode ? 'registerCode' : 'resetPasswordCode',
      },
    })
  }

  signIn ({ email, password }: { email: string, password: string, }) {
    return this.restService.request({
      method: 'POST',
      url: URL_SIGN_IN,
      data: {
        login: email,
        password,
        scope: config.authScope,
        loginType: LOGIN_TYPE,
      },
    })
  }

  isContactExists (email: string) {
    return this.restService.request({
      method: 'GET',
      url: URL_CHECK_PROFILE + '?email=' + encodeURIComponent(email),
    })
  }

  getProfile () {
    return this.restService.request({
      method: 'GET',
      headers: this.getBearerHeaders(),
      url: URL_PROFILE,
    })
  }

  updatePassword (oldPassword: string, newPassword: string) {
    return this.restService.request({
      method: 'POST',
      headers: this.getBearerHeaders(),
      url: URL_UPDATE_PASSWORD,
      data: {
        oldPassword,
        newPassword,
      },
    })
  }

  updateResetPassword ({ email, password, secret }: { email: string, password: string, secret?: string | null }) {
    return this.restService.request({
      method: 'POST',
      headers: this.getBearerHeaders(),
      url: URL_RESET_PASSWORD,
      data: {
        login: email,
        password,
        secret,
      },
    })
  }

  refreshToken (refreshToken: string) {
    return this.restService.request({
      method: 'POST',
      url: URL_REFRESH_TOKEN,
      data: {
        refreshToken,
      },
    })
  }

  loadConfig () {
    return this.restService.request({
      method: 'GET',
      url: URL_CONFIG,
    })
  }
}
