import React from 'react'
import PropTypes from 'prop-types'
import { TYPE_AMOUNT, TYPE_HOURS, TYPE_QUANTITY } from '../constants'

const ItemsTableTitles = ({ itemsType, currency }) => {
  switch (itemsType) {
    case TYPE_AMOUNT:
      return (
        <>
          <div>Description</div>
          <div>Amount, {currency}</div>
        </>
      )
    case TYPE_HOURS:
      return (
        <>
          <div>Description</div>
          <div>Rate, {currency}</div>
          <div>Hours</div>
          <div>Subtotal, {currency}</div>
        </>
      )
    case TYPE_QUANTITY:
      return (
        <>
          <div>Description</div>
          <div>Rate, {currency}</div>
          <div>Qty</div>
          <div>Subtotal, {currency}</div>
        </>
      )
    default:
      return null
  }
}

ItemsTableTitles.propTypes = {
  itemsType: PropTypes.string,
  currency: PropTypes.string,
}

export default ItemsTableTitles
