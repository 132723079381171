import { Scrollbars } from 'react-custom-scrollbars'
import cn from 'classnames'
import React from 'react'
import { Form, Formik, Field } from 'formik'
import PropTypes from 'prop-types'
import Icon from '../../components/Icon/Icon'
import { Button } from '../../components/Button'
import InputText from '../../components/InputText'
import Datepicker from '../../components/Datepicker'
import LoadingState from '../../components/LoadingState'
import {
  CURRENCY_RUB,
  CURRENCY_USD,
  CURRENCY_EUR,
  CURRENCY_NZD,
  CURRENCY_AUD,
  LOADING_STATE_LOADING,
  LOADING_STATE_LOADED,
  LOADING_STATE_ERROR,
} from '../../constants'
import { PaymentRequestModel } from '../../models/PaymentRequests/PaymentRequestModel'
import { itemsTypesMap } from './constants'
import BigNumber from 'bignumber.js'
import { ACCOUNT_TYPE_TREZOR, FORMAT_FIAT_NUMBERS } from '../../ducks/accounts/constants'
import styles from './AddInvoiceCard.module.scss'
import CustomSelect from '../../components/Select/CustomSelect'
import {
  handleAddItem,
  handleAddLogo,
  handleChangeItemsType,
  handleClickEnter,
  handleRemoveItem,
  handleAddFile,
  handleToggleAdditionalFields,
  handleSetTemplate,
  handleToggleDeletePopup,
  handleGetRequestLogo,
  handleSetTrezorWallet,
} from './parts/handlers'
import ItemsTable from './parts/ItemsTable'
import BillToSelect from './parts/BilllToSelect'
import InvoiceAccountSelect from './parts/InvoiceAccountSelect'
import AttachmentsArea from './parts/AttachmentsArea'
import LogoArea from './parts/LogoArea'
import AdditionalFieldsSelector from './parts/AdditionalFieldsSelector'
import get from 'lodash.get'
import {
  PAYMENT_REQUEST_STATUS_DELETED,
  PAYMENT_REQUEST_STATUS_DRAFT,
  PAYMENT_REQUEST_STATUS_UNPAID,
} from '../../ducks/paymentRequests/constants'
import TemplateSelector from './parts/TemplateSelector'
import BigPopup from '../../components/BigPopup'
import DeleteMessage from './parts/DeleteMessage'
import { requiredValidation } from './validate'
import Label from '../../components/Label/Label'
import { isTestnetAccount } from "../../ducks/accounts/utils"
import { amplitudeSendEvent } from '../../utils/analytics'


export default class AddInvoiceCardComponent extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: false,
      isShowBillToHint: false,
      isDeletePopupOpen: false,
      showedFields: {
        billFrom: false,
        subtitle: false,
      },
      isShowRequiredFields: false,
      trezorAccounts: {},
    }

    this.handlers = {
      handleSetTrezorWallet: handleSetTrezorWallet.bind(this),
      handleClickEnter: handleClickEnter.bind(this),
      handleAddItem: handleAddItem.bind(this),
      handleRemoveItem: handleRemoveItem.bind(this),
      handleChangeItemsType: handleChangeItemsType.bind(this),
      handleAddLogo: handleAddLogo.bind(this),
      handleAddFile: handleAddFile.bind(this),
      handleToggleAdditionalFields: handleToggleAdditionalFields.bind(this),
      handleSetTemplate: handleSetTemplate.bind(this),
      handleToggleDeletePopup: handleToggleDeletePopup.bind(this),
      handleGetRequestLogo: handleGetRequestLogo.bind(this),
    }
  }

  componentDidMount () {
    const element = document.getElementById(`inputId_billTo`)
    if (element) {
      element.focus()
    }
    this.setState({
      showedFields: {
        billFrom: !!get(this.props.initialValues, 'billFrom', false),
        subtitle: !!get(this, 'props.request.invoice.title', false),
      },
    })
    console.log('componentDidMount Add Invoice')
  }

  isDeletable = () => {
    const { request, profile } = this.props
    return request && profile.id === request.fromUserId && request.status === PAYMENT_REQUEST_STATUS_DRAFT
  }

  onMouseOver = () => {
    this.setState({
      isShowRequiredFields: true,
    })
  }

  onMouseLeave = () => {
    this.setState({
      isShowRequiredFields: false,
    })
  }

  renderForm = (formikProps) => {
    const {
      errors,
      touched,
      values,
      handleChange,
      handleBlur,
      submitForm,
      setFieldValue,
      submitCount,
    } = formikProps
    const { accounts, requestId, request } = this.props
    const handleSend = (e) => {
      amplitudeSendEvent('Invoice sent', {})
      setFieldValue('statusText', PAYMENT_REQUEST_STATUS_UNPAID)
      setTimeout(() => {
        submitForm(e)
      }, 100)
    }
    const handleSaveDraft = (e) => {
      amplitudeSendEvent('Draft saved', {})
      setFieldValue('statusText', PAYMENT_REQUEST_STATUS_DRAFT)
      setTimeout(() => {
        submitForm(e)
      }, 0)
    }

    const totalValue = values.items.reduce((acc, item) => {
      if (!item) {
        return acc
      }
      if (!new BigNumber(item.amount).isNaN()) {
        return acc.plus(item.amount)
      }
      if (!new BigNumber(item.count * item.rate).isNaN()) {
        return acc.plus(item.count * item.rate)
      }
      return acc
    }, new BigNumber(0)).toFormat(2, FORMAT_FIAT_NUMBERS)

    const billToPlaceholder = `Address
City, Country
Position`
    const loadingState = get(this, 'props.loadingState', null)
    const requestLoadingState = get(this, 'props.request.loadingState', null)
    const isDraft = get(this, 'props.request.status', null) === PAYMENT_REQUEST_STATUS_DRAFT
    const requiredFieldsErrors = requiredValidation(values)
    const isRequiredFieldFilled = Object.keys(requiredFieldsErrors).length <= 0
    const { isShowRequiredFields, trezorAccounts } = this.state
    const min = 10
    const max = 17
    const totalStyles = {}
    const totalTestSting = `${totalValue || '00.00'}${values.currency}`
    if (totalTestSting.length > min) {
      const minus = totalTestSting.length < max ? totalTestSting.length - min : max - min
      totalStyles.fontSize = (24 - minus) + 'px'
    }
    const trezorAccount = get(trezorAccounts, values.blockchain, null)
    const accountsList = [
      {
        id: `trezor_${get(trezorAccount, 'blockchain', values.blockchain)}`,
        type: ACCOUNT_TYPE_TREZOR,
        name: 'Trezor wallet',
        address: get(trezorAccount, 'address', null),
        isTestnet: isTestnetAccount(trezorAccount || { blockchain: values.blockchain }),
        blockchain: get(trezorAccount, 'blockchain', values.blockchain),
        defaultAccount: false,
        loadingState: get(trezorAccount, 'loadingState', null),
      },
      ...accounts,
    ]

    return (
      <>
        {loadingState === LOADING_STATE_LOADED && this.props.isSent && (
          <div className={styles.loadingContainer}>
            <div className={styles.message}>
              <Icon type='checkmark-circle' colorType='green' />
              {values.statusText === PAYMENT_REQUEST_STATUS_DRAFT
                ? <div className='h4 gray-30'>Your draft has been saved</div>
                : <div className='h4 gray-30'>Invoice has been sent to<br />{values.billTo}</div>}
              <Button colorType='new-border' onClick={this.props.onClose}>CLOSE</Button>
            </div>
          </div>
        )}
        {requestLoadingState === LOADING_STATE_LOADED
        && this.props.isDeleteSent
        && get(this, 'props.request.status', null) === PAYMENT_REQUEST_STATUS_DELETED
        && (
          <div className={styles.loadingContainer}>
            <div className={styles.message}>
              <Icon type='checkmark-circle' colorType='green' />
              <div className='h4 gray-30'>Your draft has been deleted.</div>
              <Button colorType='new-border' onClick={this.props.onClose}>CLOSE</Button>
            </div>
          </div>
        )}
        {requestLoadingState === LOADING_STATE_ERROR
        && this.props.isDeleteSent
        && get(this, 'props.request.status', null) === PAYMENT_REQUEST_STATUS_DRAFT
        && (
          <div className={styles.loadingContainer}>
            <div className={styles.message}>
              <Icon type='attention' colorType='red' />
              <div className='h4 gray-30'>There was a problem deleting your draft.<br />Please try again</div>
              <Button colorType='new-border' onClick={this.props.handleResetDeleteFlag}>CLOSE</Button>
            </div>
          </div>
        )}

        {(loadingState === LOADING_STATE_LOADING || requestLoadingState === LOADING_STATE_LOADING) && (
          <div className={styles.loadingContainer}>
            <LoadingState loadingState={LOADING_STATE_LOADING} />
          </div>
        )}

        <div className={styles.sideContent}>
          <div
            className={styles.sendButtonWrapper}
          >
            {!isRequiredFieldFilled && (
              <Label
                label='Fill all the required fields to send the invoice or save it as draft to edit later.'>
                <div
                  className={styles.hoverBLock}
                  onMouseOver={this.onMouseOver}
                  onMouseLeave={this.onMouseLeave}
                />
              </Label>
            )}
            <Button
              id='createInvoiceButton'
              onClick={handleSend}
              colorType='new-filled'
              disabled={!isRequiredFieldFilled}
            >send</Button>
          </div>
          {!values.requestId || request.status === PAYMENT_REQUEST_STATUS_DRAFT
            ? (
              <Button
                onClick={handleSaveDraft}
                colorType='new-border'
              >save draft</Button>
            )
            : null}
          {this.isDeletable() &&
          <Button colorType='new-border' onClick={this.handlers.handleToggleDeletePopup}>delete draft</Button>}
          <div className={styles.summary + ' gray-30'}>
            <div className='capture'>Total:</div>
            <div className='summary-amount'>
              <div className='h3' style={totalStyles}>
                {totalValue || '00.00'}<span className='h4' style={totalStyles}>{values.currency}</span>
              </div>
            </div>
            <AdditionalFieldsSelector
              showedFields={this.state.showedFields}
              handleToggleAdditionalFields={this.handlers.handleToggleAdditionalFields(formikProps)}
            />
          </div>
          <TemplateSelector handleSetTemplate={this.handlers.handleSetTemplate(formikProps)} />

          {loadingState === LOADING_STATE_ERROR && this.props.isSent && Object.keys(errors).length <= 0 && (
            <div className={styles.failedMessage}>
              <Icon type='attention' colorType='red' />
              {values.statusText === PAYMENT_REQUEST_STATUS_DRAFT
                ? (
                  <>
                    <div className='h5 gray-30'>There was a problem saving your draft.</div>
                    <div className='capture gray-30'>Please try again.</div>
                  </>
                )
                : (
                  <>
                    <div className='h5 gray-30'>Sorry, invoice<br />cannot be {requestId ? 'updated' : 'sent'}</div>
                    <div className='capture gray-30'>Please don’t close window<br />and try later</div>
                  </>)}
            </div>
          )}
          {Object.keys(errors).length > 0 && submitCount > 0 && (
            <div className={styles.failedMessage}>
              <Icon type='attention' colorType='red' />
              <div className='h5 gray-30'>Validation error</div>
              <div className='capture gray-30'>Please check the fields highlighted with red.</div>
            </div>
          )}
        </div>

        <div className={styles.mainContent}>
          <Scrollbars style={{ height: 'calc(100vh - 72px)' }}>
            <div>
              <Form
                name='newInvoiceForm'
                autoComplete="off"
              >
                <div className={styles.section + ' gray-70'}>
                  <LogoArea
                    request={this.props.request}
                    formikProps={formikProps}
                    handleAddLogo={this.handlers.handleAddLogo(formikProps)}
                    handleGetRequestLogo={this.handlers.handleGetRequestLogo}
                  />
                </div>
                <div className={styles.section + ' gray-70'}>
                  <div className={styles.sectionTitle + ' h5'}>Invoice Details</div>
                  <div className={cn(styles.line, styles.billToLine)}>
                    <label className='capture'>Bill to</label>
                    <BillToSelect
                      isShowRequiredFields={!!(isShowRequiredFields && requiredFieldsErrors.billTo)}
                      formikProps={formikProps}
                      billToSearchList={this.props.billToSearchList}
                      request={this.props.request}
                    />
                  </div>
                  {this.state.showedFields.billFrom && (
                    <div className={cn(styles.line, styles.billFromLine)}>
                      <label className='capture'>Bill from</label>
                      <InputText
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.billFrom && !!touched.billFrom}
                        errorText={touched.billFrom ? errors.billFrom: null}
                        value={values.billFrom}
                        name='billFrom'
                        placeholder={billToPlaceholder}
                        className={styles.billFromStyles}
                        textarea
                      />
                    </div>
                  )}
                  {this.state.showedFields.subtitle && (
                    <div className={styles.line}>
                      <label className='capture'>Title</label>
                      <InputText
                        placeholder='Invoice Title'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.subtitle && !!touched.subtitle}
                        value={values.subtitle}
                        name='subtitle'
                      />
                    </div>
                  )}
                  <div className={cn(styles.line, styles.invoiceNumberLine)}>
                    <label className='capture'>Invoice Number</label>
                    <InputText
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorColor={submitCount <= 0 && isShowRequiredFields ? 'blue' : null}
                      error={(!!touched.invoiceNumber && (submitCount > 0 || !!values.invoiceNumber) && !!errors.invoiceNumber) || !!(isShowRequiredFields && requiredFieldsErrors.invoiceNumber)}
                      errorText={(!!touched.invoiceNumber && (submitCount > 0 || !!values.invoiceNumber) && !!errors.invoiceNumber) ? errors.invoiceNumber : null}
                      value={values.invoiceNumber}
                      disabled={!!values.requestId && !isDraft}
                      name='invoiceNumber'
                      placeholder='0000001'
                    />
                  </div>

                  <div className={styles.line}>
                    <label className='capture'>Due</label>
                    <Field
                      error={(!!touched.due && (submitCount > 0 || !!values.due) && !!errors.due) || !!(isShowRequiredFields && requiredFieldsErrors.due)}
                      component={Datepicker}
                      name='due'
                      popperModifiers={{
                        flip: {
                          behavior: ['bottom'],
                        },
                        preventOverflow: {
                          enabled: false,
                        },
                      }}
                      minDate={new Date()}
                    />
                  </div>
                </div>
                <div className={styles.separator} />

                <div className={cn(styles.section, styles.accountsSection, 'gray-70')}>
                  <div className={styles.sectionTitle + ' h5'}>Payment Details</div>
                  <InvoiceAccountSelect
                    handleSetTrezorWallet={this.handlers.handleSetTrezorWallet(formikProps)}
                    isShowRequireError={!!(isShowRequiredFields && requiredFieldsErrors.account)}
                    formikProps={formikProps}
                    accounts={accountsList}
                  />
                </div>
                <div className={styles.separator} />

                <div className={cn(styles.section, styles.itemsSection, 'gray-70')}>
                  <div className={styles.sectionTitle + ' h5'}>Invoice Items</div>
                  <div className={styles.line}>
                    <CustomSelect
                      onChange={this.handlers.handleChangeItemsType(formikProps)}
                      defaultValue={itemsTypesMap[values.itemsType]}
                      name='itemsType'
                      options={Object.values(itemsTypesMap)}
                    />
                    <CustomSelect
                      onChange={({ value }) => setFieldValue('currency', value)}
                      error={!!errors.currency && !!touched.currency}
                      defaultValue={{ value: values.currency, label: values.currency }}
                      value={{ value: values.currency, label: values.currency }}
                      name='currency'
                      options={[
                        CURRENCY_RUB,
                        CURRENCY_USD,
                        CURRENCY_EUR,
                        CURRENCY_NZD,
                        CURRENCY_AUD,
                      ].map((currency) => ({ value: currency, label: currency }))}
                    />
                  </div>
                  <ItemsTable
                    isShowRequireError={!!(isShowRequiredFields && requiredFieldsErrors.items)}
                    requiredFieldsErrors={requiredFieldsErrors}
                    formikProps={formikProps}
                    handleClickEnter={this.handlers.handleClickEnter}
                    handleRemoveItem={this.handlers.handleRemoveItem}
                    handleAddItem={this.handlers.handleAddItem(formikProps)}
                    totalValue={totalValue}
                  />
                </div>
                <div className={styles.separator} />

                <div className={styles.section + ' gray-70'}>
                  <div className={styles.sectionTitle + ' h5'}>Attachments</div>
                  <AttachmentsArea
                    formikProps={formikProps}
                    handleAddFile={this.handlers.handleAddFile(formikProps)}
                  />
                </div>
                <div className={styles.separator} />

                <div className={styles.section + ' gray-70'}>
                  <div className={styles.sectionTitle + ' h5'}>Note (optional)</div>
                  <InputText
                    className={styles.noteStyles}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.comment && !!touched.comment}
                    value={values.comment}
                    name='comment'
                    placeholder='Type your notes here'
                    textarea
                  />
                </div>
              </Form>
            </div>
          </Scrollbars>
        </div>
      </>
    )
  }

  render () {
    return (
      <div>
        <div className={styles.header}>
          <button className='closeButton' onClick={this.props.onClose}><Icon scale='1.5' type='close'
            colorType='gray-50' />
          </button>
          <div
            className='title h3 gray-70'
          >{this.props.request
              ? 'Edit Invoice #' + this.props.request.invoice.totalNumber
              : 'New Invoice'}</div>
        </div>
        <div className={styles.panel}>
          <Formik
            validateOnChange
            validateOnBlur
            validate={this.props.validate}
            initialValues={{ ...this.props.initialValues }}
            onSubmit={this.props.handleSubmit}
            render={this.renderForm}
          />
        </div>
        {this.state.isDeletePopupOpen && (
          <BigPopup onClose={this.handlers.handleToggleDeletePopup} smallMode>
            <DeleteMessage
              onDelete={this.props.handleDelete(this.props.request.requestId)}
            />
          </BigPopup>
        )}
      </div>
    )
  }
}

AddInvoiceCardComponent.propTypes = {
  handleDelete: PropTypes.func,
  profile: PropTypes.object,
  billToSearchList: PropTypes.object,
  requestId: PropTypes.string,
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  emptyItems: PropTypes.object,
  loadingState: PropTypes.string,
  validate: PropTypes.func,
  handleResetFormLoadingState: PropTypes.func,
  request: PropTypes.instanceOf(PaymentRequestModel),
  accounts: PropTypes.arrayOf(PropTypes.shape({
    address: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    blockchain: PropTypes.string,
  })),
  isSent: PropTypes.bool,
  isDeleteSent: PropTypes.bool,
  getInitialValues: PropTypes.func,
  handleResetDeleteFlag: PropTypes.func,
  getRequestLogo: PropTypes.func,
  initialValues: PropTypes.object,
}

