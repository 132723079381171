import PropTypes from 'prop-types'
import React from 'react'
import { BLOCKCHAIN_BITCOIN, BLOCKCHAIN_BITCOIN_TESTNET } from '../../ducks/bitcoin/constants'
import { BLOCKCHAIN_ETHEREUM, BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY } from '../../ducks/ethereum/constants'
import SendBitcoinForm from './SendBitcoinForm'
import SendEthereumForm from './SendEthereumForm'
import { AccountModel } from '../../models/Accounts/AccountModel'

function SendFormResolver (props) {
  const { account } = props
  switch (account.blockchain) {
    case BLOCKCHAIN_ETHEREUM:
    case BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY:
      return <SendEthereumForm {...props} />
    case BLOCKCHAIN_BITCOIN:
    case BLOCKCHAIN_BITCOIN_TESTNET:
    default:
      return <SendBitcoinForm {...props} />
  }
}

SendFormResolver.propTypes = {
  handleChangeTab: PropTypes.func,
  account: PropTypes.instanceOf(AccountModel),
}

export default SendFormResolver

