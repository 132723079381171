import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { useState } from 'react'
import styles from './AddPaymentRequestSidePanel.module.scss'
import InputText from '../../components/InputText'
import Button from '../../components/Button/Button'
import { ratesSymbolMappedListSelector } from '../../ducks/rates/selectors'
import get from 'lodash.get'
import BigNumber from 'bignumber.js'
import { BITCOIN_DECIMAL_LENGTH, BITCOIN_SYMBOL } from '../../ducks/bitcoin/constants'
import { ACTIVE_CURRENCIES } from '../../constants'
import { sendReceiveMessageToEmail } from '../../ducks/accounts/thunks'
import CustomSelect from '../../components/Select/CustomSelect'
import { currentCurrencySelector, profileInfoSelector } from '../../ducks/profile/selectors'
import { FORMAT_FIAT_NUMBERS, ACCOUNT_TYPE_TREZOR } from '../../ducks/accounts/constants'
import { accountByIdSelector } from '../../ducks/accounts/selectors'
import AccountSelect from '../Select/AccountSelect'
import { ACCOUNTS_ACTIVE_BLOCKCHAINS } from '../../ducks/accounts/constants'
import { SECOND_STEP } from './AddPaymentRequestSidePanel'
import { amplitudeSendEvent } from "../../utils/analytics"

function FirstStep ({ rates, accounts, formikProps, handleSetTrezorWallet }) {
  const {
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    values,
    touched,
    validateForm,
  } = formikProps

  const [submitCount, setSubmitCount] = useState(0)
  const account = accounts.find((account) => values.accountId === account.id)
  const symbol = account ? account.symbol : BITCOIN_SYMBOL
  const rate = get(rates, `${symbol}.${values.currency}`, '--')

  const handleSetAmount = (e) => {
    const field = get(e, 'target.name', null)
    const value = get(e, 'target.value', null)
    switch (field) {
      case 'fiatAmount':
      case 'cryptoAmount': {
        if (!symbol) {
          handleChange(e)
          return
        }

        const rate = get(rates, field === 'fiatAmount' ? `${symbol}.${values.currency}` : `${values.currency}.${symbol}`, 1)
        handleChange(e)
        setFieldValue(
          field === 'fiatAmount' ? 'cryptoAmount' : 'fiatAmount',
          value > 0 ? new BigNumber(value).dividedBy(rate).toFixed(field === 'cryptoAmount' ? 2 : BITCOIN_DECIMAL_LENGTH) : '',
        )
        break
      }
      case 'currency': {
        const rate = get(rates, `${value}.${symbol}`, 1)
        setFieldValue('currency', value)
        if (values.fiatAmount > 0) {
          setFieldValue(
            'cryptoAmount',
            new BigNumber(values.fiatAmount)
              .multipliedBy(rate)
              .toFixed(BITCOIN_DECIMAL_LENGTH),
          )
        }
        break
      }
      case 'accountId': {
        handleChange(e)

        const isNew = get(e, 'target.isNew', false)
        setFieldValue('isNewAddress', isNew)
        setTimeout(() => {
          validateForm()
        }, 0)

        if (values.fiatAmount > 0 && symbol) {
          const rate = get(rates, `${symbol}.${values.currency}`, 1)
          setFieldValue(
            'cryptoAmount',
            new BigNumber(values.fiatAmount)
              .multipliedBy(rate)
              .toFixed(BITCOIN_DECIMAL_LENGTH),
          )
        }
        break
      }
      default:
        break
    }
  }

  const handleNextStep = () => {
    setTimeout(() => {
      amplitudeSendEvent('Payment request sent', {})
      setSubmitCount(submitCount + 1)
      validateForm()
        .then((errors) => {
          if (Object.keys(errors).length <= 0) {
            setFieldValue('step', SECOND_STEP)
          }
        })
    }, 0)
  }

  const postHandleSetTrezorWallet = (walletData) => {
    const wallet = handleSetTrezorWallet(walletData)
    if (wallet.address) {
      handleSetAmount({
        target: {
          name: 'accountId',
          value: wallet.address,
          isNew: false,
          isTrezor: true,
        },
      })
    }
  }

  return (
    <div className={styles.firstStepWrapper}>
      <div className={styles.amount}>
        <div className={styles.amountLine}>
          <div className={styles.selectWrapper}>
            <label className='capture gray-70'>Receive payment to</label>
            <AccountSelect
              formikProps={formikProps}
              accounts={accounts}
              handleSetTrezorWallet={postHandleSetTrezorWallet}
              selectProps={{
                isCreatableSelect: true,
                onChange: (value) => {
                  if (value) {
                    // when user choose any created wallet
                    handleSetAmount({
                      target: {
                        name: 'accountId',
                        value: value.id || value.value,
                        isNew: !!value.__isNew__,
                        isTrezor: value.type === ACCOUNT_TYPE_TREZOR,
                      },
                    })
                  }
                },
                value: account,
                isShowRequireError: !!errors.accountId,
                errorText: errors.accountId ? errors.accountId : null,
                error: !!errors.accountId,
              }}
            />
          </div>
          <div className={styles.selectWrapper}>
            <label className='capture'>&nbsp;</label>
            <CustomSelect
              onChange={(option) => {
                setFieldValue('blockchain', option.value)
                const defaultAccount = accounts.find((account) => account.blockchain === option.value && account.defaultAccount)
                const firstAccount = accounts.find((account) => account.blockchain === option.value)
                const existedAccountId = get(defaultAccount, 'id', null) || get(firstAccount, 'id', null)
                if (existedAccountId) {
                  setFieldValue('accountId', existedAccountId)
                  setFieldValue('isNewAddress', false)
                }
              }}
              defaultValue={ACCOUNTS_ACTIVE_BLOCKCHAINS.find((blockchain) => blockchain.value === values.blockchain) || ACCOUNTS_ACTIVE_BLOCKCHAINS[0]}
              name='blockchain'
              options={ACCOUNTS_ACTIVE_BLOCKCHAINS}
            />
          </div>
        </div>
      </div>
      <div className={styles.amount}>
        <div className={styles.amountLine}>
          <InputText
            name='fiatAmount'
            onChange={handleSetAmount}
            onBlur={handleBlur}
            error={(touched.fiatAmount || submitCount > 0) && !!errors.fiatAmount}
            value={values.fiatAmount}
            label='Amount'
            placeholder='Enter amount'
            errorText={(touched.fiatAmount || submitCount > 0) && errors.fiatAmount}
          />
          <CustomSelect
            label='&nbsp;'
            onChange={({ value }) => handleSetAmount({
              target: {
                name: 'currency',
                value,
              },
            })}
            defaultValue={{
              label: values.currency,
              value: values.currency,
            }}
            name='currency'
            options={Object.keys(ACTIVE_CURRENCIES).map((currency) => {
              return {
                label: currency,
                value: currency,
              }
            })}
          />
        </div>
        <InputText
          name='cryptoAmount'
          onChange={handleSetAmount}
          onBlur={handleBlur}
          error={(touched.cryptoAmount || submitCount > 0) && !!errors.cryptoAmount}
          value={values.cryptoAmount}
          errorText={(touched.cryptoAmount || submitCount > 0) && errors.cryptoAmount}
          postContent={symbol ? symbol : ''}
          placeholder='Enter amount'
          postPadding='60px'
        />
      </div>

      <div className={styles.rates}>
        <span className='capture gray-50'>Exchange rate:</span>
        {symbol && rate
          ? (
            <span
              className='capture gray-70'
            >{new BigNumber(rate).toFormat(2, FORMAT_FIAT_NUMBERS)} {values.currency}/{symbol}</span>
          )
          : (<span className='capture gray-70'>--</span>)}
      </div>

      <div className={styles.message}>
        <InputText
          name='message'
          onChange={handleChange}
          onBlur={handleBlur}
          error={(touched.message || submitCount > 0) && !!errors.message}
          value={values.message}
          label='Message'
          placeholder='Type your message here...'
          errorText={(touched.message || submitCount > 0) && errors.message}
        />
      </div>

      <div className={styles.action}>
        <Button type='button' onClick={handleNextStep}>Generate request</Button>
      </div>

    </div>
  )
}

FirstStep.propTypes = {
  rates: PropTypes.any,
  formikProps: PropTypes.object,
  handleSetTrezorWallet: PropTypes.func,
  accounts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    isTestnet: PropTypes.bool,
    blockchain: PropTypes.string,
    defaultAccount: PropTypes.bool,
    symbol: PropTypes.string,
  })),
}

function mapStateToProps (state, props) {
  return {
    account: accountByIdSelector(props.accountId)(state),
    profile: profileInfoSelector(state),
    rates: ratesSymbolMappedListSelector(state),
    currentCurrency: currentCurrencySelector(state),
  }
}

function mapDispatchToProps (dispatch) {
  return {
    handleSendReceiveMessageToEmail: (values, account) => dispatch(sendReceiveMessageToEmail(values, account)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstStep)
