import PropTypes from 'prop-types'
import React from 'react'
import styles from './NotificationPopup.module.scss'
import Icon from '../Icon/Icon'
import config from '../../config/getConfig'
import { notificationPropsSelector } from '../../ducks/ui/selectors'
import { setNotification } from '../../ducks/ui/actions'
import { connect } from 'react-redux'

function NotificationPopup ({ action, onClose, title, subTitle, isOpen }) {

  const iconParams = {
    type: 'info-circle',
    colorType: 'blue',
  }

  if (!title || !subTitle || !isOpen) {
    return null
  }

  return (
    <div className={styles.wrapper}>
      <button className={styles.closeButton} onClick={onClose}>
        <Icon type='close' scale='1.5' colorType='gray-50' />
      </button>
      <div className={styles.content}>
        <div className={styles.icon}><Icon {...iconParams} /></div>
        <div className={styles.title + ' h5 gray-70'}>{title}</div>
        {subTitle && <div className={styles.subTitle + ' capture gray-50'}>{subTitle}</div>}
        {action && (
          <button
            className={styles.action + 'capture-small blue-bk'}
            onClick={action.actionOnClick}>{action.actionTitle}</button>
        )}
      </div>
    </div>
  )
}

NotificationPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  action: PropTypes.shape({
    actionOnClick: PropTypes.func,
    actionTitle: PropTypes.string,
  }),
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
}

const mapStateToProps = (state) => {
  const warningPopupNotification = notificationPropsSelector('warningPopup')(state)
  return {
    isOpen: warningPopupNotification.isOpen,
    title: config.getConfig('warningNotificationTitle'),
    subTitle: config.getConfig('warningNotificationSubTitle'),
  }
}

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(setNotification('warningPopup', { 'isOpen': false })),
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPopup)
