// @flow
import get from 'lodash.get'
import * as a from './actions'
import { RestService } from '../../services/RestService'
import { push } from 'connected-react-router'
import configClass from '../../config/getConfig'
import {
  LOADING_STATE_ERROR,
  LOADING_STATE_LOADED,
  LOADING_STATE_LOADING,
} from '../../constants'
import {
  getProfile,
  subscribeToUserEvents,
  startLastActivityTimer,
  clearLastActivityIntervals,
} from '../profile/thunks'
import { startRatesUpdate } from '../rates/thunks'
import { loadAccounts } from '../accounts/thunks'
import { destroySession } from './actions'
import AuthError from '../../errors/AuthError'
import { accountsSelector } from '../accounts/selectors'
import { getBitcoinFeeRate } from '../bitcoin/thunks'
import { InsightSocketService } from '../../services/InsightSocketService'
import { CacheService } from '../../services/CacheService'
import { authSelector } from './selectors'
import {
  AUTH_FLOW_RESET_PASSWORD,
  AUTH_FLOW_SIGN_IN,
  AUTH_FLOW_SIGN_UP,
  AUTH_SESSION_CODE_CONFIRMED, AUTH_SESSION_CODE_EXPIRED, AUTH_SESSION_CODE_SENT,
} from './constants'
import { profileInfoSelector } from '../profile/selectors'
import { initUIState } from '../ui/thunks'
import { error413Handler } from './utils'
import { getUsersHint } from '../paymentRequests/thunks'
import type { Dispatch } from 'redux'
import type { getStateType } from '../accounts/models'
import Logger from '../../services/Logger'
import { EthereumSubscribeService } from "../../services/EthereumSubscribeService"
import { getEthereumLastBlocks, subscribeToLastBlock } from "../ethereum/thunks"
import { amplitudeSendEvent, amplitudeSetUserId } from '../../utils/analytics'

export const signUp = (values: { email: string, password: string }) => async (dispatch: Dispatch, getState: getStateType) => {
  try {
    dispatch(a.updateAuthStatus(LOADING_STATE_LOADING))

    const { secretCode } = authSelector(getState())
    const { data } = await RestService.request('authService::signUp', { ...values, secret: secretCode })
    if (data.error) {
      if (data.status === 414) {
        const { data } = await RestService.request('authService::sendCode', {
          email: values.email,
          isForce: true,
          isRegisterCode: true,
        })
        const confirmCodeSendTime = get(data, 'result.sentAt', null)
        if (!confirmCodeSendTime) {
          throw new AuthError('Something went wrong...', 401)
        }
        dispatch(a.updateAuth({
          confirmCodeSendTime,
          authSession: AUTH_SESSION_CODE_EXPIRED,
        }))
        dispatch(a.updateAuthStatus(LOADING_STATE_LOADED))
      } else {
        const message = get(data, 'error', 'Something went wrong.')
        throw new AuthError(message, data.status)
      }
    } else {
      if (data.result.profile && !data.result.profile.isConfirmed) {
        throw new AuthError('Not confirmed e-mail', 401)
      }
      const token = get(data, 'result.token', null)
      const refreshToken = get(data, 'result.refreshToken', null)
      if (!token) {
        throw new Error('Something went wrong')
      }
      RestService.authToken = token
      RestService.refreshToken = refreshToken
      amplitudeSendEvent('Password created', {})
      await dispatch(appInitialization())
      dispatch(goToMainPage())
      dispatch(a.updateAuthStatus(LOADING_STATE_LOADED))
    }
  } catch (e) {
    const errorStatus = get(e, 'code', 400)
    const errorMessage = get(e, 'message', 'Unknown error')
    dispatch(a.updateAuthStatus(LOADING_STATE_ERROR, errorMessage, errorStatus))
  }
}

export const signIn = (values: { email: string, password: string }) => async (dispatch: Dispatch) => {
  try {
    dispatch(a.updateAuthStatus(LOADING_STATE_LOADING))

    const { data } = await RestService.request('authService::signIn', { ...values })
    error413Handler(data)
    if (data.result.profile && !data.result.profile.isConfirmed) {
      throw new AuthError('Not confirmed e-mail', 401)
    }
    const token = get(data, 'result.token', null)
    const refreshToken = get(data, 'result.refreshToken', null)
    if (!token) {
      throw new Error('Something went wrong')
    }
    RestService.authToken = token
    RestService.refreshToken = refreshToken
    amplitudeSetUserId(data.result.profile.id)
    amplitudeSendEvent('Signed in', { Succesfully: 'Yes' })
    await dispatch(appInitialization())
    dispatch(goToMainPage())
    dispatch(a.updateAuthStatus(LOADING_STATE_LOADED))
  } catch (e) {
    amplitudeSendEvent('Signed in', { Succesfully: 'No' })
    const errorStatus = get(e, 'code', 400)
    const errorMessage = get(e, 'message', 'Unknown error')
    dispatch(a.updateAuthStatus(LOADING_STATE_ERROR, errorMessage, errorStatus))
  }
}

export const logout = () => async (dispatch: Dispatch) => {
  RestService.deleteAuthToken()
  RestService.deleteRefreshToken()
  dispatch(clearLastActivityIntervals())
  amplitudeSetUserId(null)
  InsightSocketService.disconnect(true)
  dispatch(push('/auth'))
  dispatch(destroySession())
  CacheService.clearAllCache()
}

export const checkEmail = (email: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(a.updateEmailLoadingState(LOADING_STATE_LOADING))

    const response = await RestService.request('authService::isContactExists', email)
    const isProfileConfirmed = get(response, 'data.result.isConfirmed', false)
    let confirmCodeSendTime = null
    if (!isProfileConfirmed) {
      amplitudeSendEvent('Email provided', { UserType: 'New' })
      const responseSignUp = await RestService.request('authService::sendCode', {
        email,
        isForce: false,
        isRegisterCode: true,
      })
      error413Handler(responseSignUp.data)
      confirmCodeSendTime = get(responseSignUp, 'data.result.sentAt', null)
    } else {
      amplitudeSendEvent('Email provided', { UserType: 'Existing' })
    }
    dispatch(a.updateAuth({
      isProfileConfirmed,
      confirmCodeSendTime,
      authSession: AUTH_SESSION_CODE_SENT,
      email,
    }))
    dispatch(a.setAuthFLow(isProfileConfirmed ? AUTH_FLOW_SIGN_IN : AUTH_FLOW_SIGN_UP))
    dispatch(a.updateEmailLoadingState(LOADING_STATE_LOADED))
  } catch (e) {
    const errorStatus = get(e, 'code', 400)
    const errorMessage = get(e, 'message', 'Unknown error')
    dispatch(a.updateEmailLoadingState(LOADING_STATE_ERROR, errorMessage, errorStatus))
  }
}

export const resetLogin = () => async (dispatch: Dispatch) => {
  dispatch(a.resetLogin())
}

export const setDesktopViewport = () => async () => {
  const viewport = document.querySelector("meta[name=viewport]")
  if (viewport) {
    viewport.setAttribute('content', 'width=1350, initial-scale=0.5, minimum-scale=0.25, maximum-scale=1.0, user-scalable=yes')
  }
}

export const isAuthenticated = () => {
  return RestService.isAuthenticated()
}

export const goToMainPage = () => (dispatch: Dispatch) => {
  dispatch(push('/payments'))
}

export const appInitialization = () => async (dispatch: Dispatch, getState: getStateType) => {
  try {
    dispatch(a.updateAppLoadingState(LOADING_STATE_LOADING))
    dispatch(a.updateLoadingStep('Getting your Profile data'))

    await configClass.loadConfig()

    dispatch(startLastActivityTimer())

    dispatch(initUIState())
    await dispatch(getProfile())
    const state = getState()
    const profile = state.profile.info
    if (profile.id) {
      amplitudeSetUserId(profile.id)
    }

    Logger.identify(profile.id, {
      name: profile.name,
      email: profile.email,
    })

    dispatch(a.updateLoadingStep('Connecting to WebSocket server'))

    InsightSocketService.connect(RestService.authToken)
    EthereumSubscribeService.connect(RestService.authToken)
    dispatch(subscribeToLastBlock())

    dispatch(a.updateLoadingStep('Loading accounts'))

    await dispatch(loadAccounts(profile.id))
    await dispatch(subscribeToUserEvents(profile.id))

    dispatch(a.updateLoadingStep('Getting balances, currency rates and teams'))

    await Promise.all([
      dispatch(getBitcoinFeeRate()),
      dispatch(getEthereumLastBlocks()),
      dispatch(startRatesUpdate()),
      dispatch(getUsersHint()),
    ])

    RestService.handleRefreshToken()

    const accounts = accountsSelector(getState())
    const isLastName = profile.lastName && profile.lastName.length > 0
    const isFirstName = profile.firstName && profile.firstName.length > 0
    if (accounts.length > 0 && isLastName && isFirstName) {
      dispatch(goToMainPage())
    }

    dispatch(a.updateAppLoadingState(LOADING_STATE_LOADED))
  } catch (e) {
    Logger.error(e)
    dispatch(a.updateAppLoadingState(LOADING_STATE_ERROR))
    InsightSocketService.disconnect(true)
    RestService.deleteAuthToken()
    RestService.deleteRefreshToken()
    dispatch(push('/error'))
    throw e
  }
}

export const confirmCode = (email: string, code: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(a.updateAuthStatus(LOADING_STATE_LOADING))
    const { data } = await RestService.request('authService::confirmCode', email, code)
    error413Handler(data)

    const isValid = get(data, 'result.isValid', false)
    const secretCode = get(data, 'result.secret', false)
    if (!isValid) {
      amplitudeSendEvent('Email confirmed', { Verified: 'wrong code' })
      throw new AuthError('The code you have entered is not valid', 401)
    }
    amplitudeSendEvent('Email confirmed', { Verified: 'Yes' })
    dispatch(a.updateAuth({
      isCodeConfirmed: isValid,
      secretCode,
      authSession: AUTH_SESSION_CODE_CONFIRMED,
      confirmCodeSendTime: null,
    }))
    dispatch(a.updateAuthStatus(LOADING_STATE_LOADED))
  } catch (e) {

    const errorStatus = get(e, 'code', 400)
    const errorMessage = get(e, 'message', 'Unknown error')
    dispatch(a.updateAuthStatus(LOADING_STATE_ERROR, errorMessage, errorStatus))
  }
}

export const resendConfirmationCode = (email: string) => async (dispatch: Dispatch, getState: getStateType) => {
  try {
    dispatch(a.updateAuthStatus(LOADING_STATE_LOADING))
    const { authFlow } = authSelector(getState())
    const { data } = await RestService.request('authService::sendCode', {
      email,
      isRegisterCode: authFlow === AUTH_FLOW_SIGN_UP,
    })
    const confirmCodeSendTime = get(data, 'result.sentAt', null)
    if (!confirmCodeSendTime) {
      throw new AuthError('The code you have entered is not valid', 401)
    }
    dispatch(a.updateAuth({
      authSession: AUTH_SESSION_CODE_SENT,
      confirmCodeSendTime,
    }))
    dispatch(a.updateAuthStatus(LOADING_STATE_LOADED))
  } catch (e) {

    const errorStatus = get(e, 'code', 400)
    const errorMessage = get(e, 'message', 'Unknown error')
    dispatch(a.updateAuthStatus(LOADING_STATE_ERROR, errorMessage, errorStatus))
  }
}

export const resetPassword = () => async (dispatch: Dispatch, getState: getStateType) => {
  try {
    dispatch(a.updateAuthStatus(LOADING_STATE_LOADING))
    const { email } = authSelector(getState())
    const { data } = await RestService.request('authService::sendCode', {
      email,
      isForce: true,
      isRegisterCode: false,
    })
    error413Handler(data)

    const confirmCodeSendTime = get(data, 'result.sentAt', null)
    if (!confirmCodeSendTime) {
      throw new AuthError('Something went wrong...', 401)
    }
    dispatch(a.setAuthFLow(AUTH_FLOW_RESET_PASSWORD))
    dispatch(a.updateAuth({
      confirmCodeSendTime,
      authSession: AUTH_SESSION_CODE_SENT,
    }))
    dispatch(a.updateAuthStatus(LOADING_STATE_LOADED))
  } catch (e) {
    const errorStatus = get(e, 'code', 400)
    const errorMessage = get(e, 'message', 'Unknown error')
    dispatch(a.updateAuthStatus(LOADING_STATE_ERROR, errorMessage, errorStatus))
  }
}

export const updateResetPassword = ({ password }: { password: string }) => async (dispatch: Dispatch, getState: getStateType) => {
  console.log('updateResetPassword', password)
  try {
    dispatch(a.updateAuthStatus(LOADING_STATE_LOADING))
    const { email } = authSelector(getState())
    const { secretCode } = authSelector(getState())
    const { data } = await RestService.request('authService::updateResetPassword', {
      password,
      email,
      secret: secretCode,
    })

    if (data.error) {
      if (data.status === 414) {
        const { data } = await RestService.request('authService::sendCode', {
          email,
          isForce: true,
          isRegisterCode: false,
        })
        const confirmCodeSendTime = get(data, 'result.sentAt', null)
        if (!confirmCodeSendTime) {
          throw new AuthError('Something went wrong...', 401)
        }
        dispatch(a.updateAuth({
          confirmCodeSendTime,
          authSession: AUTH_SESSION_CODE_EXPIRED,
        }))
        dispatch(a.updateAuthStatus(LOADING_STATE_LOADED))
      } else {
        const message = get(data, 'error', 'Something went wrong.')
        throw new AuthError(message, data.status)
      }
    } else {
      if (data.result && !data.result.isConfirmed) {
        throw new AuthError('Something wend wrong.', 401)
      }

      dispatch(a.updateAuthStatus(LOADING_STATE_LOADED))
      dispatch(signIn({ email, password }))
    }
  } catch (e) {

    const errorStatus = get(e, 'code', 400)
    const errorMessage = get(e, 'message', 'Unknown error')
    dispatch(a.updateAuthStatus(LOADING_STATE_ERROR, errorMessage, errorStatus))
  }
}

export const resetPasswordFromProfile = () => async (dispatch: Dispatch, getState: getStateType) => {
  try {
    dispatch(a.updateAuthStatus(LOADING_STATE_LOADING))
    const info = profileInfoSelector(getState())
    const { data } = await RestService.request('authService::sendCode', {
      email: info.email,
      isForce: true,
      isRegisterCode: false,
    })

    const confirmCodeSendTime = get(data, 'result.sentAt', null)
    if (!confirmCodeSendTime) {
      throw new AuthError('Something went wrong...', 401)
    }
    dispatch(a.updateAuthStatus(LOADING_STATE_LOADED))
    await dispatch(logout())

    dispatch(a.setAuthFLow(AUTH_FLOW_RESET_PASSWORD))
    dispatch(a.updateAuth({
      email: info.email,
      confirmCodeSendTime,
      authSession: AUTH_SESSION_CODE_SENT,
    }))
  } catch (e) {
    const errorStatus = get(e, 'code', 400)
    const errorMessage = get(e, 'message', 'Unknown error')
    dispatch(a.updateAuthStatus(LOADING_STATE_ERROR, errorMessage, errorStatus))
  }
}
