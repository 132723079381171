export const AUTH_DUCK = 'auth'

export const AUTH_UPDATE_STATUS = 'auth/updateStatus'
export const APP_UPDATE_LOADING_STATE = 'app/updateLoadingStatus'
export const APP_UPDATE_LOADING_STEP = 'app/updateLoadingStep'
export const AUTH_CHECK_EMAIL_LOADING_STATE = 'auth/checkEmailLoadingStep'
export const AUTH_UPDATE_EMAIL = 'auth/updateEmail'
export const AUTH_RESET_LOGIN = 'auth/resetLogin'
export const AUTH_SET_FLOW = 'auth/setFlow'

export const AUTH_FLOW_SIGN_IN = 'sigIn'
export const AUTH_FLOW_SIGN_UP = 'sigUp'
export const AUTH_FLOW_RESET_PASSWORD = 'resetPassword'

export const AUTH_SESSION_CODE_SENT = 'codeSent'
export const AUTH_SESSION_CODE_CONFIRMED = 'codeConfirmed'
export const AUTH_SESSION_CODE_EXPIRED = 'codeExpired'
