import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import throttle from 'lodash.throttle'
import { amplitudeInit } from '../../utils/analytics'
import { appInitialization, isAuthenticated } from '../../ducks/auth/thunks'
import { updateLastActivityThunk } from '../../ducks/profile/thunks'
import { push } from 'connected-react-router'
import styles from './InitProvider.module.scss'
import Logger from '../../services/Logger'

class InitProvider extends React.PureComponent {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isAppInitialized: PropTypes.bool,
    appLoadingState: PropTypes.string,
    handleInitialize: PropTypes.func,
    children: PropTypes.node,
    goToErrorPage: PropTypes.func,
    updateLastActivity: PropTypes.func,
  }

  async componentDidMount () {
    amplitudeInit()
    if (this.props.isAuthenticated) {
      await this.props.handleInitialize()
    }
  }

  componentDidCatch (error, errorInfo) {
    this.props.goToErrorPage(error, errorInfo)
  }

  handleMouseMove = () => {
    this.props.updateLastActivity()
  }

  render () {
    return (
      <div className={styles.wrapper} onMouseMove={throttle(this.handleMouseMove, 1000)}>
        {this.props.children}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(),
  appLoadingState: state.auth.appLoadingState,
})

const mapDispatchToProps = (dispatch) => ({
  handleInitialize: () => dispatch(appInitialization()),
  goToErrorPage: (error, errorInfo) => {
    Logger.error(error, errorInfo)
    dispatch(push('/error'))
  },
  updateLastActivity: () => dispatch(updateLastActivityThunk()),
})

export default connect(mapStateToProps, mapDispatchToProps)(InitProvider)
