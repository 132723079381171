import React from 'react'
import Icon from '../../../components/Icon/Icon'
import styles from '../AddInvoiceCard.module.scss'
import PropTypes from 'prop-types'

const AdditionalFieldsSelector = ({ handleToggleAdditionalFields, showedFields }) => {
  return (
    <div className={styles.additionalFieldsSelector}>
      <button onClick={handleToggleAdditionalFields('billFrom', showedFields.billFrom)}>
        <Icon type={showedFields.billFrom ? 'minus-circle' : 'plus-circle'} colorType='blue' />
        <div className='blue-bk'>From</div>
      </button>
      <button onClick={handleToggleAdditionalFields('subtitle', showedFields.subtitle)}>
        <Icon type={showedFields.subtitle ? 'minus-circle' : 'plus-circle'} colorType='blue' />
        <div className='blue-bk'>Title</div>
      </button>
    </div>
  )
}

AdditionalFieldsSelector.propTypes = {
  handleToggleAdditionalFields: PropTypes.func,
  showedFields: PropTypes.shape({
    billFrom: PropTypes.bool,
    subtitle: PropTypes.bool,
  }),
}

export default AdditionalFieldsSelector
