import { AccountModel } from '../../models/Accounts/AccountModel'
import * as c from './constants'
import { LOADING_STATE_ERROR, LOADING_STATE_LOADED } from '../../constants'

const getInitialState = () => ({
  list: [],
  loadingState: LOADING_STATE_LOADED,
  sendFormSettings: {
    fees: null,
    loadingState: null,
  },
})

const mutations = {
  [c.ACCOUNTS_UPDATE_LOADING_STATE]: (state, { loadingState }) => {
    return {
      ...state,
      loadingState,
    }
  },
  [c.ACCOUNTS_ADD_ACCOUNT]: (state, { account }) => {
    const newAccount = account
    if (!newAccount.id) {
      //eslint-disable-next-line
      console.warn('Account shouldn\'t have an empty ID')
      return state
    }

    const existedAccount = state.list.find((account) => account.id === newAccount.id)
    if (existedAccount) {
      return {
        ...state,
        list: state.list.map((account) => newAccount.id === account.id
          ? newAccount
          : account,
        ),
      }
    }

    return {
      ...state,
      list: state.list.concat(newAccount),
    }
  },
  [c.ACCOUNTS_REMOVE_ACCOUNT]: (state, { accountId }) => {
    const filteredList = state.list.filter((account) => account.id !== accountId)

    return {
      ...state,
      list: [...filteredList],
    }
  },
  [c.ACCOUNTS_UPDATE_BALANCE]: (state, { accountId, balance, formattedBalance }) => {
    return {
      ...state,
      list: state.list.map((account) => {
        if (account.id === accountId) {
          return new AccountModel({
            ...account,
            balance,
            formattedBalance,
            balanceLoadingState: LOADING_STATE_LOADED,
          })
        }
        return account
      }),
    }
  },
  [c.ACCOUNTS_INCREASE_BALANCE]: (state, { accountId, increaseBalance, increaseFormattedBalance }) => {
    return {
      ...state,
      list: state.list.map((account) => {
        if (account.id === accountId) {
          return new AccountModel({
            ...account,
            balance: account.balance.plus(increaseBalance),
            formattedBalance: account.formattedBalance.plus(increaseFormattedBalance),
          })
        }
        return account
      }),
    }
  },
  [c.ACCOUNTS_UPDATE_BALANCE_ERROR]: (state, { accountId }) => {
    return {
      ...state,
      list: state.list.map((account) => {
        if (account.id === accountId) {
          return new AccountModel({
            ...account,
            balanceLoadingState: LOADING_STATE_ERROR,
          })
        }
        return account
      }),
    }
  },
  [c.ACCOUNTS_UPDATE]: (state, { account }) => {
    return {
      ...state,
      list: state.list.map((oldAccount) => {
        if (oldAccount.id === account.id) {
          return account
        }
        return oldAccount
      }),
    }
  },
  [c.ACCOUNTS_UPDATE_SEND_FORM_SETTINGS]: (state, { settings }) => {
    return {
      ...state,
      sendFormSettings: {
        ...state.sendFormSettings,
        ...settings,
      },
    }
  },
}

export default (state = getInitialState(), { type, ...payload }) => {
  return (type in mutations)
    ? mutations[type](state, payload)
    : state
}
