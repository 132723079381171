import React from 'react'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import PropTypes from 'prop-types'
import get from 'lodash.get'
import cn from 'classnames'
import styles from './CustomSelect.module.scss'
import Icon from '../Icon/Icon'
import { errorsColorsMap } from '../../styles/styleVariables'

export default class CustomSelect extends React.Component {
  static propTypes = {
    errorColor: PropTypes.string,
    styles: PropTypes.object,
    error: PropTypes.bool,
    isCreatableSelect: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    className: PropTypes.string,
    label: PropTypes.string,
    errorText: PropTypes.string,
    errorWithIcon: PropTypes.bool,
  }

  constructor (props) {
    super(props)
    this.customRef = React.createRef()
  }

  render () {
    const selectStyles = {
      control: (styles) => {
        const animation = this.props.error ? 'errorAnimation .2s linear 1' : null
        const errorColor = get(errorsColorsMap, `${this.props.errorColor}.color`, errorsColorsMap.default.color)
        const backgroundColor = get(errorsColorsMap, `${this.props.errorColor}.background`, errorsColorsMap.default.background)
        const boxShadow = get(errorsColorsMap, `${this.props.errorColor}.boxShadow`, errorsColorsMap.default.boxShadow)
        return ({
          ...styles,
          padding: '2px 6px',
          minHeight: '42px',
          border: this.props.error ? `1px solid ${errorColor}` : 'solid 1px #cfd2d7',
          animation,
          boxShadow: this.props.error ? boxShadow : 'none',
          '&:hover': {
            borderColor: this.props.error ? errorColor : '#cfd2d7',
            boxShadow: 'none',
          },
          backgroundColor: this.props.error ? backgroundColor : null,
        })
      },
      option: (styles, { isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          padding: '13px 12px',
          color: '#5B5D69',
          fontFamily: 'Nunito Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '13px',
          lineHeight: '130%',
          cursor: isDisabled ? 'not-allowed' : 'pointer',
          backgroundColor: isDisabled
            ? null
            : isSelected
              ? '#f6fbfb'
              : isFocused
                ? '#f6fbfb'
                : null,
        }
      },
      singleValue: (styles, { isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          color: '#5B5D69',
          fontFamily: 'Nunito Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '13px',
          lineHeight: '130%',
          backgroundColor: isDisabled
            ? null
            : isSelected
              ? '#f6fbfb'
              : isFocused
                ? '#f6fbfb'
                : null,
        }
      },
      placeholder: (styles) => ({
        ...styles,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#5b5d69',
        opacity: 0.5,
      }),
      input: (styles) => ({
        ...styles,
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '13px',
      }),
      menu: (styles) => ({
        ...styles,
        borderRadius: '3px',
        marginTop: '0',
        marginBottom: '0',
        boxShadow: '0px 10px 10px #f0f2f9',
      }),
      menuList: (styles) => {
        return {
          ...styles,
          padding: '0',
        }
      },
      indicatorSeparator: (styles) => ({
        ...styles,
        display: 'none',
      }),
    }

    let Component = Select
    if (this.props.isCreatableSelect) {
      Component = CreatableSelect
    }

    const handleFocus = () => {
      const value = get(this, 'props.value.value', null)
      const handleInputChange = get(this, 'customRef.select.select.handleInputChange', null)
      this.props.onChange(null)
      if (handleInputChange && value) {
        handleInputChange({ currentTarget: { value } }, { action: 'input-change' })
      }
      if (typeof this.props.onFocus === 'function') {
        this.props.onFocus()
      }
    }
    return (
      <div className={this.props.className}>
        {this.props.label ? <label className={styles.label}>{this.props.label}</label> : ''}
        <Component
          {...this.props}
          styles={{
            ...selectStyles,
            ...this.props.styles,
          }}
          ref={(ref) => {
            this.customRef = ref
          }}
          onFocus={this.props.isCreatableSelect ? handleFocus : this.props.onFocus}
          onChange={(value) => {
            this.props.onChange(value)
            const inputRef = get(this, 'customRef.select.select.inputRef', null)
            if (inputRef) {
              inputRef.blur()
            }
          }}
        />
        {this.props.errorText ? <div className={cn(styles.errorText, 'capture', 'red')}>
          {this.props.errorWithIcon && <Icon type='attention' colorType='red' />}
          {this.props.errorText}
        </div> : ''}
      </div>
    )
  }
}
