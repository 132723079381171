//@flow
/*eslint-disable */
import EventEmitter from 'events'
import config from '../config/index'
import type { AnyWallet } from '../ducks/accounts/models'
import Logger from './Logger'
import Web3 from "web3"
import get from 'lodash.get'

type KeyType = {
  address: string,
  meta: {
    network: string
  }
}
type SubscriptionType = {
  on: (key: string, (...params: any)=> void)=> SubscriptionType,
  unsubscribe: ()=> void,
}

class EthereumSubscribeServiceClass extends EventEmitter {
  token: string | null
  initSubscriptionsArray: Array<{
    key: string,
    init: ()=> void,
  }>
  subscriptions: { [string]: (...params: any)=> Promise<any> | void }
  urls: {
    main: string,
    rinkeby: string,
  }
  globalSubscriptions: {
    [string]: SubscriptionType
  }
  web3: {
    [string]: {
      eth: {
        subscribe: (eventName: string, callback: ()=> void)=>SubscriptionType,
        getBlock: (blockHash: string, loadTransactions: boolean)=> any,
      }
    }
  }

  static getSubscriptionKey (account: KeyType) {
    return `${account.meta.network}:address:${account.address}`
  }

  constructor (urls: {
    main: string,
    rinkeby: string,
  }) {
    super()
    this.initSubscriptionsArray = []
    this.urls = urls
    this.globalSubscriptions = {}
    this.subscriptions = {}
    this.web3 = {}
  }

  getSubscriptionKey = (account: KeyType) => {
    return EthereumSubscribeServiceClass.getSubscriptionKey(account)
  }

  connect = (token: string | null) => {
    try {
      if (token) {
        this.setToken(token)
      }

      Object.entries(this.urls).forEach(([key, url]) => {
        const provider = new Web3.providers.WebsocketProvider(url)
        this.web3[key] = new Web3(provider)
        const newBlockHeadersSubscription = this.web3[key].eth.subscribe('newBlockHeaders', (error, result) => {
          if (!error) {
            return
          }
          Logger.error(error, { 'newBlockHeaders': true, result })
        })
          .on("data", (blockHeader) => {
            this.emit('block', { blockchain: key, block: blockHeader })
            this.getBlockAndEmit(blockHeader.hash, key)
          })
          .on("error", (error) => {
            Logger.error(error, { key, url })
          })
        this.globalSubscriptions[key] = newBlockHeadersSubscription
      })

      this.runSubscriptionInit()
    } catch (e) {
      Logger.error(e)
    }
  }

  getBlockAndEmit = async (blockHash: string, web3Key: string, repeatCount: number = 1) => {
    try {
      const web3 = this.web3[web3Key]

      const block = await web3.eth.getBlock(blockHash, true)
      if (!block && repeatCount <= 3) {
        setTimeout(() => {
          this.getBlockAndEmit(blockHash, web3Key, repeatCount + 1)
        }, repeatCount * 1000)
        return
      }

      const transactions = get(block, 'transactions', [])

      transactions.forEach((tx) => {
        const from = tx.from.toLowerCase()
        const to = tx.to == null
          ? null
          : tx.to.toLowerCase()

        const txToEmit = {
          tx: {
            ...tx,
            blockTime: block.timestamp,
          },
        }

        if (from) {
          const emitKey = EthereumSubscribeServiceClass.getSubscriptionKey({
            address: from,
            meta: { network: web3Key },
          })
          this.emit(`${emitKey}:tx`, txToEmit)
        }

        if (to) {
          const emitKey = EthereumSubscribeServiceClass.getSubscriptionKey({
            address: to,
            meta: { network: web3Key },
          })
          this.emit(`${emitKey}:tx`, txToEmit)
        }
      })
    } catch (e) {
      Logger.error(e, { name: 'web3.eth.getBlock' })
    }
  }

  disconnect (clearSubscriptionsArray: boolean = false) {
    try {
      if (clearSubscriptionsArray) {
        this.initSubscriptionsArray = []
      }

      Object.entries(this.subscriptions)
        .forEach(([key, subscription]) => {
          this.removeListener(key, subscription)
        })
      Object.entries(this.globalSubscriptions)
        .forEach(([, subscription]) => {
          if (subscription && typeof subscription.unsubscribe === 'function') {
            // $flow-disable-line
            subscription.unsubscribe()
          }
        })
      this.globalSubscriptions = {}
      this.subscriptions = {}
      this.web3 = {}
    } catch (e) {
      Logger.error(e)
    }
  }

  clearSubscriptions = () => {
    this.subscriptions = {}
  }

  removeSubscription = (subscriptionKey: string) => {
    // $flow-disable-line
    this.initSubscriptionsArray = this.initSubscriptionsArray.filter(({ key }) => key !== subscriptionKey)
  }

  addSubscription = (subscriptionKey: string, initFunction: () => void) => {
    if (typeof initFunction !== 'function') {
      //eslint-disable-next-line
      console.warn('addSubscription not a function: ', typeof initFunction)
      return null
    }

    // $flow-disable-line
    this.initSubscriptionsArray.push({
      key: subscriptionKey,
      init: initFunction,
    })

    const web3Key = subscriptionKey.split(':').shift()

    if (this.web3[web3Key]) {
      initFunction()
    }
  }

  runSubscriptionInit = () => {
    if (!this.initSubscriptionsArray.length) {
      return null
    }

    this.initSubscriptionsArray.forEach(({ init }) => {
      init()
    })
  }

  subscribeAccount (account: AnyWallet, txCallback: (tx: any) => Promise<any>, confirmationCallback?: (data: any) => void) {
    const subscribeKey = EthereumSubscribeServiceClass.getSubscriptionKey({
      address: account.address,
      meta: {
        network: account.meta.network,
      },
    })

    if (this.subscriptions[subscribeKey]) {
      //eslint-disable-next-line
      console.warn('Account is already subscribed to WS: ', account, this.subscriptions[subscribeKey])
      return null
    }

    this.subscriptions[subscribeKey] = txCallback
    this.on(`${subscribeKey}:tx`, txCallback)
    if(confirmationCallback){
      // doing nothing
    }
  }

  unsubscribeAccount (account: AnyWallet) {
    const subscribeKey = EthereumSubscribeServiceClass.getSubscriptionKey({
      address: account.address,
      meta: {
        network: account.meta.network,
      },
    })
    Object.entries(this.subscriptions)
      .forEach(([key, subscription]) => {
        if (subscription && typeof subscription.close === 'function' && subscribeKey === key) {
          // $flow-disable-line
          subscription.close()
          delete this.subscriptions[subscribeKey]
        }
      })
    this.removeSubscription(subscribeKey)
  }

  subscribeToLastBlock (callback: ({ blockchain: string, block: any })=>void) {
    this.subscriptions.block = callback
    this.on(`block`, callback)
  }

  unsubscribeToLastBlock () {
    if (this.subscriptions.block) {
      this.subscriptions.block.close()
    }
    delete this.subscriptions.block
  }

  setToken = (token: string | null) => {
    this.token = token
  }

  getToken = () => {
    return this.token
  }
}

export const EthereumSubscribeService = new EthereumSubscribeServiceClass(config.urls.ethereumWS)
