import React from 'react'
import styles from './MobileMessage.module.scss'
import { getCookie, setCookie } from '../../utils/auth'
import isMobile from 'ismobilejs'


export default function MobileMessage () {
  return (
    <div className={styles.wrapper}>
      <div className={styles.logo} />
      <div className={styles.image} />
      <div>
        <div className={styles.title + ' h5 gray-70'}>Please use desktop version</div>
        <div className={styles.subTitle + ' capture gray-50'}>
          {'We are working on the mobile version of PaymentX, but it is not ready yet. Please use desktop version instead for better experience'}
        </div>
        <div
          className={styles.button}
          onClick={() => {
            setCookie('isUserWarned', true)
            window.location = '/'
          }}
        >I understand, take me to mobile site anyway</div>
      </div>
    </div>
  )
}

export const isNeedToWarnMobileUser = () => {
  const isUserWarned = getCookie('isUserWarned')
  const isClientMobile = isMobile()
  return (isClientMobile.phone || isClientMobile.tablet) && !isUserWarned
}

