import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { logout } from '../../ducks/auth/thunks'

class Logout extends React.PureComponent {
  static propTypes = {
    logout: PropTypes.func,
  }

  componentDidMount () {
    this.props.logout()
  }

  render () {
    return null
  }
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
})

export default connect(null, mapDispatchToProps)(Logout)
