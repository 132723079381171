import * as c from './constants'
import omit from 'lodash.omit'
import { LOADING_STATE_LOADED } from '../../constants'

const sortByDate = (a, b) => {
  if ((a.createdAt || a) > (b.createdAt || b)) {
    return -1
  }
  if ((a.createdAt || a) < (b.createdAt || b)) {
    return 1
  }
  return 0
}

const getInitialState = () => ({
  requests: {
    loadingState: LOADING_STATE_LOADED,
    limit: null,
    offset: null,
    requestList: [],
  },
  forms: {
    newInvoiceForm: null,
  },
  usersHint: {},
})

const mutations = {
  [c.PAYMENT_REQUESTS_SET_LIST]: (state, { requestList, limit, offset, loadingState }) => {
    return {
      ...state,
      requests: {
        ...state.requests,
        loadingState,
        requestList,
        limit,
        offset,
      },
    }
  },
  [c.PAYMENT_REQUESTS_SET_LOADING_STATE]: (state, { loadingState }) => {
    return {
      ...state,
      requests: {
        ...state.requests,
        loadingState,
      },
    }
  },
  [c.PAYMENT_REQUESTS_DELETE]: (state, { requestId }) => {
    const filtered = state.requests.requestList.filter((r) => r.requestId !== requestId)
    return {
      ...state,
      requests: {
        ...state.requests,
        requestList: filtered,
      },
    }
  },
  [c.PAYMENT_REQUESTS_UPDATE]: (state, { paymentRequest }) => {
    const filtered = state.requests.requestList.filter((r) => r.requestId !== paymentRequest.requestId)
    filtered.push(paymentRequest)
    return {
      ...state,
      requests: {
        ...state.requests,
        requestList: filtered.sort(sortByDate),
      },
    }
  },
  [c.PAYMENT_REQUESTS_MULTIPLE_UPDATE]: (state, { requests }) => {
    return {
      ...state,
      requests: {
        ...state.requests,
        requestList: state.requests.requestList.map((request) => {
          return requests[request.requestId] || request
        }),
      },
    }
  },
  [c.PAYMENT_REQUESTS_REMOVE]: (state, { requestType, paymentRequestId }) => {
    if (state[requestType]) {
      return {
        ...state,
        [requestType]: {
          ...state[requestType],
          requests: {
            ...omit(state[requestType].requests, [paymentRequestId]),
          },
        },
      }
    }
    return state
  },
  [c.PAYMENT_REQUESTS_SORT_LIST]: (state, { requestType, sortColumn, sortDirection }) => {
    if (state[requestType]) {
      return {
        ...state,
        [requestType]: {
          ...state[requestType],
          sortColumn,
          sortDirection,
        },
      }
    }
    return state
  },
  [c.PAYMENT_REQUESTS_UPDATE_FORM_LOADING_STATE]: (state, { formName, loadingState }) => {
    if (formName) {
      return {
        ...state,
        forms: {
          ...state.forms,
          [formName]: loadingState,
        },
      }
    }
    return state
  },
  [c.PAYMENT_REQUESTS_UPDATE_USERS_HINT]: (state, { list }) => {
    return {
      ...state,
      usersHint: {
        ...list,
      },
    }
  },
}

export default (state = getInitialState(), { type, ...payload }) => {
  return (type in mutations)
    ? mutations[type](state, payload)
    : state
}
