import get from 'lodash.get'
import { connect } from 'react-redux'
import React from 'react'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import ProfileCard from '../../partials/ProfileCard'
import USER_SVG from '../../images/user.svg'
import { getProfile } from '../../ducks/profile/thunks'
import { profileInfoSelector, profileLoadingStateSelector } from '../../ducks/profile/selectors'
import Icon from '../Icon/Icon'
import { clearRequestItemSet } from '../../ducks/payroll/actions'
import BigPopup from '../BigPopup'
import styles from './index.module.scss'

class NavigationBar extends React.PureComponent {
  static propTypes = {
    getProfile: PropTypes.func,
    logout: PropTypes.func,
    clearRequestSet: PropTypes.func,
    profileLoadingState: PropTypes.shape({
      loadingState: PropTypes.string,
      error: PropTypes.string,
      result: PropTypes.number,
    }),
  }

  constructor (props) {
    super(props)
    this.state = {
      profilePanel: false,
      addInvoice: false,
    }
  }

  componentDidMount () {
    setTimeout(() => {
      this.setState(() => ({
        profilePanel: false,
      }))
    }, 1)
  }

  openSidePanel = (panel) => {
    return () => this.setState({ [panel]: true })
  }

  closeSidePanel = (panel) => {
    return () => this.setState({ [panel]: false })
  }

  openProfileSidePanel = () => {
    this.openSidePanel('profilePanel')()
  }

  render () {
    const profile = get(this, 'props.profile', {})
    const avatar = get(profile, 'avatar.url', null)
    const { firstName, lastName, email } = profile

    const nameTitle = firstName && lastName ? firstName + ' ' + lastName : email

    return (
      <div className={styles.wrapper}>
        <div className={styles.logoContainer}>
          <div className={styles.logo} />
        </div>
        <div className={styles.avatarContainerWrapper} onClick={this.openProfileSidePanel}>
          <div className={styles.avatarContainer}>
            <img className={styles.userAvatar} src={avatar || USER_SVG} alt='user-avatar'/>
          </div>
          <div className={styles.avatarText + ' hide-mobile'}>{nameTitle}</div>
          <div className={styles.avatarDropDown + ' hide-mobile'} data-selenium-id='profileButton'><Icon type='chevron-down' colorType='gray-50' /></div>
        </div>

        {this.state.profilePanel &&
        <BigPopup
          onClose={this.closeSidePanel('profilePanel')}
          loadingState={this.props.profileLoadingState.loadingState}
        >
          <ProfileCard onClose={this.closeSidePanel('profilePanel')} />
        </BigPopup>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    profileLoadingState: profileLoadingStateSelector(state),
    profile: profileInfoSelector(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearRequestSet: () => dispatch(clearRequestItemSet()),
  getProfile: () => dispatch(getProfile()),
  logout: () => dispatch(push('/logout')),
})

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar)
