import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { isAuthenticated } from '../../ducks/auth/thunks'

const AuthRoute = ({ component: Component, isAuthenticated, redirectTo, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        switch (true) {
          case !isAuthenticated:
            return (<Redirect to={{
              pathname: redirectTo,
              state: { from: props.location },
            }}
            />)
          default:
            return <Component {...props} />
        }
      }
      }
    />
  )
}

AuthRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  isAppInitialized: PropTypes.bool,
  redirectTo: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  location: PropTypes.object,
}

AuthRoute.defaultProps = {
  isAuthenticated: false,
  isAppInitialized: false,
}

const mapStateToProps = (state) => {
  return {
    appLoadingState: state.auth.appLoadingState,
    authLoadingState: state.auth.loadingState,
    isAuthenticated: isAuthenticated(),
  }
}

export default withRouter(connect(mapStateToProps)(AuthRoute))
