import Joi from 'joi'
import ValidationError from '../../errors/ValidationError'

export default class AbstractModel {
  constructor (data, schema, options = {}) {
    const { error, value } = Joi.validate(
      (data instanceof Function) ? data(this) : data,
      (schema instanceof Function) ? schema() : schema,
      options,
    )
    if (error) {
      throw new ValidationError(`${error}`)
    }
    Object.assign(this, value)
  }
}
