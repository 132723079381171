import * as Yup from 'yup'
import { IMAGE_MIME_TYPE } from '../../../utils/constants'

const FILE_SIZE = 20 * 1000000

export const validate = (value) => {
  let error = null
  try {
    Yup
      .mixed()
      .test(
        'fileSize',
        'File too large',
        (value) => {
          return value && value.size ? value.size <= FILE_SIZE : true
        },
      )
      .test(
        'fileFormat',
        'Unsupported Format',
        (value) => {
          return value ? IMAGE_MIME_TYPE.includes(value.type || value.extension) : true
        },
      )
      .validateSync(value)
  } catch (e) {
    error = e.message
  }
  return error
}
