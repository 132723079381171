// @flow
import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../components/Button'
import globalStyles from './styles.module.scss'
import Icon from '../../components/Icon/Icon'
import { TAB_TRANSACTIONS } from './constants'

type SendFormMessageProps = {
    onClose?: () => void,
    isSuccess: boolean,
    handleChangeTab: (tab: string) => void,
}
function SendFormMessage (props: SendFormMessageProps) {
  const { onClose, isSuccess, handleChangeTab } = props
  return (
    <div className={globalStyles.messageContainer}>
      <div className={globalStyles.messageHeader}>
        <button onClick={onClose}><Icon scale='1.5' type='close' colorType='gray-50' /></button>
        <div className='h4 gray-70'>Send funds</div>
      </div>
      <div className={globalStyles.messageContent}>
        {isSuccess
          ? <>
                <Icon type='checkmark-circle' colorType='green' />
                <div className='h5 gray-30'>Payment sent successfully</div>
                <div className='capture gray-30'>Please wait for network confirmation</div>
            </>
          : <>
                <Icon type='attention' colorType='red' />
                <div className='h5 gray-30'>Failed</div>
                <div className='capture gray-30'>Unable to send funds.<br />
                    Please try again. Contact support if the issue still persists.
                </div>
            </>
        }
        <div className={globalStyles.messageActions}>
          <Button
            type='button'
            onClick={isSuccess ? handleChangeTab(TAB_TRANSACTIONS) : onClose}
            colorType='new-border'>{isSuccess ? 'OK' : 'CLOSE'}</Button>
        </div>
      </div>
    </div>
  )
}

SendFormMessage.propTypes = {
  onClose: PropTypes.func,
  isSuccess: PropTypes.bool,
  handleChangeTab: PropTypes.func,
}

export default SendFormMessage
