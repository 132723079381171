import * as c from './constants'

const getInitialState = () => ({
  lastBlock: {},
})

const mutations = {
  [c.ETHEREUM_LAST_BLOCK]: (state, { blockchain, block }) => {
    return {
      ...state,
      lastBlock: {
        ...state.lastBlock,
        [blockchain]: { ...block },
      },
    }
  },
}

export default (state = getInitialState(), { type, ...payload }) => {
  return (type in mutations)
    ? mutations[type](state, payload)
    : state
}
