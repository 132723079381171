import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../components/Button'
import Icon from '../../components/Icon/Icon'
import config from '../../config/getConfig'

import globalStyles from './InactivityMessage.module.scss'

type Props = {
  onClose: () => void,
  logout: () => void,
  activityDate: Date,
}
type State = {
  secondsLeft: number,
}

class InactivityMessage extends React.PureComponent<Props, State> {
  intervalId: number | string | null
  initialActivity: Date

  static propTypes = {
    onClose: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    activityDate: PropTypes.instanceOf(Date).isRequired,
  }

  constructor (props: Props) {
    super(props)
    this.intervalId = null
    this.initialActivity = props.activityDate
    this.state = {
      secondsLeft: Math.ceil(config.getConfig('inactivityIntervalLogout') / 1000),
    }
  }

  componentDidMount () {
    this.intervalId = setInterval(() => {
      if (this.state.secondsLeft > 0) {
        this.setState((prevState) => {
          const newSeconds = prevState.secondsLeft - 1
          return {
            ...prevState,
            secondsLeft: Math.max(newSeconds, 0),
          }
        })
      } else {
        this.clearInterval()
        this.props.logout()
      }
    }, 1000)
  }

  componentWillUnmount () {
    this.clearInterval()
  }

  isActivityChanged = () => {
    return this.props.activityDate !== this.initialActivity
  }

  clearInterval = () => {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  }

  render () {
    const minutesLeft = Math.floor(this.state.secondsLeft / 60)
    const secondsLeft = this.state.secondsLeft - 60 * minutesLeft

    return (
      <div className={globalStyles.messageContainer}>
        <div className={globalStyles.messageHeader}>
          <button
            onClick={this.props.onClose}
          ><Icon scale='1.5' type='close' colorType='gray-50' />
          </button>
          <div className='h4 gray-70'>Your session expires in {minutesLeft ? `${minutesLeft}m` : ''} {secondsLeft}s
          </div>
        </div>
        <div className={globalStyles.messageContent}>
          <Icon type={'info-circle'} colorType={'gray-30'} />
          <div className='h5 gray-30'>You were inactive for too long. To protect your data, we will log you out. <br />Do
            you want to return to PaymentX?
          </div>
          <div className={globalStyles.messageActions}>
            <Button
              type='button'
              onClick={this.props.onClose}
              colorType='new-border'>{'Continue session'}</Button>
          </div>
        </div>
      </div>
    )
  }
}

export default InactivityMessage
