import * as c from './constants'
import { LOADING_STATE_LOADED } from '../../constants'

const getInitialState = () => ({
  loadingState: LOADING_STATE_LOADED,
  sortField: 'fullName',
  sortDirection: 'asc',
  list: [],
})

const mutations = {
  [c.CONTACTS_UPDATE_STATUS]: (state, action) => {
    return {
      ...state,
      loadingState: action.loadingState,
    }
  },
  [c.CONTACTS_UPDATE_LIST]: (state, action) => {
    return {
      ...state,
      list: action.list,
    }
  },
  [c.CONTACTS_UPDATE_SORTING]: (state, action) => {
    return {
      ...state,
      sortField: action.sortField,
      sortDirection: action.sortDirection,
    }
  },
  [c.CONTACTS_ADD_CONTACT]: (state, action) => {
    return {
      ...state,
      list: state.list.concat(action.contact),
    }
  },
}

export default (state = getInitialState(), { type, ...payload }) => {
  return (type in mutations)
    ? mutations[type](state, payload)
    : state
}
