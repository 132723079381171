// @flow
import * as Yup from 'yup'
import { connect } from 'react-redux'
import React from 'react'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import InputText from '../../components/InputText'
import { LOADING_STATE_ERROR, LOADING_STATE_LOADED, LOADING_STATE_LOADING } from '../../constants'
import { Button } from '../../components/Button'
import { AccountModel } from '../../models/Accounts/AccountModel'
import { accountLoadingStateSelector, accountNamesSelector } from '../../ducks/accounts/selectors'
import { updateAccount } from '../../ducks/accounts/thunks'
import type { HotWallet } from '../../ducks/accounts/models'
import { ACCOUNT_TYPE_COLD, ACCOUNT_TYPE_HOT } from '../../ducks/accounts/constants'
import Notification from '../../components/Notification/'
import styles from './SettingsForm.module.scss'
import globalStyles from './styles.module.scss'
import Icon from '../../components/Icon/Icon'
import {
  TAB_BACKUP,
  TAB_CHANGE_PASSWORD,
  TAB_RECOVER,
  TAB_UNLINK,
} from './constants'
import LoadingState from '../../components/LoadingState'

type Props = {
  onClose: () => void,
  updateAccount: (account: AccountModel) => void,
  account: HotWallet,
  loadingState: string,
  onBack: () => void,
  handleChangeTab: (tab: string)=>void,
  accountList: Array<string>,
}

type State = {
  isSent: boolean,
}

type FormValues = {
  address: string,
  name: string,
}

class SettingsForm extends React.PureComponent<Props, State> {
  static propTypes = {
    onClose: PropTypes.func,
    updateAccount: PropTypes.func,
    account: PropTypes.object,
    loadingState: PropTypes.string,
    onBack: PropTypes.func,
    handleChangeTab: PropTypes.func,
    accountList: PropTypes.arrayOf(PropTypes.string),
  }

  constructor (props) {
    super(props)

    this.state = {
      isSent: false,
    }
  }

  validation = (values) => {
    const errors = {}

    try {
      Yup.mixed()
        .test('Wallet name is required', (value) => {
          return !!value
        })
        .test('Wallet name is invalid', (value) => {
          return !!value.trim()
        })
        .test(
          'You already have an account with this name',
          (value) => {
            return value.trim() !== this.props.account.name ? !this.props.accountList.includes(value.trim()) : true
          },
        )
        .validateSync(values.name)
    } catch (error) {
      errors.name = error.type
    }

    return errors
  }


  renderOption = ({ icon, title, descr, action }) => {
    return (
      <div className={styles.option} onClick={action}>
        <div className={styles.optionIcon}>
          <Icon type={icon} colorType='blue' />
        </div>
        <div className={styles.optionTitle + ' h5 blue-bk'}>{title}</div>
        <div className={styles.optionDescr + ' capture gray-50'}>
          {descr}
        </div>
      </div>
    )
  }

  renderForm () {
    const handleSubmit = async (values: FormValues) => {
      const { account } = this.props
      this.props.updateAccount(new AccountModel({
        ...account,
        name: values.name,
      }))
      this.setState(() => ({
        isSent: true,
      }))
    }


    return (<Formik
      initialValues={{
        name: this.props.account ? this.props.account.name : '',
      }}
      validate={this.validation}
      onSubmit={handleSubmit}
    >
      {(formProps) => {
        const {
          handleChange,
          handleSubmit,
          values,
          errors,
          setFieldValue,
        } = formProps

        if (this.props.loadingState === LOADING_STATE_LOADING) {
          return (
            <div className={styles.loaderWrapper}>
              <LoadingState loadingState={this.props.loadingState} />
            </div>
          )
        }

        return (
          <div className={styles.renameFormContainer}>
            <Form name='sendTokensForm' onSubmit={handleSubmit}>
              <div className={styles.renameLabel + ' capture gray-70'}>Wallet name</div>
              <div className={styles.renameInput}>
                <InputText
                  name='name'
                  onChange={handleChange}
                  onBlur={(e) => {
                    setFieldValue('name', e.target.value.trim())
                  }}
                  error={!!errors.name}
                  errorText={errors.name}
                  value={values.name}
                />
              </div>
              <Button type='submit' colorType='new-border'>Save</Button>

              {this.state.isSent && (
                <div className={styles.notificationWrapper}>
                  {this.props.loadingState === LOADING_STATE_LOADED && (
                    <Notification message='Successfully renamed' type='success' />
                  )}
                  {this.props.loadingState === LOADING_STATE_ERROR && (
                    <Notification message='Failed to rename' type='error' />
                  )}
                </div>
              )}
            </Form>
            <div className={styles.optionsList}>

              {this.props.account && [ACCOUNT_TYPE_HOT].includes(this.props.account.type) && this.renderOption({
                icon: 'refresh-left',
                title: 'Backup your wallet',
                descr: 'Please write down recovery phrase and keep it in a safe place',
                action: this.props.handleChangeTab(TAB_BACKUP),
              })}

              {this.props.account && [ACCOUNT_TYPE_HOT].includes(this.props.account.type) && this.renderOption({
                icon: 'password-2',
                title: 'Change wallet password',
                descr: 'Please use strong passwords for the security of your funds',
                action: this.props.handleChangeTab(TAB_CHANGE_PASSWORD),
              })}

              {this.props.account && [ACCOUNT_TYPE_COLD].includes(this.props.account.type) && this.renderOption({
                icon: 'refresh-left',
                title: 'Recover wallet from backup',
                descr: 'For this procedure you need 12 words of recovery phrase',
                action: this.props.handleChangeTab(TAB_RECOVER),
              })}

              {this.renderOption({
                icon: 'unlink',
                title: 'Unlink wallet from account',
                descr: 'Don’t worry, you will not lose funds. You can recover your wallet later',
                action: this.props.handleChangeTab(TAB_UNLINK),
              })}
            </div>
          </div>
        )
      }}
    </Formik>)
  }

  render () {
    return (
      <div>
        <div className={globalStyles.tabTitle + ' h4 gray-70'}>Settings</div>
        <div className={styles.settingsWrapper}>
          {this.renderForm()}
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    loadingState: accountLoadingStateSelector(state),
    accountList: accountNamesSelector(state),
  }
}

function mapDispatchToProps (dispatch) {
  return {
    updateAccount: (accountData) => dispatch(updateAccount(accountData)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsForm)
