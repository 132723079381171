//@flow
import moment from 'moment'
import { createSelector } from 'reselect'
import {
  TEAMS_DUCK,
} from './constants'
import { profileInfoSelector } from '../profile/selectors'

export const teamsDuckSelector = (state: any) => {
  return state[TEAMS_DUCK]
}

export const teamsListSelector = createSelector(
  teamsDuckSelector,
  ({ list }) => {
    return list
  },
)

export const myTeamsSelector = (includeRemoved: boolean = false) => createSelector(
  teamsListSelector,
  (teams) => {
    return teams
      .filter((team) => team.isOwner)
      .sort((teamA, teamB) => {
        if (moment(teamA.createdAt) > moment(teamB.createdAt)) {
          return 1
        }
        if (moment(teamA.createdAt) < moment(teamB.createdAt)) {
          return -1
        }
        return 0
      })
      .filter((team) => {
        if (includeRemoved === false) {
          return !team.removed
        }
        return true
      })
  },
)

export const getCurrentTeamId = createSelector(
  myTeamsSelector(),
  (myTeams) => myTeams.length && myTeams[0].teamId ? myTeams[0].teamId : null,
)

export const currentTeamSelector = createSelector(
  myTeamsSelector(),
  (myTeams) => myTeams.length && myTeams[0].teamId ? myTeams[0] : null,
)

export const teamByIdSelector = (teamId: string) => createSelector(
  teamsListSelector,
  (teams) => {
    return teams.find((t) => t.teamId === teamId)
  },
)

export const currentTeamMemberByIdSelector = (memberId: string) => createSelector(
  currentTeamSelector,
  (currentTeam) => {
    if (!currentTeam) {
      return null
    }

    return currentTeam.members.find((member) => {
      return memberId === member.id
    })
  },
)

export const allMembersListSelector = createSelector(
  teamsListSelector,
  (teams) => {
    const memberList = {}
    teams.forEach(({ members }) => {
      members.forEach((member) => {
        memberList[member.id] = member
      })
    })

    return memberList
  },
)

export const allTeamMemberListSelector = (teamId: string) => createSelector(
  teamByIdSelector(teamId),
  (team) => team.members,
)

export const memberInfoSelector = (teamId: string) => createSelector(
  teamByIdSelector(teamId),
  profileInfoSelector,
  (team, profile) => {
    if (!team) {
      return {}
    }

    return team.members.find((member) => member.profileId === profile.id)
  },
)
