
export default {
  urls: {
    paymentRequestsService: 'https://payment-requests.paymentx.io',
    authService: 'https://profile-sso.paymentx.io',
    profileService: 'https://profile.paymentx.io',
    teamService: 'https://team.paymentx.io',
    bitcoinTestnetMiddlewareService: 'https://middleware-bitcoin-testnet-rest.chronobank.io',
    bitcoinMiddlewareService: 'https://middleware-bitcoin-mainnet-rest.chronobank.io',
    bitcoinTestnetService: 'https://middleware-bitcoin-testnet-rest.chronobank.io',
    bitcoinService: 'https://middleware-bitcoin-rest.chronobank.io',
    payrollService: 'https://payrolls.paymentx.io',
    walletService: 'https://wallet.paymentx.io',
    blockexplorerService: 'https://insight.bitpay.com',
    insightService: 'https://wsproxy.paymentx.io',
    insightSocketService: 'wss://wsproxy.paymentx.io',
    mediaService: 'https://media.paymentx.io',
    ethereum: {
      mainnet: 'https://mainnet.infura.io/v3/c4b50a02444f4e7a8ab613d3caaa44b5',
      rinkeby: 'https://ethereum-sepolia-rpc.publicnode.com',
    },
    ethereumWS: {
      mainnet: 'wss://mainnet.infura.io/ws/v3/c4b50a02444f4e7a8ab613d3caaa44b5',
      rinkeby: 'wss://2Fyzkqe1tf8wptITbe7Kny0AIU6:808adcdeb56ce4ee49ab9dcf2abc9350@eth2-beacon-prater.infura.io',
    },
  },
  bitcoinDecimalLength: 6,
  authScope: 'paymentx-prod',
  defaultBitcoinFeeRate: 100,
  inactivityIntervalWarning: 180000,
  inactivityIntervalLogout: 120000, // this timer starts after inactivityIntervalWarning appears
}
