export const ETHEREUM_DUCK = 'ethereum'

export const ETHEREUM_ACCOUNT_KEY_MAINNET = 'mainnet'
export const ETHEREUM_ACCOUNT_KEY_TESTNET_RINKEBY = 'rinkeby'

export const ETHEREUM_DECIMALS = 1000000000000000000
export const ETHEREUM_DECIMAL_LENGTH = 18

export const ETHEREUM_SYMBOL = 'ETH'

export const BLOCKCHAIN_ETHEREUM = 'ethereum'
export const BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY = 'ethereum_rinkeby'

export const BLOCKCHAIN_ETHEREUM_REMOTE = 'ETH'
export const BLOCKCHAIN_ETHEREUM_TESTNET_REMOTE = 'ETH'
export const ETHEREUM_LAST_BLOCK = 'ethereumLastBlock'


export const UPDATE_TRANSACTION_STATE = 'ethereum/UPDATE_TRANSACTION_STATE'
