import AbstractRESTService from './AbstractRESTService'

const BALANCE_URL = (address) => `addr/${address}/balance`
const GET_UTXOS_URL = (address) => `addr/${address}/utxo`
const ADDRESS_URL = '/addr'

export default class BitcoinMiddlewareService extends AbstractRESTService {
  addAddressToMiddleware (address) {
    return this.restService.post(ADDRESS_URL, { address })
  }

  getBalanceForAddress (address) {
    return this.restService.request({
      method: 'GET',
      headers: this.getBearerHeaders(),
      url: BALANCE_URL(address),
    })
  }

  getUTXOSForAddress (address) {
    return this.restService.request({
      method: 'GET',
      headers: this.getBearerHeaders(),
      url: GET_UTXOS_URL(address),
    })
  }
}
