// Google Analytics
export function gaSendEvent (category, action, label, value) {
    if (process.env.VUE_APP_MODE === 'prod' && window.gtag) {
        window.gtag('event', action, {
            'event_category': category,
            'event_label': label,
            'value': value
        })
    }
}

// Facebook Pixel
function fbAvailable () {
    return process.env.VUE_APP_MODE === 'prod' && window.fbq
}

export function fbSendEvent (event) {
    if (fbAvailable()) {
        window.fbq('track', event)
    }
}

export function fbSendCustomEvent (event) {
    if (fbAvailable()) {
        window.fbq('trackCustom', event)
    }
}

// Amplitude
let amplitudeWasInitiated = false
const amplitudeEventsQueue = []

function amplitudeAvailable () {
    return window.amplitude
}

export function amplitudeInit (userId = null) {
    if (amplitudeAvailable()) {
        const key = window && window.location.host.indexOf('paymentx.io') !== -1  ? '7d5e887f9bc501416dc3c31dbd6db168' : '76c8ffd7574f8a62c44c737355ca5a88'
        window.amplitude
            .getInstance()
            .init(key, userId || null, { includeReferrer: true, includeUtm: true })
        amplitudeWasInitiated = true
        for (let event of amplitudeEventsQueue) {
            amplitudeSendEvent(event.eventType, event.eventProperties)
        }
    }
}

export function amplitudeSendEvent (eventType, eventProperties = {}) {
    if (amplitudeWasInitiated) {
        window.amplitude.getInstance().logEvent(eventType, eventProperties)
    } else {
        amplitudeEventsQueue.push({ eventType, eventProperties })
    }
}

export function amplitudeSetUserId (userId) {
    if (amplitudeWasInitiated) {
        window.amplitude.setUserId(userId || null)
    }
}
