import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import InputText from '../../components/InputText'
import { Button } from '../../components/Button'
import styles from './Login.module.scss'
import { LOADING_STATE_LOADED, LOADING_STATE_LOADING } from '../../constants'
import Icon from '../../components/Icon/Icon'


const SignInStep = ({ auth, signIn, renderSignInMessage, handleResetLogin, handleResetPassword }) => {

  const validation = (values) => {
    const errors = {}

    if (!values.password) {
      errors.password = 'Password is required'
    }

    if (values.password.length < 4) {
      errors.password = 'Your password should have at least 4 symbols'
    }

    return errors
  }

  const handleSubmit = (values) => {
    signIn({ ...values, email: auth.email, nickname: auth.email })
  }

  const render = (formProps) => {
    const {
      handleSubmit,
      handleChange,
      handleBlur,
      submitForm,
      values,
      errors,
      touched,
    } = formProps

    if (auth.loadingState === LOADING_STATE_LOADED && auth.result) {
      return (
        <div className='messageContainer'>
          {renderSignInMessage(auth.result)} {values.email}
        </div>
      )
    }

    return (
      <Form
        name='SignIn_form'
        onSubmit={handleSubmit}
        data-selenium-id='authPage'
      >
        <div className={styles.fieldsWrapper}>
          <div className={styles.title + ' gray'}>Sign in or create a new account</div>
          <div className={styles.inputLabel + ' gray-70'}>Enter your password</div>
          <div className={styles.inputs}>
            <InputText
              autoFocus
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.password && !!touched.password}
              errorText={auth.result ? renderSignInMessage(auth.result) : errors.password}
              errorWithIcon
              disabled={auth.loadingState === LOADING_STATE_LOADING}
              value={values.password}
              placeholder='Password'
              name='password'
              type='password'
              data-selenium-id='password'
            />
            <Button
              id='passwordPageActionButton'
              handleClick={submitForm}
              data-selenium-id='signin'
              colorType='login'
              type='button'
              disabled={!values.password || auth.loadingState === LOADING_STATE_LOADING}
            >{(auth.loadingState === LOADING_STATE_LOADING)
                ? <div className='messageContainer'>
                  <div className={styles.loader} />
                </div>
                : 'Sign in'}</Button>
          </div>
          <div className={styles.additionalActions}>
            <button className={styles.goBack}
              disabled={auth.loadingState === LOADING_STATE_LOADING}
              type='button'
              onClick={handleResetLogin}>
              <Icon type='arrow-left' colorType='blue' />
              <span className='capture blue-bk'>Go back</span>
            </button>
            <button
              type='button'
              className='capture gray-50'
              onClick={handleResetPassword}
            >Forgot password?
            </button>
          </div>
        </div>
      </Form>
    )
  }

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        password: '',
      }}
      validate={validation}
      onSubmit={handleSubmit}
      render={render}
    />
  )
}

SignInStep.propTypes = {
  auth: PropTypes.object,
  signIn: PropTypes.func,
  renderSignInMessage: PropTypes.func,
  handleResetLogin: PropTypes.func,
  handleResetPassword: PropTypes.func,
}

export default SignInStep

