//@flow
import React from 'react'
import PropTypes from 'prop-types'
import { copy } from '../../utils/general'
import Icon from '../Icon/Icon'
import type { Node } from 'react'
import styles from './CopyButton.module.scss'

// endregion
type Props = {
  className?: string,
  copyString: string,
  children?: Node,
}
type State = {
  isCopied: boolean,
}

export default class CopyButton extends React.PureComponent<Props, State> {
  static propTypes = {
    className: PropTypes.string,
    copyString: PropTypes.string,
    children: PropTypes.node,
  }

  constructor (props: Props) {
    super(props)
    this.state = {
      isCopied: false,
    }
  }

  handleClick = (e: Event) => {
    e.preventDefault()
    e.stopPropagation()
    copy(this.props.copyString)
    this.setState({ isCopied: true })

    setTimeout(() => {
      this.setState({ isCopied: false })
    }, 3000)
  }

  render () {
    return (
      <div className={styles.copyWrapper + ' ' + (this.props.className || '') + ' copy-wrapper'}>
        {this.props.children
          ? (
            <div className='button' onClick={this.handleClick}>
              {this.props.children}
            </div>
          )
          : (
            <Icon type='copy-2' colorType='gray-30' onClick={this.handleClick} />
          )}
        {this.state.isCopied && (
          <div className={styles.copiedMessage + ' copy-message capture white-bk'}>Copied to clipboard</div>
        )}
      </div>
    )
  }
}
