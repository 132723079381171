import * as c from './constants'
import { LOADING_STATE_LOADED } from '../../constants'

const getInitialState = () => ({
  currentStep: null,
  currentPanelStep: c.PAYROLL_STEP_REQUEST_SETTINGS,
  transactions: {},
  fixedRates: null,
  feeRate: null,
  collections: {},
  blockchainsSettings: {},
  blockchainsSettingsLoadingState: LOADING_STATE_LOADED,
  selectedRequestIds: new Set(),
  selectedRequestPreviewId: new Set(),
  selectedRequestsType: c.PAYROLL_SELECTED_ITEMS_TYPE_MAIN_LIST,
})

const mutations = {
  [c.PAYROLL_UPDATE_SETTINGS_LOADING_STATE]: (state, { loadingState }) => {
    return {
      ...state,
      blockchainsSettingsLoadingState: loadingState,
    }
  },
  [c.PAYROLL_UPDATE_STEP]: (state, { step }) => {
    return {
      ...state,
      currentPanelStep: step,
    }
  },
  [c.REQUEST_ITEM_UPDATE_LIST]: (state, { selectedRequestIds }) => {
    return {
      ...state,
      selectedRequestIds: new Set(selectedRequestIds),
    }
  },
  [c.REQUEST_ITEM_CLEAR_PREVIEW_INVOICE]: (state) => {
    return {
      ...state,
      selectedRequestPreviewId: new Set(),
      blockchainsSettingsLoadingState: LOADING_STATE_LOADED,
    }
  },
  [c.REQUEST_ITEM_SELECT_PREVIEW_INVOICE]: (state, { requestId }) => {
    return {
      ...state,
      selectedRequestPreviewId: new Set(state.selectedRequestPreviewId).add(requestId),
      blockchainsSettingsLoadingState: LOADING_STATE_LOADED,
      selectedRequestsType: c.PAYROLL_SELECTED_ITEMS_TYPE_PREVIEW_INVOICE,
    }
  },
  [c.REQUEST_ITEM_SELECT]: (state, { requestId }) => {
    return {
      ...state,
      selectedRequestIds: new Set(state.selectedRequestIds).add(requestId),
      selectedRequestsType: c.PAYROLL_SELECTED_ITEMS_TYPE_MAIN_LIST,
      blockchainsSettingsLoadingState: LOADING_STATE_LOADED,
    }
  },
  [c.REQUEST_ITEM_REMOVE]: (state, { requestId }) => {
    const newSet = new Set(state.selectedRequestIds)
    newSet.delete(requestId)
    return {
      ...state,
      selectedRequestIds: newSet,
    }
  },
  [c.UPDATE_PAYROLL_TRANSACTIONS]: (state, { transactions }) => {
    return {
      ...state,
      transactions,
    }
  },
  [c.RESET_PAYROLL_SETTINGS]: (state) => {
    return {
      ...state,
      currentStep: null,
      transactions: {},
      fixedRates: null,
      feeRate: null,
      collections: {},
      blockchainsSettings: {},
      blockchainsSettingsLoadingState: LOADING_STATE_LOADED,
      selectedRequestIds: new Set(),
    }
  },
  [c.REQUEST_ITEM_CLEAR_SET]: (state) => {
    return {
      ...state,
      selectedRequestIds: new Set(),
    }
  },
  [c.PAYROLL_UPDATE_SETTINGS]: (state, action) => {
    return {
      ...state,
      blockchainsSettings: action.accounts,
      blockchainsSettingsLoadingState: LOADING_STATE_LOADED,
    }
  },
  [c.PAYROLL_UPDATE_SETTINGS_BLOCKCHAIN]: (state, action) => {
    return {
      ...state,
      blockchainsSettings: {
        ...state.blockchainsSettings,
        [action.blockchain]: {
          ...action,
        },
      },
    }
  },
  [c.PAYROLL_SET_LOADING_STATE]: (state, { loadingState, message }) => {
    return {
      ...state,
      loadingState,
      errorMessage: message,
    }
  },
  [c.PAYROLL_CREATE_SET_LOADING_STATE]: (state, { createPayrollLoadingState, createPayrollErrorMessage }) => {
    return {
      ...state,
      createPayrollLoadingState,
      createPayrollErrorMessage,
    }
  },
  [c.PAYROLL_SET_STEP]: (state, { step }) => {
    return {
      ...state,
      currentStep: step,
    }
  },
  [c.PAYROLL_ADD_TRANSACTION]: (state, { tx }) => {
    return {
      ...state,
      transactions: {
        ...state.transactions,
        [tx.id]: tx,
      },
    }
  },
  [c.PAYROLL_UPDATE_PAYROLL_INFO]: (state, { teamId, payrollInfo }) => {
    return {
      ...state,
      payrollsInfo: {
        ...state.payrollsInfo,
        [teamId]: payrollInfo,
      },
    }
  },
  [c.PAYROLL_REMOVE_PAYROLL_INFO]: (state, { teamId }) => {
    const info = { ...state.payrollsInfo }
    delete info[teamId]

    return {
      ...state,
      payrollsInfo: {
        ...info,
      },
    }
  },
  [c.PAYROLL_UPDATE_PAYROLL_ITEM_REQUEST]: (state) => {
    return {
      ...state,
    }
  },
  [c.PAYROLL_UPDATE_PAYROLL_ITEMS_LIST]: (state) => {
    return {
      ...state,
    }
  },
  [c.PAYROLL_UPDATE_PAYROLL_ITEMS_LOADING_STATE]: (state) => {
    return {
      ...state,
    }
  },
  [c.PAYROLL_FIX_RATES]: (state, { rates }) => {
    return {
      ...state,
      fixedRates: { ...rates },
    }
  },
  [c.PAYROLL_UPDATE_FIXED_RATE]: (state, { symbol, currency, rate }) => {
    return {
      ...state,
      fixedRates: {
        ...state.fixedRates,
        [symbol]: {
          ...state.fixedRates[symbol],
          [currency]: rate,
          [currency + '_fixTime']: new Date(),
        },
      },
    }
  },
  [c.PAYROLL_UPDATE_SETTINGS_ITEM]: (state, { blockchain, item }) => {
    return {
      ...state,
      runPayrollSettings: {
        ...state.runPayrollSettings,
        blockchains: {
          ...state.runPayrollSettings.blockchains,
          [blockchain]: {
            ...state.runPayrollSettings.blockchains[blockchain],
            [item.key]: item.value,
          },
        },
      },
    }
  },
  [c.PAYROLL_UPDATE_FEE]: (state, { blockchain, fee }) => {
    return {
      ...state,
      runPayrollSettings: {
        ...state.runPayrollSettings,
        blockchains: {
          ...state.runPayrollSettings.blockchains,
          [blockchain]: {
            ...state.runPayrollSettings.blockchains[blockchain],
            fee,
          },
        },
      },
    }
  },
  [c.PAYROLL_UPDATE_RUN_PAYROLL_SETTINGS]: (state, { settings }) => {
    return {
      ...state,
      runPayrollSettings: { ...settings },
    }
  },
}

export default (state = getInitialState(), { type, ...payload }) => {
  return (type in mutations)
    ? mutations[type](state, payload)
    : state
}
