import PropTypes from 'prop-types'
import React from 'react'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import InputText from '../../components/InputText'
import { Button } from '../../components/Button'
import { LOADING_STATE_LOADING } from '../../constants'
import styles from './Login.module.scss'


const EmailStep = ({ auth, checkEmailLoadingState, checkEmail, renderSignInMessage }) => {

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter valid email address')
      .required('Email is required'),
  })

  const handleSubmit = (values) => {
    checkEmail(values.email)
  }

  const render = (formProps) => {
    const {
      handleSubmit,
      handleChange,
      handleBlur,
      submitForm,
      values,
      errors,
      touched,
    } = formProps

    const handleEmailTrimDecorator = (e) => {
      e.target.value = e.target.value.trim()
      handleChange(e)
    }

    return (
      <Form
        name='Email_form'
        onSubmit={handleSubmit}
        data-selenium-id='authPage'
      >
        <div className={styles.fieldsWrapperAnimated}>
          <div className={styles.title + ' gray'}>Sign in or create a new account</div>
          <div className={styles.inputLabel + ' gray-70'}>
            Please type your email address to sign in to
            <br />PaymentX or create a new PaymentX account
          </div>

          <div className={styles.inputs}>
            <InputText
              onChange={handleEmailTrimDecorator}
              autoFocus
              onBlur={handleBlur}
              error={errors.email && touched.email}
              errorText={auth.result ? renderSignInMessage(auth.result) : errors.email}
              errorWithIcon
              value={values.email}
              name='email'
              type='text'
              placeholder='your_mail@domain.com'
              disabled={checkEmailLoadingState === LOADING_STATE_LOADING}
              data-selenium-id='email'
            />
            <Button
              id='emailPageActionButton'
              colorType='login'
              type='button'
              disabled={!values.email || checkEmailLoadingState === LOADING_STATE_LOADING}
              handleClick={submitForm}
              data-selenium-id='continue'>
              {(checkEmailLoadingState === LOADING_STATE_LOADING) ? (
                <div className='messageContainer'>
                  <div className={styles.loader} />
                </div>
              ) : 'Next'}</Button>
          </div>
        </div>
      </Form>
    )
  }

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        email: '',
        password: '',
        repeatPassword: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      render={render}
    />
  )
}

EmailStep.propTypes = {
  auth: PropTypes.object,
  checkEmailLoadingState: PropTypes.string,
  checkEmail: PropTypes.func,
  renderSignInMessage: PropTypes.func,
}

export default EmailStep

