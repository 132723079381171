//@flow
import { requestPrices } from '../../services/RatesService'
import * as a from './constants'
import { setErrorPageData } from '../ui/actions'
import { intervalRatesDataSelector } from './selectors'
import get from 'lodash.get'
import Logger from '../../services/Logger'
import { Dispatch, GetState } from 'redux'

// eslint-disable-next-line
export const startRatesUpdate = () => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const state = getState().rates
    const tokens = state.tokens
    const currencies = state.currencies

    const updateRates = async () => {
      try {
        const { data } = await requestPrices(tokens, currencies)
        const rates = tokens.reduce((acc, firstToken) => {
          acc[firstToken] = {}
          tokens.forEach((token) => {
            const rate = get(data, `${firstToken}.${token}`, 1 / get(data, `${token}.${firstToken}`, 1))
            acc[firstToken][token] = rate
          })
          return acc
        }, {})
        dispatch({
          type: a.RATES_UPDATE_RATES,
          rates,
          tokens,
        })
      } catch (e) {
        Logger.error(e)
        const { intervalId } = intervalRatesDataSelector(getState())
        clearInterval(intervalId)
        dispatch({
          type: a.RATES_UPDATE_INTERVAL_ID,
          intervalId: null,
        })
        dispatch(setErrorPageData({
          title: 'Exchange rate error.',
          subTitle: 'PaymentX can\'t load exchange rates from exchange rate service provider. Please try again in a few minutes.',
        }))
        throw e
      }
    }

    await updateRates()
    const intervalId = setInterval(updateRates, state.intervalTime)

    dispatch({
      type: a.RATES_UPDATE_INTERVAL_ID,
      intervalId,
    })

  } catch (e) {
    Logger.error(e)

    const { intervalId } = intervalRatesDataSelector(getState())
    clearInterval(intervalId)
    dispatch({
      type: a.RATES_UPDATE_INTERVAL_ID,
      intervalId: null,
    })
    dispatch(setErrorPageData({
      title: 'Exchange rate error.',
      subTitle: 'PaymentX can\'t load exchange rates from exchange rate service provider. Please try again in a few minutes.',
    }))
    throw e
  }
}
