//@flow
import cn from 'classnames'
import { connect } from 'react-redux'
import get from 'lodash.get'
import styles from './PaymentsTable.module.scss'
import React from 'react'
import PropTypes from 'prop-types'
import {
  requestsLoadingStateSelector,
  activityListSelector, usersHintSelector, isExportRequestsExistedSelector,
} from '../../ducks/paymentRequests/selectors'
import { exportPaymentRequestsToCSV, getPaymentRequests } from '../../ducks/paymentRequests/thunks'
import { selectedRequestIdsSelector, payrollCurrentPanelStepSelector } from '../../ducks/payroll/selectors'
import {
  PAYMENT_REQUEST_STATUS_FAILED,
  PAYMENT_REQUEST_STATUS_PAID,
  PAYMENT_REQUEST_STATUS_UNPAID,
  PAYMENT_REQUEST_STATUS_IN_PROGRESS, PAYMENT_REQUEST_STATUS_DRAFT, PAYMENT_REQUEST_COLLECTION_PENDING,
} from '../../ducks/paymentRequests/constants'
import Icon from '../Icon/Icon'
import Checkbox from '../Checkbox'
import { LOADING_STATE_LOADED, LOADING_STATE_LOADING } from '../../constants'
import LoadingState from '../LoadingState'
import { MobilePanelWatcher } from './mobilePanelWatcher'
import { selectRequestItemThunk } from '../../ducks/payroll/thunks'
import { clearRequestItemSet } from '../../ducks/payroll/actions'
import { removeRequestItemThunk, selectRequestsListThunk } from '../../ducks/payroll/thunks/runPayrollThunks'
import { PAYROLL_STEP_SEND } from '../../ducks/payroll/constants'
import { PaymentRequestModel } from '../../models/PaymentRequests/PaymentRequestModel'
import PaymentRequestStatusIcon from '../PaymentRequestStatusIcon/PaymentRequestStatusIcon'
import { getFormattedInvoiceStatus, isPendingRequest } from '../../ducks/paymentRequests/utils'
import { getMainSymbolForBlockchain, truncate } from '../../utils/general'
import Label from '../Label/Label'
import { customLoadingStatesSelector } from "../../ducks/ui/selectors"
import { isTestnetAccount } from "../../ducks/accounts/utils"
import { amplitudeSendEvent } from "../../utils/analytics"

type Props = {
  getPaymentRequests: () => void,
  openInvoiceCard: (requestId: string) => void,
  openAddInvoiceCard: (requestId: string) => void,
  selectedRequestSet: any,
  clearRequestSet: () => void,
  loadingState: string,
  activityList: Array<any>,
  historyActivityList: Array<any>,
  selectRequest: (requestId: string) => void,
  selectRequestsList: (list: Array<string>) => void,
  removeRequest: (requestId: string) => void,
  currentStep: string,
  handleupdatePayrollSettings: () => void,
  isAllSelected: boolean,
  usersHints: any,
  handleExportCsv: () => void,
  exportCSVLoadingState: string,
  isExportRequestsExisted: boolean,
}

type State = {
  isAllSelected: boolean,
}

class PaymentTable extends React.PureComponent<Props, State> {
  static propTypes = {
    usersHints: PropTypes.object,
    getPaymentRequests: PropTypes.func,
    openInvoiceCard: PropTypes.func,
    selectedRequestSet: PropTypes.instanceOf(Set),
    clearRequestSet: PropTypes.func,
    loadingState: PropTypes.string,
    activityList: PropTypes.arrayOf(PropTypes.object),
    historyActivityList: PropTypes.arrayOf(PropTypes.array),
    selectRequest: PropTypes.func,
    selectRequestsList: PropTypes.func,
    removeRequest: PropTypes.func,
    currentStep: PropTypes.string,
    openAddInvoiceCard: PropTypes.func,
    handleExportCsv: PropTypes.func,
    exportCSVLoadingState: PropTypes.string,
    isExportRequestsExisted: PropTypes.bool,
  }

  constructor (props) {
    super(props)
    this.state = {
      isAllSelected: false,
    }
  }

  componentDidMount () {
    this.props.getPaymentRequests()
    MobilePanelWatcher.watchThePointer()
  }

  componentWillUnmount () {
    this.props.clearRequestSet()
  }

  componentDidUpdate () {
    MobilePanelWatcher.updateState()
  }

  handleSelect = (requestId, item) => (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (this.props.selectedRequestSet.has(requestId)) {
      this.props.removeRequest(requestId)
    } else {
      if (item && !item.isFromMe && item.status === 'Unpaid') {
        amplitudeSendEvent('Invoice selected', {})
      }
      this.props.selectRequest(requestId)
    }
  }

  getIdsListForAllSelection = () => {
    const activityList = this.props.activityList
    if (!Array.isArray(activityList) || !activityList.length) {
      return []
    }

    const selectedRequests = []
    activityList.forEach((activity: any) => {
      if (activity.collection.date !== PAYMENT_REQUEST_COLLECTION_PENDING) {
        return null
      }

      activity.requests.forEach((request) => {
        if (isPendingRequest(request.status)) {
          if (request && !request.isFromMe && request.status === 'Unpaid') {
            amplitudeSendEvent('Invoice selected', {})
          }
          selectedRequests.push(request.requestId)
        }
      })
    })

    return selectedRequests
  }

  handleSelectAll = (e) => {
    e.preventDefault()
    e.stopPropagation()

    this.props.selectRequestsList(this.props.isAllSelected ? [] : this.getIdsListForAllSelection())
  }

  isActiveCheckbox = (request) => {
    return !request.isFromMe && this.props.currentStep !== PAYROLL_STEP_SEND &&
      [
        PAYMENT_REQUEST_STATUS_FAILED,
        PAYMENT_REQUEST_STATUS_UNPAID,
      ].includes(request.status)
  }

  onMouseOver = (request: PaymentRequestModel) => () => {
    const element = document && document.getElementById(`payrollRequestId_${request.requestId || request}`)
    if (element) {
      element.classList.add('visible')
    }
  }

  onMouseLeave = (request: PaymentRequestModel) => () => {
    const element = document && document.getElementById(`payrollRequestId_${request.requestId || request}`)
    if (element) {
      element.classList.remove('visible')
    }
  }

  renderLine = (isActiveInvoices) => (item) => {
    const {
      requestId,
      name,
      email,
      status,
      createdAt,
      amount,
      amountCrypto,
      preSymbol,
      paymentAsset,
      isFromMe,
      isContact,
      createdAtTime,
      paymentBlockchain,
      invoiceNumber,
      invoiceTitle,
    } = item

    const customStatus = getFormattedInvoiceStatus(status, isFromMe)
    const avatar = get(this.props.usersHints[email], 'avatar.url', null)

    const symbol = getMainSymbolForBlockchain(paymentBlockchain)
    const isTestnet = isTestnetAccount({ blockchain: paymentBlockchain })

    return (
      <tr
        onMouseOver={this.onMouseOver(item)}
        onMouseLeave={this.onMouseLeave(item)}
        key={requestId}
        onClick={status === PAYMENT_REQUEST_STATUS_DRAFT
          ? () => this.props.openAddInvoiceCard(requestId)
          : this.props.openInvoiceCard(requestId, item)}
        className={'capture gray-70'}
      >
        <td
          className={`${styles.checkbox} ${this.props.selectedRequestSet.size > 0 ? styles.visible : ''} ${styles.checkboxDesktop}`}
          id={'payrollRequestId_' + requestId}>
          <Checkbox
            disabled={!this.isActiveCheckbox(item)}
            value={[PAYMENT_REQUEST_STATUS_FAILED, PAYMENT_REQUEST_STATUS_UNPAID].includes(item.status) ? this.props.selectedRequestSet.has(requestId) : false}
            onClick={this.handleSelect(requestId, item)}
            className='InvoicesCheckbox'
          />
        </td>

        <td className={styles.status}>
          <div>
            <PaymentRequestStatusIcon type='list' requestId={requestId} />
            <div>{status === PAYMENT_REQUEST_STATUS_DRAFT ? truncate(invoiceTitle, 17) : customStatus}</div>
          </div>
        </td>

        <td className={styles.tdCreatedAt + ' gray-50'}>
          {createdAtTime}{isActiveInvoices || status === PAYMENT_REQUEST_STATUS_DRAFT
            ? <> <br />{createdAt}</>
            : ''}
        </td>

        <td className={styles.tdInvoiceNumber + ' gray-50'}>
          {invoiceNumber.length > 20 ? (
            <Label label={invoiceNumber}>{truncate(invoiceNumber, 20)}</Label>
          ) : invoiceNumber}
        </td>

        <td className={styles.paymentAsset + ' td-paymentAsset hide-mobile'}>
          <div>
            <Icon
              type={symbol}
              colorType={isTestnet ? 'gray-50' : 'blue'}
            />
            <div className={isTestnet ? 'gray-50' : 'blue-bk'}>{paymentAsset}</div>
          </div>
        </td>
        <td className={styles.avatar}>
          {isContact && avatar && <img src={avatar} alt={name} />}
          {isContact && !avatar && <Icon type='user-circle' colorType='gray-50' />}
          {!isContact && <Icon type='mail' colorType='gray-50' />}
        </td>
        <td className={styles.tdName}>
          {name.length > 30 ? (
            <Label label={name}>{truncate(name, 30)}</Label>
          ) : name}
        </td>
        <td className={styles.tdCryptoAmount}>
          <div className={cn(styles.amountWrapper, { 'plus': preSymbol === '+' })}>
            <div className='amount'>{amount}</div>
            {[PAYMENT_REQUEST_STATUS_PAID, PAYMENT_REQUEST_STATUS_IN_PROGRESS].includes(status) &&
            <div className='amountCrypto'>{amountCrypto}</div>}
          </div>
        </td>
        <td
          className={`${styles.checkbox} ${this.props.selectedRequestSet.size > 0 ? styles.visible : ''} ${styles.checkboxMobile}`}
          id={'payrollRequestId_' + requestId}>
          <Checkbox
            disabled={!this.isActiveCheckbox(item)}
            value={[PAYMENT_REQUEST_STATUS_FAILED, PAYMENT_REQUEST_STATUS_UNPAID].includes(item.status) ? this.props.selectedRequestSet.has(requestId) : false}
            onClick={this.handleSelect(requestId)}
            className='InvoicesCheckbox'
          />
        </td>
      </tr>
    )
  }

  render () {
    const { activityList, loadingState, handleExportCsv, exportCSVLoadingState, isExportRequestsExisted } = this.props

    return (
      <div className={styles.paymentsContainer}>
        <div className={styles.mobilePanelPointer} id='PaymentsTable_mobile_pointer' />
        {loadingState === LOADING_STATE_LOADED ? (
          <div>
            {isExportRequestsExisted && (
              <>
                {exportCSVLoadingState === LOADING_STATE_LOADING
                  ? (
                    <div className={styles.exportButtonWrapperLoader}>
                      <LoadingState loadingState={exportCSVLoadingState} />
                    </div>
                  )
                  : (
                    <div className={styles.exportButtonWrapper + ' hide-mobile'}>
                      <button onClick={handleExportCsv}>
                        <Icon colorType='blue' type='csv' />
                        <div className='capture blue-bk'>Export invoices</div>
                      </button>
                    </div>
                  )}
              </>
            )}
            {activityList.length > 0
              ? (
                <table className={styles.table}>
                  <tbody>
                    {activityList.map(({ collection, requests }) => {
                      if (requests.length <= 0) {
                        return null
                      }
                      if (collection.date !== PAYMENT_REQUEST_COLLECTION_PENDING) { // it's history collection
                        return (
                          <React.Fragment key={collection.date}>
                            <tr className={styles.headerTitle + ' h5 gray-70'}>
                              <td colSpan='9' className={styles.groupDate}>
                                <span className='h5 gray-70'>{collection.date}</span>
                              </td>
                            </tr>
                            {requests.map(this.renderLine(false))}
                          </React.Fragment>
                        )
                      } else {
                        const isDisabledAll = !requests.filter((request) => {
                          return [
                            PAYMENT_REQUEST_STATUS_FAILED,
                            PAYMENT_REQUEST_STATUS_UNPAID,
                          ].includes(request.status)
                          && !request.isFromMe
                        }).length
                        return (
                          <React.Fragment key='pending'>
                            <tr
                              className={styles.headerTitle + ' h5 gray-70'}
                              onMouseOver={this.onMouseOver('all')}
                              onMouseLeave={this.onMouseLeave('all')}
                            >
                              <td colSpan='8' id='PaymentsTable_pending_td'>
                                <div>
                                  <div
                                    className={`${styles.checkbox} ${styles.headerCheckbox} ${this.props.selectedRequestSet.size > 0 ? styles.visible : ''}`}
                                    id='payrollRequestId_all'>
                                    <Checkbox
                                      disabled={this.props.currentStep === PAYROLL_STEP_SEND || isDisabledAll}
                                      value={this.props.isAllSelected}
                                      multipleIcon
                                      onClick={this.handleSelectAll}
                                    />
                                  </div>
                                  <span className='h5 gray-70'>Pending</span>
                                </div>
                              </td>
                            </tr>
                            {requests.map(this.renderLine(true))}
                          </React.Fragment>
                        )
                      }
                    })}
                  </tbody>
                </table>
              ) : (
                <div className={styles.nothingMessage}>
                  <Icon type='info-circle' colorType='gray-30' />
                  <div className='h5 gray-30'>You don’t have any<br /> money requests yet.</div>
                </div>
              )}
          </div>
        ) : (<div className={styles.loaderContainer}><LoadingState
          loadingState={loadingState}
          reloadData={this.props.getPaymentRequests}
        /></div>)}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const activityList = activityListSelector(state)
  const selectedRequestSet = selectedRequestIdsSelector(state)
  const firstCollection = activityList[0]
  let isAllSelected = false
  if (firstCollection) {
    isAllSelected = firstCollection && firstCollection.collection.date === PAYMENT_REQUEST_COLLECTION_PENDING
      ? selectedRequestSet.size > 0 && !firstCollection.requests.some((request) => {
        return [
          PAYMENT_REQUEST_STATUS_FAILED,
          PAYMENT_REQUEST_STATUS_UNPAID,
        ].includes(request.status)
        && !request.isFromMe
        && !selectedRequestSet.has(request.requestId)
      })
      : false
  }

  return {
    usersHints: usersHintSelector(state),
    activityList,
    selectedRequestSet,
    currentStep: payrollCurrentPanelStepSelector(state),
    selectedRequestSets: state.payroll.selectedRequestIds,
    loadingState: requestsLoadingStateSelector(state),
    isAllSelected,
    exportCSVLoadingState: customLoadingStatesSelector('exportCSVLoadingState')(state),
    isExportRequestsExisted: isExportRequestsExistedSelector(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearRequestSet: () => dispatch(clearRequestItemSet()),
  selectRequest: (requestId) => dispatch(selectRequestItemThunk(requestId)),
  selectRequestsList: (requestList) => dispatch(selectRequestsListThunk(requestList)),
  removeRequest: (requestId) => dispatch(removeRequestItemThunk(requestId)),
  getPaymentRequests: () => dispatch(getPaymentRequests()),
  handleExportCsv: () => dispatch(exportPaymentRequestsToCSV()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTable)
