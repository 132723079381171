export const BITCOIN_DUCK = 'bitcoin'
export const COIN_TYPE_BTC_MAINNET = 0
export const COIN_TYPE_BTC_TESTNET = 1

export const BITCOIN_UPDATE_FEE_RATE = 'bitcoin/UPDATE_FEE_RATE'
export const BITCOIN_UPDATE_TRANSACTION_STATE = 'bitcoin/UPDATE_TRANSACTION_STATE'

export const BITCOIN_DECIMALS = 100000000
export const BITCOIN_DECIMAL_LENGTH = 8

export const BITCOIN_ACCOUNT_KEY_MAINNET = 'mainnet'
export const BITCOIN_ACCOUNT_KEY_TESTNET = 'testnet'

export const BITCOIN_SYMBOL = 'BTC'

export const BLOCKCHAIN_BITCOIN = 'bitcoin'
export const BLOCKCHAIN_BITCOIN_TESTNET = 'bitcoin_testnet'

export const BLOCKCHAIN_BITCOIN_REMOTE = 'BTC'
export const BLOCKCHAIN_BITCOIN_TESTNET_REMOTE = 'BTC'
