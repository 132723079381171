// @flow
import moment from 'moment'
import BigNumber from 'bignumber.js'
import CryptoJS from 'crypto-js'
import AddressValidation from 'wallet-address-validator'
import { BLOCKCHAIN_BITCOIN_TESTNET } from '../bitcoin/constants'
import {
  TRANSACTION_ICON_NAME_IN,
  TRANSACTION_ICON_NAME_OUT,
  TRANSACTION_ICON_NAME_PENDING,
  UNKNOWN_ADDRESS,
} from '../accounts/constants'
import {
  BLOCKCHAIN_ETHEREUM,
  ETHEREUM_DECIMALS,
  ETHEREUM_SYMBOL,
  BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY,
  ETHEREUM_DECIMAL_LENGTH,
} from './constants'
import type { HotWallet, AnyWallet } from '../accounts/models'
import type { Transaction } from '../accounts/models'
import config from '../../config/getConfig'

export const checkPassOfAccount = (account?: HotWallet, password?: string) => {
  try {
    if (!account || !password) {
      return false
    }
    const mnemonic = CryptoJS.AES.decrypt(account.encryptedKey, password)
    const mnemonicString = CryptoJS.enc.Utf8.stringify(mnemonic)
    return mnemonicString.length > 0
  } catch (e) {
    return false
  }
}

export const checkNetworkOfAddress = (address: string) => {
  if (!address) {
    return 'unknown network'
  }

  if (AddressValidation.validate(address, BLOCKCHAIN_ETHEREUM)) {
    return BLOCKCHAIN_ETHEREUM
  } else if (AddressValidation.validate(address, BLOCKCHAIN_ETHEREUM, 'testnet')) {
    return BLOCKCHAIN_BITCOIN_TESTNET
  } else {
    return 'unknown network'
  }
}

export const createTx = (from: string, to: string, value: BigNumber) => {
  return {
    from: from,
    to: [
      { address: to, value: new BigNumber(value).multipliedBy(ETHEREUM_DECIMALS) },
    ],
  }
}

export const getChainOfAccount = (account: { blockchain: string }) => {
  switch (account.blockchain) {
    case BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY:
      return 'rinkeby'
    default:
      return 'mainnet'
  }
}

export const convertToBigNumber = (amount: string | number) => {
  return new BigNumber(amount).dividedBy(ETHEREUM_DECIMALS)
}

export const trimZeros = (string: string) => {
  return string.replace(/[0]+$/, '')
}

/**
 * For Chronobank node format
 * @param tx
 * @param address
 * @returns {{symbol: string, fees, amount: BigNumber, blockchain: string, txId: string, block: *, from: *, time: moment.Moment, to: *}}
 */
export const formatBlockExplorerTx = (tx: any, address: string): Transaction => {
  const feeBn = new BigNumber(tx.gas).multipliedBy(tx.gasPrice).dividedBy(ETHEREUM_DECIMALS)
  const to: string = `${tx.to}`.toLocaleLowerCase()
  const from: string = `${tx.from}`.toLocaleLowerCase()
  const isPositive: boolean = to === address.toLocaleLowerCase()
  const amount = new BigNumber(tx.value)

  return {
    txId: tx.hash,
    time: tx.blockTime ? moment.unix(tx.blockTime) : null,
    block: parseInt(tx.blockNumber),
    fees: trimZeros(feeBn.toString()),
    feeBn: feeBn,
    amount: convertToBigNumber(amount).multipliedBy(isPositive ? 1 : -1),
    symbol: ETHEREUM_SYMBOL,
    blockchain: BLOCKCHAIN_ETHEREUM,
    confirmations: tx.blockNumber ? tx.confirmations : 0,
    isPending: !tx.blockNumber,
    isPositive,
    fromAddress: [from],
    fromAddressText: from || UNKNOWN_ADDRESS,
    type: !tx.blockNumber ? TRANSACTION_ICON_NAME_PENDING : isPositive ? TRANSACTION_ICON_NAME_IN : TRANSACTION_ICON_NAME_OUT,
    from: [{ address: from }],
    to: [{ address: to }],
    toAddress: [to],
    toAddressText: to || UNKNOWN_ADDRESS,
  }
}

export const formatBalance = (unformattedBalance: string | number | null = null): { balance: BigNumber | null, formattedBalance: BigNumber | null } => {
  if (unformattedBalance === null || unformattedBalance === 'undefined') {
    return {
      balance: null,
      formattedBalance: null,
    }
  }
  const balance = new BigNumber(unformattedBalance)
  return {
    balance,
    formattedBalance: new BigNumber(balance.dividedBy(ETHEREUM_DECIMALS).toFixed(config.getConfig('ethereumDecimalLength'))),
  }
}

export const getExplorerLink = (txId: string, account: AnyWallet) => {
  const mainnetExplorer = 'https://etherscan.io/tx/'
  const testnetRinkebyExplorer = 'https://rinkeby.etherscan.io/tx/'
  switch (account.blockchain) {
    case BLOCKCHAIN_ETHEREUM:
      return `${mainnetExplorer}${txId}`
    case BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY:
      return `${testnetRinkebyExplorer}${txId}`
    default:
      break
  }
  return null
}

export const getDecimals = () => {
  return ETHEREUM_DECIMALS
}

export const getDecimalLength = () => {
  return ETHEREUM_DECIMAL_LENGTH
}

export const isTestnetAccount = (account: { blockchain: string }) => {
  return [BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY]
    .includes(account.blockchain)
}

// eslint-disable-next-line no-unused-vars
export const createPaymentLink = (accountAddress: string, value: string, message: string): string => {
  return `ethereum:${accountAddress}?value=${value}`
}
