// INFO: See API at https://min-api.cryptocompare.com/

import axios from 'axios'

const axiosClient = axios.create({
  baseURL: 'https://min-api.cryptocompare.com',
  responseType: 'json',
  timeout: 10000,
})

// eslint-disable-next-line import/prefer-default-export
export const requestPrices = async (tokens, currencies) => {
  return axiosClient.get(`/data/pricemulti?fsyms=${tokens}&tsyms=${currencies}`)
}
