import EthereumMemoryDevice from '../../signers/EthereumMemoryDevice'
import {
  ETHEREUM_DUCK,
  BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY,
  BLOCKCHAIN_ETHEREUM,
  ETHEREUM_ACCOUNT_KEY_TESTNET_RINKEBY,
  ETHEREUM_ACCOUNT_KEY_MAINNET,
} from './constants'
import { ACCOUNT_TYPE_TREZOR } from "../accounts/constants"
import EthereumTrezorDevice from "../../signers/EthereumTrezorDevice"
import { createSelector } from "reselect"

export const getSigner = (mnemonic, network, type) => {
  switch (type) {
    case ACCOUNT_TYPE_TREZOR:
      return new EthereumTrezorDevice(network)
    default:
      return new EthereumMemoryDevice(mnemonic, network)
  }
}

export const getNetwork = (blockchain) => {
  switch (blockchain) {
    case BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY:
      return ETHEREUM_ACCOUNT_KEY_TESTNET_RINKEBY
    case BLOCKCHAIN_ETHEREUM:
    default:
      return ETHEREUM_ACCOUNT_KEY_MAINNET
  }
}

export const getRemoteBlockchain = (blockchain: string): string => {
  switch (blockchain) {
    case BLOCKCHAIN_ETHEREUM:
    case BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY:
      return BLOCKCHAIN_ETHEREUM
    default:
      return null
  }
}

export const ethereumDuckSelector = (state) => {
  return state[ETHEREUM_DUCK]
}

export const lastBlockSelector = (blockchain) => createSelector(
  ethereumDuckSelector,
  ({ lastBlock }) => lastBlock[blockchain],
)

export const transactionSendingStateSelector = (state) => {
  const { transactionSendingState } = ethereumDuckSelector(state)
  return transactionSendingState
}
