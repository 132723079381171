import PropTypes from 'prop-types'
import React from 'react'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import InputText from '../../components/InputText'
import { Button } from '../../components/Button'
import { LOADING_STATE_LOADING } from '../../constants'
import styles from './Login.module.scss'
import Icon from '../../components/Icon/Icon'
import RecallConfirmationCodeTimer from './RecallConfirmationCodeTimer'
import { AUTH_FLOW_RESET_PASSWORD, AUTH_SESSION_CODE_EXPIRED } from '../../ducks/auth/constants'


const ConfirmCodeStep = ({ auth, confirmCode, handleResetLogin, handleRecallCode }) => {
  const validationSchema = Yup.object().shape({
    code: Yup.string()
      .required('Code is required'),
  })

  const handleSubmit = (values) => {
    confirmCode(auth.email, values.code)
  }

  const renderTitle = () => {
    if (auth.authSession === AUTH_SESSION_CODE_EXPIRED) {
      return <>Your session expired. New confirmation code has been sent to <span
        className='blue-bk'>{auth.email}</span>.<br />Please
        enter the code to reset the password.</>
    }
    if (auth.authFlow === AUTH_FLOW_RESET_PASSWORD) {
      return <>A confirmation code has been sent to <span className='blue-bk'>{auth.email}</span>.<br />Please
        enter the code to reset the password.</>
    } else {
      return <>A confirmation code has been sent to <span className='blue-bk'>{auth.email}</span>.<br />Please
        enter the code to confirm your email.</>
    }
  }

  const render = (formProps) => {
    const {
      handleSubmit,
      handleChange,
      handleBlur,
      submitForm,
      values,
      errors,
      touched,
    } = formProps

    const handleTrimDecorator = (e) => {
      e.target.value = e.target.value.trim()
      handleChange(e)
    }

    const authError = (auth.error && auth.result !== 414) ? auth.error : null
    return (
      <Form
        name='Confirm_code_form'
        onSubmit={handleSubmit}
        data-selenium-id='authPage'
      >
        <div className={styles.fieldsWrapper}>
          <div>
            <div className={styles.title + ' gray'}>{auth.authFlow === AUTH_FLOW_RESET_PASSWORD || auth.authSession === AUTH_SESSION_CODE_EXPIRED
              ? 'Reset the password'
              : 'Create a new account'}</div>
            <div className={styles.inputLabel + ' gray-70'}>
              {renderTitle()}
            </div>
            <div className={styles.inputs}>
              <InputText
                onChange={handleTrimDecorator}
                autoFocus
                onBlur={handleBlur}
                error={!!((errors.code && touched.code) || !!authError)}
                errorText={errors.code || authError}
                errorWithIcon
                value={values.code}
                name='code'
                type='text'
                disabled={auth.loadingState === LOADING_STATE_LOADING}
                data-selenium-id='code'
              />
              <Button
                id='confirmCodePageActionButton'
                colorType='login'
                type='button'
                disabled={!values.code || auth.loadingState === LOADING_STATE_LOADING}
                handleClick={submitForm}
                data-selenium-id='continue'>
                {(auth.loadingState === LOADING_STATE_LOADING) ? (
                  <div className='messageContainer'>
                    <div className={styles.loader} />
                  </div>
                ) : 'Next'}</Button>
            </div>
            <div className={styles.additionalActions}>
              <button
                className={styles.goBack}
                disabled={auth.loadingState === LOADING_STATE_LOADING}
                type='button'
                onClick={handleResetLogin}>
                <Icon type='arrow-left' colorType='blue' />
                <span className='capture blue-bk'>Edit email address</span>
              </button>
              {auth.result !== 413 && auth.loadingState !== LOADING_STATE_LOADING ? ( // blocked user error
                <RecallConfirmationCodeTimer
                  confirmCodeSendTime={auth.confirmCodeSendTime}
                  handleRecallCode={handleRecallCode}
                  loadingState={auth.loadingState}
                />
              ) : null}
            </div>
          </div>
        </div>
      </Form>
    )
  }

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        code: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      render={render}
    />
  )
}

ConfirmCodeStep.propTypes = {
  auth: PropTypes.object,
  confirmCode: PropTypes.func,
  handleResetLogin: PropTypes.func,
  handleRecallCode: PropTypes.func,
  renderSignUpMessage: PropTypes.func,
}

export default ConfirmCodeStep
