import { connect } from 'react-redux'
import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon'
import {
  PAYMENT_REQUEST_STATUS_CANCELLED,
  PAYMENT_REQUEST_STATUS_DECLINED, PAYMENT_REQUEST_STATUS_DRAFT,
  PAYMENT_REQUEST_STATUS_FAILED,
  PAYMENT_REQUEST_STATUS_IN_PROGRESS,
  PAYMENT_REQUEST_STATUS_PAID,
  PAYMENT_REQUEST_STATUS_PENDING_APPROVAL,
  PAYMENT_REQUEST_STATUS_UNPAID,
} from '../../ducks/paymentRequests/constants'
import { PaymentRequestModel } from '../../models/PaymentRequests/PaymentRequestModel'
import { profileInfoSelector } from '../../ducks/profile/selectors'
import { requestByIdSelector } from '../../ducks/paymentRequests/selectors'
import styles from './PaymentRequestStatusIcon.module.scss'

const TYPE_LIST = 'list'
const TYPE_DETAIL = 'detail'

class PaymentRequestStatusIcon extends React.PureComponent {
  static propTypes = {
    type: PropTypes.oneOf([
      TYPE_LIST,
      TYPE_DETAIL,
    ]),
    request: PropTypes.instanceOf(PaymentRequestModel),
    requestId: PropTypes.string,
    profile: PropTypes.shape({
      id: PropTypes.string,
    }),
  }

  getIcon () {
    const settings = {
      backgroundColor: 'transparent',
      iconType: null,
      iconColorType: null,
    }
    switch (this.props.request.status) {
      case PAYMENT_REQUEST_STATUS_DRAFT:
        settings.iconType = 'draft'
        settings.iconColorType = 'gray-70'
        settings.backgroundColor = '#F0F2F9'
        break
      case PAYMENT_REQUEST_STATUS_PENDING_APPROVAL:
        settings.iconType = 'refresh-double'
        settings.iconColorType = 'gray-50'
        settings.backgroundColor = '#F6FBFB'
        break
      case PAYMENT_REQUEST_STATUS_DECLINED:
        settings.iconType = 'block'
        settings.iconColorType = 'red'
        settings.backgroundColor = '#FFF7F7'
        break
      case PAYMENT_REQUEST_STATUS_UNPAID:
        settings.iconType = 'clock'
        settings.iconColorType = 'gray-50'
        settings.backgroundColor = '#F6FBFB'
        break
      case PAYMENT_REQUEST_STATUS_CANCELLED:
        settings.iconType = 'close-circle'
        settings.iconColorType = 'red'
        settings.backgroundColor = '#FFF7F7'
        break
      case PAYMENT_REQUEST_STATUS_IN_PROGRESS:
        settings.iconType = 'time'
        settings.iconColorType = 'orange'
        settings.backgroundColor = '#FFF9EE'
        break
      case PAYMENT_REQUEST_STATUS_FAILED:
        settings.iconType = 'attention'
        settings.iconColorType = 'red'
        settings.backgroundColor = '#FFF7F7'
        break
      case PAYMENT_REQUEST_STATUS_PAID:
        if (this.props.request.fromUserId === this.props.profile.id) {
          settings.iconType = 'collapse-down'
          settings.iconColorType = 'green'
          settings.backgroundColor = '#EEFDED'
          break
        } else {
          settings.iconType = 'arrow-to-top'
          settings.iconColorType = 'blue'
          settings.backgroundColor = '#F7F8FF'
          break
        }
      default:
        break
    }
    return settings
  }

  render () {
    const iconSettings = this.getIcon()
    switch (this.props.type) {
      case TYPE_LIST:
      case TYPE_DETAIL:
        return <div className={styles.iconWrapper} style={{
          backgroundColor: iconSettings.backgroundColor,
        }}>
          <Icon type={iconSettings.iconType} colorType={iconSettings.iconColorType} />
        </div>
      default:
        return null
    }
  }
}

const mapStateToProps = (state, props) => {
  return {
    request: requestByIdSelector(props.requestId)(state),
    profile: profileInfoSelector(state),
  }
}

export default connect(mapStateToProps)(PaymentRequestStatusIcon)
