import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React from 'react'
import { uiDuckSelector } from '../../ducks/ui/selectors'
import { setSidePanel } from '../../ducks/ui/actions'
import BigPopup from '../BigPopup'
import AddInvoiceCard from '../../partials/AddInvoiceCard'
import { updateFormLoadingState } from '../../ducks/paymentRequests/actions'
import { updateStep } from '../../ducks/payroll/actions'
import { PAYROLL_STEP_REQUEST_SETTINGS } from '../../ducks/payroll/constants'
import InactivityMessage from '../../containers/PageContainer/InactivityMessage'
import { hideLastActivityPopupThunk } from '../../ducks/profile/thunks'
import { logout } from '../../ducks/auth/thunks'
import { lastActivitySelector, showLastActivityPopupSelector } from '../../ducks/profile/selectors'
import SendReceiveEmailForm from '../AddPaymentRequestSidePanel/AddPaymentRequestSidePanel'
import QrAddressPopup from '../QrAddressPopup/QrAddressPopup'

function ModalStack (props) {
  const {
    sidePanels,
    removeLoadingState,
    setSidePanel,
    resetPayrollSteps,
    hideActivityPopup,
    logout,
    lastActivity,
    isLastActivityPopupActive,
  } = props

  const closeAddInvoiceCard = () => {
    removeLoadingState()
    setSidePanel('addInvoiceSidePanel', { isOpen: false, requestId: null })
  }

  const openInvoiceCard = (requestId) => () => {
    resetPayrollSteps()
    setSidePanel('invoiceSidePanel', { isOpen: true, requestId })
  }

  const closeAddPaymentRequestSidePanel = () => {
    removeLoadingState()
    setSidePanel('addPaymentRequestSidePanel', { isOpen: false, account: null })
  }

  const closeQrAddressPopup = () => {
    setSidePanel('qrAddressPopup', { isOpen: false, address: null })
  }

  return (
    <>
      {sidePanels.addInvoiceSidePanel.isOpen && (
        <BigPopup onClose={closeAddInvoiceCard}>
          <AddInvoiceCard
            openInvoiceCard={openInvoiceCard}
            requestId={sidePanels.addInvoiceSidePanel.requestId}
            templateRequestId={sidePanels.addInvoiceSidePanel.templateRequestId}
          />
        </BigPopup>
      )}
      {isLastActivityPopupActive && (
        <BigPopup
          smallMode
          onClose={hideActivityPopup}
        >
          <InactivityMessage
            onClose={hideActivityPopup}
            logout={logout}
            activityDate={lastActivity}
          />
        </BigPopup>
      )}
      {sidePanels.addPaymentRequestSidePanel.isOpen && (
        <BigPopup smallMode onClose={closeAddPaymentRequestSidePanel}>
          <SendReceiveEmailForm accountId={sidePanels.addPaymentRequestSidePanel.accountId} />
        </BigPopup>
      )}
      {sidePanels.qrAddressPopup.isOpen && (
        <BigPopup smallMode onClose={closeQrAddressPopup}>
          <QrAddressPopup account={sidePanels.qrAddressPopup.account} />
        </BigPopup>
      )}
    </>
  )
}

ModalStack.propTypes = {
  lastActivity: PropTypes.instanceOf(Date),
  isLastActivityPopupActive: PropTypes.bool,
  sidePanels: PropTypes.object,
  removeLoadingState: PropTypes.func,
  setSidePanel: PropTypes.func,
  resetPayrollSteps: PropTypes.func,
  hideActivityPopup: PropTypes.func,
  logout: PropTypes.func,
}

function mapStateToProps (state) {
  const { sidePanels } = uiDuckSelector(state)
  return {
    sidePanels,
    lastActivity: lastActivitySelector(state),
    isLastActivityPopupActive: showLastActivityPopupSelector(state),
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setSidePanel: (panelName, panelProps) => dispatch(setSidePanel(panelName, panelProps)),
    removeLoadingState: () => dispatch(updateFormLoadingState('newInvoiceForm', null)),
    resetPayrollSteps: () => dispatch(updateStep(PAYROLL_STEP_REQUEST_SETTINGS)),
    hideActivityPopup: () => dispatch(hideLastActivityPopupThunk()),
    logout: () => dispatch(logout()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalStack)

