//@flow
import React from 'react'
import PropTypes from 'prop-types'
import styles from './TransactionLine.module.scss'

import type { Transaction } from '../../../ducks/accounts/models'
import Icon from '../../Icon/Icon'
import {
  TRANSACTION_ICON_NAME_IN,
  TRANSACTION_ICON_NAME_OUT,
  TRANSACTION_ICON_NAME_PENDING,
} from '../../../ducks/accounts/constants'
import { getExplorerLink } from '../../../ducks/accounts/utils'
import { AccountModel } from '../../../models/Accounts/AccountModel'
import CopyButton from '../../Button/CopyButton'

const DATE_FORMAT = 'HH:mm · MMM DD, YYYY'
const TIME_FORMAT = 'HH:mm'

type Props = {
  transaction: Transaction,
  account: any
}

type State = {
  isSelected: boolean,
}

class TransactionLine extends React.PureComponent<Props, State> {
  static propTypes = {
    transaction: PropTypes.object,
    account: PropTypes.instanceOf(AccountModel),
  }

  constructor (props: Props) {
    super(props)
    this.state = {
      isSelected: false,
    }
  }

  selectTransactionId = () => {
    this.setState((oldState) => ({
      isSelected: !oldState.isSelected,
    }))
  }

  handleViewOnBlockExplorer = (txId: string) => () => {
    window.open(
      getExplorerLink(txId, this.props.account),
      '_blank',
    )
  }

  renderStatus = () => {
    let status = ''
    let icon = ''
    switch (this.props.transaction.type) {
      case TRANSACTION_ICON_NAME_PENDING:
        status = 'In progress'
        icon = 'time'
        break
      case TRANSACTION_ICON_NAME_IN:
        status = 'Received'
        icon = 'collapse-down'
        break
      case TRANSACTION_ICON_NAME_OUT:
        status = 'Sent'
        icon = 'arrow-to-top'
        break
      default:
        break
    }
    return (
      <>
        <Icon type={icon} colorType='gray-30' />
        <span className='capture gray-70'>{status}</span>
      </>
    )
  }

  renderFullInfo = () => {
    const tx = this.props.transaction
    return (
      <div className={styles.txDetailTable + ' capture'}>
        <div>From</div>
        <div>
          <div className={styles.addressesContainer}>
            {tx.from.map(({ address }) => {
              return (
                <div key={address}>
                  <span>{address}</span>
                  <CopyButton copyString={address} />
                </div>
              )
            })}
          </div>
        </div>
        <div>To</div>
        <div>
          <div className={styles.addressesContainer}>
            {tx.to.map(({ address }) => {
              return (
                <div key={address}>
                  <span>{address}</span>
                  <CopyButton copyString={address} />
                </div>
              )
            })}
          </div>
        </div>
        <div>Miner fee</div>
        <div>{tx.fees}</div>
        <div>Confirmations</div>
        <div>{tx.confirmations > 6 ? '6+' : tx.confirmations}</div>
        <div>Transaction hash</div>
        <div>
          <div className={styles.hashContainer}>
            <div>{tx.txId}</div>
            <CopyButton copyString={tx.txId} />
          </div>
        </div>
        <div />
        <div>
          <button className={styles.explorerLink} onClick={this.handleViewOnBlockExplorer(tx.txId)}>
            <Icon type='link' colorType='blue' />
            <span className='capture blue-bk'>View on Blockchain </span>
          </button>
        </div>
      </div>
    )
  }

  render () {
    const { transaction: tx } = this.props

    const { isSelected } = this.state

    const fromAddress = Array.isArray(tx.fromAddress) ? tx.fromAddress[0] : tx.fromAddress
    const toAddress = Array.isArray(tx.toAddress) ? tx.toAddress[0] : tx.toAddress
    return (
      <div
        className={styles.container + (isSelected ? ' open' : '')}
        onClick={this.selectTransactionId}
      >
        <div className={styles.chevron}><Icon
          type={isSelected ? 'chevron-up' : 'chevron-down'}
          colorType='gray-30' />
        </div>

        <div className={styles.status}>
          {this.renderStatus()}
        </div>

        <div
          className={styles.date + ' capture gray-50'}
        > {tx.time
          ? tx.time.format(isSelected ? TIME_FORMAT : DATE_FORMAT)
          : 'Not confirmed'}
        </div>

        {isSelected
          ? this.renderFullInfo()
          : (
            <div className={styles.address}>
              <Icon type='wallet' colorType='gray-50' />
              <span className='capture gray-70'>
                {tx.isPositive
                  ? `${fromAddress}${Array.isArray(tx.fromAddress) && tx.fromAddress.length > 1 ? ' ...' : ''}`
                  : `${toAddress}${Array.isArray(tx.toAddress) && tx.toAddress.length > 1 ? ' ...' : ''}`
                }
              </span>
            </div>
          )
        }

        <div className={styles.amount + ' h5 ' + (tx.isPositive ? 'green' : 'red')}>
          {tx.isPositive ? '+' : '-'} {tx.symbol} {tx.amount.abs().toFixed(10).replace(/[0]+$/g, '')}
        </div>
      </div>
    )
  }
}


export default TransactionLine
