import { RestService } from '../services/RestService'
import config from './index'
import get from 'lodash.get'

class ConfigClass {
  constructor () {
    this.__isConfigLoaded = false
    this.config = Object.assign({}, config)
  }

  getConfig (key) {
    return key !== undefined ? get(this.config, key, null) : this.config
  }

  async loadConfig () {
    if (this.__isConfigLoaded) {
      return
    }

    const configData = await RestService.request('authService::loadConfig')
    if (configData.data.status !== 200 || !configData.data.result) {
      throw new Error('Failed to load remote config')
    }

    this.config = { ...this.config, ...configData.data.result, __isConfigLoaded: true }
  }
}

export default new ConfigClass()
