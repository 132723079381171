// @flow
import * as a from './constants'

export const updateProfileStatus = (loadingState: string, error?: string | null, status?: number | string) => ({
  type: a.PROFILE_UPDATE_PROFILE_STATUS,
  loadingState,
  error,
  result: status,
})

export const updatePasswordStatus = (loadingState: string, error?: string | null, status?: number | string) => ({
  type: a.PROFILE_UPDATE_PASSWORD_STATUS,
  loadingState,
  error,
  result: status,
})

export const updateProfile = (profile: any) => ({
  type: a.PROFILE_UPDATE_PROFILE,
  profile,
})

export const updateLastActivity = () => ({
  type: a.PROFILE_UPDATE_LAST_ACTIVITY,
})

export const showLastActivityPopup = () => ({
  type: a.PROFILE_SHOW_LAST_ACTIVITY_POPUP,
})

export const hideLastActivityPopup = () => ({
  type: a.PROFILE_HIDE_LAST_ACTIVITY_POPUP,
})

export const updateLastActivityTimerIds = (lastActivityTimerId: number) => ({
  type: a.PROFILE_UPDATE_LAST_ACTIVITY_LOGOUT_TIMER_IDS,
  lastActivityTimerId,
})
