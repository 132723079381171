import React, { useState } from 'react'
import cn from 'classnames'
import styles from '../AddInvoiceCard.module.scss'
import PropTypes from 'prop-types'
import CustomSelect from '../../../components/Select/CustomSelect'
import Icon from '../../../components/Icon/Icon'
import get from 'lodash.get'
import escapeRegExp from 'lodash.escaperegexp'
import { PAYMENT_REQUEST_STATUS_DRAFT } from '../../../ducks/paymentRequests/constants'
import { PaymentRequestModel } from '../../../models/PaymentRequests/PaymentRequestModel'

const BillToSelect = ({ formikProps, billToSearchList, request, isShowRequiredFields }) => {
  const {
    errors,
    touched,
    values,
    setFieldValue,
    setTouched,
    submitCount,
    validateForm,
  } = formikProps

  const [isShowBillToHint, setIsShowBillToHint] = useState(false)

  const selectStyles = {
    indicatorsContainer: (defaultStyles) => ({
      ...defaultStyles,
      display: 'none',
    }),
    input: (styles) => ({
      ...styles,
      maxWidth: '308px',
    }),
    menu: (styles) => ({
      ...styles,
      marginTop: '-22px',
      borderRadius: '3px',
      marginBottom: '0',
      boxShadow: '0px 10px 10px #f0f2f9',
    }),
    singleValue: (defaultStyles) => {
      return {
        ...defaultStyles,
        '>div': {
          gridTemplateAreas: '"name"',
          gridTemplateColumns: '1fr',
        },
        ['.' + styles.billToOptionIcon]: {
          display: 'none',
        },
        ['.' + styles.billToOptionName]: {
          width: '310px',
          color: '#5b5d69',
          ['.' + styles.newEmailLabel]: {
            display: 'none',
          },
          ['.' + styles.newEmailSingleLabel]: {
            display: 'inline',
          },
        },
        ['.' + styles.billToOptionEmail]: {
          display: 'none',
        },
      }
    },
  }

  const renderHint = (elem, context) => {
    const safetyQuery = escapeRegExp(context.inputValue)
    const reg = new RegExp(safetyQuery, 'i')
    const replace = (string) => {
      if (context.inputValue) {
        return `<div>${string.replace(reg, (match) => {
          return `<span class='gray'>${match}</span>`
        })}</div>`
      }
      return `<div>${string}</div>`
    }
    /**
     * if name === email remove name
     */
    if (elem.name === elem.email) {
      elem.name = null
    }

    let name = null
    let email = null
    if (elem.isPaid) {
      name = elem.name
      email = elem.email
    } else {
      name = elem.email
      email = null
    }
    const avatar = get(elem, 'avatar.url', null)

    return (
      <div className={`${styles.billToOption} ${!name || !email ? 'withoutEmail' : ''} capture gray-50`}>
        <div className={styles.billToOptionIcon}>
          <div className={styles.avatarWrapper}>
            {avatar && elem.isPaid
              ? (
                <img alt={name || email || elem.value} src={avatar} />
              )
              : (
                <Icon
                  type='user-circle'
                  colorType='gray-50'
                />
              )}
          </div>
        </div>
        {elem.__isNew__ ? (
          <>
            <div className={styles.billToOptionName}>
              <div className={styles.newEmailLabel}>{`New Email "${elem.value}"`}</div>
              <div className={styles.newEmailSingleLabel}>{elem.value}</div>
            </div>
            <div className={styles.billToOptionEmail} />
          </>
        ) : (
          <>
            <div
              className={styles.billToOptionName}
              dangerouslySetInnerHTML={{ __html: replace(name || email) }}
            />
            {(
              <div
                className={styles.billToOptionEmail + ' gray-30'}
                dangerouslySetInnerHTML={name && email ? { __html: replace(email) } : null}
              />
            )}
          </>
        )}
      </div>
    )
  }

  const isDraft = get(request, 'status', null) === PAYMENT_REQUEST_STATUS_DRAFT
  const isPaid = get(billToSearchList, `["${values.billTo}"].isPaid`, false)

  const avatar = values.billTo && isPaid
    ? get(billToSearchList, `["${values.billTo}"].avatar.url`, null)
    : null

  const name = values.billTo && isPaid
    ? get(billToSearchList, `["${values.billTo}"].name`, null)
    : null

  return (

    <div className={styles.billToFieldWrapper}>
      <CustomSelect
        className={styles.billToSelect}
        name='billTo'
        autoFocus
        isCreatableSelect
        styles={selectStyles}
        placeholder='Email'
        onInputChange={(query) => {
          setIsShowBillToHint(!!(query && query.length >= 2))
        }}
        errorColor={submitCount <= 0 && isShowRequiredFields ? 'blue' : null}
        error={(!!touched.billTo && (submitCount > 0 || !!values.billTo) && !!errors.billTo) || isShowRequiredFields}
        errorText={(!!touched.billTo && (submitCount > 0 || !!values.billTo) && !!errors.billTo) ? errors.billTo : null}
        isDisabled={values.billTo && !errors.billTo}
        onChange={(option) => {
          const newValue = option && option.value ? option.value.trim() : option
          setFieldValue('billTo', newValue)
          setTouched({
            ...(touched || {}),
            billTo: true,
          })
          if (submitCount > 0) {
            setTimeout(async () => {
              await validateForm({
                ...values,
                'billTo': newValue,
              })
            }, 0)
          }
        }}
        menuIsOpen={isShowBillToHint}
        options={Object.values(billToSearchList)
          .map((item) => {
            return {
              label: item.isPaid && item.name ? item.name : item.email,
              value: item.email,
              ...item,
            }
          })}
        formatOptionLabel={renderHint}
        value={values.billTo
          ? billToSearchList[values.billTo]
            ? {
              value: billToSearchList[values.billTo].email,
              label: billToSearchList[values.billTo].email,
              ...billToSearchList[values.billTo],
            }
            : {
              value: values.billTo,
              label: values.billTo,
              __isNew__: true,
            }
          : null}
      />
      {values.billTo && !errors.billTo ? (
        <div className={cn(styles.billToSelectedWrapper, { 'avatar': !!avatar })}>
          {
            avatar
              ? <img alt={name || values.billTo} src={avatar} />
              : <div className={styles.iconWrapper}><Icon type='mail' colorType='gray-50' /></div>
          }
          <div>
            {billToSearchList[values.billTo]
            && billToSearchList[values.billTo].name !== values.billTo
            && billToSearchList[values.billTo].isPaid
              ? <div>{name || values.billTo}</div>
              : <div>{values.billTo}</div>
            }
          </div>
          <button
            type='button'
            disabled={!!values.requestId && !isDraft}
            onClick={() => {
              setFieldValue('billTo', null)
            }}>
            <Icon type='close' colorType='gray-30' />
          </button>
        </div>
      ) : null}
    </div>
  )
}

BillToSelect.propTypes = {
  billToSearchList: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    isPaid: PropTypes.bool,
    avatar: PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
  formikProps: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    values: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    submitForm: PropTypes.func,
    setFieldValue: PropTypes.func,
    submitCount: PropTypes.number,
    setTouched: PropTypes.func,
    validateForm: PropTypes.func,
  }),
  request: PropTypes.instanceOf(PaymentRequestModel),
  isShowRequiredFields: PropTypes.bool,
}

export default BillToSelect
