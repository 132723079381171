import AuthError from '../../errors/AuthError'
import moment from 'moment'
import get from 'lodash.get'

export const error413Handler = (data) => {
  if (data.status === 413) {
    const blockedTill = get(data, 'validation.blockedTill', null)
    const now = moment.utc()
    let diff = Math.abs(now.diff(moment(blockedTill), 'hours')) + 1
    let isHours = true
    if (diff <= 1) {
      diff = Math.abs(now.diff(moment(blockedTill), 'minutes'))
      isHours = false
    }
    const dateString = `${diff} ${isHours ? 'hours' : 'minutes'}`
    const message = `You have entered wrong password too many times. Your account has been blocked for ${dateString}.`
    throw new AuthError(message, data.status)
  }
}

export const error414Handler = () => {

}
