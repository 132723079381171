import React from 'react'
import styles from './AccountSelect.module.scss'
import PropTypes from 'prop-types'
import CustomSelect from './CustomSelect'
import Icon from '../Icon/Icon'
import { BLOCKCHAIN_BITCOIN_TESTNET } from '../../ducks/bitcoin/constants'
import { ACCOUNT_TYPE_TREZOR } from '../../ducks/accounts/constants'
import TrezorConnectButton from '../TrezorConnectButton/TrezorConnectButton'

const AccountSelect = ({
  formikProps,
  accounts,
  selectProps,
  selectStyles,
  isShowRequireError,
  handleSetTrezorWallet,
}) => {
  const {
    values,
    setFieldValue,
    errors,
    submitCount,
    touched,
    setTouched,
  } = formikProps

  return (
    <CustomSelect
      errorColor={submitCount <= 0 && isShowRequireError ? 'blue' : null}
      styles={{
        singleValue: (defaultStyles) => {
          return {
            ...defaultStyles,
            '>div': {
              gridTemplateAreas: '"name"',
              gridTemplateColumns: '1fr',
            },
            ['.' + styles.accountOptionIcon]: {
              display: 'none',
            },
            ['.' + styles.accountOptionAddress]: {
              display: 'none',
            },
            ['.' + styles.accountOptionName]: {
              'span.address': {
                display: 'inline !important',
              },
              span: {
                display: 'none !important',
              },
            },
          }
        },
        ...selectStyles,
      }}
      defaultValue={values.account}
      isOptionSelected={(option) =>
        values.account && option.id === values.account.id
      }
      onChange={(option) => {
        if (option && option.__isNew__) {
          option.value = option.value.trim()
          option.label = option.label.trim()
        }
        setFieldValue('account', option)
        setTouched({
          ...(touched || {}),
          account: true,
        })
      }}
      filterOption={(option, query) => {
        return (
          `${option.data.name || option.label} ${option.data.address ||
          option.value}`
            .toLocaleLowerCase()
            .indexOf(query.toLocaleLowerCase()) >= 0
        )
      }}
      formatOptionLabel={(account) => {
        const isTestnet = account.isTestnet
          ? account.isTestnet
          : [BLOCKCHAIN_BITCOIN_TESTNET].includes(values.blockchain)

        return (
          <>
            {account.type === ACCOUNT_TYPE_TREZOR && !account.address
              ? (
                <div className={styles.trezorConnectWrapper}>
                  <TrezorConnectButton
                    callback={handleSetTrezorWallet}
                    account={account}
                    label="Trezor wallet"
                  />
                </div>
              )
              : (
                <div className={styles.accountOption}>
                  <Icon
                    className={styles.accountOptionIcon}
                    type={account.symbol}
                    colorType={isTestnet ? 'gray-30' : 'blue'}
                  />
                  {account.__isNew__ ? (
                    <>
                      <div
                        className={styles.accountOptionName + ' capture gray-70'}>
                        <span>New address</span>
                        <span className="address">{account.value}</span>
                      </div>
                      <div
                        className={
                          styles.accountOptionAddress + ' capture-small gray-50'
                        }>
                        {account.value}
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className={styles.accountOptionName + ' capture gray-70'}>
                        {account.name || account.label}
                      </div>
                      <div
                        className={
                          styles.accountOptionAddress + ' capture-small gray-50'
                        }>
                        {account.address || account.value}
                      </div>
                    </>
                  )}
                </div>
              )}
          </>
        )
      }}
      options={
        accounts
          ? accounts
            .filter((account) => account.blockchain === values.blockchain)
            .map((account) => {
              if (account.type === ACCOUNT_TYPE_TREZOR && !account.address) {
                return {
                  ...account,
                  isDisabled: true,
                }
              }
              return account
            })
          : []
      }
      value={values.account}
      isCreatableSelect
      error={
        (!!touched.account &&
          (submitCount > 0 || !!values.account) &&
          !!errors.account) ||
        isShowRequireError
      }
      placeholder="Select wallet"
      {...selectProps}
    />
  )
}

AccountSelect.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string,
      type: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      blockchain: PropTypes.string,
    }),
  ),
  formikProps: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    values: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    submitForm: PropTypes.func,
    setFieldValue: PropTypes.func,
    submitCount: PropTypes.number,
    setTouched: PropTypes.func,
  }),
  selectProps: PropTypes.object,
  selectStyles: PropTypes.object,
  isShowRequireError: PropTypes.bool,
  errorText: PropTypes.string,
  handleSetTrezorWallet: PropTypes.func,
}

export default AccountSelect
