import { connect } from 'react-redux'
import { PaymentRequestModel } from '../../models/PaymentRequests/PaymentRequestModel'
import React from 'react'
import PropTypes from 'prop-types'
import InvoiceCardComponent from './InvoiceCardComponent'
import { myTeamsSelector, allTeamMemberListSelector } from '../../ducks/teams/selectors'
import get from 'lodash.get'
import {
  accountByAddressSelector,
  walletAddressAccountSelector,
  walletsMappedByAddressAndBlockchainSelector,
} from '../../ducks/accounts/selectors'
import { profileInfoSelector } from '../../ducks/profile/selectors'
import { loadTxByIdForRequest } from '../../ducks/paymentRequests/thunks'
import { requestByIdSelector, requestsByIdsSelector, usersHintSelector } from '../../ducks/paymentRequests/selectors'
import { getExplorerLink } from '../../ducks/accounts/utils'
import { ratesBySymbolForCurrencySelector } from '../../ducks/rates/selectors'
import { TEAM_MEMBER_STATUS_ACTIVE, TEAM_MEMBER_STATUS_INVITED } from '../../constants'
import { selectRequestItemThunk } from '../../ducks/payroll/thunks'
import {
  PAYROLL_SELECTED_ITEMS_TYPE_PREVIEW_INVOICE,
  PAYROLL_STEP_SEND,
  PAYROLL_STEP_REQUEST_SETTINGS,
} from '../../ducks/payroll/constants'
import { clearRequestPreviewItem, updateStep } from '../../ducks/payroll/actions'
import {
  payrollCurrentPanelStepSelector,
  payrollCurrentStepSelector,
  payrollSettingsSelector,
  blockchainsSettingsLoadingStateSelector,
} from '../../ducks/payroll/selectors'
import { downloadFile, getRequestLogo } from '../../ducks/paymentRequests/thunks'
import { cancelRequest } from '../../ducks/paymentRequests/thunks'
import { LOADING_STATE_ERROR, LOADING_STATE_LOADED, LOADING_STATE_LOADING } from '../../constants/index'
import { PAYMENT_REQUEST_STATUS_DELETED } from '../../ducks/paymentRequests/constants'
import { removePaymentRequestLocal } from '../../ducks/paymentRequests/actions'
import { setSidePanel } from '../../ducks/ui/actions'
import { amplitudeSendEvent } from "../../utils/analytics"

class InvoiceCardContainer extends React.PureComponent {
  static propTypes = {
    accounts: PropTypes.objectOf(PropTypes.shape({
      name: PropTypes.string,
      blockchain: PropTypes.string,
      address: PropTypes.string,
    })),
    billToSearchList: PropTypes.object,
    setSidePanel: PropTypes.func,
    removePaymentRequestLocal: PropTypes.func,
    blockchainsSettingsLoadingState: PropTypes.oneOf([
      LOADING_STATE_ERROR,
      LOADING_STATE_LOADED,
      LOADING_STATE_LOADING,
    ]),
    loadTxByIdForRequest: PropTypes.func,
    onClose: PropTypes.func,
    request: PropTypes.instanceOf(PaymentRequestModel),
    team: PropTypes.object,
    handleApprove: PropTypes.func,
    handleDecline: PropTypes.func,
    downloadFile: PropTypes.func,
    getRequestLogo: PropTypes.func,
    cancelRequest: PropTypes.func,
    openAddInvoiceCard: PropTypes.func,
    currentPayrollStep: PropTypes.string,
    selectRequestItem: PropTypes.func,
    clearSelectedRequestSet: PropTypes.func,
    updateStep: PropTypes.func,
  }

  constructor (props) {
    super(props)
    this.state = {
      isCancelSent: false,
    }
  }

  componentDidMount () {
    this.props.updateStep(PAYROLL_STEP_REQUEST_SETTINGS)
    this.props.selectRequestItem(this.props.request.requestId)
    if (this.props.request.invoice.transactions.length > 0) {
      this.props.loadTxByIdForRequest(this.props.request.requestId)
    }
    if (this.props.request.invoice.logo) {
      this.props.getRequestLogo(this.props.request.requestId)
    }
  }

  componentDidUpdate (prevProps) {
    const currentStatus = this.props.request && this.props.request.status
    const prevStatus = prevProps.request && prevProps.request.status
    if (this.props.request && currentStatus !== prevStatus) {
      this.props.loadTxByIdForRequest(this.props.request.requestId)
    }
  }

  componentWillUnmount () {
    this.props.updateStep(PAYROLL_STEP_REQUEST_SETTINGS)
  }

  handlePay = () => {
    this.props.updateStep(PAYROLL_STEP_SEND)
  }

  handleCancel = (requestId) => () => {
    this.props.cancelRequest(requestId)
    this.setState({
      isCancelSent: true,
    })
  }

  handleEdit = (requestsId) => () => {
    this.props.onClose()
    setTimeout(() => {
      this.props.openAddInvoiceCard(requestsId)
    }, 600)
  }

  handleViewOnBlockExplorer = (txId) => () => {
    window.open(
      getExplorerLink(txId, { blockchain: this.props.request.paymentBlockchain }),
      '_blank',
    )
  }

  handleClose = async () => {
    await this.props.updateStep(PAYROLL_STEP_REQUEST_SETTINGS)
    await this.props.clearSelectedRequestSet()
    this.props.onClose()
    setTimeout(() => {
      if (this.props.request.status === PAYMENT_REQUEST_STATUS_DELETED) {
        this.props.removePaymentRequestLocal(this.props.request.requestId)
      }
    }, 600)
  }

  handleCopyInvoice = () => {
    amplitudeSendEvent('Invoice copied', {})
    this.props.onClose()
    this.props.setSidePanel('addInvoiceSidePanel', { isOpen: true, templateRequestId: this.props.request.requestId })
  }

  render () {
    return (
      <InvoiceCardComponent
        {...this.props}
        isCancelSent={this.state.isCancelSent}
        onClose={this.handleClose}
        handleSave={this.handleSave}
        handleEdit={this.handleEdit}
        handleCancel={this.handleCancel}
        handlePay={this.handlePay}
        handleViewOnBlockExplorer={this.handleViewOnBlockExplorer}
        handleCopyInvoice={this.handleCopyInvoice}
      />
    )
  }
}


function mapStateToProps (state, props) {
  const profile = profileInfoSelector(state)
  const teams = myTeamsSelector()(state)
  const currentPayrollStep = payrollCurrentStepSelector(state)
  const currentRequestPayStep = payrollCurrentPanelStepSelector(state)
  const team = teams.length > 0 ? teams[0] : null
  const request = requestByIdSelector(props.requestId)(state)
  const members = team ? allTeamMemberListSelector(team.teamId)(state) : []
  const requestWallet = walletAddressAccountSelector(request.paymentWallet)(state)

  const payrollSettings = payrollSettingsSelector(state)
  const blockchainsSettingsLoadingState = blockchainsSettingsLoadingStateSelector(state)
  const requestList = requestsByIdsSelector([props.requestId])(state)
  let notHotWallet = false
  let notEnoughMoney = false
  requestList.forEach((r) => {
    const currentSettings = payrollSettings[r.paymentBlockchain]
    if (currentSettings && !get(currentSettings, 'accountId', false) && !get(currentSettings, 'paymentType', false)) {
      notHotWallet = !!r.paymentBlockchain
      if (!currentSettings.enoughMoney) {
        notEnoughMoney = true
      }
    }
  })

  const membersIds = Object.values(members).reduce((accumulator, member) => {
    if ([TEAM_MEMBER_STATUS_ACTIVE, TEAM_MEMBER_STATUS_INVITED].includes(member.status) && member.profileId) {
      accumulator.push(member.profileId)
    }
    return accumulator
  }, [])

  return {
    billToSearchList: usersHintSelector(state),
    blockchainsSettingsLoadingState,
    rate: request && request.paymentAsset && ratesBySymbolForCurrencySelector(request.paymentAsset, request.settlementAsset)(state),
    request,
    requestWallet,
    team,
    currentPayrollStep,
    currentRequestPayStep,
    notHotWallet,
    notEnoughMoney,
    isFromTeamMember: membersIds.includes(request.fromUserId),
    isItMyRequest: request && profile.id === request.fromUserId,
    members,
    account: accountByAddressSelector(request.paymentWallet)(state),
    accounts: walletsMappedByAddressAndBlockchainSelector(state),
  }
}

function mapDispatchToProps (dispatch) {
  return {
    loadTxByIdForRequest: (requestId) => dispatch(loadTxByIdForRequest(requestId)),
    selectRequestItem: (requestId) => dispatch(selectRequestItemThunk(requestId, PAYROLL_SELECTED_ITEMS_TYPE_PREVIEW_INVOICE)),
    clearSelectedRequestSet: () => dispatch(clearRequestPreviewItem()),
    downloadFile: (attachmentId, requestId) => dispatch(downloadFile(attachmentId, requestId)),
    updateStep: (step) => dispatch(updateStep(step)),
    cancelRequest: (requestId) => dispatch(cancelRequest(requestId)),
    getRequestLogo: (requestId) => dispatch(getRequestLogo(requestId)),
    removePaymentRequestLocal: (requestId) => dispatch(removePaymentRequestLocal(requestId)),
    setSidePanel: (panelName, panelProps) => dispatch(setSidePanel(panelName, panelProps)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceCardContainer)
