import get from 'lodash.get'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import config from '../../config/getConfig'
import BigNumber from 'bignumber.js'
import { requestsByIdsSelector } from '../../ducks/paymentRequests/selectors'
import Icon from '../../components/Icon/Icon'
import {
  cryptoPaymentSelector,
  fixedRatesSelector,
  payrollSettingsSelector,
  selectedRequestListByTypeSelector,
} from '../../ducks/payroll/selectors'
import { selectTypeAccountsSelector } from '../../ducks/accounts/selectors'
import { updatePayrollSettingsParams } from '../../ducks/payroll/thunks/runPayrollThunks'
import styles from './BlockchainPart.module.scss'

import type { HotWallet } from '../../ducks/accounts/models'
import { currentCurrencySelector, profileInfoSelector } from '../../ducks/profile/selectors'
import { ACCOUNT_TYPE_TREZOR, FORMAT_FIAT_NUMBERS } from '../../ducks/accounts/constants'
import Checkbox from '../../components/Checkbox/index'
import Label from '../../components/Label/Label'
import { PAYROLL_SELECTED_ITEMS_TYPE_MAIN_LIST } from '../../ducks/payroll/constants'
import { confirmPasswordCardParamsSelector } from '../../ducks/ui/selectors'
import { hideFeeWarning } from '../../ducks/ui/thunks'
import CustomSelect from '../../components/Select/CustomSelect'
import { LOADING_STATE_LOADED } from "../../constants"
import TrezorConnectButton from "../../components/TrezorConnectButton/TrezorConnectButton"
import { getMainSymbolForBlockchain } from "../../utils/general"
import cn from 'classnames'
import { BLOCKCHAIN_ETHEREUM, BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY } from '../../ducks/ethereum/constants'
import { isTestnetAccount } from "../../ducks/accounts/utils"
import { amplitudeSendEvent } from "../../utils/analytics"

type Props = {
  blockchainType: string,
  rateMain: string,
  rates: any,
  allRates: any,
  mainCurrency: string,
  walletList: Array<HotWallet>,
  settings: any,
  type: string,
  requestList: Array<any>,
  cryptoPaymentSelector: any,
  canPaySeparateTransactions: boolean,
  updatePayrollSettingsBlockchain: (params: {
    accountId: string,
    multiplier: string,
    isSeparateTransactions: boolean,
    paymentType: string,
  }) => void
}

class BlockchainPart extends React.PureComponent<Props> {
  static propTypes = {
    canPaySeparateTransactions: PropTypes.bool,
    hideFeeWarning: PropTypes.func,
    isShownFeeWarning: PropTypes.bool,
    type: PropTypes.string,
    cryptoPaymentSelector: PropTypes.objectOf(PropTypes.shape({
      count: PropTypes.number,
      amount: PropTypes.instanceOf(BigNumber),
    })),
    rateMain: PropTypes.number,
    rates: PropTypes.objectOf(PropTypes.number),
    allRates: PropTypes.objectOf(PropTypes.object),
    mainCurrency: PropTypes.string,
    settings: PropTypes.object,
    requestList: PropTypes.arrayOf(PropTypes.object),
    blockchainType: PropTypes.string.isRequired,
    walletList: PropTypes.arrayOf(PropTypes.object).isRequired,
    updatePayrollSettingsBlockchain: PropTypes.func.isRequired,
  }

  handleTggleSepparatedTransactions = () => {
    const { settings } = this.props
    this.props.updatePayrollSettingsBlockchain({
      accountId: settings.accountId,
      paymentType: settings.paymentType,
      multiplier: 1,
      isSeparateTransactions: !settings.isSeparateTransactions,
    })
  }

  handleChangeFee = ({ value }) => {
    const { settings } = this.props
    this.props.updatePayrollSettingsBlockchain({
      accountId: settings.accountId,
      multiplier: value,
      paymentType: settings.paymentType,
      isSeparateTransactions: settings.isSeparateTransactions,
    })
  }

  handleChangeWallet = ({ value , ...other }) => {
    const wallet = (this.props.walletList || []).find(item => item.id === value)
    if (wallet) {
      let cryptocurrency = ''
      switch (wallet.blockchain) {
        case 'bitcoin' :
          cryptocurrency = 'Bitcoin'
              break
        case 'bitcoin_testnet' :
          cryptocurrency = 'Bitcoin Test'
          break
        case 'ethereum' :
          cryptocurrency = 'Ether'
          break
        case 'ethereum_rinkeby' :
          cryptocurrency = 'Ether Test'
          break
      }
      amplitudeSendEvent('Wallet changed', { Cryptocurrency: cryptocurrency })
    }

    const { settings } = this.props
    if ([ACCOUNT_TYPE_TREZOR].includes(value)) {
      this.props.updatePayrollSettingsBlockchain({
        accountId: null,
        paymentType: value,
        multiplier: settings.multiplier,
        isSeparateTransactions: settings.isSeparateTransactions,
      })
    } else {
      this.props.updatePayrollSettingsBlockchain({
        accountId: value,
        paymentType: null,
        multiplier: settings.multiplier,
        isSeparateTransactions: settings.isSeparateTransactions,
      })
    }
  }

  convertToFiat = (crypto) => {
    return new BigNumber(crypto).multipliedBy(this.props.rateMain).toFormat(2, BigNumber.ROUND_UP, FORMAT_FIAT_NUMBERS)
  }

  getTotalRequestsAmount = () => {
    const { requestList, allRates, blockchainType, mainCurrency, rateMain } = this.props

    if (!requestList.length) {
      return new BigNumber(0)
    }

    let totalFiatValue = new BigNumber(0)
    requestList.filter((request) => request.paymentBlockchain === blockchainType).forEach((request) => {
      if (mainCurrency !== request.settlementAsset) {
        const rate = get(allRates, mainCurrency + '.' + request.settlementAsset, 0)
        totalFiatValue = totalFiatValue.plus(new BigNumber(request.settlementAmount).dividedBy(rate))
      } else {
        totalFiatValue = totalFiatValue.plus(request.settlementAmount)
      }
    })

    return totalFiatValue.dividedBy(rateMain)
  }

  render () {
    const { requestList } = this.props

    return requestList.length
      ? this.renderBlock()
      : null
  }

  renderBlock () {
    const { settings, walletList, blockchainType } = this.props

    if (!settings || (!get(settings, 'paymentType', false) && !get(settings, 'accountId', false))) {
      return null
    }

    const separatedMultiplier = settings.isSeparateTransactions ? settings.separateTransactionsAll * settings.multiplier : settings.multiplier

    const fees = settings.feeList
      ? [
        {
          value: 1,
          title: `${this.convertToFiat(settings.feeList[1].multipliedBy(separatedMultiplier))} ${this.props.mainCurrency} - Slow and cheap`,
        },
        {
          value: 2,
          title: `${this.convertToFiat(settings.feeList[2].multipliedBy(separatedMultiplier))} ${this.props.mainCurrency} - Normal`,
        },
        {
          value: 3.5,
          title: `${this.convertToFiat(settings.feeList[3.5].multipliedBy(separatedMultiplier))} ${this.props.mainCurrency} - Fast and expensive`,
        },
      ]
      : null

    const amount = this.getTotalRequestsAmount()
    const account = walletList.find((account) => account.id === settings.accountId)
    const trezorAccount = {
      value: ACCOUNT_TYPE_TREZOR,
      label: 'Trezor wallet',
    }
    const walletOptionsList = [
      trezorAccount,
      ...walletList.map((wallet) => {
        return {
          value: wallet.id,
          label: wallet.name,
        }
      }),
    ]

    const selectStyles = {
      control: (defaultStyles) => {
        return {
          ...defaultStyles,
          borderColor: 'transparent !important',
          boxShadow: 'none !important',
          backgroundColor: '#ffffff',
          borderRadius: '0px',
          minHeight: '50px',
        }
      },
      indicatorContainer: (defaultStyles) => {
        return {
          ...defaultStyles,
          padding: '5px',
        }
      },
      singleValue: (defaultStyles) => {
        return {
          ...defaultStyles,
          marginLeft: '-10px',
          ['.' + styles.listOption]: {
            display: 'none',
          },
          ['.' + styles.singleOption]: {
            display: 'flex',
          },
        }
      },
    }

    const symbol = getMainSymbolForBlockchain(blockchainType)
    const isTestnet = isTestnetAccount({ blockchain: blockchainType })
    return (
      <div className={styles.blockchainPartContainer + ' BlockchainPart'}>

        {this.props.type === PAYROLL_SELECTED_ITEMS_TYPE_MAIN_LIST && settings.isSeparateTransactions && (
          <div className={styles.walletLine}>
            <Checkbox
              disabled={[BLOCKCHAIN_ETHEREUM, BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY].includes(blockchainType) && settings.separateTransactionsAll > 1}
              className={styles.checkbox}
              value={!!settings.isSeparateTransactions}
              onClick={this.handleTggleSepparatedTransactions}
            />
            <div className={styles.separatedLabel + ' capture-small'}>
              <span className='gray-50'>Separate transactions</span>
              <Label label='PaymentX will pay each invoice with a separate transaction.'>
                <Icon type='info-circle' colorType='blue' />
              </Label>
            </div>
          </div>
        )}

        <div className={styles.walletLine}>
          <Icon
            type={symbol}
            colorType={isTestnet ? 'gray-30' : 'blue'} />
          <div className='capture-small'>
            <span className='gray-50'>Amount: </span>
            <span
              className='gray-70'
            >{this.getTotalRequestsAmount().toFormat(config.getConfig('bitcoinDecimalLength')) + ' ' + symbol}</span>
          </div>
        </div>

        {Object.entries(this.props.rates)
          .map(([asset, rate]) => {
            rate = new BigNumber(rate).toFormat(2, FORMAT_FIAT_NUMBERS)
            return (
              <div key={asset} className={styles.walletLine}>
                <div className={styles.exchangeIcon} />
                <div className={styles.lineText}>{`${rate} ${asset}/${symbol}`}</div>
              </div>
            )
          })}

        {fees && <div className={styles.walletLine + ' ' + styles.accounts}>
          <div className={styles.feeRelativeContainer}>
            {settings.isSeparateTransactions && settings.separateTransactionsAll > 1 ? (
              <Label
                className={styles.billIcon}
                isOpen={!this.props.isShownFeeWarning}
                withButton={!this.props.isShownFeeWarning}
                onButtonClick={this.props.hideFeeWarning}
                label='This fee amount is approximate. Real fee will be recalculated and may vary from the amount shown.' />
            ) : (<div className={styles.billIcon} />)}
            <CustomSelect
              className={styles.accountWrapper}
              isSearchable={false}
              styles={selectStyles}
              onChange={this.handleChangeFee}
              defaultValue={{
                value: settings.multiplier,
                label: fees.find(({ value }) => {
                  return value === settings.multiplier
                }).title,
              }}
              formatOptionLabel={(elem) => {
                return (
                  <div className={styles.optionWrapper}>
                    <div className={styles.singleOption}>
                      <div className={styles.fakeIcon} />
                      <div className='capture-small gray-70'>
                        <div>{elem.label}</div>
                      </div>
                    </div>
                    <div className={styles.listOption} key={elem.value}>{elem.label}</div>
                  </div>
                )
              }}
              name='itemsType'
              options={fees.map(({ value, title }) => {
                return {
                  value,
                  label: title,
                }
              })}
            />
          </div>
        </div>}

        <div className={styles.walletLine + ' ' + styles.accounts}>
          <div className={styles.walletRelativeContainer}>
            <CustomSelect
              className={styles.accountWrapper}
              isSearchable={false}
              styles={selectStyles}
              onChange={this.handleChangeWallet}
              defaultValue={account ? {
                value: account.id,
                label: account.name,
              }
                : settings.paymentType === ACCOUNT_TYPE_TREZOR
                  ? trezorAccount
                  : null}
              formatOptionLabel={(elem) => {
                return (
                  <div className={styles.optionWrapper}>
                    <div className={styles.singleOption}>
                      {elem.value === ACCOUNT_TYPE_TREZOR
                        ? <Icon type='trezor' colorType='gray-70' />
                        : <Icon type='wallet' colorType='gray-70' />}
                      <div className={styles.ellipsis + ' capture-small gray-70'}>
                        <div>{elem.label}</div>
                        <div>
                          {account && `(${account.formattedBalance.toFormat(config.getConfig('bitcoinDecimalLength'))} ${account.symbol})`}
                        </div>
                      </div>
                    </div>
                    <div className={cn(styles.listOption, styles.walletsListOption)} key={elem.value}>
                      {elem.value === ACCOUNT_TYPE_TREZOR
                        ? <Icon type='trezor' colorType='blue' />
                        : <Icon type={account.symbol} colorType={account.meta.isTestnet ? 'gray-30' : 'blue'} />}
                      <div className={elem.value === ACCOUNT_TYPE_TREZOR ? 'blue-bk' : 'gray-70'}>{elem.label}</div>
                    </div>
                  </div>
                )
              }}
              name='itemsType'
              options={walletOptionsList}
            />
            {account && [ACCOUNT_TYPE_TREZOR].includes(account.type) && account.loadingState !== LOADING_STATE_LOADED && (
              <div className={styles.connectWrapper}>
                <TrezorConnectButton
                  accountId={account.id}
                  callback={() => this.handleChangeWallet({ value: account.id })} />
              </div>
            )}
          </div>
        </div>

        {account && (
          <div className={styles.walletLine + ' ' + styles.totalDebited}>
            <Icon type='sum' colorType='gray-70' />
            <div>
              <label className='capture-small gray-50'>Total to debit</label>
              <div
                className='h5 gray-70'>{amount.plus(this.props.settings.fee || 0).toFormat(config.getConfig('bitcoinDecimalLength')).toString() + ' ' + getMainSymbolForBlockchain(settings.blockchain)}</div>
            </div>
          </div>
        )}

        {!settings.enoughMoney && fees && (
          <div className={styles.walletLine}>
            <Icon type='attention' colorType='red' />
            <div className={styles.walletNotEnoughMoney}>
              Insufficient balance. Please top up this wallet or select another one
            </div>
          </div>
        )}
      </div>)
  }
}

const mapStateToProps = (state, ownProps) => {
  const selectedRequestSet = selectedRequestListByTypeSelector(ownProps.type)(state)
  const requestList = requestsByIdsSelector([...selectedRequestSet])(state)
  const payrollSettings = payrollSettingsSelector(state)
  const allRates = fixedRatesSelector(state)
  const hotWallets = selectTypeAccountsSelector(ownProps.blockchainType)(state)
  const { isShownFeeWarning } = confirmPasswordCardParamsSelector(state)
  const profile = profileInfoSelector(state)
  const mainCurrency = currentCurrencySelector(state)
  const rates = requestList.reduce((a, r) => {
    if (!a[r.settlementAsset] && ownProps.blockchainType === r.paymentBlockchain) {
      a[r.settlementAsset] = get(allRates, `${r.paymentAsset}.${r.settlementAsset}`)
    }
    if (!a[mainCurrency] && ownProps.blockchainType === r.paymentBlockchain) {
      a[mainCurrency] = get(allRates, `${r.paymentAsset}.${mainCurrency}`)
    }
    return a
  }, {})

  return {
    isShownFeeWarning,
    allRates,
    mainCurrency,
    cryptoPaymentSelector: cryptoPaymentSelector(ownProps.type)(state),
    rateMain: get(rates, mainCurrency),
    rates: rates,
    requestList,
    walletList: hotWallets,
    settings: payrollSettings[ownProps.blockchainType],
    canPaySeparateTransactions: profile.canPaySeparateTransactions,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    hideFeeWarning: () => dispatch(hideFeeWarning()),
    updatePayrollSettingsBlockchain: (params) => {
      const { accountId, isSeparateTransactions, paymentType, multiplier } = params
      return dispatch(updatePayrollSettingsParams({
        accountId,
        isSeparateTransactions: !!isSeparateTransactions,
        multiplier: multiplier || 1,
        blockchain: ownProps.blockchainType,
        paymentType,
      }))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockchainPart)
