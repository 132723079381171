//@flow
import cn from 'classnames'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import BlockchainPart from './BlockchainPart'
import Button from '../../components/Button/Button'
import { Scrollbars } from 'react-custom-scrollbars'
import {
  blockchainsSettingsLoadingStateSelector, fixedRatesSelector,
  payrollCurrentPanelStepSelector,
  payrollSettingsSelector,
  selectedRequestListByTypeSelector,
} from '../../ducks/payroll/selectors'
import { requestsByIdsSelector } from '../../ducks/paymentRequests/selectors'
import { setSidePanel } from '../../ducks/ui/actions'
import { clearRequestItemSet, updateStep } from '../../ducks/payroll/actions'
import {
  PAYROLL_SELECTED_ITEMS_TYPE_MAIN_LIST,
  PAYROLL_SELECTED_ITEMS_TYPE_PREVIEW_INVOICE,
  PAYROLL_STEP_REQUEST_SETTINGS,
  PAYROLL_STEP_SEND,
} from '../../ducks/payroll/constants'
import { MobilePanelWatcher } from '../../components/PaymentsTable/mobilePanelWatcher'
import ConfirmPasswordCard from './ConfirmPasswordCard'
import { LOADING_STATE_LOADED, LOADING_STATE_LOADING } from '../../constants'
import { currentCurrencySelector } from '../../ducks/profile/selectors'
import BigNumber from 'bignumber.js'
import get from 'lodash.get'
import { FORMAT_FIAT_NUMBERS, ACCOUNT_TYPE_TREZOR } from '../../ducks/accounts/constants'
import styles from './RequestPayCard.module.scss'
import { PaymentRequestModel } from '../../models/PaymentRequests/PaymentRequestModel'
import LoadingState from '../../components/LoadingState'
import Icon from '../../components/Icon/Icon'
import { updatePayrollSettings } from '../../ducks/payroll/thunks'
import { amplitudeSendEvent } from "../../utils/analytics"

type Props = {
  requestList: Set<string>,
  customSelectedList: Set<string>,
  currentStep: string,
  selectedType: string,
  rate: string,
  type: string,
  walletError: boolean,
  notEnoughMoney: boolean,
  openNewAccountSidePanel: () => void,
  closePreviewInvoicePanel: () => void,
  clearRequestSet: () => void,
  handlePayByTrezor: () => void,
  updateStep: (step: string) => void,
  payrollSettings: any,
  settingsLoadingState: string,
  requestListFull: Array<string>,
  mainCurrency: string,
  rates: any,
  payrollSettingsBlockchains: Array<string>,
}

class RequestPayCard extends React.PureComponent<Props> {
  static propTypes = {
    handlePayByTrezor: PropTypes.func,
    requestList: PropTypes.instanceOf(Set),
    customSelectedList: PropTypes.instanceOf(Set),
    walletError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    notEnoughMoney: PropTypes.bool,
    payrollSettings: PropTypes.object,
    clearRequestSet: PropTypes.func,
    closePreviewInvoicePanel: PropTypes.func,
    openNewAccountSidePanel: PropTypes.func,
    updateStep: PropTypes.func,
    currentStep: PropTypes.string,
    type: PropTypes.string,
    settingsLoadingState: PropTypes.string,
    requestListFull: PropTypes.arrayOf(PropTypes.instanceOf(PaymentRequestModel)),
    mainCurrency: PropTypes.string,
    rates: PropTypes.objectOf(PropTypes.object),
    payrollSettingsBlockchains: PropTypes.arrayOf(PropTypes.string),
  }

  static defaultProps = {
    type: PAYROLL_SELECTED_ITEMS_TYPE_MAIN_LIST,
    closePreviewInvoicePanel: () => {
    },
  }

  componentDidMount () {
    MobilePanelWatcher.updateState()
  }

  addNewWallet = () => {
    switch (this.props.type) {
      case PAYROLL_SELECTED_ITEMS_TYPE_MAIN_LIST:
        this.props.clearRequestSet()
        this.props.openNewAccountSidePanel()
        break
      case PAYROLL_SELECTED_ITEMS_TYPE_PREVIEW_INVOICE:
        this.props.closePreviewInvoicePanel()
        this.props.openNewAccountSidePanel()
        break
      default:
        break
    }
  }

  handlePayByTrezor = () => {
    this.props.handlePayByTrezor()
  }

  handlePayClick = () => {
    if (!this.props.walletError) {
      this.props.updateStep(PAYROLL_STEP_SEND)
      amplitudeSendEvent('Payment initiated', {})
    }
  }

  renderNoWalletError = () => {
    return (
      <div className={cn(styles.noWalletsContainer, 'nowallets-container')}>
        <div className={styles.noWalletsInnerContainer}>
          <div className={styles.noWalletsIcon}><Icon type='wallet' colorType='gray-30' /></div>
          <div className={styles.noWalletsText}>
            {this.props.type === PAYROLL_SELECTED_ITEMS_TYPE_MAIN_LIST
              ?
              <div className={styles.noWalletsTextList}>You need to unlock <br /> or add a wallet for <br /> paying for
                some of the<br /> selected money <br />
                requests.</div>
              :
              <div className={styles.noWalletsTextSingle}>You need to have an <br /> unlocked wallet to pay <br /> this
                invoice.</div>
            }
          </div>
          <div className={styles.addNewWalletWrapper}>
            {this.props.type === PAYROLL_SELECTED_ITEMS_TYPE_MAIN_LIST && (
              <button className={styles.addNewWallet} onClick={this.addNewWallet}>
                <Icon type='plus-circle' colorType='blue' />
                <span>Add wallet</span>
              </button>
            )}
            <button className={styles.addNewWallet} onClick={this.handlePayByTrezor}>
              <Icon type='trezor' colorType='blue' />
              <span>Pay by Trezor</span>
            </button>
          </div>
        </div>
      </div>
    )
  }

  renderRequestPanel = () => {
    const { requestList, walletError, notEnoughMoney, settingsLoadingState, type, payrollSettingsBlockchains } = this.props

    const totalAmount: BigNumber = this.props.requestListFull.reduce((total: BigNumber, r: PaymentRequestModel) => {
      if (this.props.mainCurrency !== r.settlementAsset) {
        const rate = get(this.props.rates, this.props.mainCurrency + '.' + r.settlementAsset, 0)
        return total.plus(new BigNumber(r.settlementAmount).dividedBy(rate))
      } else {
        return total.plus(r.settlementAmount)
      }
    }, new BigNumber(0))
    return (
      <div className={cn(styles.container, 'request-pay-container')}>

        {type === PAYROLL_SELECTED_ITEMS_TYPE_MAIN_LIST &&
        <>
          <div className={styles.header}>
            <div className={styles.mainTotal}>
              <div className='h4 gray-70'>{totalAmount.toFormat(2, FORMAT_FIAT_NUMBERS)}</div>
              <div className='h4 gray-50'>{this.props.mainCurrency}</div>
            </div>
            <div className={styles.payButton}>
              <Button
                handleClick={this.handlePayClick}
                className='add-btn'
                disabled={!!walletError || !!notEnoughMoney || settingsLoadingState === LOADING_STATE_LOADING}
                colorType='new-filled'>Pay</Button>
            </div>

          </div>
          <div className={styles.requestAmount}>
            {`${requestList.size} request${requestList.size === 1 ? '' : 's'} selected`}
          </div>
        </>}
        {walletError !== false && this.renderNoWalletError()}
        {walletError === false && settingsLoadingState === LOADING_STATE_LOADED
          ? (<Scrollbars className={styles.blochainPartWrapper + ' Swipe_scrollContainer'}>
            {payrollSettingsBlockchains.map((blockchain, key) => (
              <BlockchainPart key={key} blockchainType={blockchain} type={type} />
            ))}
          </Scrollbars>)
          : null}
        {settingsLoadingState === LOADING_STATE_LOADING && (
          <div className={styles.blockchainPartLoadingContainer}>
            <LoadingState loadingState={settingsLoadingState} loaderClassName={styles.loader} />
          </div>
        )}
      </div>
    )
  }

  renderSteps = () => {
    switch (this.props.currentStep) {
      case PAYROLL_STEP_REQUEST_SETTINGS:
        return this.renderRequestPanel()

      case PAYROLL_STEP_SEND:
        return <ConfirmPasswordCard
          handleGoBack={this.props.updateStep}
          type={this.props.type}
        />

      default:
        throw new Error('Unknown step')
    }
  }

  render () {
    return this.renderSteps()
  }
}

const mapStateToProps = (state, ownProps) => {
  const settingsLoadingState = blockchainsSettingsLoadingStateSelector(state)
  const currentStep = payrollCurrentPanelStepSelector(state)
  const selectedRequestSet = selectedRequestListByTypeSelector(ownProps.type)(state)
  const payrollSettings = payrollSettingsSelector(state)
  const requestList = requestsByIdsSelector([...selectedRequestSet])(state)
  let notHotWallet = false
  let notEnoughMoney = false
  requestList.forEach((r) => {
    const currentSettings = payrollSettings[r.paymentBlockchain]
    if (currentSettings && !get(currentSettings, 'paymentType', false)) {
      if (!get(currentSettings, 'accountId', false)) {
        notHotWallet = !!r.paymentBlockchain
      }
      if (!currentSettings.enoughMoney) {
        notEnoughMoney = true
      }
    }
  })

  return {
    payrollSettingsBlockchains: Object.keys(payrollSettings),
    rates: fixedRatesSelector(state),
    mainCurrency: currentCurrencySelector(state),
    settingsLoadingState,
    currentStep,
    requestListFull: requestList,
    requestList: selectedRequestSet,
    walletError: notHotWallet,
    notEnoughMoney,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    openNewAccountSidePanel: () => dispatch(setSidePanel('addAccountSidePanel', { isOpen: true })),
    clearRequestSet: () => dispatch(clearRequestItemSet()),
    updateStep: (step) => dispatch(updateStep(step)),
    handlePayByTrezor: () => dispatch(updatePayrollSettings(null, ownProps.type, ACCOUNT_TYPE_TREZOR)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestPayCard)
