import AbstractRESTService from './AbstractRESTService'

const URL_CREATE_NEW_PAYMENT_REQUEST = '/requests'
const URL_PAYMENT_REQUESTS_FROM_ME = '/requests/my'
const URL_ATTACHMENTS = '/attachments'
const URL_PDF = '/pdf'
const URL_PDF_SEND_TO_EMAIL = '/pdf/send'
const URL_REQUESTS = '/requests'
const URL_REQUESTS_PAY = '/requests/pay'
const URL_REQUESTS_CANCEL = '/requests/cancel'
const URL_TRANSACTIONS = '/transactions'
const URL_GET_HINTS = '/requests/hint'
const URL_SEND_QR_TO_EMAIL = '/requests/qr'

export default class PaymentRequestsService extends AbstractRESTService {
  /**
   * Create a new payment request
   * @param {PaymentRequestModel} paymentRequest
   */
  createPaymentRequest (paymentRequest) {
    return this.restService.request({
      method: 'POST',
      url: URL_CREATE_NEW_PAYMENT_REQUEST,
      headers: this.getBearerHeaders(),
      data: {
        payload: paymentRequest,
      },
    })
  }

  /**
   * Update existing a new payment request
   * @param {PaymentRequestModel} paymentRequest
   * @param {string} requestId - payment request ID
   * @param {boolean} isDraft - is request is draft
   */
  updatePaymentRequest (paymentRequest, requestId, isDraft) {
    return this.restService.request({
      method: 'PUT',
      url: `${URL_CREATE_NEW_PAYMENT_REQUEST}/${requestId}${isDraft ? '/draft' : ''}`,
      headers: this.getBearerHeaders(),
      data: {
        requestId,
        payload: paymentRequest,
      },
    })
  }

  /**
   * delete existing PaymentRequest
   * @param {string} requestId - payment request ID
   */
  deletePaymentRequest (requestId) {
    return this.restService.request({
      method: 'DELETE',
      url: URL_CREATE_NEW_PAYMENT_REQUEST + '/' + requestId,
      headers: this.getBearerHeaders(),
    })
  }

  /**
   * Get PaymentRequests witch created from me
   * @groupBy {string} - params object
   */
  getPaymentRequests () {
    return this.restService.request({
      method: 'GET',
      url: URL_REQUESTS + `?limit=2500`,
      headers: this.getBearerHeaders(),
    })
  }

  /**
   * Pay requests. New way.
   * @payload {array} - payload params
   */
  payRequest (payload) {
    return this.restService.request({
      method: 'POST',
      url: URL_REQUESTS_PAY,
      headers: this.getBearerHeaders(),
      data: {
        payload,
      },
    })
  }

  /**
   * Get specific request by id
   * @requestId {string} - request id
   */
  getRequestById (requestId: string) {
    return this.restService.request({
      method: 'GET',
      url: URL_REQUESTS + '/' + requestId,
      headers: this.getBearerHeaders(),
    })
  }

  /**
   * Get attachment data
   */
  getRequestAttachment (attachmentId, requestId) {
    return this.restService.request({
      method: 'GET',
      url: URL_ATTACHMENTS + `?attachment-id=${attachmentId}&request-id=${requestId}`,
      headers: this.getBearerHeaders(),
    })
  }

  /**
   * Get PaymentRequests witch created from me
   * @param {string|number} limit - limit of list
   * @param {string|number} offset - offset from first item
   */
  getPaymentRequestsFromMe (limit, offset) {
    return this.restService.request({
      method: 'GET',
      url: URL_PAYMENT_REQUESTS_FROM_ME + `?limit=${limit}&offset=${offset}`,
      headers: this.getBearerHeaders(),
    })
  }

  /**
   * Add attachment to existing payment request
   * @param {string} requestId - payment request ID
   * @param {object} payload - { 'name': 'Attachment Name', 'extension': 'pdf|jpg|jpeg|png|docx|xlsx', 'data': 'base64 encoded file data', }
   */
  addAttachment (requestId, payload) {
    return this.restService.request({
      method: 'POST',
      url: URL_ATTACHMENTS,
      headers: this.getBearerHeaders(),
      data: {
        requestId,
        payload,
      },
    })
  }

  /**
   * Delete attachment from existing payment request
   * @param {string} requestId - payment request ID
   * @param {object} attachmentId - attachment ID
   */
  deleteAttachment (requestId, attachmentId) {
    return this.restService.request({
      method: 'DELETE',
      url: URL_REQUESTS,
      headers: this.getBearerHeaders(),
      data: {
        payload: {
          requestId,
          attachmentId,
        },
      },
    })
  }

  /**
   * get attachment from existing payment request
   * @param {string} requestId - payment request ID
   * @param {object} attachmentId - attachment ID
   */
  getAttachment (requestId, attachmentId) {
    return this.restService.request({
      method: 'GET',
      url: URL_ATTACHMENTS + `?request-id=${requestId}&attachment-id=${attachmentId}`,
      headers: this.getBearerHeaders(),
    })
  }

  /**
   * Add transaction to track
   * @param {string} requestId - payment request ID
   * @param {Array} payload - transactions list [{'hash', 'description',}]
   */
  tieTransactions (requestId, payload) {
    return this.restService.request({
      method: 'POST',
      url: URL_TRANSACTIONS,
      headers: this.getBearerHeaders(),
      data: {
        requestId,
        payload,
      },
    })
  }

  /**
   * Add transaction to track
   * @param {Array} payload - list of requests and transactions
   * {
   *   "payload" : [
   *     {
   *         "requestId" : "507f191e810c19729de860ea",
   *         "hash" : "0xd169b932f36f3da42082ac722ee6c96e1044bbdcaa3538e5ef88f46d90259a",
   *         "description" : "Invoice #0001 ETH additional"
   *     },
   *     {
   *         "requestId" : "507f191e810c19729de860eb",
   *         "hash" : "0xd169b932f36f3da42082ac722ee6c96e1044bbdcaa3538e5ef88f46d90259b",
   *         "description" : "Invoice #0002 ETH additional"
   *     }
   *   ]
   * }
   *
   */
  tieMultipleTransactions (payload) {
    return this.restService.request({
      method: 'POST',
      url: URL_TRANSACTIONS,
      headers: this.getBearerHeaders(),
      data: {
        payload,
      },
    })
  }

  /**
   * Update transaction to track
   * @param {string} requestId - payment request ID
   * @param {Array} payload - transactions list [{'hash', 'description',}]
   */
  updateTransactions (requestId, payload) {
    return this.restService.request({
      method: 'PUT',
      url: URL_TRANSACTIONS,
      headers: this.getBearerHeaders(),
      data: {
        requestId,
        payload,
      },
    })
  }

  /**
   * Remove transaction from track
   * @param {string} requestId - payment request ID
   * @param {Array} payload - transactions list [{'hash', 'description',}]
   */
  unTieTransactions (requestId, payload) {
    return this.restService.request({
      method: 'DELETE',
      url: URL_TRANSACTIONS,
      headers: this.getBearerHeaders(),
      data: {
        requestId,
        payload,
      },
    })
  }

  /**
   * Cancel requests
   * @param {string} requestId - array of request ids
   */
  cancelRequests (requestId) {
    return this.restService.request({
      method: 'POST',
      url: URL_REQUESTS_CANCEL,
      headers: this.getBearerHeaders(),
      data: {
        payload: {
          requestId,
        },
      },
    })
  }

  getHints () {
    return this.restService.request({
      method: 'GET',
      url: URL_GET_HINTS,
      headers: this.getBearerHeaders(),
    })
  }

  /**
   * Create a new payment request
   * @param {PaymentRequestModel} payload
   */
  sendReceiveMessageToEmail (payload) {
    return this.restService.request({
      method: 'POST',
      url: URL_SEND_QR_TO_EMAIL,
      headers: this.getBearerHeaders(),
      data: {
        payload,
      },
    })
  }

  /**
   * get payment request as pdf
   * @param {string} requestId - payment request ID
   */
  getPaymentRequestPDF (requestId) {
    return this.restService.request({
      method: 'GET',
      url: URL_PDF + `/${requestId}`,
      headers: this.getBearerHeaders(),
    })
  }

  /**
   * send payment request to email as pdf file
   * @param {string} requestId - payment request ID
   * @param {string} email - email of receiver
   */
  sendPaymentRequestToEmailAsPDF (requestId, email) {
    return this.restService.request({
      method: 'POST',
      url: URL_PDF_SEND_TO_EMAIL,
      headers: this.getBearerHeaders(),
      data: {
        payload: {
          requestId,
          email,
        },
      },
    })
  }
}
