//@flow
import AbstractRESTService from './AbstractRESTService'
import { BLOCKCHAIN_BITCOIN, BLOCKCHAIN_BITCOIN_TESTNET } from '../ducks/bitcoin/constants'
import type { BlockchainType, PrefixType } from '../ducks/accounts/models'
import get from 'lodash.get'
import Logger from './Logger'

export default class InsightService extends AbstractRESTService {
  getURLPrefixByBlockchain = (blockchain: BlockchainType): PrefixType => {
    switch (blockchain) {
      case BLOCKCHAIN_BITCOIN:
        return 'bitcoin_livenet'
      case BLOCKCHAIN_BITCOIN_TESTNET:
        return 'bitcoin_testnet'
      default:
        throw new Error('Unknown blockchain value: ' + blockchain)
    }
  }

  getUtxo (blockchain: BlockchainType, address: string) {
    const URLPrefix = this.getURLPrefixByBlockchain(blockchain)
    return this.restService.request({
      method: 'GET',
      url: `/${URLPrefix}/address/${address}/utxo`,
      headers: this.getBearerHeaders(),
    })
  }

  getBalance (blockchain: BlockchainType, address: string) {
    const URLPrefix = this.getURLPrefixByBlockchain(blockchain)
    return this.restService.request({
      method: 'GET',
      url: `/${URLPrefix}/address/${address}/balance`,
      headers: this.getBearerHeaders(),
    })
  }

  sendTx (blockchain: BlockchainType, rawtx: any) {
    const prefix = this.getURLPrefixByBlockchain(blockchain)
    return this.restService.request({
      method: 'POST',
      url: `/${prefix}/tx/send`,
      headers: this.getBearerHeaders(),
      data: { rawtx },
    })
  }

  getTx (blockchain: BlockchainType, txId: string) {
    const prefix = this.getURLPrefixByBlockchain(blockchain)
    return this.restService.request({
      method: 'GET',
      url: `/${prefix}/tx/${txId}`,
      headers: this.getBearerHeaders(),
    })
  }

  async getFeeRate (blockchain: BlockchainType) {
    const URLPrefix = this.getURLPrefixByBlockchain(blockchain)
    try {
      const res = await this.restService.request({
        method: 'GET',
        url: `${URLPrefix}/fee`,
        headers: this.getBearerHeaders(),
      })
      const rate = get(res, 'data.medium', null)
      return rate || 100 // default satoshis per byte for testnets
    } catch (e) {
      Logger.error(e, { blockchain })
      return null
    }
  }

  getTransactionsForMultipleAddresses (blockchain: BlockchainType, addresses: Array<string>, lastBlock: number, limit: number) {
    const prefix = this.getURLPrefixByBlockchain(blockchain)
    return this.restService.request({
      method: 'GET',
      url: `/${prefix}/address/${addresses.join(',')}/txs?before=${lastBlock}&limit=${limit}`,
      headers: this.getBearerHeaders(),
    })
  }
}
