import * as c from './constants'
import { LOADING_STATE_LOADED } from '../../constants'
import { ProfileModel } from '../../models/Contacts/ProfileModel'

const getInitialState = () => ({
  loadingState: LOADING_STATE_LOADED,
  passwordLoadingState: LOADING_STATE_LOADED,
  error: null,
  result: null,
  lastActivityPopupActive: false,
  lastActivity: new Date(),
  lastActivityTimerId: null,
  info: new ProfileModel(),
})

const mutations = {
  [c.PROFILE_UPDATE_PROFILE_STATUS]: (state, { loadingState, error, result }) => {
    return {
      ...state,
      loadingState,
      error,
      result,
    }
  },
  [c.PROFILE_UPDATE_PASSWORD_STATUS]: (state, { loadingState, error, result }) => {
    return {
      ...state,
      passwordLoadingState: loadingState,
      error,
      result,
    }
  },
  [c.PROFILE_UPDATE_PROFILE]: (state, { profile }) => {
    return {
      ...state,
      info: profile,
    }
  },
  [c.PROFILE_UPDATE_LAST_ACTIVITY]: (state) => {
    return {
      ...state,
      lastActivity: new Date(),
    }
  },
  [c.PROFILE_UPDATE_LAST_ACTIVITY_LOGOUT_TIMER_IDS]: (state, { lastActivityTimerId }) => {
    return {
      ...state,
      lastActivityTimerId,
    }
  },
  [c.PROFILE_SHOW_LAST_ACTIVITY_POPUP]: (state) => {
    return {
      ...state,
      lastActivityPopupActive: true,
    }
  },
  [c.PROFILE_HIDE_LAST_ACTIVITY_POPUP]: (state) => {
    return {
      ...state,
      lastActivityPopupActive: false,
      lastActivity: new Date(),
    }
  },
}

export default (state = getInitialState(), { type, ...payload }) => {
  return (type in mutations)
    ? mutations[type](state, payload)
    : state
}
