import bip39 from 'bip39'
import React from 'react'
import PropTypes from 'prop-types'
import styles from './CreateWallet.module.scss'
import CopyButton from '../../../components/Button/CopyButton'
import Icon from '../../../components/Icon/Icon'
import Button from '../../../components/Button/Button'

const CreateMnemonic = ({ mnemonic: mnemonicProps, handleSubmit, onBack }) => {
  const mnemonic = mnemonicProps || bip39.generateMnemonic()
  if (!mnemonic) {
    return null
  }

  return (
    <div className={styles.formWrapperMnemonic}>

      <div className={styles.formTitle + ' h5 gray-70'}>Backup wallet</div>
      <div className='capture gray-70'>Please carefully write down these 12 words. You need to copy and<br />
        save them to access this wallet in the future. <span className='red'>Don’t lose them!</span> They<br />
        cannot be recovered.
      </div>

      <div className={styles.wordsGrid}>
        {mnemonic.split(' ').map((word, key) => {
          return <div className={styles.word} key={key + word}><span>{key + 1}</span>{word}</div>
        })}
      </div>
      <div className={styles.copyButtonWrapper}>
        <CopyButton copyString={mnemonic ? mnemonic : ''}>
          <>
            <Icon type='copy-2' colorType='blue' />
            <span className='capture blue-bk'>Copy to clipboard</span>
          </>
        </CopyButton>
      </div>
      <div className={styles.actionsMnemonic}>
        <Button
          type='button'
          onClick={onBack}
          colorType='new-border'
        >back</Button>
        <Button
          onClick={handleSubmit(mnemonic)}
          type='submit'
          colorType='new-filled'>NEXT</Button>
      </div>
    </div>
  )
}
CreateMnemonic.propTypes = {
  handleSubmit: PropTypes.func,
  onBack: PropTypes.func,
  mnemonic: PropTypes.string,
}

export default CreateMnemonic
