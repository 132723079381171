import AbstractRESTService from './AbstractRESTService'

/**
 * @deprecated
 */
export default class BitcoinService extends AbstractRESTService {
  getUtxo (blockchain, address) {
    return this.restService.get(`/account/${address}/utxo`)
  }

  getBalance (address) {
    return this.restService.get(`/account/${address}/balance`)
  }

  getBlocksInfo () {
    return this.restService.get(`/blocks/info`)
  }

  getBlockInfo (blockIndex) {
    return this.restService.get(`/blocks/info/${blockIndex}`)
  }

  getTx (hash) {
    return this.restService.get(`/tx/info/${hash}`)
  }

  sendTx (blockchain, hex) {
    return this.restService.post('/tx/send', {
      hex,
    })
  }

  getFeeRate () {
    return this.restService.get(`/tx/estimate/feerate`)
  }
}
