import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styles from './PaymentDetailsBlock.module.scss'
import { PaymentRequestModel } from '../../models/PaymentRequests/PaymentRequestModel'
import { PAYMENT_REQUEST_STATUS_PAID } from '../../ducks/paymentRequests/constants'
import Icon from '../../components/Icon/Icon'
import { BITCOIN_SYMBOL } from '../../ducks/bitcoin/constants'
import cn from 'classnames'
import CopyButton from '../../components/Button/CopyButton'
import { getExplorerLink, isTestnetAccount, convertToBigNumber } from '../../ducks/accounts/utils'
import { setSidePanel } from '../../ducks/ui/actions'
import { getMainSymbolForBlockchain } from "../../utils/general"

const PaymentDetailsBlock = ({ isItMyRequest, request, requestWallet, invoiceTransaction, accounts, handleSetSidePanel }) => {
  const handleViewOnBlockExplorer = (invoiceTransaction) => () => {
    window.open(
      getExplorerLink(invoiceTransaction.hash, { blockchain: request.paymentBlockchain }),
      '_blank',
    )
  }
  const symbol = getMainSymbolForBlockchain(request.paymentBlockchain)
  const isTestnet = isTestnetAccount({ blockchain: request.paymentBlockchain })

  const renderWalletName = (blockchain, address, name, isCopyAction) => {
    return (
      <div className={styles.walletLine}>
        <Icon
          type={symbol}
          colorType={isTestnet ? 'gray-30' : 'blue'}
        />
        <div className={styles.walletNameWrapper}>
          {name && <div className={cn(styles.walletName, 'capture', 'gray-70')}>
            <div className='capture-small gray-50'>{address}</div>
            <div>{name}</div>
          </div>}
          <div className={cn(
            styles.walletAddress, {
              'capture-small gray-50': name,
              'capture gray-70': !name,
            },
          )}>
            <div>{address}</div>
          </div>
        </div>
        {isCopyAction
          ? <CopyButton className={styles.actionButton} copyString={address} />
          : (
            <button
              className={styles.actionButton}
              onClick={handleSetSidePanel({ address, name })}
            ><Icon type='qrcode' colorType='blue' />
            </button>
          )}
      </div>
    )
  }

  const renderTransactionFrom = () => {
    if (!invoiceTransaction || !invoiceTransaction.inputs || !invoiceTransaction.inputs.length) {
      return null
    }

    let lineCounter = 0

    const inputs = invoiceTransaction.inputs.reduce((acc, input) => {
      const key = `${request.paymentBlockchain}-${input.address}`
      const existedAccount = accounts[key]
      acc[key] = existedAccount || input
      return acc
    }, {})

    return (
      <>
        {Object.values(inputs).map((input) => {
          lineCounter++
          return (
            <div key={'address_' + input.address} className='capture gray-70'>
              {lineCounter === 1 ? <label className='capture gray-50'>From</label> : <label className='capture' />}
              {renderWalletName(request.paymentBlockchain, input.address, input.name, true)}
            </div>
          )
        })}
      </>
    )
  }


  return (
    <div className={styles.paymentDetailsBlockWrapper}>
      <div className={styles.title}>
        <label className='capture gray-50'>{isItMyRequest
          ? request.status === PAYMENT_REQUEST_STATUS_PAID ? 'Payment received to' : 'Receive payment to'
          : request.status === PAYMENT_REQUEST_STATUS_PAID ? 'Payment sent to' : 'Send payment to'
        }</label>
      </div>
      <div className={styles.accountName}>
        {renderWalletName(request.paymentBlockchain, request.paymentWallet, requestWallet)}
      </div>
      {invoiceTransaction && (
        <div className={styles.transactionTable}>
          {renderTransactionFrom()}
          {invoiceTransaction.fee && (
            <div className='capture gray-70'>
              <label className='capture gray-50'>Miner fee</label>
              <div>
                {convertToBigNumber({ blockchain: request.paymentBlockchain }, invoiceTransaction.fee).toString() + ' ' + BITCOIN_SYMBOL}
              </div>
            </div>
          )}
          {invoiceTransaction.confirmations > 0 && (
            <div className='capture gray-70'>
              <label className='capture gray-50'>Confirmations</label>
              <div>
                {invoiceTransaction.confirmations > 6 ? '6+' : parseInt(invoiceTransaction.confirmations)}
              </div>
            </div>
          )}
          <div className='capture gray-70'>
            <label className='capture gray-50'>Transaction hash</label>
            <div className={styles.transactionHashWrapper}>
              <div className={styles.transactionHash}>{invoiceTransaction.hash}</div>
              <CopyButton className={styles.actionButton} copyString={invoiceTransaction.hash} />
            </div>
          </div>
          <div>
            <label className='capture' />
            <button className={styles.viewOnBlockchainButton} onClick={handleViewOnBlockExplorer(invoiceTransaction)}>
              <Icon type='lock' colorType='blue' />
              <span className='capture blue-bk'>View on Blockchain</span>
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

PaymentDetailsBlock.propTypes = {
  isItMyRequest: PropTypes.bool,
  request: PropTypes.instanceOf(PaymentRequestModel),
  requestWallet: PropTypes.string,
  invoiceTransaction: PropTypes.object,
  accounts: PropTypes.object,
  handleSetSidePanel: PropTypes.func,
}

function mapStateToProps () {
  return {}
}

function mapDispatchToProps (dispatch) {
  return {
    handleSetSidePanel: (account) => () => dispatch(setSidePanel('qrAddressPopup', { isOpen: true, account })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetailsBlock)
