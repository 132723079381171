import React from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import InputText from '../../../components/InputText'
import { Formik } from 'formik'
import { Button } from '../../../components/Button'
import styles from './CreateWallet.module.scss'

const CreatePassword = ({ handleSubmit, onBack, initialValues }) => {


  const validation = (values) => {
    const errors = {}

    try {
      Yup.mixed()
        .test('Password is required', (value) => {
          return !!value
        })
        .test(
          'Minimum 8 symbols',
          (value) => {
            return value.length >= 8
          },
        )
        .validateSync(values.password)
    } catch (error) {
      errors.password = error.type
    }

    try {
      Yup.mixed()
        .test('Please repeat the password', (value) => {
          return !!value
        })
        .test(
          'Password phrases are not identical',
          (value) => {
            return values.password === value
          },
        )
        .validateSync(values.confirmPassword)
    } catch (error) {
      errors.confirmPassword = error.type
    }

    return errors
  }

  return (
    <div className={styles.formWrapper}>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          password: initialValues.password,
          confirmPassword: initialValues.confirmPassword,
        }}
        validate={validation}
        onSubmit={handleSubmit}
      >
        {(formikProps) => {
          const {
            handleChange,
            submitForm,
            handleBlur,
            values,
            errors,
          } = formikProps
          return (
            <form name='setPassword'>
              <div className={styles.line + ' h5 gray-70'}>Protect your wallet with the password</div>
              <div className={styles.line}>
                <InputText
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label='Create password'
                  errorText={errors['password']}
                  error={!!errors['password']}
                  name='password'
                  type='password'
                />
              </div>
              <div className={styles.line}>
                <InputText
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label='Confirm password'
                  errorText={errors['confirmPassword']}
                  error={!!errors['confirmPassword']}
                  name='confirmPassword'
                  type='password'
                />
              </div>
              <div className={styles.actions}>
                <Button
                  type='button'
                  onClick={onBack}
                  colorType='new-border'
                >back</Button>
                <Button
                  onClick={submitForm}
                  type='submit'
                  colorType='new-filled'>NEXT</Button>
              </div>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}
CreatePassword.propTypes = {
  handleSubmit: PropTypes.func,
  onBack: PropTypes.func,
  initialValues: PropTypes.shape({
    password: PropTypes.string,
    confirmPassword: PropTypes.string,
  }),
}


export default CreatePassword