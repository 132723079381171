//@flow
import { getBitcoinSigner } from '../bitcoin/selectors'
import bitcoin from 'bitcoinjs-lib'
import CryptoJS from 'crypto-js'
import get from 'lodash.get'
import BigNumber from 'bignumber.js'
import { ACCOUNT_KEY_MAINNET, ACCOUNT_TYPE_COLD } from './constants'
import { AccountModel } from '../../models/Accounts/AccountModel'
import {
  BITCOIN_ACCOUNT_KEY_MAINNET,
  BITCOIN_ACCOUNT_KEY_TESTNET,
  BLOCKCHAIN_BITCOIN_REMOTE,
  BLOCKCHAIN_BITCOIN_TESTNET_REMOTE,
  BLOCKCHAIN_BITCOIN,
  BLOCKCHAIN_BITCOIN_TESTNET,
  BITCOIN_DECIMALS,
  COIN_TYPE_BTC_MAINNET,
  COIN_TYPE_BTC_TESTNET,
} from '../bitcoin/constants'
import type { AnyWallet, BlockchainType, ColdWallet, HotWallet, Transaction, transactionObject } from './models'
import { LOADING_STATE_LOADING } from '../../constants'
import config from '../../config/getConfig'
import AddressValidation from 'wallet-address-validator'
import {
  BLOCKCHAIN_ETHEREUM_REMOTE,
  BLOCKCHAIN_ETHEREUM_TESTNET_REMOTE,
  ETHEREUM_ACCOUNT_KEY_MAINNET,
  BLOCKCHAIN_ETHEREUM,
  BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY,
  ETHEREUM_ACCOUNT_KEY_TESTNET_RINKEBY,
} from '../ethereum/constants'
import * as Eth from '../ethereum/utils'
import * as Btc from '../bitcoin/utils'
import Logger from '../../services/Logger'
import { getSigner } from "./selectors"

export const getUtils = (account: { blockchain: string }) => {
  switch (account.blockchain) {
    case BLOCKCHAIN_ETHEREUM:
    case BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY:
      return Eth
    case BLOCKCHAIN_BITCOIN:
    case BLOCKCHAIN_BITCOIN_TESTNET:
    default:
      return Btc
  }
}

export const getBlockhainFromRemoteAccount = (remoteAccount: any) => {
  let network = get(remoteAccount, 'meta.network', null)
  // in old wallets network can be 'main'
  if (network === 'main') {
    network = ACCOUNT_KEY_MAINNET
  }

  if ([BLOCKCHAIN_BITCOIN_REMOTE, BLOCKCHAIN_BITCOIN_TESTNET_REMOTE].includes(remoteAccount.blockchain)) {
    if (network === BITCOIN_ACCOUNT_KEY_MAINNET) {
      return BLOCKCHAIN_BITCOIN
    } else if (network === BITCOIN_ACCOUNT_KEY_TESTNET) {
      return BLOCKCHAIN_BITCOIN_TESTNET
    }
  }
  if ([BLOCKCHAIN_ETHEREUM_REMOTE, BLOCKCHAIN_ETHEREUM_TESTNET_REMOTE].includes(remoteAccount.blockchain)) {
    if (network === ETHEREUM_ACCOUNT_KEY_MAINNET) {
      return BLOCKCHAIN_ETHEREUM
    } else if (network === ETHEREUM_ACCOUNT_KEY_TESTNET_RINKEBY) {
      return BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY
    }
  }
}

export const transformRemoteAccountToLocal = (remoteAccount: any): ColdWallet => {
  let network = get(remoteAccount, 'meta.network', null)
  // in old wallets network can be 'main'
  if (network === 'main') {
    network = ACCOUNT_KEY_MAINNET
  }
  return new AccountModel({
    id: remoteAccount.id,
    name: remoteAccount.name,
    blockchain: getBlockhainFromRemoteAccount(remoteAccount),
    type: remoteAccount.type || ACCOUNT_TYPE_COLD,
    symbol: remoteAccount.meta.symbol,
    address: remoteAccount.address,
    defaultAccount: remoteAccount.isDefault,
    network,
    meta: {
      ...remoteAccount.meta,
      network,
      isTestnet: network !== ACCOUNT_KEY_MAINNET,
    },
    // use balanceLoadingState if you need to check if balance is loaded
    balanceLoadingState: LOADING_STATE_LOADING,
    balance: new BigNumber(0),
    formattedBalance: new BigNumber(0),
  })
}

export const formatBalanceByBlockchain = (blockchain: BlockchainType, unformattedBalance: string | number | null = null): { balance: BigNumber | null, formattedBalance: BigNumber | null } => {
  switch (blockchain) {
    case BLOCKCHAIN_BITCOIN:
    case BLOCKCHAIN_BITCOIN_TESTNET: {
      if (unformattedBalance === null || unformattedBalance === 'undefined') {
        return {
          balance: null,
          formattedBalance: null,
        }
      }
      const balance = new BigNumber(unformattedBalance)
      return {
        balance,
        formattedBalance: new BigNumber(balance.dividedBy(BITCOIN_DECIMALS).toFixed(config.getConfig('bitcoinDecimalLength'))),
      }
    }
    default:
      throw new Error('Unknown blockchain value: ' + blockchain)
  }
}

export const isTestnetAccount = (account: { blockchain: string }) => {
  const utils = getUtils(account)
  return utils.isTestnetAccount(account)
}

export const getExplorerLink = (txId: string, account: AnyWallet) => {
  const utils = getUtils(account)
  return utils.getExplorerLink(txId, account)
}

export const getMnemonicPhrase = (account: HotWallet, password: string): string | null => {
  try {
    const mnemonic = CryptoJS.AES.decrypt(account.encryptedKey, password)
    return CryptoJS.enc.Utf8.stringify(mnemonic)
  } catch (error) {
    return null
  }
}

export const getAddressFromMnemonic = (account: AnyWallet, mnemonic: string) => {
  try {
    switch (account.blockchain) {
      case BLOCKCHAIN_BITCOIN: {
        const signer = getBitcoinSigner(mnemonic, bitcoin.networks.bitcoin, COIN_TYPE_BTC_MAINNET)
        return signer.getAddress()
      }
      case BLOCKCHAIN_BITCOIN_TESTNET: {
        const signer = getBitcoinSigner(mnemonic, bitcoin.networks.testnet, COIN_TYPE_BTC_TESTNET)
        return signer.getAddress()
      }
      case BLOCKCHAIN_ETHEREUM:
      case BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY: {
        const signer = getSigner(account.blockchain, mnemonic)
        return signer.getAddress()
      }
      default:
        return null
    }
  } catch (error) {
    return null
  }
}

export const getTransactionList = (transactions: transactionObject): Array<Transaction> => {
  const transactionList = []

  if (!transactions || Object.values(transactions).length <= 0) {
    return []
  }

  Object.keys(transactions).forEach((key) => {
    transactionList.push(transactions[key])
  })

  return transactionList
}

export const validateAddress = (blockchain: string, address: string) => {
  try {
    if (!blockchain || !address) {
      return false
    }
    let blockchainFormatted = blockchain.indexOf(BLOCKCHAIN_BITCOIN) >= 0 ? BLOCKCHAIN_BITCOIN : null
    if (!blockchainFormatted) {
      blockchainFormatted = blockchain.indexOf(BLOCKCHAIN_ETHEREUM) >= 0 ? BLOCKCHAIN_ETHEREUM : null
    }
    return AddressValidation
      .validate(address.trim(), blockchainFormatted, blockchain === BLOCKCHAIN_BITCOIN ? 'prod' : 'testnet')
  } catch (e) {
    Logger.error(e, { blockchain, address })
  }
}

export const checkPassOfAccount = (account: HotWallet, password: string) => {
  const utils = getUtils(account)
  return utils.checkPassOfAccount(account, password)
}

export const getDecimals = (account: { blockchain: string }) => {
  const utils = getUtils(account)
  return utils.getDecimals()
}

export const getDecimalLength = (account: { blockchain: string }) => {
  const utils = getUtils(account)
  return utils.getDecimalLength()
}

export const convertToBigNumber = (account: { blockchain: string }, amount: string | number) => {
  const utils = getUtils(account)
  return utils.convertToBigNumber(amount)
}

export const createPaymentLink = (blockchain: string, accountAddress: string, value: string, message: string) => {
  const utils = getUtils({ blockchain })
  return utils.createPaymentLink(accountAddress, value, message)
}
