import { Scrollbars } from 'react-custom-scrollbars'
import cn from 'classnames'
import { connect } from 'react-redux'
import React from 'react'
import PropTypes from 'prop-types'
import bip39 from 'bip39'

import { addAccount } from '../../ducks/accounts/thunks'
import { accountLoadingStateSelector, accountNamesSelector } from '../../ducks/accounts/selectors'
import { ADD_PUBLIC, CREATE_NEW, RECOVER_WALLET } from './constants'
import styles from './styles.module.scss'
import Icon from '../../components/Icon/Icon'
import AddPublicAddress from './AddPublicAddress'
import CreateWallet from './CreateWallet/CreateWallet'
import RecoverWallet from './CreateWallet/RecoverWallet'
import { amplitudeSendEvent } from "../../utils/analytics"

const STEP_CREATE_WALLET_NAME = { number: 0, name: '' }
const STEP_ACCOUNT_VIEW = { number: 0, name: 'accountView' }
const STEP_SET_PASSWORD = { number: 1, name: 'setPassword' }
const STEP_CREATE_MNEMONIC = { number: 2, name: 'createMnemonic' }
const STEP_CONFIRM_MNEMONIC = { number: 3, name: 'confirmMnemonic' }
const STEP_ADD_PUBLIC_WALLET = { number: 1, name: 'addPublicWallet' }
const STEP_RECOVER_PUBLIC_WALLET = { number: 1, name: 'recoverPublicWallet' }

function getDefaultState () {
  return {
    step: STEP_CREATE_WALLET_NAME,
    createdAccountId: null,
    account: {
      name: '',
    },
    passPhrase: null,
    generatedMnemonic: bip39.generateMnemonic(),
    isOpenAddPublicAddressForm: false,
    isOpenAddTrezorWalletForm: false,
    isOpenCreateWalletForm: false,
    isOpenRecoverWalletForm: false,
  }
}

class CreateAccountCard extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    onCreateAccount: PropTypes.func,
    saveAccount: PropTypes.func,
    accountLoadingState: PropTypes.string,
    accountList: PropTypes.arrayOf(PropTypes.string),
    onOpenAccountViewPanel: PropTypes.func,
  }

  constructor (props) {
    super(props)
    this.state = {
      ...getDefaultState(),
    }
  }

  onWalletNameProceed = (values) => {
    this.setState(() => ({
      account: {
        ...this.state.account,
        name: values.walletName,
        blockchain: values.blockchain,
      },
    }))

    switch (values.action) {
      case CREATE_NEW:
        return this.setState({ step: STEP_SET_PASSWORD })
      case ADD_PUBLIC:
        return this.setState({ step: STEP_ADD_PUBLIC_WALLET })
      case RECOVER_WALLET:
        return this.setState({ step: STEP_RECOVER_PUBLIC_WALLET })
      default:
        return
    }
  }

  onSetPasswordProceed = (passPhrase) => {
    this.setState({
      step: STEP_CREATE_MNEMONIC,
      passPhrase,
    })
  }

  onCreateMnemonic = () => {
    this.setStep(STEP_CONFIRM_MNEMONIC)
  }

  setStep = (step) => {
    this.setState({ step })
  }

  onConfirmBackupStep = async () => {
    const { account, generatedMnemonic, passPhrase } = this.state

    const accountId = await this.props.saveAccount(account, generatedMnemonic, passPhrase)
    if (accountId) {
      this.openViewCard(accountId)
    }
  }

  openViewCard = (accountId) => {
    this.setState({
      step: STEP_ACCOUNT_VIEW,
      createdAccountId: accountId,
    })
  }

  handleToggleTab = (tabName, value) => (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (tabName === 'isOpenCreateWalletForm' && !this.state.isOpenCreateWalletForm) {
      amplitudeSendEvent('New wallet chosen', {})
    }
    if (tabName === 'isOpenAddPublicAddressForm' && !this.state.isOpenAddPublicAddressForm) {
      amplitudeSendEvent('Public address chosen', {})
    }
    if (tabName === 'isOpenRecoverWalletForm' && !this.state.isOpenRecoverWalletForm) {
      amplitudeSendEvent('Recovery wallet chosen', {})
    }
    this.setState({
      isOpenAddPublicAddressForm: false,
      isOpenAddTrezorWalletForm: false,
      isOpenCreateWalletForm: false,
      isOpenRecoverWalletForm: false,
      [tabName]: value,
    })
  }

  renderOption = ({ icon, title, descr, action, form, isActive }) => {
    return (
      <div className={cn(styles.option, { 'active': isActive })} onClick={action}>
        <div className={styles.optionIcon}>
          <Icon type={icon} colorType='blue' />
        </div>
        <div className={styles.optionTitle + ' h5 blue-bk'}>{title}</div>
        <div className={styles.optionDescr + ' capture gray-50'}>
          {descr}
          {form}
        </div>
      </div>
    )
  }

  render () {
    const { onClose } = this.props
    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <button className={styles.closeButton} onClick={onClose}><Icon scale='1.5' type='close'
            colorType='gray-50' /></button>
          <div className='title h3 gray-70'>Add new wallet</div>
        </div>
        <div className={styles.panel}>
          <div className={styles.sideContent} />
          <div className={styles.mainContent}>
            <Scrollbars style={{ height: 'calc(100vh - 80px)' }}>
              <div className={styles.optionsList}>

                {this.renderOption({
                  icon: 'wallet',
                  title: 'Create new wallet',
                  descr: 'Create a new wallet with PaymentX',
                  action: this.handleToggleTab('isOpenCreateWalletForm', true),
                  isActive: this.state.isOpenCreateWalletForm,
                  form: this.state.isOpenCreateWalletForm ? (
                    <CreateWallet
                      onBack={this.handleToggleTab('isOpenCreateWalletForm', false)}
                      onClose={this.props.onClose}
                      openViewCard={this.props.onOpenAccountViewPanel}
                    />
                  ) : null,
                })}

                {this.renderOption({
                  icon: 'plus-circle',
                  title: 'Add public wallet address',
                  descr: <>You can receive funds, but you will not be able to spend them<br /> until
                    you provide
                    backup phrase</>,
                  action: this.handleToggleTab('isOpenAddPublicAddressForm', true),
                  isActive: this.state.isOpenAddPublicAddressForm,
                  form: this.state.isOpenAddPublicAddressForm ? (
                    <AddPublicAddress
                      onBack={this.handleToggleTab('isOpenAddPublicAddressForm', false)}
                      onClose={this.props.onClose}
                      openViewCard={this.props.onOpenAccountViewPanel}
                    />
                  ) : null,
                })}

                {/*this.renderOption({
                  icon: 'trezor',
                  title: 'Connect Trezor',
                  descr: <>Chose the blockchain which you want to connect to<br /> your PaymentX account</>,
                  action: this.handleToggleTab('isOpenAddTrezorWalletForm', true),
                  isActive: this.state.isOpenAddTrezorWalletForm,
                  form: this.state.isOpenAddTrezorWalletForm ? (
                    <ConnectTrezorWallet
                      onBack={this.handleToggleTab('isOpenAddTrezorWalletForm', false)}
                      onClose={this.props.onClose}
                      openViewCard={this.props.onOpenAccountViewPanel}
                    />
                  ) : null,
                })*/}

                {this.renderOption({
                  icon: 'refresh-left',
                  title: 'Recover wallet from backup',
                  descr: 'For this procedure you need 12 words of recovery phrase',
                  action: this.handleToggleTab('isOpenRecoverWalletForm', true),
                  isActive: this.state.isOpenRecoverWalletForm,
                  form: this.state.isOpenRecoverWalletForm ? (
                    <RecoverWallet
                      onBack={this.handleToggleTab('isOpenRecoverWalletForm', false)}
                      onClose={this.props.onClose}
                      openViewCard={this.props.onOpenAccountViewPanel}
                    />
                  ) : null,
                })}
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
    )
  }
}

function mapDispatchToProps (dispatch) {
  return {
    saveAccount: (...props) => dispatch(addAccount(...props)),
  }
}

function mapStateToProps (state) {

  return {
    accountList: accountNamesSelector(state),
    accountLoadingState: accountLoadingStateSelector(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccountCard)
