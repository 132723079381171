//@flow
export const SORTING_DIRECTION_ASC = 'asc'
export const SORTING_DIRECTION_DESC = 'desc'

export const LOADING_STATE_LOADED = 'loaded'
export const LOADING_STATE_LOADING = 'loading'
export const LOADING_STATE_ERROR = 'error'

export const TRANSACTION_STATE_SENT = 'sent'
export const TRANSACTION_STATE_SENDING = 'sending'
export const TRANSACTION_STATE_ERROR = 'error'

export const TEAM_MEMBER_STATUS_REMOVED = 'removed'
export const TEAM_MEMBER_STATUS_ACTIVE = 'active'
export const TEAM_MEMBER_STATUS_INVITED = 'invited'

export const SESSION_DESTROY = 'session/DESTROY'

// success statuses
export const STATUS_200 = 200
export const STATUS_201 = 201
export const STATUS_204 = 204

export const STATUS_400 = 400
export const STATUS_500 = 500

export const CURRENCY_RUB = 'RUB'
export const CURRENCY_USD = 'USD'
export const CURRENCY_EUR = 'EUR'
export const CURRENCY_NZD = 'NZD'
export const CURRENCY_AUD = 'AUD'

export const CURRENCY_BTC = 'BTC'
export const CURRENCY_ETH = 'ETH'

export const ACTIVE_CURRENCIES = {
  [CURRENCY_USD]: {
    label: 'USD — United States dollar',
    value: CURRENCY_USD,
  },
  [CURRENCY_RUB]: {
    label: 'RUB — Russian ruble',
    value: CURRENCY_RUB,
  },
  [CURRENCY_EUR]: {
    label: 'EUR — Euro',
    value: CURRENCY_EUR,
  },
  [CURRENCY_NZD]: {
    label: 'NZD — New Zealand dollar',
    value: CURRENCY_NZD,
  },
  [CURRENCY_AUD]: {
    label: 'AUD — Australian dollar',
    value: CURRENCY_AUD,
  },
}

export const EVENT_INVOICE_CREATE = 'create_invoice'
export const EVENT_INVOICE_UPDATE = 'update_invoice'
export const EVENT_INVOICE_EDIT = 'edit_invoice'

export type LOADING_TYPE = 'loaded' | 'loading' | 'error'
export type TRANSACTION_STATE_TYPE = 'sent' | 'sending' | 'error'
export type STATUS_SUCCESS_TYPE = 200 | 201 | 204
