
export const TEAMS_DUCK = 'teams'

export const TEAMS_UPDATE = 'teams/UPDATE'
export const TEAMS_UPDATE_LOADING_STATE = 'teams/UPDATE_LOADING_STATE'
export const TEAMS_ADD_NEW_MEMBER_EMAIL_DATA = 'teams/TEAMS_ADD_NEW_MEMBER_EMAIL_DATA'
export const TEAMS_INVITE_MEMBER_UPDATE_LOADING_STATE = 'teams/TEAMS_INVITE_MEMBER_UPDATE_LOADING_STATE'
export const TEAMS_ACTIVATE_MEMBER_UPDATE_LOADING_STATE = 'teams/TEAMS_ACTIVATE_MEMBER_UPDATE_LOADING_STATE'
export const TEAMS_UPDATE_SORTING = 'teams/UPDATE_SORTING'

export const TEAM_FIXED_SALARY_MEMBER = 'Employee'
export const TEAM_HOURLY_SALARY_MEMBER = 'Contractor'

export const TEAM_MEMBER_PAYMENT_TYPE_FIXED = 'Fixed'
export const TEAM_MEMBER_PAYMENT_TYPE_HOURLY = 'Hourly'
