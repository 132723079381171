import get from 'lodash.get'
import cn from 'classnames'
import BigNumber from 'bignumber.js'
import React from 'react'
import moment from 'moment'
import config from '../../config/getConfig'
import PropTypes from 'prop-types'
import { Button } from '../../components/Button'
import { PaymentRequestModel } from '../../models/PaymentRequests/PaymentRequestModel'
import { AccountModel } from '../../models/Accounts/AccountModel'
import Icon from '../../components/Icon/Icon'
import { Scrollbars } from 'react-custom-scrollbars'
import { BITCOIN_SYMBOL } from '../../ducks/bitcoin/constants'
import { FORMAT_FIAT_NUMBERS } from '../../ducks/accounts/constants'
import { TYPE_AMOUNT, TYPE_HOURS, TYPE_QUANTITY } from '../AddInvoiceCard/constants'
import { truncate } from '../../utils/general'
import RequestPayCard from '../RequestPayCard'
import { PAYROLL_SELECTED_ITEMS_TYPE_PREVIEW_INVOICE, PAYROLL_STEP_SEND } from '../../ducks/payroll/constants'
import {
  PAYMENT_REQUEST_STATUS_DECLINED,
  PAYMENT_REQUEST_STATUS_FAILED,
  PAYMENT_REQUEST_STATUS_IN_PROGRESS,
  PAYMENT_REQUEST_STATUS_PAID,
  PAYMENT_REQUEST_STATUS_PENDING_APPROVAL,
  PAYMENT_REQUEST_STATUS_UNPAID,
} from '../../ducks/paymentRequests/constants'
import { LOADING_STATE_ERROR, LOADING_STATE_LOADED, LOADING_STATE_LOADING } from '../../constants'
import styles from './InvoiceCardNew.module.scss'

import { getFormattedInvoiceStatus } from '../../ducks/paymentRequests/utils'
import BigPopup from '../../components/BigPopup'
import CancelMessage from './CancelMessage'
import PaymentRequestStatusIcon from '../../components/PaymentRequestStatusIcon/PaymentRequestStatusIcon'
import Notification from '../../components/Notification/'
import PDFActions from './PDFActions'
import PaymentDetailsBlock from './PaymentDetailsBlock'
import { convertToBigNumber } from "../../ducks/accounts/utils"


export default class InvoiceCardComponent extends React.PureComponent {
  static propTypes = {
    billToSearchList: PropTypes.object,
    handleCopyInvoice: PropTypes.func,
    isCancelSent: PropTypes.bool,
    blockchainsSettingsLoadingState: PropTypes.oneOf([
      LOADING_STATE_ERROR,
      LOADING_STATE_LOADED,
      LOADING_STATE_LOADING,
    ]),
    onClose: PropTypes.func,
    handleSubmit: PropTypes.func,
    handlePay: PropTypes.func,
    downloadFile: PropTypes.func,
    emptyItems: PropTypes.object,
    loadingState: PropTypes.string,
    requestWallet: PropTypes.string,
    currentPayrollStep: PropTypes.string,
    currentRequestPayStep: PropTypes.string,
    notHotWallet: PropTypes.bool,
    notEnoughMoney: PropTypes.bool,
    isItMyRequest: PropTypes.bool,
    validate: PropTypes.func,
    handleEdit: PropTypes.func,
    handleCancel: PropTypes.func,
    handleDownloadAttachment: PropTypes.func,
    initialValues: PropTypes.object,
    handleResetFormLoadingState: PropTypes.func,
    request: PropTypes.instanceOf(PaymentRequestModel),
    account: PropTypes.instanceOf(AccountModel),
    accounts: PropTypes.objectOf(PropTypes.shape({
      name: PropTypes.string,
      blockchain: PropTypes.string,
      address: PropTypes.string,
    })),
    defaultAccount: PropTypes.instanceOf(AccountModel),
    isSent: PropTypes.bool,
  }

  constructor (props) {
    super(props)
    this.state = {
      isCancelPopupOpen: false,
      isBillFromShown: !!get(this.props.request, 'invoice.billFrom', false),
    }
  }

  handleToggleBillFromShown = () => {
    this.setState(({ isBillFromShown }) => ({
      isBillFromShown: !isBillFromShown,
    }))
  }

  isEditable = () => {
    const { request, isItMyRequest } = this.props
    return isItMyRequest && request.status === PAYMENT_REQUEST_STATUS_UNPAID
  }

  isPayable = () => {
    const { request, isItMyRequest } = this.props
    return !isItMyRequest && [PAYMENT_REQUEST_STATUS_UNPAID, PAYMENT_REQUEST_STATUS_FAILED].includes(request.status)
  }

  isCancelable = () => {
    const { request, isItMyRequest } = this.props
    return isItMyRequest && [
      PAYMENT_REQUEST_STATUS_UNPAID,
      PAYMENT_REQUEST_STATUS_DECLINED,
      PAYMENT_REQUEST_STATUS_PENDING_APPROVAL,
    ].includes(request.status)
  }

  isPaid = () => {
    const { request } = this.props
    return request.status === PAYMENT_REQUEST_STATUS_PAID
  }

  isInProgress = () => {
    const { request } = this.props
    return request.status === PAYMENT_REQUEST_STATUS_IN_PROGRESS
  }

  toggleCancelPopup = () => {
    this.setState((prevState) => {
      return {
        isCancelPopupOpen: !prevState.isCancelPopupOpen,
      }
    })
  }

  renderItemsTableTitles = (type, currency) => {
    switch (type) {
      case TYPE_AMOUNT:
        return (
          <>
            <div>Description</div>
            <div>Amount, {currency}</div>
          </>
        )
      case TYPE_HOURS:
        return (
          <>
            <div>Description</div>
            <div>Rate, {currency}</div>
            <div>Hours</div>
            <div>Subtotal, {currency}</div>
          </>
        )
      case TYPE_QUANTITY:
        return (
          <>
            <div>Description</div>
            <div>Rate, {currency}</div>
            <div>Qty</div>
            <div>Subtotal, {currency}</div>
          </>
        )
      default:
        return null
    }
  }

  renderItemsTableInputs = (request, item, key) => {

    const descrPath = `description`
    const pathAmount = `amount`
    const pathRate = `rate`
    const pathCount = `count`

    let subtotal = new BigNumber(get(item, pathRate, 0)).multipliedBy(new BigNumber(get(item, pathCount, 0)))
    subtotal = subtotal.isNaN() ? '0.00' : subtotal.toFormat(2, FORMAT_FIAT_NUMBERS)

    const renderItems = () => {
      switch (item.type) {
        case TYPE_AMOUNT: {
          return (
            <React.Fragment key={key}>
              <div>
                {new BigNumber(get(item, pathAmount, '')).toFormat(2, FORMAT_FIAT_NUMBERS)}
              </div>
            </React.Fragment>
          )
        }
        case TYPE_HOURS:
        case TYPE_QUANTITY: {
          return (
            <React.Fragment key={key}>
              <div>
                {new BigNumber(get(item, pathRate, 0)).toFormat(2, FORMAT_FIAT_NUMBERS)}
              </div>
              <div>
                {get(item, pathCount, '')}
              </div>
              <div><span>{subtotal}</span></div>
            </React.Fragment>
          )
        }
        default:
          return null
      }
    }

    return (
      <React.Fragment key={key}>
        <div className='description'>
          {get(item, descrPath, '')}
        </div>
        {renderItems()}
      </React.Fragment>)
  }

  renderItemsTable = () => {
    const { request } = this.props
    const type = request.invoice.items[0].type

    return (
      <>
        <div className={styles.itemTableTitle + ' capture gray-50 ' + (type === TYPE_AMOUNT ? styles.amountType: '')}>
          {this.renderItemsTableTitles(type, request.settlementAsset)}
        </div>
        <div className={styles.itemTableBody + ' capture gray-70 ' + (type === TYPE_AMOUNT ? styles.amountType: '')}>
          {request.invoice.items.map((item, key) => {
            return this.renderItemsTableInputs(request, item, key)
          })}
        </div>
      </>
    )
  }

  renderAttachmentsTable = () => {
    const { request } = this.props
    const attachments = request.invoice.attachments || []

    return (
      <div className={styles.attachmentsWrapper}>
        {attachments.map((attach) => {
          const name = truncate(attach.name, 50)

          return (
            <div className={styles.attachment} key={attach.name}>
              <Icon type='file-empty' colorType='gray-50' />
              <div
                className={styles.attachmentTitle}
                onClick={() => this.props.downloadFile(attach.attachmentId, request.requestId)}
              >{name}</div>
            </div>
          )
        })}
      </div>
    )
  }

  renderTotalAmount = () => {
    const { request, isItMyRequest } = this.props
    const transaction = this.getInvoiceTransaction()
    let amountCrypto = null
    if (transaction) {
      amountCrypto = transaction.amount
        ? convertToBigNumber({ blockchain: request.paymentBlockchain }, transaction.amount)
        : new BigNumber(request.settlementAmount).dividedBy(transaction.xrate)
    }

    if (!this.isPaid()) {
      return (
        <div className='summary-amount'>
          <div className='h3'>{new BigNumber(request.settlementAmount).toFormat(2, FORMAT_FIAT_NUMBERS)}<span
            className='h4'>{request.settlementAsset}</span></div>
        </div>
      )
    }

    if (!request) {
      return null
    }

    if (isItMyRequest) {
      return (
        <div className='summary-amount'>
          <div className='h3 received'>+ {new BigNumber(request.settlementAmount).toFormat(2, FORMAT_FIAT_NUMBERS)}<span
            className='h4'>{request.settlementAsset}</span></div>
          {amountCrypto && <div
            className='h5 crypto-amount'>+ {amountCrypto.toFixed(config.getConfig('bitcoinDecimalLength')) + ' ' + BITCOIN_SYMBOL}</div>}
        </div>
      )
    } else {
      return (
        <div className='summary-amount'>
          <div className='h3 sent'>- {new BigNumber(request.settlementAmount).toFormat(2, FORMAT_FIAT_NUMBERS)}<span
            className='h4'>{request.settlementAsset}</span></div>
          {amountCrypto && <div
            className='h5 crypto-amount'>- {amountCrypto.toFixed(config.getConfig('bitcoinDecimalLength')) + ' ' + BITCOIN_SYMBOL}</div>}
        </div>
      )
    }
  }

  getInvoiceTransaction = () => {
    const invoice = this.props.request.invoice
    return invoice.transactions.length ? invoice.transactions[invoice.transactions.length - 1] : null
  }

  handlePay = () => {
    if (this.props.notHotWallet || this.props.notEnoughMoney) {
      return null
    }

    return this.props.handlePay()
  }

  getInvoiceStatus = () => {
    const { request, isItMyRequest } = this.props
    if (!request) {
      return null
    }

    return getFormattedInvoiceStatus(request.status, isItMyRequest)
  }

  render () {
    const {
      request,
      onClose,
      isItMyRequest,
      billToSearchList,
      requestWallet,
      accounts,
    } = this.props

    if (!request) {
      return null
    }

    const totalValue = request.invoice.items.reduce((acc, item) => {
      if (!new BigNumber(item.amount).isNaN()) {
        return acc.plus(item.amount)
      }
      if (!new BigNumber(item.count * item.rate).isNaN()) {
        return acc.plus(item.count * item.rate)
      }
      return acc
    }, new BigNumber(0)).toFormat(2, FORMAT_FIAT_NUMBERS)
    const invoiceTransaction = this.getInvoiceTransaction()
    const currency = request.invoice.items[0].currency
    const dueTo = moment(new Date(request.invoice.due))
    const createdAt = moment(new Date(request.createdAt))
    const paidAt = invoiceTransaction && moment(new Date(invoiceTransaction.blockTime))
    const payError = this.props.notHotWallet || this.props.notEnoughMoney
    const xrate = invoiceTransaction && invoiceTransaction.xrate && new BigNumber(invoiceTransaction.xrate)
    const xrateDate = invoiceTransaction && invoiceTransaction.xrateDate && moment(new Date(invoiceTransaction.xrateDate))

    const billTo = request.invoice.payerAddress
    const billToIsPaid = get(billToSearchList, `["${billTo}"].isPaid`, false)
    const billToAvatar = billTo && billToIsPaid
      ? get(billToSearchList, `["${billTo}"].avatar.url`, null)
      : null
    const billToName = billTo && billToIsPaid
      ? get(billToSearchList, `["${billTo}"].name`, null)
      : null

    const billFrom = request.invoice.payeeAddress
    const billFromIsPaid = get(billToSearchList, `["${billFrom}"].isPaid`, false)
    const billFromAvatar = billFrom && billFromIsPaid
      ? get(billToSearchList, `["${billFrom}"].avatar.url`, null)
      : null
    const billFromName = billFrom && billFromIsPaid
      ? get(billToSearchList, `["${billFrom}"].name`, null)
      : null

    console.log('Invoice Card')
    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <button className={styles.closeButton} onClick={onClose}><Icon scale='1.5' type='close' colorType='gray-50' />
          </button>
          <div className='title h3 gray-70'>Invoice #{request.invoice.totalNumber}</div>
        </div>
        {request.loadingState === LOADING_STATE_LOADING && <div className={styles.loadingState}>
          <div className={styles.loader} />
        </div>}
        {request.loadingState === LOADING_STATE_LOADED && <div className={styles.panel}>

          <div className={styles.sideContent}>
            <Scrollbars style={{ width: '265px', height: 'calc(100vh - 80px)' }}>
              <div className={styles.SideContent__content}>
                {this.isPayable() && this.props.currentRequestPayStep !== PAYROLL_STEP_SEND && <div className={styles.buttonContainer}
                  onClick={this.handlePay}
                >
                  <Button
                    disabled={this.props.blockchainsSettingsLoadingState === LOADING_STATE_LOADING || payError}
                    colorType='new-filled'
                  >PAY</Button>
                </div>}
                {this.isEditable() && <div className={styles.buttonContainer} onClick={this.props.handleEdit(request.requestId)}>
                  <Button
                    disabled={request.invoice.logo && [undefined, null].includes(request.invoice.logo.loadingState)}
                    colorType='new-filled'
                  >EDIT</Button>
                </div>}
                {isItMyRequest && <div className={styles.buttonContainer} onClick={this.props.handleCopyInvoice}>
                  <Button
                    disabled={request.invoice.logo && [undefined, null].includes(request.invoice.logo.loadingState)}
                    colorType='new-border'
                  >MAKE A COPY</Button>
                </div>}
                {this.isCancelable() && <div className={styles.buttonContainer} onClick={this.toggleCancelPopup}>
                  <Button colorType='new-border'>CANCEL</Button>
                </div>}
                {this.props.currentRequestPayStep !== PAYROLL_STEP_SEND && (
                  <>
                    <div className={styles.summary + ' gray-30'}>
                      <div className='summary-label capture'>Total:</div>
                      {this.renderTotalAmount()}
                    </div>

                    {this.isPaid() && (
                      <>

                        <div className={styles.sidePanelInfoLine}>
                          <div className='description'>
                            <PaymentRequestStatusIcon type='detail' requestId={request.requestId} />
                          </div>
                          <div className='value'>
                            <div className='capture gray-70'><span
                              className='gray-50'>Status:&nbsp;</span>{this.getInvoiceStatus()}</div>
                          </div>
                        </div>
                        {paidAt && <>

                          <div className={styles.sidePanelInfoLine}>
                            <div className='description'>
                              <Icon type='calendar' colorType='gray-70' />
                            </div>
                            <div className='value'>
                              <div className='capture gray-50'>Issued:</div>
                              <div className='capture gray-70'>{createdAt.format('MMMM DD, YYYY')}</div>
                            </div>
                          </div>

                          <div className={styles.sidePanelInfoLine}>
                            <div className='description'>
                              <Icon type={isItMyRequest ? 'collapse-down' : 'arrow-to-top'} colorType='gray-70' />
                            </div>
                            <div className='value'>
                              <div className='capture gray-50'>{isItMyRequest ? 'Received:' : 'Sent:'}</div>
                              <div className='capture gray-70'>
                                {paidAt.format('MMMM DD, YYYY')} at {paidAt.format('HH:mm')}
                              </div>
                            </div>
                          </div>
                        </>}
                        {xrate && <>

                          <div className={styles.sidePanelInfoLine}>
                            <div className='description'>
                              <Icon type='activity' colorType='gray-70' />
                            </div>
                            <div className='value exchange-rate'>
                              <div className='capture gray-50'>Rate:</div>
                              <div className='exhange-rate-value'>
                                {xrate.toFormat(FORMAT_FIAT_NUMBERS)} {request.settlementAsset}/{request.paymentAsset}
                              </div>
                              <div className='exhange-rate-date'>
                                on {xrateDate.format('DD.MM.YYYY')} at {xrateDate.format('HH:mm')}
                              </div>
                              <div className='exhange-rate-source-name'>
                                ({invoiceTransaction.xrateSource})
                              </div>
                            </div>
                          </div>
                        </>}
                      </>
                    )}
                    {!this.isPaid() && (
                      <>
                        <div className={styles.sidePanelInfoLine}>
                          <div className='description'>
                            <PaymentRequestStatusIcon type='detail' requestId={request.requestId} />
                          </div>
                          <div className='value'>
                            <div className='capture gray-70'><span
                              className='gray-50'>Status:&nbsp;</span>{this.getInvoiceStatus()}</div>
                          </div>
                        </div>
                        <div className={styles.sidePanelInfoLine}>
                          <div className='description'>
                            <Icon type='calendar' colorType='gray-70' />
                          </div>
                          <div className='value'>
                            <div className='capture gray-50'>Issued:</div>
                            <div className='capture gray-70'>{createdAt.format('MMMM DD, YYYY')}</div>
                          </div>
                        </div>
                        <div className={styles.sidePanelInfoLine}>
                          <div className='description'>
                            <Icon type='calendar' colorType='gray-70' />
                          </div>
                          <div className='value'>
                            <div className='capture gray-50'>Due:</div>
                            <div className='capture gray-70'>{dueTo.format('MMMM DD, YYYY')}</div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                {(this.isPayable() || !!this.props.currentPayrollStep) &&
                <div className={styles.paymentContainer + (this.props.currentRequestPayStep === PAYROLL_STEP_SEND?' isConfirmed':'')}>
                  <RequestPayCard
                    type={PAYROLL_SELECTED_ITEMS_TYPE_PREVIEW_INVOICE}
                    closePreviewInvoicePanel={onClose}
                  />
                </div>
                }
                <PDFActions requestId={request.requestId} />
              </div>
            </Scrollbars>
          </div>

          <div className={styles.mainContent}>
            <Scrollbars style={{ height: 'calc(100vh - 80px)' }}>

              <div className={styles.notificationsWrapper}>
                {request.loadingState === LOADING_STATE_LOADED && this.props.isCancelSent && (
                  <Notification message='Invoice has been cancelled' type='success' />
                )}
                {request.loadingState === LOADING_STATE_ERROR && this.props.isCancelSent && (
                  <Notification message='Something went wrong' type='error' />
                )}
              </div>

              {request.invoice.logo && (
                <div className={styles.section}>
                  <div className={styles.logoWrapper}>
                    {request.invoice.logo.loadingState === LOADING_STATE_LOADED
                      ? (<img
                        className={styles.logoImage}
                        alt={request.invoice.logo.name}
                        src={request.invoice.logo.data} />)
                      : request.invoice.logo.loadingState === LOADING_STATE_ERROR
                        ? (<div>Downloading logo failed</div>)
                        : (<div className={styles.logoLoader} />)}
                  </div>
                </div>
              )}

              <div className={styles.section + ' gray-70'}>
                <div className={styles.sectionTitle + ' h5'}>Invoice Details</div>
                {isItMyRequest && <div className={styles.line}>
                  <label className='capture'>Bill to</label>
                  <div className={styles.lineData}>
                    <div className={cn(styles.billToWrapper, { 'avatar': !!billToAvatar })}>
                      {billToAvatar
                        ? <img
                          alt={billToName || request.invoice.payerAddress}
                          src={billToAvatar}
                        />
                        : (
                          <div className={styles.iconWrapper}>
                            <Icon
                              type={billToName && !billToAvatar ? 'user-circle' : 'mail'}
                              colorType='gray-30'
                            />
                          </div>
                        )}
                      {billToName || request.invoice.payerAddress}
                    </div>
                  </div>
                </div>}
                {isItMyRequest && request.invoice.billFrom && <div className={styles.line + ' vertAlignTop'}>
                  <label className='capture'>Bill From</label>
                  <div className={styles.lineData + ' preFormat'}>{request.invoice.billFrom}</div>
                </div>}
                {!isItMyRequest && <div className={styles.line}>
                  <label className='capture'>From</label>
                  <div className={styles.lineData}>
                    <div className={cn(styles.billToWrapper, { 'avatar': !!billFromAvatar })}>
                      {billFromAvatar
                        ? <img
                          alt={billFromName || request.invoice.payeeAddress}
                          src={billFromAvatar}
                        />
                        : (
                          <div className={styles.iconWrapper}>
                            <Icon
                              type={billFromName && !billFromAvatar ? 'user-circle' : 'mail'}
                              colorType='gray-30'
                            />
                          </div>
                        )}
                      {billFromName || request.invoice.payeeAddress}
                    </div>
                  </div>
                </div>}
                {!isItMyRequest && <div className={styles.lineData}>
                  <label className='capture' />
                  <div className={styles.lineData}>
                    {request.invoice.billFrom && (
                      <div className={styles.billFromWrapper}>
                        <button onClick={this.handleToggleBillFromShown}>
                          <span
                            className='capture-small gray-50'>{this.state.isBillFromShown ? 'Hide details' : 'Show details'}</span>
                          <Icon type={this.state.isBillFromShown ? 'chevron-up' : 'chevron-down'} colorType='gray-30' />
                        </button>
                        {this.state.isBillFromShown && (
                          <div className={styles.billFromContent + ' capture gray-70'}>
                            {request.invoice.billFrom}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>}
                {request.invoice.title && <div className={styles.line}>
                  <label className='capture'>Title</label>
                  <div className={styles.lineData}>
                    {request.invoice.title}
                  </div>
                </div>}
                <div className={styles.line}>
                  <label className='capture'>Invoice Number</label>
                  <div className={styles.lineData}>
                    {`#${request.invoice.totalNumber}`}
                  </div>
                </div>
              </div>
              <div className={styles.section + ' gray-70'}>
                <div className={styles.sectionTitle + ' h5'}>Payment Details</div>
                <PaymentDetailsBlock
                  isItMyRequest={isItMyRequest}
                  request={request}
                  requestWallet={requestWallet}
                  invoiceTransaction={this.getInvoiceTransaction()}
                  accounts={accounts}
                />
              </div>
              <div className={styles.separator} />
              <div className={styles.section + ' gray-70'}>
                <div className={styles.sectionTitle + ' h5'}>Invoice items</div>
                {this.renderItemsTable()}
                <div className={styles.itemTotalWrapper}>
                  <div>
                    <span className='capture gray-30'>Total</span>
                    <span className='capture gray-70'>{totalValue} {currency}</span>
                  </div>
                </div>
              </div>
              {request.invoice.attachments.length > 0 &&
              <>
                <div className={styles.separator} />
                <div className={styles.section + ' gray-70'}>
                  <div className={styles.sectionTitle + ' h5'}>Attachments</div>
                  {this.renderAttachmentsTable()}
                </div>
              </>}
              {request.notes &&
              <>
                <div className={styles.separator} />
                <div className={styles.section + ' gray-70'}>
                  <div className={styles.sectionTitle + ' h5'}>Note</div>
                  <div className={styles.notes}>
                    {request.notes}
                  </div>
                </div>
              </>}
            </Scrollbars>
          </div>
        </div>}

        {this.state.isCancelPopupOpen && (
          <BigPopup onClose={this.toggleCancelPopup} smallMode>
            <CancelMessage
              onCancel={this.props.handleCancel(request.requestId)}
            />
          </BigPopup>
        )}

      </div>
    )
  }
}
