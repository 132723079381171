import { createSelector } from 'reselect'
import { PROFILE_DUCK } from './constants'
import { CURRENCY_RUB } from '../../constants'
import { ProfileModel } from "../../models/Contacts/ProfileModel"

export const profileDuckSelector = (state) => {
  return state[PROFILE_DUCK]
}

export const profileLoadingStateSelector = createSelector(
  profileDuckSelector,
  (profile) => ({
    loadingState: profile.loadingState,
    error: profile.error,
    result: profile.result,
  }),
)

export const passwordLoadingStateSelector = createSelector(
  profileDuckSelector,
  (profile) => ({
    loadingState: profile.passwordLoadingState,
    error: profile.error,
    result: profile.result,
  }),
)

export const profileInfoSelector = createSelector(
  profileDuckSelector,
  (profile) => {
    return new ProfileModel({
      ...profile.info,
    })
  },
)

export const currentCurrencySelector = createSelector(
  profileInfoSelector,
  ({ primaryAsset }) => primaryAsset || CURRENCY_RUB,
)

export const lastActivitySelector = createSelector(
  profileDuckSelector,
  ({ lastActivity }) => lastActivity,
)

export const showLastActivityPopupSelector = createSelector(
  profileDuckSelector,
  ({ lastActivityPopupActive }) => lastActivityPopupActive,
)

export const getActivityTimerIdsSelector = createSelector(
  profileDuckSelector,
  ({ lastActivityTimerId, logoutActivityTimeoutId }) => {
    return { lastActivityTimerId, logoutActivityTimeoutId }
  },
)
