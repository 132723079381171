import TrezorConnect from 'trezor-connect'

type transactionType = {
  to: string,
  value: string,
  data: string,
  nonce: string,
  gasLimit: string,
  gasPrice: string,
}
export default class EthereumTrezorDevice {
  constructor () {
    this.setManifest()
    this.path = `m/44'/60'/0'/0/0`
    // https://github.com/ethereum/EIPs/blob/master/EIPS/eip-155.md#list-of-chain-ids
    this.chainMap = {
      'mainnet': 1,
      'rinkeby': 4,
      'ropsten': 3,
      'goerli': 5,
      'Kovan': 42,
    }
  }

  async getAccount () {
    return TrezorConnect.ethereumGetAddress({ path: this.path })
  }

  async getAddress () {
    const info = await TrezorConnect.ethereumGetAddress({ path: this.path })
    return info.payload.address
  }

  async signTransaction () {
    throw new Error('Method not allowed, use composeTransaction.')
  }

  async composeTransaction (transaction: transactionType, chain: string) {
    return TrezorConnect.ethereumSignTransaction({
      path: this.path,
      transaction: {
        ...transaction,
        chain: this.chainMap[chain],
      },
    })
  }

  setManifest () {
    TrezorConnect.manifest({
      email: 'developer@xyz.com',
      appUrl: 'https://app.paymentx.io',
    })
  }
}

