import * as Yup from 'yup'
import { ACCEPT_DOCS, IMAGE_MIME_TYPE } from '../../utils/constants'
import { TYPE_AMOUNT, TYPE_HOURS, TYPE_QUANTITY } from './constants'
import moment from 'moment'
import BigNumber from 'bignumber.js'
import { PAYMENT_REQUEST_STATUS_UNPAID } from '../../ducks/paymentRequests/constants'
import { validateAddress } from '../../ducks/accounts/utils'

const FILE_SIZE = 20 * 1000000
const validation = (values) => {

  const errors = {}
  const isItSend = values.statusText === PAYMENT_REQUEST_STATUS_UNPAID
  try {
    Yup
      .mixed()
      .test('invoiceNumber', 'Invoice number is required', (value) => {
        return isItSend ? !!value : true
      })
      .test('invoiceNumber', 'Please use only these symbols in the invoice number: "0-9 a-z A-Z _.,+-"', (value) => {
        const res = value.match(/[^A-Za-z0-9_.,+-]/g)
        return !res
      })
      .test('invoiceNumber', 'Invoice number is too long', (value) => {
        return value.length <= 32
      })
      .test('invoiceNumber', 'Invoice number is too short', (value) => {
        if (value) {
          return value.length >= 1
        }
        return true
      })
      .validateSync(values.invoiceNumber)
  } catch (error) {
    errors.invoiceNumber = error.message
  }

  try {
    Yup.mixed()
      .test('billTo', 'Bill to is required', (value) => {
        return isItSend ? (!!value && !!value.trim()) : true
      })
      .test('billTo', (value) => {
        if ((!!value && !!value.trim())) {
          return Yup
            .string()
            .email('Bill to must be an email')
            .validateSync(value.trim())
        }
        return true
      })
      .validateSync(values.billTo)
  } catch (error) {
    errors.billTo = error.message
  }

  try {
    Yup.mixed()
      .test('due', 'Due is required', (value) => {
        return isItSend ? !!value : true
      })
      .test(
        'due',
        'Invalid date',
        (value) => {
          return moment(value).isValid() && moment(value) > moment()
        },
      )
      .validateSync(values.due)

  } catch (error) {
    errors.due = true
  }

  try {
    Yup.mixed()
      .test('account', 'Account is required', (value) => {
        return isItSend ? !!value : true
      })
      .test(
        'account',
        'Account address is invalid',
        (value) => {
          if (value) {
            return validateAddress(values.blockchain, value.address || value.value)
          }
          return true
        })
      .validateSync(values.account)
  } catch (error) {
    errors.account = error.message
  }

  try {
    Yup.mixed()
      .test(
        'billFrom',
        'The text is too long. Please change it to something shorter.',
        (value) => {
          if (isItSend && value) {
            const valueAr = value.split('\n')
            return valueAr.length <= 4
          }
          return true
        },
      )
      .validateSync(values.billFrom)
  } catch (error) {
    errors.billFrom = error.message
  }

  // attachments
  const attachmentsSchema = Yup
    .mixed()
    .test(
      'fileSize',
      'File too large',
      (value) => {
        if (!value) {
          return true
        }
        if (value.attachmentId) {
          return true
        }
        if (value.isLoading || value.isErrored) {
          return true
        }
        const y = /(=*)$/gi.exec(value.data + '=')
        const fileSize = (value.data.length * (3 / 4)) - (y[0] ? y[0].length : 0)
        return value && fileSize <= FILE_SIZE
      },
    )
    .test(
      'fileFormat',
      'Unsupported Format',
      (value) => {
        if (!value) {
          return true
        }
        if (value.attachmentId) {
          return true
        }
        if (value.isLoading || value.isErrored) {
          return true
        }
        return value ? ACCEPT_DOCS.includes(value.type || value.extension) : true
      },
    )

  values.attachments.forEach((item, key) => {
    try {
      attachmentsSchema.validateSync(item)
    } catch (error) {
      //eslint-disable-next-line
      console.log('Attachment validation error: ', error)
      if (!errors.attachments) {
        errors.attachments = []
      }
      if (!errors.attachments[key]) {
        errors.attachments[key] = {}
      }
      errors.attachments[key] = error.message || 'Unable to download'
    }
  })

  const logoSchema = Yup
    .mixed()
    .test(
      'fileSize',
      'File too large',
      (value) => {
        if (!value) {
          return true
        }
        if (value.attachmentId) {
          return true
        }
        if (value.isLoading || value.isErrored) {
          return true
        }
        const y = /(=*)$/gi.exec(value.data + '=')
        const fileSize = (value.data.length * (3 / 4)) - (y[0] ? y[0].length : 0)
        return value && fileSize <= FILE_SIZE
      },
    )
    .test(
      'fileFormat',
      'Unsupported Format',
      (value) => {
        if (!value) {
          return true
        }
        if (value.attachmentId) {
          return true
        }
        if (value.isLoading || value.isErrored) {
          return true
        }
        return value ? IMAGE_MIME_TYPE.includes(value.type || value.extension) : true
      },
    )

  try {
    if (values.logo && values.logo.data) {
      logoSchema.validateSync(values.logo)
    }
  } catch (error) {
    //eslint-disable-next-line
    console.log('Logo validation error: ', error)
    errors.logo = error.message || 'Unable to download'
  }


  // items
  const descrSchema = isItSend ? Yup.string().required('error') : Yup.string()
  const amountSchema = Yup
    .mixed()
    .test('Amount is required', (value) => {
      return isItSend ? !!value : true
    })
    .test('error', (value) => {
      if (value) {
        return parseFloat(value).toFixed(2) === value && parseFloat(value) > 0
      }
      return true
    })

  const countSchema = Yup
    .mixed()
    .test('Count is required', (value) => {
      return isItSend ? !!value : true
    })
    .test('error', (value) => {
      if (value) {
        const bnVal = new BigNumber(value)
        return !bnVal.isNaN() && parseFloat(value) > 0
      }
      return true
    })

  const itemsSchemaTypes = {
    [TYPE_AMOUNT]: {
      description: descrSchema,
      amount: amountSchema,
    },
    [TYPE_HOURS]: {
      description: descrSchema,
      rate: amountSchema,
      count: countSchema,
    },
    [TYPE_QUANTITY]: {
      description: descrSchema,
      rate: amountSchema,
      count: countSchema,
    },
  }
  values.items.forEach((item, key) => {
    Object.entries(itemsSchemaTypes[values.itemsType]).forEach(([field, schema]) => {
      try {
        schema.validateSync(item[field])
      } catch (error) {
        if (!errors.items) {
          errors.items = []
        }
        if (!errors.items[key]) {
          errors.items[key] = {}
        }
        errors.items[key][field] = 'error'
      }
    })
  })
  return errors
}

export const requiredValidation = (values) => {

  const errors = {}
  try {
    Yup
      .mixed()
      .test('invoiceNumber', 'Invoice number is required', (value) => {
        return !!value
      })
      .validateSync(values.invoiceNumber)
  } catch (error) {
    errors.invoiceNumber = error.message
  }

  try {
    Yup.mixed()
      .test('billTo', 'Bill to is required', (value) => {
        return !!value && !!value.trim()
      })
      .validateSync(values.billTo)
  } catch (error) {
    errors.billTo = error.message
  }

  try {
    Yup.mixed()
      .test('due', 'Due is required', (value) => {
        const isValidDate = (value instanceof Date && !isNaN(value))
        return !!value && isValidDate
      })
      .validateSync(values.due)
  } catch (error) {
    errors.due = true
  }

  try {
    Yup.mixed()
      .test('account', 'Account is required', (value) => {
        return !!value
      })
      .validateSync(values.account)
  } catch (error) {
    errors.account = error.message
  }

  // items
  const descrSchema = Yup.string().required('error')
  const amountSchema = Yup
    .mixed()
    .test('Amount is required', (value) => {
      return !!value
    })

  const countSchema = Yup
    .mixed()
    .test('Count is required', (value) => {
      return !!value
    })

  const itemsSchemaTypes = {
    [TYPE_AMOUNT]: {
      description: descrSchema,
      amount: amountSchema,
    },
    [TYPE_HOURS]: {
      description: descrSchema,
      rate: amountSchema,
      count: countSchema,
    },
    [TYPE_QUANTITY]: {
      description: descrSchema,
      rate: amountSchema,
      count: countSchema,
    },
  }

  values.items.forEach((item, key) => {
    Object.entries(itemsSchemaTypes[values.itemsType]).forEach(([field, schema]) => {
      try {
        schema.validateSync(item[field])
      } catch (error) {
        if (!errors.items) {
          errors.items = []
        }
        if (!errors.items[key]) {
          errors.items[key] = {}
        }
        errors.items[key][field] = 'error'
      }
    })
  })
  return errors
}

export default validation
