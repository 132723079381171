import PropTypes from 'prop-types'
import styles from './QrAddressPopup.module.scss'
import Icon from '../../components/Icon/Icon'
import React, { useState, useEffect } from 'react'
import QRCode from 'qrcode'
import LoadingState from '../LoadingState'
import CopyButton from '../Button/CopyButton'

function QrAddressPopup ({ account, onClose }) {
  const { address, name } = account
  const [qrCode, setQrCode] = useState(null)

  useEffect(() => {
    QRCode.toDataURL(address, { margin: 0, width: 348, height: 348, errorCorrectionLevel: 'H' })
      .then((url) => {
        setQrCode(url)
      })
  }, [address])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button
          onClick={onClose}
        ><Icon type='close' colorType='gray-50' />
        </button>
        <div className='h4 gray-70'>{name || address}</div>
      </div>
      <div className={styles.body}>
        {qrCode
          ? <img src={qrCode} alt={address} />
          : <LoadingState loadingState='loading' />
        }
        <div className={styles.addressWrapper}>
          <Icon type='wallet' colorType='gray-70' />
          <div className={styles.address}>{address}</div>
          <CopyButton copyString={address} />
        </div>
      </div>
    </div>
  )
}

QrAddressPopup.propTypes = {
  onClose: PropTypes.func,
  account: PropTypes.shape({
    address: PropTypes.string,
    name: PropTypes.string,
  }),
}

export default QrAddressPopup

