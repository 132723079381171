import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import styles from './Checkbox.module.scss'

class Checkbox extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = { value: props.value }
  }

  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    multipleIcon: PropTypes.bool,
    className: PropTypes.string,
    iconClassName: PropTypes.string,
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value })
  }

  handleDisableClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  render () {
    const { iconClassName, className } = this.props
    return (
      <div className={styles.wrapper + ' ' + className } data-is-checked={!!this.props.value}>
        <div
          className={cn(styles.checkbox, {
            'checked': this.props.value,
            'disabled': this.props.disabled,
            'multiple-icon': this.props.multipleIcon,
          }, iconClassName)}
          onClick={!this.props.disabled ? this.props.onClick : this.handleDisableClick}
        />
        <input
          name={this.props.name}
          type='hidden'
          value={this.state.value}
          onChange={this.handleChange}
        />
      </div>
    )
  }
}

export default Checkbox
