// @flow
import * as a from './constants'
import { SESSION_DESTROY } from '../../constants'
import { AUTH_SET_FLOW } from './constants'

export const updateAuthStatus = (loadingState: string, errorMessage?: string | null, errorStatus?: number | null) => ({
  type: a.AUTH_UPDATE_STATUS,
  loadingState,
  error: errorMessage,
  result: errorStatus,
})

export const updateAppLoadingState = (loadingState: string) => ({
  type: a.APP_UPDATE_LOADING_STATE,
  loadingState,
})

export const updateEmailLoadingState = (loadingState: string, errorMessage?: string | null, errorStatus?: number | null) => ({
  type: a.AUTH_CHECK_EMAIL_LOADING_STATE,
  loadingState,
  error: errorMessage,
  result: errorStatus,
})

export const updateLoadingStep = (loadingStep: string) => ({
  type: a.APP_UPDATE_LOADING_STEP,
  loadingStep,
})

export const updateAuth = (authData: {
  isCodeConfirmed?: boolean | null,
  isProfileConfirmed?: boolean | null,
  confirmCodeSendTime?: boolean | null,
  email?: string | null,
  secretCode?: string | null,
}) => ({
  type: a.AUTH_UPDATE_EMAIL,
  authData,
})

export const resetLogin = () => ({
  type: a.AUTH_RESET_LOGIN,
})

export const destroySession = () => ({
  type: SESSION_DESTROY,
})

export const setAuthFLow = (authFlow: string) => ({
  type: AUTH_SET_FLOW,
  authFlow,
})
