// @flow
import get from 'lodash.get'
import { connect } from 'react-redux'
import React from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import BigNumber from 'bignumber.js'
import { Form, Formik } from 'formik'
import InputText from '../../components/InputText'
import NewSelect from '../../components/Select/newSelect'
import { BITCOIN_DECIMALS } from '../../ducks/bitcoin/constants'
import {
  LOADING_STATE_LOADING,
  TRANSACTION_STATE_ERROR,
  TRANSACTION_STATE_SENDING,
  TRANSACTION_STATE_SENT,
} from '../../constants'
import { checkPassOfAccount, createTx } from '../../ducks/bitcoin/utils'
import { bitcoinTransactionSendingStateSelector } from '../../ducks/bitcoin/selectors'
import { ratesSymbolMappedListSelector } from '../../ducks/rates/selectors'
import { Button } from '../../components/Button'

import type { TRANSACTION_STATE_TYPE } from '../../constants'
import styles from './SendForm.module.scss'
import globalStyles from './styles.module.scss'
import type { HotWallet } from '../../ducks/accounts/models'
import type { TransactionSend, Utxo } from '../../ducks/bitcoin/models'
import { calculateFeeForAccount, sendTransaction } from '../../ducks/accounts/thunks'
import { sendFormSettingSelector } from '../../ducks/accounts/selectors'
import { ACCOUNT_TYPE_HOT, FORMAT_FIAT_NUMBERS } from '../../ducks/accounts/constants'
import { currentCurrencySelector } from '../../ducks/profile/selectors'
import BigPopup from '../../components/BigPopup'
import LoadingState from '../../components/LoadingState'
import { setSidePanel } from '../../ducks/ui/actions'
import SendFormMessage from './SendFormMessage'
import { validateAddress } from '../../ducks/accounts/utils'

const validationFee = (account: HotWallet) => (values, fees) => {
  const errors = {}
  if (!values.walletAddress) {
    errors.walletAddress = 'Required'
  } else if (!validateAddress(account.blockchain, values.walletAddress)) {
    errors.walletAddress = 'Address is not valid'
  }
  if (!values.amount) {
    errors.amount = 'Required'
  } else if (account.formattedBalance.minus(fees[values.multiplier] || 0).lt(new BigNumber(values.amount))) {
    errors.amount = 'Not enough funds'
  }
  if (!values.multiplier) {
    errors.multiplier = 'Required'
  }

  return errors
}

const validation = ({ account, sendFormSettings }) => (values) => {
  const errors = validationFee(account)(values, sendFormSettings.fees)

  if ([ACCOUNT_TYPE_HOT].includes(account.type) && !values.passPhrase) {
    errors.passPhrase = 'Required'
  } else if ([ACCOUNT_TYPE_HOT].includes(account.type) && !checkPassOfAccount(account, values.passPhrase)) {
    errors.passPhrase = 'Password is incorrect'
  }

  try {
    Yup.mixed()
      .test('Required',
        (value) => {
          return !!value
        })
      .test(
        'Amount is invalid',
        (value) => parseFloat(value).toString() === value && parseFloat(value) > 0,
      )
      .validateSync(values.amount)
  } catch (e) {
    errors.amount = e.type
  }


  return errors
}

type Props = {
  onClose: () => void,
  sendTransaction: ({ account: HotWallet, tx: TransactionSend, password: string, multiplier: number }) => void,
  account: HotWallet,
  transactionSendingState: TRANSACTION_STATE_TYPE,
  rateMain: number,
  sendFormSettings: any,
  currentCurrency: string,
  calculateFee: (account: HotWallet, to: Array<any>, isSendAll?: boolean) => Promise<any>,
  handleChangeTab: (tab: string) => void,
  setSidePanel: (panelName: string, panelProps: any) => void,
}

type State = {
  isSent: boolean,
  isSendAll: boolean,
}

type FormValues = {
  walletAddress: string,
  amount: string,
  passPhrase: string,
  multiplier: number,
}

class SendBitcoinForm extends React.PureComponent<Props, State> {
  accountUTXO: Promise<Array<Utxo>> | null

  static propTypes = {
    handleChangeTab: PropTypes.func,
    onClose: PropTypes.func,
    sendTransaction: PropTypes.func,
    account: PropTypes.object,
    transactionSendingState: PropTypes.string,
    calculateFee: PropTypes.func,
    rateMain: PropTypes.number,
    currentCurrency: PropTypes.string,
    sendFormSettings: PropTypes.shape({
      loadingState: PropTypes.string,
      fees: PropTypes.objectOf(PropTypes.object),
    }),
    setSidePanel: PropTypes.func,
  }

  constructor (props) {
    super(props)

    this.state = {
      isSent: false,
      isSendAll: false,
    }
    this.accountUTXO = null
  }

  componentDidMount () {
    const { account } = this.props
    this.props.calculateFee(account, [])
  }

  convertBTCtoFiat = (btc = 0) => {
    const btcBN = new BigNumber(btc)
    return (btcBN.isNaN() ? new BigNumber(0) : new BigNumber(btc))
      .multipliedBy(this.props.rateMain)
      .toFormat(2, BigNumber.ROUND_UP, FORMAT_FIAT_NUMBERS)
  }

  clearUtxo = () => {
    this.accountUTXO = null
  }

  txSentDone = () => {
    this.setState({
      isSent: false,
    })
  }

  sendEverything = (setFieldValue, values) => () => {
    this.props.calculateFee(
      this.props.account,
      [{ address: values.walletAddress }],
      true,
    )
      .then((fees) => {
        const everythingBalance = Math.max(this.props.account.formattedBalance.minus(fees[values.multiplier]), new BigNumber(0))
        setFieldValue('amount', everythingBalance.toString(), false)
        this.setState({
          isSendAll: true,
        })
      })
  }

  handleSubmit = (values: FormValues, formikProps) => {
    const { account } = this.props
    this.props.sendTransaction({
      account,
      tx: createTx(account.address, values.walletAddress, new BigNumber(values.amount)),
      password: values.passPhrase,
      multiplier: values.multiplier,
      isSendAll: this.state.isSendAll,
    })
    formikProps.setFieldValue('passPhrase', '')
    this.setState({
      isSent: true,
    })
  }

  handleOpenResetPasswordForm = () => {
    const { account } = this.props
    this.props.setSidePanel('recoverAccountPasswordForm', {
      isOpen: true,
      accountId: account.id,
    })
  }

  renderForm = (formikProps) => {
    const {
      handleChange,
      handleBlur,
      handleSubmit,
      values,
      errors,
      setFieldValue,
      submitForm,
      validateForm,
    } = formikProps

    const fees = this.props.sendFormSettings.fees
    const feesOptions = fees ? [
      {
        value: 1,
        title: `${this.convertBTCtoFiat(fees[1])} ${this.props.currentCurrency} - Slow and cheap`,
      },
      {
        value: 2,
        title: `${this.convertBTCtoFiat(new BigNumber(fees[2]))} ${this.props.currentCurrency} - Normal`,
      },
      {
        value: 3.5,
        title: `${this.convertBTCtoFiat(fees[3.5])} ${this.props.currentCurrency} - Fast and expensive`,
      },
    ] : []

    const handleSubmitWithFeeRecall = () => {
      validateForm()
        .then((errors) => {
          if (Object.keys(errors).length <= 0) {
            this.props.calculateFee(
              this.props.account,
              [{
                address: values.walletAddress,
                value: new BigNumber(values.amount).multipliedBy(BITCOIN_DECIMALS),
              }],
              this.state.isSendAll,
            )
              .then(() => {
                submitForm()
              })
          }
        })
    }

    const handleChangeAmount = (e) => {
      handleChange(e)
      this.setState({
        isSendAll: false,
      })
    }

    return (
      <>
        <div className={globalStyles.tabTitle + ' h4 gray-70'}>Send</div>
        {this.props.transactionSendingState === TRANSACTION_STATE_SENDING
        || this.props.sendFormSettings.loadingState === LOADING_STATE_LOADING
          ? (
            <div className={styles.loaderWrapper}>
              <LoadingState loadingState={LOADING_STATE_LOADING} />
            </div>
          )
          : (
            <Form className={styles.form} name='sendTokensForm' onSubmit={handleSubmit}>
              <div className={styles.fakeForAutofill}>
                <input name='name' type='text' />
                <input name='password' type='password' />
              </div>
              <div className={styles.formContainer}>
                <div className='label capture gray-70'>Send To</div>
                <div>
                  <InputText
                    name='walletAddress'
                    placeholder='Wallet address'
                    type='text'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.walletAddress}
                    errorText={errors.walletAddress}
                    value={values.walletAddress}
                  />
                </div>
                <div className='label capture gray-70'>Amount</div>
                <div>
                  <InputText
                    name='amount'
                    placeholder='0.00 '
                    postContent='BTC'
                    postPadding='60px'
                    onChange={handleChangeAmount}
                    onBlur={handleBlur}
                    error={!!errors.amount}
                    errorText={errors.amount}
                    value={values.amount}
                  />
                  <div className={styles.actions}>
                    <div className={styles.fiatAmount + ' capture gray-70'}>
                      <span>{`≈ ${this.convertBTCtoFiat(values.amount)} ${this.props.currentCurrency}`}</span>
                    </div>
                    <button
                      className='capture blue-bk'
                      type='button'
                      onClick={this.sendEverything(setFieldValue, values)}>Send max
                    </button>
                  </div>
                </div>
                <div className='label capture gray-70'>Transaction fee</div>
                <div className={styles.select}>
                  <NewSelect
                    name='multiplier'
                    value={values.multiplier}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {feesOptions.map((option) => {
                      return <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.title}
                      </option>
                    })}
                  </NewSelect>
                </div>
                {[ACCOUNT_TYPE_HOT].includes(this.props.account.type) && (
                  <>
                    <div className='label capture gray-70'>Wallet password</div>
                    <div>
                      <InputText
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.passPhrase}
                        errorText={errors.passPhrase}
                        value={values.passPhrase}
                        placeholder='Enter password to confirm transaction'
                        name='passPhrase'
                        type='password'
                      />
                      <div
                        className={styles.resetPasswordButton}>
                        <button
                          type='button'
                          className='capture gray-50'
                          onClick={this.handleOpenResetPasswordForm}>
                          Forgot password?
                        </button>
                      </div>
                    </div>
                  </>
                )}
                <div />
                <div>
                  <Button
                    onClick={handleSubmitWithFeeRecall}
                    className={styles.sendBtn}
                    type='button'
                    colorType='new-filled'>Send</Button>
                </div>
              </div>
            </Form>
          )
        }
        {this.state.isSent && [TRANSACTION_STATE_SENT, TRANSACTION_STATE_ERROR].includes(this.props.transactionSendingState) && (
          <BigPopup onClose={this.txSentDone} smallMode>
            <SendFormMessage
              isSuccess={this.props.transactionSendingState === TRANSACTION_STATE_SENT}
              handleChangeTab={this.props.handleChangeTab} />
          </BigPopup>
        )}
      </>
    )
  }

  render () {
    return (
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validate={validation(this.props)}
        initialValues={{
          walletAddress: '',
          amount: '',
          passPhrase: '',
          multiplier: 1,
        }}
        onSubmit={this.handleSubmit}
        render={this.renderForm}
      />
    )
  }
}

function mapStateToProps (state, props) {
  const rates = ratesSymbolMappedListSelector(state)
  const currentCurrency = currentCurrencySelector(state)
  const fee = sendFormSettingSelector(state)

  return {
    currentCurrency,
    rateMain: get(rates, props.account.symbol + '.' + currentCurrency),
    sendFormSettings: fee,
    transactionSendingState: bitcoinTransactionSendingStateSelector(state),
  }
}

function mapDispatchToProps (dispatch) {
  return {
    sendTransaction: (options) => dispatch(sendTransaction(options)),
    calculateFee: (account, to, isSendAll) => dispatch(calculateFeeForAccount(account, to, isSendAll)),
    setSidePanel: (panelName, panelProps) => dispatch(setSidePanel(panelName, panelProps)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SendBitcoinForm)
