import React from 'react'
import PropTypes from 'prop-types'
import styles from './Notification.module.scss'
import Icon from '../Icon/Icon'
import cn from 'classnames'

export default class Index extends React.PureComponent {

  static propTypes = {
    message: PropTypes.string,
    type: PropTypes.oneOf(['success', 'error']),
    callback: PropTypes.func,
    'data-selenium-id': PropTypes.string,
  }

  constructor (props) {
    super(props)
    this.state = {
      show: false,
    }
  }

  componentDidMount () {
    setTimeout(() => {
      this.setState({ show: false })
      if (this.props.callback && typeof this.props.callback === 'function') {
        this.props.callback()
      }
    }, 5 * 1000)
    setTimeout(() => {
      this.setState({ show: true })
    }, 100)
  }

  render () {
    const info = {
      success: {
        icon: 'checkmark-circle',
        iconColor: 'green',
      },
      error: {
        icon: 'attention',
        iconColor: 'red',
      },
    }
    return (
      <div className={cn(styles.notification, this.props.type, { 'show': this.state.show })}>
        {info[this.props.type] && (
          <Icon type={info[this.props.type].icon} colorType={info[this.props.type].iconColor}
          />)}
        <span className='capture-small' data-selenium-id={this.props['data-selenium-id']}>{this.props.message}</span>
      </div>
    )
  }
}
