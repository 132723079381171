export const TYPE_AMOUNT = 'amount'
export const TYPE_HOURS = 'hours'
export const TYPE_QUANTITY = 'quantity'
export const itemsTypesMap = {
  [TYPE_AMOUNT]: {
    value: TYPE_AMOUNT,
    label: 'Amount Only',
  },
  [TYPE_HOURS]: {
    value: TYPE_HOURS,
    label: 'Hours',
  },
  [TYPE_QUANTITY]: {
    value: TYPE_QUANTITY,
    label: 'Quantity',
  },
}
