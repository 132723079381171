import { AUTH_DUCK } from './constants'

export const authSelector = (state) => {
  return state[AUTH_DUCK]
}

export const checkEmailLoadingStateSelector = (state) => {
  return state[AUTH_DUCK].checkEmailLoadingState
}

export const loginEmailSelector = (state) => {
  return state[AUTH_DUCK].email
}
