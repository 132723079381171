import * as c from './constants'

const getInitialState = () => ({
  feeRates: {},
  transactionSendingState: null, // 'sending'
})

const mutations = {
  [c.BITCOIN_UPDATE_FEE_RATE]: (state, { blockchain, feeRate }) => {
    return {
      ...state,
      feeRates: {
        ...state.feeRates,
        [blockchain]: feeRate,
      },
    }
  },
  [c.BITCOIN_UPDATE_TRANSACTION_STATE]: (state, { sendingState }) => {
    return {
      ...state,
      transactionSendingState: sendingState,
    }
  },
}

export default (state = getInitialState(), { type, ...payload }) => {
  return (type in mutations)
    ? mutations[type](state, payload)
    : state
}
