import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash.get'
import BigNumber from 'bignumber.js'
import { FORMAT_FIAT_NUMBERS } from '../../../ducks/accounts/constants'
import { TYPE_AMOUNT, TYPE_QUANTITY, TYPE_HOURS } from '../constants'
import InputText from '../../../components/InputText'

const ItemsTableInputs = ({ formikProps, item, itemKey, handleClickEnter, isShowRequireError, requiredFieldsErrors }) => {
  const {
    errors,
    values,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
    submitCount,
  } = formikProps
  const descrPath = `items[${itemKey}].description`
  const pathAmount = `items[${itemKey}].amount`
  const pathRate = `items[${itemKey}].rate`
  const pathCount = `items[${itemKey}].count`
  const isDescrRequireError = isShowRequireError && !!get(requiredFieldsErrors, descrPath, false)
  const isAmountRequireError = isShowRequireError && !!get(requiredFieldsErrors, pathAmount, false)
  const isRateRequireError = isShowRequireError && !!get(requiredFieldsErrors, pathRate, false)
  const isCountRequireError = isShowRequireError && !!get(requiredFieldsErrors, pathCount, false)


  if (!item) {
    return null
  }
  let subtotal = new BigNumber(get(values, pathRate, 0)).multipliedBy(new BigNumber(get(values, pathCount, 0)))
  subtotal = subtotal.isNaN() ? '0.00' : subtotal.toFormat(2, FORMAT_FIAT_NUMBERS)

  const handleBlurFiatValue = (path) => (e) => {
    const value = e.target.value
    setFieldValue(path, new BigNumber(value).isNaN() ? value : new BigNumber(value).toFixed(2))
    return handleBlur(e)
  }
  const renderItems = () => {
    switch (values.itemsType) {
      case TYPE_AMOUNT: {
        const amountValue = get(values, pathAmount, '')
        return (
          <React.Fragment key={itemKey}>
            <div className='input'><InputText
              placeholder='00.00'
              onChange={handleChange}
              onBlur={handleBlurFiatValue(pathAmount)}
              errorColor={submitCount <= 0 && isAmountRequireError ? 'blue' : null}
              error={((submitCount > 0 || !!amountValue) && get(touched, pathAmount, false) && !!get(errors, pathAmount, false)) || isAmountRequireError}
              value={amountValue}
              name={pathAmount}
              onKeyPress={handleClickEnter(formikProps, itemKey)}
            /></div>
          </React.Fragment>
        )
      }
      case TYPE_HOURS:
      case TYPE_QUANTITY: {
        const min = 10
        const max = 14
        const subtotalStyles = {}
        if (subtotal.length > min) {
          const minus = subtotal.length < max ? subtotal.length - min : max - min
          subtotalStyles.fontSize = (13 - minus) + 'px'
        }

        const rateValue = get(values, pathRate, '')
        const countValue = get(values, pathCount, '')
        return (
          <React.Fragment key={itemKey}>
            <div className='input'><InputText
              placeholder='00.00'
              onChange={handleChange}
              onBlur={handleBlurFiatValue(pathRate)}
              errorColor={submitCount <= 0 && isRateRequireError ? 'blue' : null}
              error={((submitCount > 0 || !!rateValue) && get(touched, pathRate, false) && !!get(errors, pathRate, false)) || isRateRequireError}
              value={rateValue}
              name={pathRate}
              onKeyPress={handleClickEnter(formikProps, itemKey)}
              decriseFontOptions={{ min: 8, max: 11 }}
            /></div>
            <div className='input'><InputText
              placeholder='00'
              onChange={handleChange}
              onBlur={handleBlur}
              errorColor={submitCount <= 0 && isCountRequireError ? 'blue' : null}
              error={((submitCount > 0 || !!countValue) && get(touched, pathCount, false) && !!get(errors, pathCount, false)) || isCountRequireError}
              value={countValue}
              name={pathCount}
              onKeyPress={handleClickEnter(formikProps, itemKey)}
              decriseFontOptions={{ min: 7, max: 10 }}
            /></div>
            <div
              style={subtotalStyles}
            ><span>{subtotal}</span></div>
          </React.Fragment>
        )
      }
      default:
        return null
    }
  }

  const descrValue = get(values, descrPath, '')
  return (
    <React.Fragment key={itemKey}>
      <div className='input description'>
        <div className='shadow'>{descrValue}</div>
        <InputText
          id={descrPath}
          placeholder='Type your description here'
          onChange={handleChange}
          onBlur={handleBlur}
          errorColor={submitCount <= 0 && isDescrRequireError ? 'blue' : null}
          error={((submitCount > 0 || !!descrValue) && get(touched, descrPath, false) && !!get(errors, descrPath, false)) || isDescrRequireError}
          value={descrValue}
          name={descrPath}
          onKeyPress={handleClickEnter(formikProps, itemKey)}
          textarea
          rows={1}
        />
      </div>
      {renderItems()}
    </React.Fragment>
  )
}

ItemsTableInputs.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string,
    amount: PropTypes.string,
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rate: PropTypes.string,
  }),
  itemKey: PropTypes.number,
  handleClickEnter: PropTypes.func,
  formikProps: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    values: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    submitForm: PropTypes.func,
    setFieldValue: PropTypes.func,
    submitCount: PropTypes.number,
  }),
  isShowRequireError: PropTypes.bool,
  requiredFieldsErrors: PropTypes.object,
}

export default ItemsTableInputs
