import { createSelector } from 'reselect'
import { RATES_DUCK } from './constants'
import get from 'lodash.get'

export const ratesDuckSelector = (state) => {
  return state[RATES_DUCK]
}

export const ratesSymbolMappedListSelector = createSelector(
  ratesDuckSelector,
  ({ rates }) => {
    return rates.reduce((accumulator, rate) => {
      return {
        ...accumulator,
        [rate.token]: { ...rate.rates, updatedAt: rate.updatedAt },
      }
    }, {})
  },
)

export const ratesBySymbolForCurrencySelector = (symbol, currency) => createSelector(
  ratesSymbolMappedListSelector,
  (rates) => {
    return get(rates, `${symbol}.${currency}`, 0)
  },
)

export const intervalRatesDataSelector = createSelector(
  ratesDuckSelector,
  (duck) => {
    return {
      intervalId: duck.intervalId,
      intervalTime: duck.intervalTime,
    }
  },
)
