import AbstractRESTService from './AbstractRESTService'

const URL_GET_PAYROLLS = '/payrolls?teamId='
const URL_RUN_PAYROLL = '/payrolls/run'
const URL_RUN_PAYROLL_OFF_CYCLE = '/payrolls/run-off-cycle'
const URL_PAYROLL = '/payrolls'
const URL_PAYROLL_CAPTURE = '/requests/capture'
const URL_PAYROLL_REQUESTS_PAYMENTS = '/requests/payments'
const URL_PAYROLL_ITEMS = '/payroll-items?payrollId='

export default class PayrollService extends AbstractRESTService {
  /**
   * get payrolls
   * @param {string} teamId - team ID
   */
  getPayrolls (teamId) {
    return this.restService.request({
      method: 'GET',
      url: URL_GET_PAYROLLS + teamId,
      headers: this.getBearerHeaders(),
    })
  }

  /**
   * run payroll
   * @param {string} payrollId - payroll ID
   * @param {string} payrollItemId - payroll item ID
   */
  runPayroll (payrollId, payrollItemId) {
    return this.restService.request({
      method: 'POST',
      url: URL_RUN_PAYROLL,
      headers: this.getBearerHeaders(),
      data: {
        payload: {
          payrollId,
          payrollItemId,
        },
      },
    })
  }

  /**
   * run payroll off-cycle
   * @param {string} payrollId - payroll ID
   */
  runPayrollOffCycle (payrollId) {
    return this.restService.request({
      method: 'POST',
      url: URL_RUN_PAYROLL_OFF_CYCLE,
      headers: this.getBearerHeaders(),
      data: {
        payload: {
          payrollId,
        },
      },
    })
  }

  /**
   * create payroll
   * @param {object} payload - object with values to create a payroll
   */
  createPayroll (payload) {
    return this.restService.request({
      method: 'POST',
      url: URL_PAYROLL,
      headers: this.getBearerHeaders(),
      data: {
        payload,
      },
    })
  }

  /**
   * Update payroll
   * @param {object} payload - object with values to update a payroll
   * @param {string} payrollId
   */
  updatePayroll (payload, payrollId) {
    return this.restService.request({
      method: 'PUT',
      url: URL_PAYROLL,
      headers: this.getBearerHeaders(),
      data: {
        payrollId,
        payload,
      },
    })
  }

  /**
   * Delete payroll
   * @param {string} payrollId
   */
  deletePayroll (payrollId) {
    return this.restService.request({
      method: 'DELETE',
      url: URL_PAYROLL,
      headers: this.getBearerHeaders(),
      data: {
        payload: {
          payrollId,
        },
      },
    })
  }

  /**
   * get payroll items
   * @param {string} payrollId - payroll ID
   */
  getPayrollItems (payrollId) {
    return this.restService.request({
      method: 'GET',
      url: URL_PAYROLL_ITEMS + payrollId,
      headers: this.getBearerHeaders(),
    })
  }

  capture (payload) {
    return this.restService.request({
      method: 'POST',
      url: URL_PAYROLL_CAPTURE,
      headers: this.getBearerHeaders(),
      data: {
        payload,
      },
    })
  }

  requestsPayments (payload: Array<any>) {
    return this.restService.request({
      method: 'POST',
      url: URL_PAYROLL_REQUESTS_PAYMENTS,
      headers: this.getBearerHeaders(),
      data: {
        payload,
      },
    })
  }
}
