import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../components/Button'
import styles from './PopupMessage.module.scss'
import Icon from '../../components/Icon/Icon'

const PopupMessage = (props) => {
  const {
    icon,
    onClose,
    actionsClassName,
    title,
    subTitle,
    actions,
    headerTitle,
  } = props
  return (
    <div className={styles.messageContainer}>
      <div className={styles.messageHeader}>
        <button type='button' onClick={onClose}><Icon scale='1.5' type='close' colorType='gray-50' /></button>
        <div className='h4 gray-70'>{headerTitle}</div>
      </div>
      <div className={styles.messageContent}>
        {icon && (
          <Icon {...icon} />
        )}

        {title && <div className='h5 gray-30'>{title}</div>}
        {subTitle && <div className='capture gray-30'>{subTitle}</div>}

        <div className={styles.messageActions + ' ' + actionsClassName} style={{
          gridTemplateColumns: `repeat(${actions.length}, 1fr)`,
        }}>
          {actions.map((action, i) => {
            if (action.node) {
              return <React.Fragment key={i}>{action.node}</React.Fragment>
            }
            return (
              <Button
                type='button'
                key={i}
                disabled={action.disabled}
                onClick={action.onClick || onClose}
                colorType={action.colorType || 'new-border'}>
                {action.title || 'close'}
              </Button>
            )
          })}
        </div>
      </div>
    </div>
  )
}
PopupMessage.propTypes = {
  icon: PropTypes.object, // icon component params
  onClose: PropTypes.func,
  actionsClassName: PropTypes.string,
  headerTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  actions: PropTypes.arrayOf(PropTypes.shape({
    node: PropTypes.node,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
    colorType: PropTypes.string,
    onClick: PropTypes.func,
  })),
}

export default PopupMessage
