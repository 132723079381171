import LogRocket from "logrocket"

export default class Logger {
  static error (err, extra) {
    try {
      if (process.env.NODE_ENV === 'production') {
        LogRocket.captureMessage({ err, extra: JSON.stringify(extra) })
      } else if (process.env.NODE_ENV !== 'test') { // not needed to log errors in console while checked errored way in tests.
        // eslint-disable-next-line
        console.error(err, extra)
      }
    } catch (e) {
      LogRocket.captureException(e, {
        tags: {
          type: 'error in logger',
        },
        extra,
      })
    }
  }

  static identify (...params) {
    if (process.env.NODE_ENV === 'production') {
      LogRocket.identify(...params)
    }
  }

  static init () {
    if (process.env.NODE_ENV === 'production') {
      LogRocket.init('slaxtb/apppaymentxio', {
        network: {
          isEnabled: false,
        },
        dom: {
          isEnabled: false,
        },
        console: {
          isEnabled: {
            info: false,
            log: false,
            debug: false,
            warn: false,
            error: true,
          },
          shouldAggregateConsoleErrors: true,
        },
      })
    }
  }
}
