import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import { isDeviceWidth } from '../../utils/general'
import Swipe from '../../utils/swipe'

const DISTANCE_TO_SWIPE = 100

export default class SwipePanel extends React.PureComponent {
  static propTypes = {
    containerId: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  }

  constructor (props) {
    super(props)

    this.state = {
      isOpened: false,
    }
  }

  componentDidMount () {
    this.walletsPanel = document.getElementById('walletsContainer')
    if (isDeviceWidth(1024)) {
      this.addSwipeListeners()
    }
  }

  componentWillUnmount () {
    this.walletsPanel = null
  }

  addSwipeListeners = () => {
    const swipe = new Swipe(this.walletsPanel)
    swipe.subscribe()
    this.walletsPanel.addEventListener('swipe', (e) => {
      this.walletsPanel.style.height = null
      this.walletsPanel.style.transition = null
      this.handleSwipe(e)
    })
    this.walletsPanel.addEventListener('tap', (e) => {
      this.walletsPanel.style.height = null
      this.handleClosedTap(e)
    })
    this.walletsPanel.addEventListener('swipeMove', (e) => {
      this.handleMove(e)
    })
  }

  toggleClassOpen = () => {
    if (this.state.isOpened) {
      this.walletsPanel.classList.remove('opened')
      this.walletsPanel.classList.add('closed')
    } else {
      this.walletsPanel.classList.remove('closed')
      this.walletsPanel.classList.add('opened')
    }

    this.setState({
      ...this.state,
      isOpened: !this.state.isOpened,
    })
  }

  handleSwipe = (e) => {
    if (Math.abs(e.detail.distY) >= DISTANCE_TO_SWIPE) {
      if ((this.state.isOpened && e.detail.direction === 'down') || (!this.state.isOpened && e.detail.direction === 'up')) {
        this.toggleClassOpen()
      }
    }
  }

  handleMove = (e) => {
    this.walletsPanel.style.transition = 'none'

    if (this.state.isOpened) {
      this.walletsPanel.style.height = 'calc(80vh + ' + e.detail.distY + 'px)'
    } else {
      this.walletsPanel.style.height = 115 + e.detail.distY + 'px'
    }
  }

  handleClosedTap = (e) => {
    if (!this.state.isOpened) {
      e.preventDefault()
      this.toggleClassOpen()
    }
  }

  handleBackgroundContainer = () => {
    this.walletsPanel.style.transition = null
    this.toggleClassOpen()
  }

  render () {
    return (
      <>
        <div className={styles.backgroundContainer + ' ' + (this.state.isOpened?'opened':'closed')} onClick={this.handleBackgroundContainer}/>
        <div id={this.props.containerId} className={styles.additionalContainer + ' ' + (this.state.isOpened?'opened':'closed')}>
          <div className={styles.swipeThing} />
          {this.props.children}
        </div>
      </>
    )
  }
}
