// @flow
import React from 'react'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import InputText from '../../components/InputText'
import { Button } from '../../components/Button'
import type { HotWallet } from '../../ducks/accounts/models'
import { getMnemonicPhrase } from '../../ducks/accounts/utils'
import globalStyles from './styles.module.scss'
import Icon from '../../components/Icon/Icon'
import styles from './BackupForm.module.scss'
import CopyButton from '../../components/Button/CopyButton'


type Props = {
  account: HotWallet,
  onClose?: () => void
}

type FormValues = {
  password: string,
}

type State = {
  mnemonic: string | null,
}

export default class BackupForm extends React.PureComponent<Props, State> {
  static propTypes = {
    account: PropTypes.object,
    setMnemonic: PropTypes.func,
    onClose: PropTypes.func,
  }

  constructor (props: Props) {
    super(props)
    this.state = {
      mnemonic: null,
    }
  }

  validation = (values: FormValues) => {
    const errors = {}

    if (!values.password) {
      errors.password = 'Password is required'
    }

    if (!getMnemonicPhrase(this.props.account, values.password)) {
      errors.password = 'Wrong password'
    }
    return errors
  }

  render () {
    const handleSubmit = async (values: FormValues) => {
      this.setState({
        mnemonic: getMnemonicPhrase(this.props.account, values.password),
      })
    }

    return (
      <Formik
        validateOnChange={false}
        initialValues={{
          password: '',
        }}
        validate={this.validation}
        onSubmit={handleSubmit}
      >
        {(formProps) => {
          const {
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
          } = formProps

          return (
            <div className={globalStyles.messageContainer}>
              <div className={globalStyles.messageHeader}>
                <button onClick={this.props.onClose}><Icon scale='1.5' type='close' colorType='gray-50' /></button>
                <div className='h4 gray-70'>Backup wallet</div>
              </div>
              <div className={styles.content}>
                {!this.state.mnemonic
                  ? (
                    <Form className={styles.form} name='sendTokensForm' onSubmit={handleSubmit}>
                      <div className={styles.input}>
                        <InputText
                          name='password'
                          type='password'
                          label='Your wallet password'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.password}
                          value={values.password}
                        />
                      </div>
                      <div className={styles.action}>
                        <Button
                          disabled={!values.password}
                          type='submit'
                          colorType='new-filled'
                        >See the backup phrase</Button>
                      </div>
                    </Form>
                  )
                  : (
                    <div className={styles.phraseContainer}>
                      <div className='capture gray-70'>
                        Please carefully write down these 12 words. You need to copy and<br />
                        save them to access this wallet in the future. <span
                          className='red'>Don’t lose them!</span> They<br />
                        cannot be recovered.
                      </div>
                      <div className={styles.wordsGrid}>
                        {this.state.mnemonic.split(' ').map((word, key) => {
                          return <div className={styles.word} key={word}><span>{key + 1}</span>{word}</div>
                        })}
                      </div>
                      <div className={styles.copyButtonWrapper}>
                        <CopyButton copyString={this.state.mnemonic ? this.state.mnemonic : ''}>
                          <>
                            <Icon type='copy-2' colorType='blue' />
                            <span className='capture blue-bk'>Copy to clipboard</span>
                          </>
                        </CopyButton>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )
        }}
      </Formik>
    )
  }
}
