import bip39 from 'bip39'
import etherHDkey from 'ethereumjs-wallet/hdkey'

export default class EthereumMemoryDevice {
  constructor (mnemonic) {
    this.mnemonic = mnemonic
  }

  getZeroWallet () {
    const seed = bip39.mnemonicToSeed(this.mnemonic)
    const HDwallet = etherHDkey.fromMasterSeed(seed)
    return HDwallet.derivePath("m/44'/60'/0'/0/0").getWallet()
  }

  getAddress () {
    const zeroWallet = this.getZeroWallet()
    return zeroWallet.getAddressString()
  }

  async signTransaction (tx) {
    const zeroWallet = this.getZeroWallet()
    tx.sign(zeroWallet.privKey)
    return tx
  }

  getKeyPair () {
    throw new Error('Method not implemented.')
  }
}
