import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './LoadingState.module.scss'
import {
  LOADING_STATE_LOADED,
  LOADING_STATE_LOADING,
  LOADING_STATE_ERROR,
} from '../../constants'
import Icon from '../Icon/Icon'

class LoadingState extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    loaderClassName: PropTypes.string,
    reloadData: PropTypes.func,
    loadingState: PropTypes.oneOf([
      LOADING_STATE_ERROR,
      LOADING_STATE_LOADED,
      LOADING_STATE_LOADING,
    ]),
    loadingMessage: PropTypes.string,
    errorMessage: PropTypes.string,
  }

  static defaultProps = {
    loadingMessage: 'Loading.. Please wait',
    errorMessage: 'Failed to Load Data',
  }

  reloadPage = () => {
    window.location.reload()
  }

  render () {
    switch (this.props.loadingState) {
      case LOADING_STATE_LOADING:
        return (
          <div className={cn(styles.content, this.props.className)}>
            <div className={cn(styles.loader, this.props.loaderClassName)} />
          </div>
        )

      case LOADING_STATE_ERROR:
        return (
          <div className={cn(styles.content, this.props.className)}>
            <Icon type='attention' colorType='gray-30' className={styles.errorIcon} />
            <div className={styles.errorMessage}>{this.props.errorMessage}</div>
            <div className={styles.reloadMessage}>
              Please <button onClick={this.reloadPage}>reload page</button> or {
                this.props.reloadData
                  ? <button onClick={this.props.reloadData}>try load again</button>
                  : 'try again'
              }
            </div>
          </div>
        )

      case LOADING_STATE_LOADED:
      default:
        return null
    }
  }
}

export default LoadingState
