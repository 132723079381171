import * as c from './constants'
import { LOADING_STATE_LOADED } from '../../constants'
import { AUTH_FLOW_SIGN_IN } from './constants'

const getInitialState = () => ({
  loadingState: LOADING_STATE_LOADED,
  loadingStep: null,
  appLoadingState: null,
  checkEmailLoadingState: null,
  authFlow: AUTH_FLOW_SIGN_IN,
  isCodeConfirmed: null,
  isProfileConfirmed: null,
  authSession: null,
  confirmCodeSendTime: null,
  secretCode: null,
  email: null,
  error: null,
  result: null,
})

const mutations = {
  [c.AUTH_UPDATE_STATUS]: (state, { loadingState, error, result }) => {
    return {
      ...state,
      loadingState,
      error,
      result,
    }
  },
  [c.APP_UPDATE_LOADING_STATE]: (state, { loadingState }) => {
    return {
      ...state,
      appLoadingState: loadingState,
    }
  },
  [c.AUTH_CHECK_EMAIL_LOADING_STATE]: (state, { loadingState, error, result }) => {
    return {
      ...state,
      checkEmailLoadingState: loadingState,
      error,
      result,
    }
  },
  [c.AUTH_UPDATE_EMAIL]: (state, { authData }) => {
    return {
      ...state,
      ...authData,
    }
  },
  [c.AUTH_RESET_LOGIN]: () => {
    return {
      ...getInitialState(),
    }
  },
  [c.APP_UPDATE_LOADING_STEP]: (state, { loadingStep }) => {
    return {
      ...state,
      loadingStep,
    }
  },
  [c.AUTH_SET_FLOW]: (state, { authFlow }) => {
    return {
      ...state,
      authFlow,
    }
  },
}

export default (state = getInitialState(), { type, ...payload }) => {
  return (type in mutations)
    ? mutations[type](state, payload)
    : state
}
