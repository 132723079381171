import configClass from '../config/getConfig'
import ContactService from './ContactService'
import AuthService from './AuthService'
import ProfileService from './ProfileService'
import PaymentRequestsService from './PaymentRequestsService'
import BitcoinMiddlewareService from './BitcoinMiddlewareService'
import TeamService from './TeamService'
import BitcoinService from './BitcoinService'
import PayrollService from './PayrollService'
import WalletService from './WalletService'
import MediaService from './MediaService'
import InsightService from './InsightService'

export const STATUS_CODE_OK = 'Ok'
export const STATUS_CODE_FAIL = 'Fail'

const config = configClass.getConfig()

const servicesConfig = [
  {
    name: 'contactService',
    class: ContactService,
    baseURL: '',
  },
  {
    name: 'paymentRequestsService',
    class: PaymentRequestsService,
    baseURL: config.urls.paymentRequestsService,
  },
  {
    name: 'authService',
    class: AuthService,
    baseURL: config.urls.authService,
  },
  {
    name: 'profileService',
    class: ProfileService,
    baseURL: config.urls.profileService,
  },
  {
    name: 'teamService',
    class: TeamService,
    baseURL: config.urls.teamService,
  },
  {
    name: 'bitcoinTestnetMiddlewareService',
    class: BitcoinMiddlewareService,
    baseURL: config.urls.bitcoinTestnetMiddlewareService,
  },
  {
    name: 'bitcoinMiddlewareService',
    class: BitcoinMiddlewareService,
    baseURL: config.urls.bitcoinMiddlewareService,
  },
  {
    name: 'bitcoinTestnetService',
    class: BitcoinService,
    baseURL: config.urls.bitcoinTestnetService,
  },
  {
    name: 'bitcoinService',
    class: BitcoinService,
    baseURL: config.urls.bitcoinService,
  },
  {
    name: 'payrollService',
    class: PayrollService,
    baseURL: config.urls.payrollService,
  },
  {
    name: 'walletService',
    class: WalletService,
    baseURL: config.urls.walletService,
  },
  {
    name: 'insightService',
    class: InsightService,
    baseURL: config.urls.insightService,
  },
  {
    name: 'mediaService',
    class: MediaService,
    baseURL: config.urls.mediaService,
  },
]

export default servicesConfig
