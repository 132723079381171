import axios from 'axios'

export default class AbstractRESTService {
  constructor (options = {}) {
    if (this.constructor.name === 'AbstractRESTService') {
      throw new Error('Can\'t create an abstract class')
    }

    this.getAuthToken = options.getAuthToken
    this.restOptions = options.restOptions
    this.restService = axios.create(options.restOptions)
    this.restService.interceptors.response.use(function (response) {
      // Do something with response data
      // console.log('Response interceptor: ', response, JSON.stringify(response))
      return response
    }, function (error) {
      // console.log('Response interceptor: ', error, JSON.stringify(error))
      // Do something with response error
      return Promise.reject(error)
    })
  }

  getHeaders = (config = {}) => ({
    ...config.headers,
    Authorization: `SsoToken ${this.getAuthToken()}`,
  })

  getBearerHeaders = (config = {}) => ({
    ...config.headers,
    Authorization: `Bearer ${this.getAuthToken()}`,
  })

  getRestOptions () {
    return this.restOptions
  }
}
