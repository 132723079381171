import AbstractRESTService from './AbstractRESTService'
// import { getRequestConfigWithAuthorization } from '../utils/auth'

// const URL_CONTACT_LIST = '/contacts'

export default class ContactsService extends AbstractRESTService {

  /**
   * Getting contact list. You can get input param via ...params
   */
  getContactList () {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([
          {
            id: '1',
            passwordHash: 'a6fab14876ae66833aa96f13a4b818f4008bf88f5910687c7981447cb8412268',
            createdAt: '2018-07-27T07:45:00Z',
            updatedAt: '2018-07-27T07:45:00Z',
            status: 'blocked', // 'active'|'unauthorized'|'confirmed'|'blocked'
            general: {
              firstName: 'Chris Foo',
              lastName: 'last name',
              dob: '2018-07-27',
              phone: '+79201234567',
              email: 'Chris@gmail.com',
              address: 'Moscow, Nikolskaya, st, app. 6',
              avatar: {
                url: 'example url',
              },
            },
            employment: {
              position: 'Developer', // from the list Designer, Developer, etc
              type: 'hourly',
              startDate: '2018-07-27',
              monthlySalaryAmount: '1000',// for hourly this is a salary for
              monthlySalaryCurrency: 'EUR',
            },
            payments: {
              currency: 'currencyId',
              BTCAddress: '1000000000000000000000000000000',
              inPayroll: false, // 'true|false'
            },
            bussines: {
              useInformation: 'true',
              name: 'LaborX',
              email: 'laborx@gmail.com',
              address: 'Moscow, Kremlin, app. 1',
              number: '000 000 000 000',
              numberType: 'BN' | 'ABN' | 'EIN',
              logo: {
                url: 'logo url',
              },
            },
          },
          {
            id: '2',
            passwordHash: 'a6fab14876ae66833aa96f13a4b818f4008bf88f5910687c7981447cb8412268',
            createdAt: '2018-07-27T07:45:00Z',
            updatedAt: '2018-07-27T07:45:00Z',
            status: 'active', // 'active'|'unauthorized'|'confirmed'|'blocked'
            general: {
              firstName: 'John Doe',
              lastName: 'last name',
              dob: '2018-07-27',
              phone: '+79201234567',
              email: 'John@gmail.com',
              address: 'Moscow, Nikolskaya, st, app. 6',
              avatar: {
                url: 'example url',
              },
            },
            employment: {
              position: 'Designer', // from the list Designer, Developer, etc
              type: 'fixed', // 'fixed'|'hourly'
              startDate: '2018-07-27',
              monthlySalaryAmount: '1000',// for hourly this is a salary for
              monthlySalaryCurrency: 'USD',
            },
            payments: {
              currency: 'currencyId',
              BTCAddress: '1000000000000000000000000000000',
              inPayroll: true, // 'true|false'
            },
            bussines: {
              useInformation: 'true',
              name: 'LaborX',
              email: 'laborx@gmail.com',
              address: 'Moscow, Kremlin, app. 1',
              number: '000 000 000 000',
              numberType: 'BN' | 'ABN' | 'EIN',
              logo: {
                url: 'logo url',
              },
            },
          },
        ])
      }, 1000)
    })

    // return this.restService.request({
    //   method: 'GET',
    //   url: URL_CONTACT_LIST,
    //   headers: getRequestConfigWithAuthorization(this.getAuthToken()),
    //   data: {},
    // })
  }

}
