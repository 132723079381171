import * as c from './constants'

const getInitialState = () => ({
  list: [],
  loadingState: 'loading',
  sortField: 'firstName',
  sortDirection: 'asc',
  inviteMemberLoadingState: null,
  activateMemberLoadingState: 'loaded',
  addNewMemberData: {
    loadingState: null,
    email: null,
    firstName: null,
    lastName: null,
  },
})

const mutations = {
  [c.TEAMS_UPDATE]: (state, action) => {
    return {
      ...state,
      list: action.list,
    }
  },
  [c.TEAMS_INVITE_MEMBER_UPDATE_LOADING_STATE]: (state, action) => {
    return {
      ...state,
      inviteMemberLoadingState: action.loadingState,
    }
  },
  [c.TEAMS_ACTIVATE_MEMBER_UPDATE_LOADING_STATE]: (state, action) => {
    return {
      ...state,
      activateMemberLoadingState: action.loadingState,
    }
  },
  [c.TEAMS_ADD_NEW_MEMBER_EMAIL_DATA]: (state, action) => {
    return {
      ...state,
      addNewMemberData: {
        ...state.addNewMemberData,
        ...action,
      },
    }
  },
  [c.TEAMS_UPDATE_LOADING_STATE]: (state, action) => {
    return {
      ...state,
      loadingState: action.loadingState,
    }
  },
  [c.TEAMS_UPDATE_SORTING]: (state, action) => {
    return {
      ...state,
      sortField: action.sortField,
      sortDirection: action.sortDirection,
    }
  },
}

export default (state = getInitialState(), { type, ...payload }) => {
  return (type in mutations)
    ? mutations[type](state, payload)
    : state
}
