import React, { useState } from 'react'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Icon from '../../components/Icon/Icon'
import styles from './InvoiceCardNew.module.scss'
import { downloadInvoiceAsPdf, sendInvoiceAsPdf } from '../../ducks/paymentRequests/thunks'
import { LOADING_STATE_ERROR, LOADING_STATE_LOADED, LOADING_STATE_LOADING } from '../../constants'
import LoadingState from '../../components/LoadingState'
import InputText from '../../components/InputText'
import Button from '../../components/Button/Button'
import { profileInfoSelector } from '../../ducks/profile/selectors'

const PDFActions = ({ downloadInvoiceAsPdf, sendInvoiceAsPdf, requestId, profile }) => {

  const [downloadLoading, setDownloadLoading] = useState(null)
  const [isShowEmail, setIsShowEmail] = useState(false)
  const [sendLoading, setSendLoading] = useState(null)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(null)
  const emailValidation = Yup.string()
    .email('Please enter valid email address')
    .required('Email is required')


  const handleDownloadInvoiceAsPdf = () => {
    setDownloadLoading(LOADING_STATE_LOADING)
    setIsShowEmail(false)
    setSendLoading(null)
    downloadInvoiceAsPdf(requestId)
      .then(() => {
        setDownloadLoading(LOADING_STATE_LOADED)
      })
      .catch(() => {
        setDownloadLoading(LOADING_STATE_ERROR)
      })
  }
  const handleSendInvoiceAsPdf = () => {
    try {
      emailValidation.validateSync(email)
      setSendLoading(LOADING_STATE_LOADING)
      sendInvoiceAsPdf(requestId, email)
        .then(() => {
          setIsShowEmail(false)
          setSendLoading(LOADING_STATE_LOADED)
        })
        .catch(() => {
          setSendLoading(LOADING_STATE_ERROR)
        })
    } catch (e) {
      setEmailError(e.message)
    }
  }

  return (
    <div className={styles.PDFActionsWrapper}>
      {downloadLoading === LOADING_STATE_LOADING
        ? (
          <div className={styles.loadingWrapper}>
            <LoadingState loadingState={downloadLoading} />
          </div>
        )
        : (
          <button className={styles.action} onClick={handleDownloadInvoiceAsPdf}>
            <Icon type='file-pdf' colorType='blue' />
            <span className='capture-small blue-bk'>Download PDF</span>
          </button>
        )}
      {downloadLoading === LOADING_STATE_ERROR && (
        <div className={styles.errorWrapper + ' capture gray-50'}>
          Something went wrong, please try again.
        </div>
      )}

      {sendLoading === LOADING_STATE_LOADING
        ? (
          <div className={styles.loadingWrapper}>
            <LoadingState loadingState={sendLoading} />
          </div>
        )
        : isShowEmail ? (
          <div className={styles.emailWrapper}>
            <InputText
              onChange={(e) => {
                setEmail(e.target.value)
              }}
              name='email'
              value={email}
              placeholder='email'
              error={!!emailError}
              errorText={emailError}
            />
            <Button
              disabled={!email}
              onClick={handleSendInvoiceAsPdf}
              type='button'
              colorType='new-border'
            >send</Button>
          </div>
        ) : (
          (
            <button className={styles.action} onClick={() => {
              setDownloadLoading(null)
              setIsShowEmail(true)
              setEmail(profile.email || '')
              setSendLoading(null)
            }}>
              <Icon type='mail' colorType='blue' />
              <span className='capture-small blue-bk'>Send to email</span>
            </button>
          )
        )
      }
      {sendLoading === LOADING_STATE_LOADED && (
        <div className={styles.successMessage + ' capture gray-50'}>
          PDF version of the invoice<br />has been sent to
          <div className='gray-70'>{email}</div>
        </div>
      )}
      {sendLoading === LOADING_STATE_ERROR && (
        <div className={styles.successMessage + ' capture gray-50'}>
          Something went wrong, please try again.
        </div>
      )}
    </div>
  )
}


PDFActions.propTypes = {
  formikProps: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    values: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    submitForm: PropTypes.func,
    setFieldValue: PropTypes.func,
    submitCount: PropTypes.number,
  }),
  profile: PropTypes.object,
  downloadInvoiceAsPdf: PropTypes.func,
  sendInvoiceAsPdf: PropTypes.func,
  requestId: PropTypes.string,
}

const mapStateToProps = (state) => ({
  profile: profileInfoSelector(state),
})

const mapDispatchToProps = (dispatch) => ({
  downloadInvoiceAsPdf: (requestId) => dispatch(downloadInvoiceAsPdf(requestId)),
  sendInvoiceAsPdf: (requestId, email) => dispatch(sendInvoiceAsPdf(requestId, email)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PDFActions)
