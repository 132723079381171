import TrezorConnect from 'trezor-connect'

export default class BitcoinTrezorDevice {
  constructor (network, isTestnet) {
    this.network = network
    this.address = null
    this.coin = isTestnet ? 'Testnet' : 'Bitcoin'
    this.setManifest()
  }

  async getAccount () {
    return TrezorConnect.getAccountInfo({
      coin: this.coin === 'Bitcoin' ? 'BTC' : 'TEST',
    })
  }

  async getAddress () {
    return this.getAccount()
  }

  async signTransaction ()  {
    throw new Error('Method not allowed, use composeTransaction.')
  }

  async composeTransaction (outputs) {
    return TrezorConnect.composeTransaction({
      outputs: outputs.map((output) => {
        return {
          amount: output.value.toString(),
          address: output.address,
        }
      }),
      // example
      // [
      //   { amount: "200000", address: "18WL2iZKmpDYWk1oFavJapdLALxwSjcSk2" },
      // ]
      coin: this.coin === 'Bitcoin' ? 'BTC' : 'TEST',
      push: false,
    })
  }

  setManifest () {
    TrezorConnect.manifest({
      email: 'developer@xyz.com',
      appUrl: 'https://app.paymentx.io',
    })
  }
}

