import { combineReducers } from 'redux'
import paymentRequests from './paymentRequests/reducer'
import contacts from './contacts/reducer'
import accounts from './accounts/reducer'
import profile from './profile/reducer'
import payroll from './payroll/reducer'
import auth from './auth/reducer'
import rates from './rates/reducer'
import teams from './teams/reducer'
import bitcoin from './bitcoin/reducer'
import ethereum from './ethereum/reducer'
import ui from './ui/reducer'

export default combineReducers({
  ui,
  auth,
  accounts,
  contacts,
  payroll,
  profile,
  paymentRequests,
  rates,
  teams,
  bitcoin,
  ethereum,
})
