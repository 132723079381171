import get from 'lodash.get'
import moment from 'moment'

const DEFAULT_CACHE_TIME = 5 * 60 * 1000 // 5 minutes

class CacheServiceClass {

  _cacheA = {}

  getFromCache = async (address: string, key: string, loadFunction: any = {}, dropCache = false) => {
    if (!this.isCacheValid(address, key) || dropCache) {
      const data = await loadFunction()
      this.addToCache(address, key, data)
    }

    return get(this._cacheA, `${address}.${key}.data`, null)
  }

  isCacheValid = (address, key) => {
    const c = get(this._cacheA, [address, key].join('.'))
    return c && c.validUntil > +new Date()
  }

  addToCache = (address, key, data) => {
    if (!(address in this._cacheA)) {
      this._cacheA[address] = {}
    }

    this._cacheA[address][key] = {
      data,
      validUntil: (+new Date()) + DEFAULT_CACHE_TIME,
      moment: moment().format(),
    }
  }

  clearCache = (address) => {
    this._cacheA[address] = {}
  }

  clearAllCache = () => {
    this._cacheA = {}
  }
}

export const CacheService = new CacheServiceClass()
