//@flow
import cn from 'classnames'
import get from 'lodash.get'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import config from '../../config/getConfig'
import LoadingState from '../../components/LoadingState'
import { Formik, Form } from 'formik'
import {
  blockchainsSettingsSelector,
  cryptoPaymentSelector,
  fixedRatesSelector,
  payrollCurrentStepSelector,
} from '../../ducks/payroll/selectors'
import Icon from '../../components/Icon/Icon'
import InputText from '../../components/InputText'
import Button from '../../components/Button/Button'
import BigNumber from 'bignumber.js'
import { getMainSymbolForBlockchain } from '../../utils/general'
import { AccountModel } from '../../models/Accounts/AccountModel'
import { checkPassOfAccount } from '../../ducks/accounts/utils'
import {
  runPayroll,
  updatePayrollSettings,
  updatePayrollSettingsParams,
} from '../../ducks/payroll/thunks/runPayrollThunks'
import { LOADING_STATE_LOADING } from '../../constants'
import {
  PAYROLL_STEPS,
  PAYROLL_STEP_REQUEST_SETTINGS,
  PAYROLL_SELECTED_ITEMS_TYPE_MAIN_LIST,
} from '../../ducks/payroll/constants'
import { updatePayrollStep } from '../../ducks/payroll/actions'
import styles from './ConfirmPasswordCard.module.scss'

import type { HotWallet } from '../../ducks/accounts/models'
import { ACCOUNT_TYPE_TREZOR, FORMAT_FIAT_NUMBERS } from '../../ducks/accounts/constants'
import { currentCurrencySelector } from '../../ducks/profile/selectors'
import Label from '../../components/Label/Label'
import { hideFeeWarning } from '../../ducks/ui/thunks'
import { confirmPasswordCardParamsSelector } from '../../ducks/ui/selectors'
import { setSidePanel } from '../../ducks/ui/actions'
import { accountsSelector } from '../../ducks/accounts/selectors'
import CustomSelect from '../../components/Select/CustomSelect'
import type { AllRates } from '../../ducks/rates/models'

type Props = {
  currentStep: string,
  cryptoPaymentSelector: any,
  blockchains: any,
  type: string,
  handleSubmit: (values: any) => void,
  handleResetPayrollStep: () => void,
  handleGoBack: (step: string) => void,
  handleupdatePayrollSettings: (type: string) => void,
  updatePayrollSettingsBlockchain: (accountId: string, feeRate: string) => void,
  mainCurrency: string,
  isShownFeeWarning: boolean,
  hideFeeWarning: () => void,
  setSidePanel: (panelName: string, panelProps: any) => void,
  accounts: Array<HotWallet>,
  allRates: AllRates,
}

type State = {
  currentBlockchainKey: number,
  currentBlockchain: string,
  isSent: boolean,
  payedBlockchains: any,
}

class ConfirmPasswordCard extends React.PureComponent<Props, State> {
  static propTypes = {
    allRates: PropTypes.object,
    hideFeeWarning: PropTypes.func,
    isShownFeeWarning: PropTypes.bool,
    currentStep: PropTypes.oneOf(Object.values(PAYROLL_STEPS)),
    cryptoPaymentSelector: PropTypes.objectOf(
      PropTypes.shape({
        count: PropTypes.number,
        amount: PropTypes.instanceOf(BigNumber),
      }),
    ),
    blockchains: PropTypes.objectOf(
      PropTypes.shape({
        accountId: PropTypes.string,
        blockchain: PropTypes.string,
        feeRate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        fee: PropTypes.instanceOf(BigNumber),
      }),
    ),
    handleSubmit: PropTypes.func,
    type: PropTypes.string,
    updatePayrollSettingsBlockchain: PropTypes.func,
    handleResetPayrollStep: PropTypes.func,
    handleGoBack: PropTypes.func,
    handleupdatePayrollSettings: PropTypes.func,
    mainCurrency: PropTypes.string,
    setSidePanel: PropTypes.func,
    accounts: PropTypes.arrayOf(PropTypes.instanceOf(AccountModel)),
  }

  static defaultProps = {
    type: PAYROLL_SELECTED_ITEMS_TYPE_MAIN_LIST,
  }

  constructor (props) {
    super(props)
    this.state = {
      currentBlockchainKey: 0,
      currentBlockchain: '',
      isSent: false,
      payedBlockchains: {},
    }
  }

  handleResetForm = () => {
  }

  convertToFiat = (crypto, blockchain) => {
    const symbol = getMainSymbolForBlockchain(blockchain)
    const rate = get(this.props.allRates, `${symbol}.${this.props.mainCurrency}`, 0)
    return new BigNumber(crypto).multipliedBy(rate).toFormat(2, BigNumber.ROUND_UP, FORMAT_FIAT_NUMBERS)
  }

  handleChangeFee = ({ value }) => {
    this.props.updatePayrollSettingsBlockchain(this.props.blockchains[this.state.currentBlockchain].accountId, value)
  }

  validation = (values) => {
    const accountId = this.props.blockchains[this.state.currentBlockchain].accountId
    const account = this.props.accounts.find((account) => account.id === accountId)

    const errors = {}

    if (account && !values.password) {
      errors.password = 'Password is required'
    }

    // $flow-disable-line
    if (account && !checkPassOfAccount(account, values.password)) {
      errors.password = 'Password does not match'
    }

    return errors
  }

  componentDidMount () {
    const firstBLockchain = Object.keys(this.props.cryptoPaymentSelector)[0]
    this.setState({
      currentBlockchain: firstBLockchain,
      currentBlockchainKey: 0,
    })
  }

  handleNextBlockchain = () => {
    const currentBlockchain = Object.keys(this.props.cryptoPaymentSelector)[this.state.currentBlockchainKey]
    const nextBLockchain = Object.keys(this.props.cryptoPaymentSelector)[this.state.currentBlockchainKey + 1]
    if (nextBLockchain) {
      this.setState(() => ({
        isSent: false,
        currentBlockchain: nextBLockchain,
        currentBlockchainKey: this.state.currentBlockchainKey + 1,
        payedBlockchains: {
          ...this.state.payedBlockchains,
          [currentBlockchain]:
          this.state.payedBlockchains[currentBlockchain] ||
          (this.state.isSent && PAYROLL_STEPS.payrollCompleted === this.props.currentStep),
        },
      }))
      this.handleResetForm()
      this.props.handleResetPayrollStep()
    }
  }

  renderSuccessMessage = () => {
    const blockchainSettings = this.props.blockchains[this.state.currentBlockchain]
    const {
      separateTransactionsFailed,
    } = blockchainSettings


    const params = {}
    if (separateTransactionsFailed > 0) {
      params.title = 'Partially failed'
      params.subTitle = (
        <div className='capture gray-30'>
          There were problems confirming some transactions. Please check invoice status and details for more
          information.
        </div>
      )
      params.icon = <Icon type='attention' colorType='gray-30' />
      params.buttonTitle = 'Ok'
    } else {
      params.title = 'Payment has been sent successfully'
      params.subTitle = null
      params.icon = <Icon type='checkmark-circle' colorType='green' />
      params.buttonTitle = 'Ok, great'
    }

    return (
      <div className={styles.message}>
        {params.icon}
        <div className='h5 gray-30'>{params.title}</div>
        {params.subTitle}
        {Object.keys(this.props.cryptoPaymentSelector)[this.state.currentBlockchainKey + 1] ? (
          <button className='capture blue-bk' onClick={this.handleNextBlockchain}>Go to next payment</button>
        )
          : (
            <Button
              type='button'
              colorType='new-filled'
              onClick={this.handleGoBack}
            >{params.buttonTitle}</Button>
          )}
      </div>
    )
  }

  renderFailMessage = () => {
    return (
      <div className={cn(styles.message, styles.fail)}>
        <Icon type='attention' colorType='gray-30' />
        <div className='h5 gray-30'>Failed</div>
        <div className='capture gray-30'>
          Unable to сonfirm payment.
          <br />
          Please try again. Contact
          <br />
          support if the issue still persists.
        </div>
        {Object.keys(this.props.cryptoPaymentSelector)[this.state.currentBlockchainKey + 1] ? (
          <button className='capture blue-bk' onClick={this.handleNextBlockchain}>
            Go to next payment
          </button>
        ) : (
          <Button type='button' colorType='new-filled' onClick={this.handleGoBack}>
            Ok
          </Button>
        )}
      </div>
    )
  }

  handleGoBack = async () => {
    const totalBlockchainsToPay = Object.keys(this.props.cryptoPaymentSelector).length
    const totalBlockchainsPaid = Object.keys(this.state.payedBlockchains).length + ( this.state.isSent ? 1 : 0 )

    if (this.state.currentBlockchainKey > 0 && totalBlockchainsToPay - totalBlockchainsPaid) {
      const currentBlockchain = Object.keys(this.props.cryptoPaymentSelector)[this.state.currentBlockchainKey]
      const nextBLockchain = Object.keys(this.props.cryptoPaymentSelector)[this.state.currentBlockchainKey - 1]
      this.setState(() => ({
        isSent: false,
        currentBlockchain: nextBLockchain,
        currentBlockchainKey: this.state.currentBlockchainKey - 1,
        payedBlockchains: {
          ...this.state.payedBlockchains,
          [currentBlockchain]:
          this.state.payedBlockchains[currentBlockchain] ||
          (this.state.isSent && PAYROLL_STEPS.payrollCompleted === this.props.currentStep),
        },
      }))
    } else {
      await this.props.handleupdatePayrollSettings(this.props.type)
      await this.props.handleGoBack(PAYROLL_STEP_REQUEST_SETTINGS)
      await this.props.handleResetPayrollStep()
    }
  }

  handleOpenResetPasswordForm = () => {
    this.props.setSidePanel('recoverAccountPasswordForm', {
      isOpen: true,
      accountId: this.props.blockchains[this.state.currentBlockchain].accountId,
    })
  }

  renderForm = (formikProps) => {
    const { handleSubmit, handleChange, handleBlur, errors, values, setFieldValue, resetForm } = formikProps

    if (!this.state.currentBlockchain) {
      return null
    }
    const blockchainSettings = this.props.blockchains[this.state.currentBlockchain]
    if (!blockchainSettings) {
      return null
    }

    this.handleResetForm = resetForm

    const handlePreSubmit = (e) => {
      e.preventDefault()
      e.stopPropagation()
      setFieldValue('blockchain', this.state.currentBlockchain)
      handleSubmit(e)
    }

    const {
      fee,
      multiplier,
      accountId,
      enoughMoney,
      separateTransactionsPaid,
      separateTransactionsFailed,
      separateTransactionsAll,
      isSeparateTransactions,
      totalDebited,
      paymentType,
      feeList,
    } = blockchainSettings
    const account = this.props.accounts.find((account: HotWallet) => account.id === accountId)
    if (!account && !paymentType) {
      return null
    }

    const count = get(this.props.cryptoPaymentSelector, `${this.state.currentBlockchain}.count`, null)
    const amount = get(this.props.cryptoPaymentSelector, `${this.state.currentBlockchain}.amount`, null)
    const fiatAmount = get(this.props.cryptoPaymentSelector, `${this.state.currentBlockchain}.fiatAmount`, null)

    const total = amount.plus(fee || 0)

    const symbol = getMainSymbolForBlockchain(this.state.currentBlockchain)
    const isPayedBlockcain = this.state.payedBlockchains[this.state.currentBlockchain]

    const separatedMultiplier = isSeparateTransactions ? separateTransactionsAll * multiplier : multiplier

    const fees = feeList
      ? [
        {
          value: 1,
          title: `${this.convertToFiat(feeList[1] * separatedMultiplier, this.state.currentBlockchain)} ${
            this.props.mainCurrency
          } - Slow and cheap`,
        },
        {
          value: 2,
          title: `${this.convertToFiat(feeList[2] * separatedMultiplier, this.state.currentBlockchain)} ${
            this.props.mainCurrency
          } - Normal`,
        },
        {
          value: 3.5,
          title: `${this.convertToFiat(feeList[3.5] * separatedMultiplier, this.state.currentBlockchain)} ${
            this.props.mainCurrency
          } - Fast and expensive`,
        },
      ] : null

    const selectStyles = {
      control: (defaultStyles) => {
        return {
          ...defaultStyles,
          borderColor: 'transparent !important',
          boxShadow: 'none !important',
          backgroundColor: '#ffffff',
          borderRadius: '0px',
          minHeight: '50px',
        }
      },
      indicatorContainer: (defaultStyles) => {
        return {
          ...defaultStyles,
          padding: '5px',
        }
      },
      singleValue: (defaultStyles) => {
        return {
          ...defaultStyles,
          marginLeft: '-10px',
          ['.' + styles.listOption]: {
            display: 'none',
          },
          ['.' + styles.singleOption]: {
            display: 'flex',
          },
        }
      },
    }

    if (this.props.currentStep !== null) {
      return (
        <Form name='confirm-payroll-form' onSubmit={handlePreSubmit}>
          <div className={styles.containerConfirm}>
            {PAYROLL_STEPS.payrollCompleted === this.props.currentStep || isPayedBlockcain ? (
              <>
                <div className={styles.header}>
                  <div className={styles.headerConfirm}>
                    <button type='button' onClick={this.handleGoBack}>
                      <Icon type='arrow-left' />
                    </button>
                    <div className='confirm-title h5 gray-70'>{`Confirm ${count} payment${count > 1 ? 's' : ''}:`}</div>
                  </div>

                  <div className={styles.mainTotal}>
                    <div className='h4 gray-70'>{fiatAmount.toFormat(2, FORMAT_FIAT_NUMBERS)}</div>
                    <div className='h4 gray-50'>{this.props.mainCurrency}</div>
                  </div>

                  <div className={styles.steps}>
                    {Object.keys(this.props.cryptoPaymentSelector).map((blockchain) => {
                      return (
                        <div key={blockchain} className={blockchain === this.state.currentBlockchain ? 'active' : ''} />
                      )
                    })}
                  </div>
                </div>

                {account && ![ACCOUNT_TYPE_TREZOR].includes(paymentType) ? (
                  <div className={styles.totalDebited}>
                    <Icon type='sum' colorType='gray-70' />
                    <div>
                      <label className='capture-small gray-50'>Total debited</label>
                      <div className='h5 gray-70'>
                        {(isSeparateTransactions ? totalDebited : amount.plus(fee || 0))
                          .toFormat(config.getConfig('bitcoinDecimalLength'))
                          .toString() +
                        ' ' +
                        symbol}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={styles.walletLine}>
                    <Icon
                      type={account ? account.symbol : null}
                      colorType={account && account.meta.isTestnet ? 'gray-30' : 'blue'}
                    />
                    <div className='capture-small'>
                      <span className='gray-50'>Amount: </span>
                      <span className='gray-70'>
                        {amount.toFormat(config.getConfig('bitcoinDecimalLength')).toString() + ' ' + symbol}
                      </span>
                    </div>
                  </div>
                )}
              </>
            ) : null}
            {PAYROLL_STEPS.payrollCompleted === this.props.currentStep || isPayedBlockcain
              ? this.renderSuccessMessage()
              : null}
            {PAYROLL_STEPS.payrollErrored === this.props.currentStep && this.renderFailMessage()}
            {[PAYROLL_STEPS.payrollRun, PAYROLL_STEPS.transactionsSend, PAYROLL_STEPS.transactionsTie].includes(
              this.props.currentStep,
            ) && (
              <>
                <div className={styles.preloader}>
                  <LoadingState loadingState={LOADING_STATE_LOADING} />
                  <div className='gray-50'>
                    {[ACCOUNT_TYPE_TREZOR].includes(paymentType) ? (
                      <>
                        Confirm your payment
                        <br />
                        in Trezor Connect.
                      </>
                    ) : (
                      <>
                        Your payment
                        <br />
                        is being confirmed.
                      </>
                    )}
                  </div>
                  <div className='capture gray-50'>Please do not close this form</div>
                  {isSeparateTransactions && separateTransactionsAll > 1 ? (
                    <div className='capture gray-50'>
                      {separateTransactionsPaid + separateTransactionsFailed} of {separateTransactionsAll} processed
                    </div>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </Form>
      )
    }

    return (
      <Form name='confirm-payroll-form' onSubmit={handlePreSubmit}>
        <div className={styles.containerConfirm + ' confirm-requestpay-container'}>
          <div className={styles.headerConfirm}>
            <button type='button' onClick={this.handleGoBack}>
              <Icon type='arrow-left' />
            </button>
            <div className='confirm-title h5 gray-70'>{`Confirm ${count} payment${count > 1 ? 's' : ''}:`}</div>
          </div>
          <div className={styles.steps}>
            {Object.keys(this.props.cryptoPaymentSelector).map((blockchain) => {
              return <div key={blockchain} className={blockchain === this.state.currentBlockchain ? 'active' : ''} />
            })}
          </div>

          <Scrollbars autoHeight autoHeightMin={410} autoHide className={styles.scrollContainer + ' Swipe_scrollContainer'}>
            <div className={styles.confirmDetailsContainer}>
              {!isPayedBlockcain && (
                <>
                  <div className={styles.walletLine}>
                    <Icon
                      type={account ? account.symbol : null}
                      colorType={account && account.meta.isTestnet ? 'gray-30' : 'blue'}
                    />
                    <div className='capture-small'>
                      <span className='gray-50'>Amount: </span>
                      <span className='gray-70'>
                        {amount.toFormat(config.getConfig('bitcoinDecimalLength')).toString() + ' ' + symbol}
                      </span>
                    </div>
                  </div>

                  {account && (
                    <div className={styles.confirmWalletLine}>
                      <Icon type='wallet' colorType='gray-70' />
                      <div className={styles.walletName + ' capture-small gray-70'}>
                        <div>{account.name}</div>
                        <div>
                          (
                          {account.formattedBalance.toFixed(config.getConfig('bitcoinDecimalLength')) +
                          ' ' +
                          account.symbol}
                          )
                        </div>
                      </div>
                    </div>
                  )}

                  {account && fees && (
                    <div className={styles.walletLine + ' ' + styles.accounts}>
                      <div className={styles.feeRelativeContainer}>
                        {isSeparateTransactions && separateTransactionsAll > 1 ? (
                          <Label
                            className={styles.billIcon}
                            isOpen={!this.props.isShownFeeWarning}
                            withButton={!this.props.isShownFeeWarning}
                            onButtonClick={this.props.hideFeeWarning}
                            label='This fee amount is approximate. Real fee will be recalculated and may vary from the amount shown.'
                          />
                        ) : (
                          <div className={styles.billIcon} />
                        )}
                        <CustomSelect
                          key={account.id}
                          className={styles.accountWrapper}
                          isSearchable={false}
                          styles={selectStyles}
                          onChange={this.handleChangeFee}
                          defaultValue={{
                            value: multiplier,
                            label: (fees.find(({ value }) => value === multiplier) || { title: '--' }).title,
                          }}
                          formatOptionLabel={(elem) => {
                            return (
                              <div className={styles.optionWrapper}>
                                <div className={styles.singleOption}>
                                  <div className={styles.fakeIcon} />
                                  <div className='capture-small gray-70'>
                                    <div>{elem.label}</div>
                                  </div>
                                </div>
                                <div className={styles.listOption} key={elem.value}>
                                  {elem.label}
                                </div>
                              </div>
                            )
                          }}
                          name='itemsType'
                          options={fees.map(({ value, title }) => {
                            return {
                              value,
                              label: title,
                            }
                          })}
                        />
                      </div>
                    </div>
                  )}

                  {account && ![ACCOUNT_TYPE_TREZOR].includes(paymentType) && (
                    <div className={styles.totalDebited}>
                      <Icon type='sum' colorType='gray-70' />
                      <div>
                        <label className='capture-small gray-50'>Total to debit</label>
                        <div className='h5 gray-70'>
                          {total.toFormat(config.getConfig('bitcoinDecimalLength')).toString() + ' ' + symbol}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

              {this.props.currentStep === null && !isPayedBlockcain && (
                <>
                  {account && ![ACCOUNT_TYPE_TREZOR].includes(paymentType) && (
                    <>
                      <InputText
                        label='Provide password'
                        className='confirm-password-input'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.password}
                        value={values.password}
                        placeholder='*******'
                        name='password'
                        type='password'
                      />
                      <div className={styles.resetPasswordButton}>
                        <button type='button' className='capture gray-50' onClick={this.handleOpenResetPasswordForm}>
                          Forgot password?
                        </button>
                      </div>

                      {errors.password && (
                        <div className={styles.errorLine}>
                          <Icon type='attention' colorType='red' />
                          <div className='capture-small red'>{errors.password}</div>
                        </div>
                      )}
                    </>
                  )}

                  <div className={styles.actionsWrapper}>
                    <Button type='submit' disabled={!enoughMoney} colorType='new-filled'>
                      {account && ![ACCOUNT_TYPE_TREZOR].includes(paymentType) ? 'Confirm' : 'Continue'}
                    </Button>
                  </div>

                  {!enoughMoney ? (
                    <div className={styles.errorLine}>
                      <Icon type='attention' colorType='red' />
                      <div className='capture-small red'>Insufficient balance</div>
                    </div>
                  ) : null}

                  {Object.keys(this.props.cryptoPaymentSelector)[this.state.currentBlockchainKey + 1] && (
                    <div className={styles.actionsWrapper}>
                      <button className='capture gray-50' onClick={this.handleNextBlockchain}>
                        Skip this payment
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </Scrollbars>
        </div>
      </Form>
    )
  }

  handleSubmit = (values) => {
    this.props.handleSubmit(values)
    this.setState({
      isSent: true,
    })
  }

  render () {
    return (
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          password: '',
        }}
        validate={this.validation}
        onSubmit={this.handleSubmit}
        render={this.renderForm}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const allRates = fixedRatesSelector(state)
  const { isShownFeeWarning } = confirmPasswordCardParamsSelector(state)
  const mainCurrency = currentCurrencySelector(state)

  return {
    allRates,
    blockchains: blockchainsSettingsSelector(state),
    accounts: accountsSelector(state),
    cryptoPaymentSelector: cryptoPaymentSelector(ownProps.type)(state),
    currentStep: payrollCurrentStepSelector(state),
    mainCurrency,
    isShownFeeWarning,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleSubmit: (values) => dispatch(runPayroll(values, ownProps.type)),
    handleResetPayrollStep: () => dispatch(updatePayrollStep(null)),
    handleupdatePayrollSettings: (type) => dispatch(updatePayrollSettings(true, type)),
    updatePayrollSettingsBlockchain: (accountId, multiplier) =>
      dispatch(
        updatePayrollSettingsParams({
          accountId,
          multiplier,
        }),
      ),
    hideFeeWarning: () => dispatch(hideFeeWarning()),
    setSidePanel: (panelName, panelProps) => dispatch(setSidePanel(panelName, panelProps)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmPasswordCard)
