import { createSelector } from 'reselect'
import { UI_DUCK } from './constants'

export const uiDuckSelector = (state) => {
  return state[UI_DUCK]
}

export const sidePanelPropsSelector = (panelName) => createSelector(
  uiDuckSelector,
  ({ sidePanels }) => sidePanels[panelName],
)

export const notificationPropsSelector = (notificationKey) => createSelector(
  uiDuckSelector,
  ({ notifications }) => notifications[notificationKey],
)

export const errorPageDataSelector = createSelector(
  uiDuckSelector,
  ({ errorPage }) => errorPage,
)

export const confirmPasswordCardParamsSelector = createSelector(
  uiDuckSelector,
  ({ ConfirmPasswordCard }) => ConfirmPasswordCard,
)

export const customLoadingStatesSelector = (param) => createSelector(
  uiDuckSelector,
  ({ customLoadingStates }) => param ? customLoadingStates[param] : customLoadingStates,
)

export const transactionFormSendingStateSelector = createSelector(
  uiDuckSelector,
  ({ sendTransactionForm }) => sendTransactionForm.transactionSendingState,
)
