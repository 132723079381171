import * as Yup from 'yup'

export const profileValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(30, 'First name can\'t be more than 30 symbols'),
  lastName: Yup.string()
    .max(30, 'Last name can\'t be more than 30 symbols'),
  email: Yup.string()
    .email()
    .required('Email name is required'),
  fromDescription: Yup.mixed()
    .test(
      'fromDescription',
      'The text is too long. Please change it to something shorter.',
      (value) => {
        if (value) {
          const valueAr = value.split('\n')
          return valueAr.length <= 4
        }
        return true
      },
    ),
})

export const passwordValidationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required('Old password is required'),
  password: Yup.string()
    .required('New password is required')
    .min(4, 'Your password should have at least 4 symbols'),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref('password', null)],
      'Confirmations of new password must be equal with new password',
    )
    .required('Confirmations of new password is required')
    .min(4, 'Your confirmations of new password should have at least 4 symbols'),
})
