export const colorWhite = '#ffffff'
export const colorGreen = '#57b8b6'
export const colorGreen2 = '#154d4d'
export const colorGreen4 = '#79c573'
export const colorGreen6 = '#45a2a0'

export const colorBlack = '#000000'
export const colorBlack3 = '#3f3f3f'

export const colorGrey2 = '#f9f9f9'
export const colorGrey3 = '#f2f2f2'
export const colorGrey5 = '#f3f3f3'
export const colorGrey8 = '#999999'
export const colorGrey12 = '#cfd2d7'
export const colorGrey13 = '#808080'
export const colorGrey14 = '#c1c1c1'
export const colorGrey15 = '#b2b8bf'
export const colorGrey16 = '#D9DBE4'

export const colorDarkGrey = '#333333'
export const colorMiddleGrey = '#999999'
export const colorLightGrey = '#d3d3d3'
export const colorYellow = '#ffbf33'
export const colorYellowDark = '#ffb00d'
export const colorYellowDark2 = '#be8000'
export const colorRed = '#ff0000'
export const colorRed2 = '#f97c7c'
export const colorBlue = '#1436E5'
export const colorBlueHover = '#506CFF'
export const colorBlueActive = '#132BA7'

export const errorsColorsMap = {
  'blue': {
    color: '#aab7f8',
    background: 'transparent',
    boxShadow: '0px 1px 6px rgba(20, 54, 229, 0.09)',
  },
  'red': {
    color: '#f97c7c',
    background: '#fffcfc',
  },
  'default': {
    color: '#f97c7c',
    background: '#fffcfc',
    boxShadow: 'none',
  },
}
