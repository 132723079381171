import {respondToVisibility} from '../../utils/general'

const CONTAINER = 'walletsContainer'
const WATCH_ELEMENT = 'PaymentsTable_mobile_pointer'

class WatcherClass {

  updateState = () => {
    const panel = document.getElementById(CONTAINER)
    if (panel) {
      const tableCheckboxes = document.querySelectorAll('.InvoicesCheckbox[data-is-checked="true"]')
      const boxesLength = (tableCheckboxes && tableCheckboxes.length) || 0
      if (boxesLength) {
        if (panel.classList.contains('hidden')) {
          panel.classList.remove('hidden')
        }
      } else {
        if (panel.dataset.isVisible !== 'false' && panel.classList.contains('hidden')) {
          panel.classList.remove('hidden')
        } else if (panel.dataset.isVisible === 'false' && !panel.classList.contains('hidden')) {
          setTimeout(() => {
            panel.classList.add('hidden')
          })
        }
      }
    }
  }

  watchThePointer = () => {
    const pointer = document.getElementById(WATCH_ELEMENT)
    respondToVisibility(pointer, (isVisible) => {
      const panel = document.getElementById(CONTAINER)
      if (!panel) {
        return null
      }

      const tableCheckboxes = document.querySelectorAll('.InvoicesCheckbox[data-is-checked="true"]')
      const checkboxLength = (tableCheckboxes && tableCheckboxes.length) || 0
      if (isVisible) {
        panel.classList.remove('hidden')
        panel.dataset.isVisible = "true"
      } else {
        if (!checkboxLength) {
          panel.classList.add('hidden')
        }
        panel.dataset.isVisible = "false"
      }
    })
  }
}

export const MobilePanelWatcher = new WatcherClass()
