import PropTypes from 'prop-types'
import React from 'react'
import { Formik, Form } from 'formik'
import InputText from '../../components/InputText'
import { Button } from '../../components/Button'
import { LOADING_STATE_LOADED, LOADING_STATE_LOADING } from '../../constants'
import Icon from '../../components/Icon/Icon'
import styles from './Login.module.scss'
import { isResponseValid } from '../../utils/general'
import { AUTH_FLOW_RESET_PASSWORD } from '../../ducks/auth/constants'


const CreatePasswordStep = ({ auth, renderSignUpMessage, handleResetLogin, signUp, handleUpdateResetPassword }) => {

  const validation = (values) => {
    const errors = {}

    if (!values.password) {
      errors.password = 'Password is required'
    } else if (values.password.length < 4) {
      errors.password = 'Your password should have at least 4 symbols'
    } else if (values.password !== values.repeatPassword) {
      errors.repeatPassword = 'Sorry but your passwords do not match'
    }

    return errors
  }

  const handleSubmit = (values) => {
    if (auth.authFlow === AUTH_FLOW_RESET_PASSWORD) {
      handleUpdateResetPassword({ ...values })
    } else {
      signUp({ ...values, email: auth.email, nickname: auth.email })
    }
  }

  const isSuccess = isResponseValid(auth.result)

  const render = (formProps) => {
    const {
      handleSubmit,
      handleChange,
      handleBlur,
      submitForm,
      values,
      touched,
      errors,
    } = formProps

    if (auth.loadingState === LOADING_STATE_LOADED && auth.result) {
      return (
        <div className='successMessageContainer'>
          {renderSignUpMessage(auth, values)}
          {isSuccess && <div className={styles.buttonsWrapper}>
            <Button
              colorType='login'
              type='button'
              handleClick={handleResetLogin}
              data-selenium-id='signup'
            >Sign In</Button>
          </div>}
        </div>
      )
    }

    return (
      <Form
        name='SignUp_form'
        onSubmit={handleSubmit}
        data-selenium-id='authPage'
      >
        <div className={styles.fieldsWrapper}>
          <div className={styles.title + ' gray'}>
            {auth.authFlow === AUTH_FLOW_RESET_PASSWORD ? 'Create a new password' : 'Create a new account'}
          </div>
          <div className={styles.inputLabel + ' gray-70'}>Create secure password for your account</div>
          <div className={styles.passwords}>
            <InputText
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password && !!errors.password}
              value={values.password}
              placeholder='Password'
              name='password'
              type='password'
              data-selenium-id='password'
            />
            <InputText
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.repeatPassword && !!errors.repeatPassword}
              value={values.repeatPassword}
              placeholder='Confirm password'
              name='repeatPassword'
              type='password'
              data-selenium-id='confirmpassword'
            />
          </div>
          {errors.password || errors.repeatPassword ? (
            <div className={styles.passwordsErrors}>
              <Icon type='attention' colorType='red' />
              <span className='capture red'>{errors.password || errors.repeatPassword}</span>
            </div>
          ) : null}
          {auth && auth.error && (
            <div className={styles.passwordsErrors}>
              <Icon type='attention' colorType='red' />
              <span className='capture red'>{renderSignUpMessage(auth, values)}</span>
            </div>
          )}

          <div className={styles.passwordButtonsWrapper}>
            <Button
              id='createPasswordPageActionButton'
              type='button'
              colorType='login'
              handleClick={submitForm}
              data-selenium-id='signup'
            >
              {(auth.loadingState === LOADING_STATE_LOADING)
                ? (
                  <div className='messageContainer'>
                    <div className={styles.loader} />
                  </div>
                )
                : auth.authFlow === AUTH_FLOW_RESET_PASSWORD ? 'Create a new password' : 'Create PaymentX account'}
            </Button>
          </div>
          <div className={styles.additionalActions}>
            <button className={styles.goBack} type='button' onClick={handleResetLogin}>
              <Icon type='arrow-left' colorType='blue' />
              <span className='capture blue-bk'>Edit email address</span>
            </button>
          </div>
        </div>
      </Form>
    )
  }

  return (
    <Formik
      initialValues={{
        password: '',
        repeatPassword: '',
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validate={validation}
      onSubmit={handleSubmit}
      render={render}
    />
  )
}

CreatePasswordStep.propTypes = {
  renderSignUpMessage: PropTypes.func,
  signUp: PropTypes.func,
  handleResetLogin: PropTypes.func,
  handleUpdateResetPassword: PropTypes.func,
  auth: PropTypes.shape({
    loadingState: PropTypes.string,
    error: PropTypes.string,
    result: PropTypes.number,
    email: PropTypes.string,
    authFlow: PropTypes.string,
  }),
}

export default CreatePasswordStep
