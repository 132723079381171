//@flow
import PropTypes from 'prop-types'
import cn from 'classnames'
import React from 'react'
import BigNumber from 'bignumber.js'
import { connect } from 'react-redux'
import { LOADING_STATE_LOADED } from '../../constants'
import { accountByIdSelector, totalFiatBalanceSelector } from '../../ducks/accounts/selectors'
import { currentCurrencySelector } from '../../ducks/profile/selectors'
import { setSidePanel } from '../../ducks/ui/actions'
import { ACCOUNT_TYPE_TREZOR, FORMAT_FIAT_NUMBERS } from '../../ducks/accounts/constants'
import Icon from '../Icon/Icon'
import styles from './Wallet.module.scss'

import type { AnyWallet } from '../../ducks/accounts/models'
import LoadingState from '../LoadingState'
import TrezorConnectButton from "../TrezorConnectButton/TrezorConnectButton"

type Props = {
  handleGetTrezorWalletInfo: (accountId: string) => () => void,
  handleOpenAccountDepositPanel: (accountId: string) => () => void,
  accountId: string,
  account: AnyWallet,
  primaryAsset: string,
  balanceFiat: BigNumber,
  setSidePanel: (panelName: string, panelProps: any) => void;
}

class Wallet extends React.PureComponent<Props> {
  static propTypes = {
    handleGetTrezorWalletInfo: PropTypes.func,
    handleOpenAccountDepositPanel: PropTypes.func,
    accountId: PropTypes.string,
    account: PropTypes.object,
    primaryAsset: PropTypes.string,
    balanceFiat: PropTypes.instanceOf(BigNumber),
    setSidePanel: PropTypes.func,
  }

  handleOpenAccountPanel = () => {
    this.props.setSidePanel('accountSidePanel', { isOpen: true, accountId: this.props.account.id })
  }

  renderBalance () {
    const { account, primaryAsset, balanceFiat } = this.props
    const isStateLoaded = account.balanceLoadingState === LOADING_STATE_LOADED
    if ([ACCOUNT_TYPE_TREZOR].includes(account.type) && account.balanceLoadingState !== LOADING_STATE_LOADED) {
      return (
        <div className={styles.connectButtonWrapper}>
          <TrezorConnectButton accountId={account.id} />
        </div>
      )
    }
    return (
      <>
        <div className={styles.summ}>{isStateLoaded
          ? account.formattedBalance.toString()
          : <LoadingState
            loadingState={account.balanceLoadingState}
            loaderClassName={styles.accountBalanceLoader} />} {account.symbol}</div>
        {isStateLoaded &&
        <div
          className={styles.convert}>{balanceFiat && balanceFiat.toFormat(2, BigNumber.ROUND_UP, FORMAT_FIAT_NUMBERS)} {primaryAsset}</div>}
      </>
    )
  }

  render () {
    const { account } = this.props

    return (
      <div className={styles.wrapper} onClick={this.handleOpenAccountPanel}>
        <div className={cn(styles.currencyContainer, { 'isTestnet': account.meta.isTestnet })}>
          <Icon type={account.symbol} colorType={account.meta.isTestnet ? 'gray-30' : 'blue'} />
        </div>
        <div className={styles.information}>
          <div className={styles.name}>{account.name}</div>
          {this.renderBalance()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { accountBalances } = totalFiatBalanceSelector(state)
  return {
    account: accountByIdSelector(ownProps.accountId)(state),
    primaryAsset: currentCurrencySelector(state),
    balanceFiat: accountBalances[ownProps.accountId],
  }
}

const mapDispatchToProps = (dispatch) => ({
  setSidePanel: (panelName, panelProps) => dispatch(setSidePanel(panelName, panelProps)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Wallet)
