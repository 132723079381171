import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import {
  Payments,
  Login,
  Logout,
  ErrorPage,
} from '../../pages'
import AuthRoute from '../../components/AuthRoute'
import InitProvider from './InitProvider'

const App = () => {
  return (
    <InitProvider>
      <Switch>
        <AuthRoute path='/payments' component={Payments} redirectTo='/auth' />
        <Route path='/auth' component={Login} />
        <Route path='/logout' component={Logout} />
        <Route path='/error' component={ErrorPage} />
        <Redirect from='/' to='/payments' />
      </Switch>
    </InitProvider>
  )
}

export default App
