import PropTypes from 'prop-types'
import get from 'lodash.get'
import { connect } from 'react-redux'
import React, { useState } from 'react'
import styles from './AddPaymentRequestSidePanel.module.scss'
import Icon from '../../components/Icon/Icon'
import { Form, Formik } from 'formik'
import Button from '../../components/Button/Button'
import { ratesSymbolMappedListSelector } from '../../ducks/rates/selectors'
import { LOADING_STATE_ERROR, LOADING_STATE_LOADED, LOADING_STATE_LOADING } from '../../constants'
import { sendReceiveMessageToEmail } from '../../ducks/accounts/thunks'
import { AccountModel } from '../../models/Accounts/AccountModel'
import { currentCurrencySelector, profileInfoSelector } from '../../ducks/profile/selectors'
import { accountByIdSelector, accountsSelector } from '../../ducks/accounts/selectors'
import FirstStep from './FirstStep'
import { BLOCKCHAIN_BITCOIN } from '../../ducks/bitcoin/constants'
import SecondStep from './SecondStep'
import { isTestnetAccount } from '../../ducks/accounts/utils'
import { validation } from './validate'
import { ACCOUNT_TYPE_TREZOR } from '../../ducks/accounts/constants'
import { getMainSymbolForBlockchain } from '../../utils/general'

export const FIRST_STEP = 1
export const SECOND_STEP = 2

function AddPaymentRequestSidePanel (props) {
  const {
    onClose,
    handleSendReceiveMessageToEmail,
    rates,
    currentCurrency,
    profile,
    accountId,
    accounts,
    account,
  } = props
  const [loadingState, setLoadingState] = useState(null)
  const defaultAccount = accounts.find((account) => account.blockchain === BLOCKCHAIN_BITCOIN && account.defaultAccount)
  const firstAccount = accounts.find((account) => account.blockchain === BLOCKCHAIN_BITCOIN)
  const [trezorAccounts, setTrezorAccounts] = useState({})

  const handleSubmit = (values) => {
    setLoadingState(LOADING_STATE_LOADING)
    const accountsList = [...accounts]
    const trezorAccount = get(trezorAccounts, values.blockchain, null)

    accountsList.push({
      id: get(trezorAccount, 'address', get(trezorAccount, 'blockchain', values.blockchain)),
      address: get(trezorAccount, 'address', null),
      blockchain: get(trezorAccount, 'blockchain', values.blockchain),
    })

    const account = accountsList.find((account) => account.blockchain === values.blockchain && account.id === values.accountId)
    const address = values.isNewAddress ? values.accountId : account.address
    handleSendReceiveMessageToEmail(values, address)
      .then(() => {
        setLoadingState(LOADING_STATE_LOADED)
      })
      .catch(() => {
        setLoadingState(LOADING_STATE_ERROR)
      })
  }

  const handleSetTrezorWallet = (walletData) => {
    if (!walletData.blockchain) {
      return null
    }
    const oldData = get(this, `state.trezorAccounts.${walletData.blockchain}`, {})
    const wallet = {
      ...oldData,
      ...walletData,
    }
    setTrezorAccounts({
      ...trezorAccounts,
      [walletData.blockchain]: wallet,
    })
    return wallet
  }


  const renderForm = (formikProps) => {
    const {
      handleSubmit,
      values,
    } = formikProps

    const trezorAccount = get(trezorAccounts, values.blockchain, null)
    const accountsList = [
      {
        id: get(trezorAccount, 'address', get(trezorAccount, 'blockchain', values.blockchain)),
        type: ACCOUNT_TYPE_TREZOR,
        name: 'Trezor wallet',
        address: get(trezorAccount, 'address', null),
        isTestnet: isTestnetAccount(trezorAccount || { blockchain: values.blockchain }),
        blockchain: get(trezorAccount, 'blockchain', values.blockchain),
        defaultAccount: false,
        loadingState: get(trezorAccount, 'loadingState', null),
        symbol: getMainSymbolForBlockchain(get(trezorAccount, 'blockchain', values.blockchain)),
      },
      ...accounts,
    ]

    if ([LOADING_STATE_LOADED, LOADING_STATE_ERROR].includes(loadingState)) {
      return (
        <div className={styles.messageContent}>
          {loadingState === LOADING_STATE_LOADED
            ? <>
              <Icon type='checkmark-circle' colorType='green' />
              <div className='h5 gray-30'>Payment request successfully sent</div>
            </>
            : <>
              <Icon type='attention' colorType='red' />
              <div className='h5 gray-30'>Failed</div>
              <div className='capture gray-30'>Something went wrong.<br />
                Please try again. Contact support if the issue still persists.
              </div>
            </>
          }
          <div className={styles.messageActions}>
            <Button
              type='button'
              onClick={loadingState === LOADING_STATE_LOADED ? onClose : setLoadingState}
              colorType='new-border'>OK</Button>
          </div>
        </div>
      )
    }

    return (
      <Form name='send-reseive-message-to-email-form' onSubmit={handleSubmit}>
        <div className={styles.addPaymentRequestFormContainer}>
          {values.step === FIRST_STEP && (
            <FirstStep
              accounts={accountsList}
              rates={rates}
              formikProps={formikProps}
              handleSetTrezorWallet={handleSetTrezorWallet}
            />
          )}
          {values.step === SECOND_STEP && (
            <SecondStep
              accounts={accountsList}
              rates={rates}
              formikProps={formikProps}
              loadingState={loadingState}
            />
          )}
        </div>
      </Form>
    )
  }

  return (
    <div className={styles.messageContainer}>
      <div className={styles.messageHeader}>
        <button
          onClick={onClose}
        ><Icon type='close' scale='1.5' colorType='gray-50' />
        </button>
        <div className='h4 gray-70'>New payment request</div>
      </div>
      <Formik
        validateOnChange={false}
        validateOnBlur
        validate={validation}
        initialValues={{
          email: profile.email,
          fiatAmount: '',
          cryptoAmount: '',
          currency: currentCurrency,
          step: FIRST_STEP,
          blockchain: get(account, 'blockchain', null) || get(defaultAccount, 'blockchain', null) || get(firstAccount, 'blockchain', null) || BLOCKCHAIN_BITCOIN,
          accountId: accountId || get(defaultAccount, 'id', null) || get(firstAccount, 'id', null) || '',
          isNewAddress: false,
          message: '',
        }}
        onSubmit={handleSubmit}
        render={renderForm}
      />
    </div>
  )
}

AddPaymentRequestSidePanel.propTypes = {
  onClose: PropTypes.func,
  handleSendEmail: PropTypes.func,
  isSent: PropTypes.bool,
  rates: PropTypes.any,
  handleSendReceiveMessageToEmail: PropTypes.func,
  account: PropTypes.instanceOf(AccountModel),
  currentCurrency: PropTypes.string,
  profile: PropTypes.object,
  accountId: PropTypes.string,
  accounts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    isTestnet: PropTypes.bool,
    blockchain: PropTypes.string,
    defaultAccount: PropTypes.bool,
    symbol: PropTypes.string,
  })),
}

function mapStateToProps (state, props) {
  const accounts = accountsSelector(state)
    .map((account) => {
      return {
        id: account.id,
        type: account.type,
        name: account.name,
        address: account.address,
        isTestnet: isTestnetAccount(account),
        blockchain: account.blockchain,
        defaultAccount: account.defaultAccount,
        symbol: account.symbol || getMainSymbolForBlockchain(account.blockchain),
      }
    })

  return {
    account: accountByIdSelector(props.accountId)(state),
    profile: profileInfoSelector(state),
    rates: ratesSymbolMappedListSelector(state),
    currentCurrency: currentCurrencySelector(state),
    accounts,
  }
}

function mapDispatchToProps (dispatch) {
  return {
    handleSendReceiveMessageToEmail: (values, account) => dispatch(sendReceiveMessageToEmail(values, account)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentRequestSidePanel)

