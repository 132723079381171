import { connect } from 'react-redux'
import React from 'react'
import PropTypes from 'prop-types'
import config from '../../config/getConfig'
import BigNumber from 'bignumber.js'
import { currentCurrencySelector } from '../../ducks/profile/selectors'
import AccountTransactionsList from '../../components/AccountTransactionsList'
import Receive from './Receive'
import { BITCOIN_ACCOUNT_KEY_TESTNET } from '../../ducks/bitcoin/constants'
import { ACCOUNT_TYPE_COLD, ACCOUNT_TYPE_HOT, ACCOUNT_TYPE_TREZOR } from '../../ducks/accounts/constants'
import { accountByIdSelector, totalFiatBalanceSelector } from '../../ducks/accounts/selectors'
import SettingsForm from './SettingsForm'
import styles from './AccountViewCard.module.scss'
import {
  TAB_BACKUP,
  TAB_CHANGE_PASSWORD,
  TAB_RECEIVE,
  TAB_RECOVER,
  TAB_SEND,
  TAB_SETTINGS,
  TAB_TRANSACTIONS,
  TAB_UNLINK,
} from './constants'

import type { AnyWallet } from '../../ducks/accounts/models'
import Icon from '../../components/Icon/Icon'
import { Scrollbars } from 'react-custom-scrollbars'
import UnlinkForm from './UnlinkForm'
import BigPopup from '../../components/BigPopup'
import RecoverForm from './RecoverForm'
import BackupForm from './BackupForm'
import ChangePasswordForm from './ChangePasswordForm'
import SendFormResolver from './SendFormResolver'

type Props = {
  accountId: null | string,
  onClose: () => void,
  account: AnyWallet,
  balanceFiat: BigNumber,
  primaryAsset: string,
  removeAccountAndClose: () => void,
}

type State = {
  activeTab: string,
  settingsActive: boolean,
  showTransactionId: null | string,
}

class AccountViewCardComponent extends React.PureComponent<Props, State> {

  static propTypes = {
    removeAccountAndClose: PropTypes.func,
    onClose: PropTypes.func,
    account: PropTypes.object,
    balanceFiat: PropTypes.instanceOf(BigNumber),
    primaryAsset: PropTypes.string,
  }

  constructor (props) {
    super(props)
    this.state = {
      activeTab: TAB_TRANSACTIONS,
      settingsActive: false,
      showTransactionId: null,
    }
  }

  toggleSettings = (isActive) => () => {
    this.setState({
      settingsActive: isActive,
    })
  }

  toggleTab = (tabName) => () => {
    this.setState({
      activeTab: tabName,
    })
  }

  handleUnlink = () => {
    this.props.onClose()
    // timeout while animation
    setTimeout(this.props.removeAccountAndClose, 700)
  }

  renderTabs = () => {
    const activeTab = this.state.activeTab
    switch (activeTab) {
      case TAB_TRANSACTIONS:
        return <AccountTransactionsList selectTransactionId={this.selectTransactionId} account={this.props.account} />
      case TAB_RECEIVE:
        return <Receive account={this.props.account} />
      case TAB_SEND:
        return <SendFormResolver
          account={this.props.account}
          handleChangeTab={this.toggleTab}
        />
      case TAB_SETTINGS:
      case TAB_UNLINK:
      case TAB_RECOVER:
      case TAB_BACKUP:
      case TAB_CHANGE_PASSWORD:
        return <SettingsForm account={this.props.account} handleChangeTab={this.toggleTab} />
      default:
        return null
    }
  }

  selectTransactionId = (transactionId = null) => {
    this.setState({
      showTransactionId: transactionId,
    })
  }

  renderSidePanel = () => {
    const { account, primaryAsset, balanceFiat } = this.props
    const { activeTab } = this.state
    const isTestnetAccount = !!(account && account.network === BITCOIN_ACCOUNT_KEY_TESTNET)
    const isColdWallet = account.type === ACCOUNT_TYPE_COLD
    const isHotWallet = [ACCOUNT_TYPE_HOT, ACCOUNT_TYPE_TREZOR].includes(account.type)

    return (
      <div className={styles.sidePanelContent}>
        <div className={styles.mainData}>
          <div className={styles.walletType}>
            <Icon
              type={account.symbol}
              colorType={account.meta.isTestnet ? 'gray-30' : 'blue'}
            />
            <span className='capture gray-70'>{account.name}</span>
          </div>
          <div className={styles.walletBalance}>
            <span
              className='h3 gray-70'
            >{account.formattedBalance.toFixed(config.getConfig('bitcoinDecimalLength'))}</span>
            <span className='h4 gray-70'>{account.symbol}</span>
          </div>
          <div className={styles.walletAmount + ' h5 gray-30'}>
            {(balanceFiat && balanceFiat.toString()) || '--'} {primaryAsset}
          </div>
          <div className={styles.walletLabels}>
            {isColdWallet && <span className={styles.walletLabel + ' capture gray-30'}>Cold wallet</span>}
            {isTestnetAccount && <span className={styles.walletLabel + ' capture gray-30'}>Testnet</span>}
          </div>
        </div>
        <div className={styles.separator} />
        <div className={styles.tabs + ' capture gray-70'}>
          <button className={styles.tab} onClick={this.toggleTab(TAB_TRANSACTIONS)}>
            <Icon type='time' colorType={activeTab === TAB_TRANSACTIONS ? 'blue' : 'gray-70'} />
            <span>Last operation</span>
          </button>
          {isHotWallet && <button className={styles.tab} onClick={this.toggleTab(TAB_SEND)}>
            <Icon type='arrow-to-top' colorType={activeTab === TAB_SEND ? 'blue' : 'gray-70'} />
            <span>Send</span>
          </button>}
          <button className={styles.tab} onClick={this.toggleTab(TAB_RECEIVE)}>
            <Icon type='collapse-down' colorType={activeTab === TAB_RECEIVE ? 'blue' : 'gray-70'} />
            <span>Receive</span>
          </button>
          <button className={styles.tab} onClick={this.toggleTab(TAB_SETTINGS)}>
            <Icon type='settings' colorType={activeTab === TAB_SETTINGS ? 'blue' : 'gray-70'} />
            <span>Settings</span>
          </button>
        </div>
      </div>
    )
  }

  render () {
    if (!this.props.accountId) {
      return null
    }

    const { account, onClose } = this.props

    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <button className={styles.closeButton} onClick={onClose}>
            <Icon type='close' colorType='gray-50' scale='1.5' />
          </button>
          <div className='title h3 gray-70'>{account.name}</div>
        </div>
        <div className={styles.panel}>
          <div className={styles.sideContent}>
            <Scrollbars style={{ width: '265px', height: 'calc(100vh - 80px)' }}>
              {this.renderSidePanel()}
            </Scrollbars>
          </div>
          <div className={styles.mainContent}>
            <Scrollbars style={{ height: 'calc(100vh - 80px)' }}>
              {this.renderTabs()}
            </Scrollbars>
          </div>
        </div>
        {this.state.activeTab === TAB_UNLINK &&
          <BigPopup onClose={this.toggleTab(TAB_SETTINGS)} smallMode>
            <UnlinkForm
              account={this.props.account}
              onUnlinkFromState={this.handleUnlink}
            />
          </BigPopup>
        }
        {this.state.activeTab === TAB_RECOVER &&
          <BigPopup onClose={this.toggleTab(TAB_SETTINGS)} smallMode>
            <RecoverForm account={this.props.account} />
          </BigPopup>
        }
        {this.state.activeTab === TAB_BACKUP &&
          <BigPopup onClose={this.toggleTab(TAB_SETTINGS)} smallMode>
            <BackupForm account={this.props.account} />
          </BigPopup>
        }
        {this.state.activeTab === TAB_CHANGE_PASSWORD &&
          <BigPopup onClose={this.toggleTab(TAB_SETTINGS)} smallMode>
            <ChangePasswordForm account={this.props.account} />
          </BigPopup>
        }
      </div>
    )
  }
}

function mapStateToProps (state, ownProps) {
  const { accountBalances } = totalFiatBalanceSelector(state)
  return {
    primaryAsset: currentCurrencySelector(state),
    account: accountByIdSelector(ownProps.accountId)(state),
    balanceFiat: accountBalances[ownProps.accountId],
  }
}

export default connect(mapStateToProps, null)(AccountViewCardComponent)
