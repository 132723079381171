import { BITCOIN_SYMBOL, BLOCKCHAIN_BITCOIN, BLOCKCHAIN_BITCOIN_TESTNET } from '../ducks/bitcoin/constants'
import { STATUS_200, STATUS_201, STATUS_204 } from '../constants'
import { ETHEREUM_SYMBOL, BLOCKCHAIN_ETHEREUM, BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY } from '../ducks/ethereum/constants'

export const shuffle = (array: Array<any>) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    // $flow-disable-line
    [array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

export const copy = (text: string) => {
  if (window.clipboardData && window.clipboardData.setData) {
    // IE specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData('Text', text)
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea')
    textarea.textContent = text
    textarea.style.position = 'fixed' // Prevent scrolling to bottom of page in MS Edge.
    // $flow-disable-line
    document.body.appendChild(textarea)
    textarea.select()
    try {
      return document.execCommand('copy') // Security exception may be thrown by some browsers.
    } catch (ex) {
      return false
    } finally {
      // $flow-disable-line
      document.body.removeChild(textarea)
    }
  }
}

export const downloadCustomFile = (data, filename) => {
  var tempLink = document.createElement('a')
  tempLink.style.display = 'none'
  tempLink.href = data
  tempLink.setAttribute('download', filename)

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank')
  }

  document.body.appendChild(tempLink)
  tempLink.click()
  document.body.removeChild(tempLink)
}

export const downloadCsvFile = (data, filename) => {
  const exportedFileName = filename + '.csv' || 'export.csv'
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' })
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, exportedFileName)
  } else {
    const link = document.createElement("a")
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob)
      link.setAttribute("href", url)
      link.setAttribute("download", exportedFileName)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

export const getServiceNameByBlockchain = (blockchain: string) => {
  const blockchainName = typeof blockchain === 'string' ? blockchain.toLowerCase() : ''
  switch (blockchainName) {
    case BLOCKCHAIN_BITCOIN:
      return 'bitcoinMiddlewareService'
    case BLOCKCHAIN_BITCOIN_TESTNET:
      return 'bitcoinTestnetMiddlewareService'
    default:
      throw new Error('Unknown blockchain value: ' + blockchain)
  }
}

export const getMainSymbolForBlockchain = (blockchain: string) => {
  switch (blockchain) {
    case BLOCKCHAIN_BITCOIN:
    case BLOCKCHAIN_BITCOIN_TESTNET:
      return BITCOIN_SYMBOL
    case BLOCKCHAIN_ETHEREUM:
    case BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY:
      return ETHEREUM_SYMBOL
    default:
      return null
  }
}

export const isResponseValid = (status: number | string) => {
  return [STATUS_200, STATUS_201, STATUS_204].includes(parseInt(status))
}

export const truncate = (fullStr: string, strLen: number, separator: string) => {
  if (fullStr.length <= strLen) return fullStr

  separator = separator || '...'

  var sepLen = separator.length,
    charsToShow = strLen - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2)

  return fullStr.substr(0, frontChars).trim() +
    separator +
    fullStr.substr(fullStr.length - backChars).trim()
}

export const doWithTimeout = (func, count = 5, timeout = 1000) => {
  if (count <= 0) {
    return
  }
  setTimeout(async () => {
    // eslint-disable-next-line
    console.log('%c try ' + count, 'background: green; color: #fff')
    try {
      await func()
    } catch (e) {
      //eslint-disable-next-line
      console.log(`%c retry after ${timeout / 1000}s`, 'background: red; color: #fff')
      await doWithTimeout(func, count - 1, timeout)
    }
  }, timeout)
}

export const isDeviceWidth = (widthMin: number, widthMax: number) => {
  const windowWidth = window.innerWidth > 0 ? window.innerWidth : window.screen.width
  if (widthMin && widthMin < windowWidth) {
    return false
  }
  if (widthMax && widthMax >= windowWidth) {
    return false
  }

  return true
}

export const respondToVisibility = (element, callback) => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      callback(entry.intersectionRatio > 0)
    })
  })

  observer.observe(element)
}
