// @flow
import * as Yup from 'yup'
import React from 'react'
import { connect } from 'react-redux'
import bip39 from 'bip39'
import CryptoJS from 'crypto-js'
import { AccountModel } from '../../models/Accounts/AccountModel'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import InputText from '../../components/InputText'
import { Button } from '../../components/Button'
import type { HotWallet } from '../../ducks/accounts/models'
import { getAddressFromMnemonic } from '../../ducks/accounts/utils'
import { updateAccount, addAccount } from '../../ducks/accounts/thunks'
import { accountLoadingStateSelector } from '../../ducks/accounts/selectors'
import { ACCOUNT_TYPE_HOT } from '../../ducks/accounts/constants'
import { profileInfoSelector } from '../../ducks/profile/selectors'
import { LOADING_STATE_LOADED, LOADING_STATE_ERROR, LOADING_STATE_LOADING } from '../../constants'
import globalStyles from './styles.module.scss'
import Icon from '../../components/Icon/Icon'
import styles from './RecoverForm.module.scss'
import LoadingState from '../../components/LoadingState'


type Props = {
  account: HotWallet,
  updateAccount: (account: AccountModel) => void,
  profile: Object,
  loadingState: string,
  addAccount: (accountData: any, mnemonic: string, passPhrase: string) => void,
  onClose?: () => void,
}

type State = {
  isSent: boolean,
  accountId: null | string,
}

type FormValues = {
  password: string,
  mnemonic: string,
}

class RecoverForm extends React.PureComponent<Props, State> {
  static propTypes = {
    account: PropTypes.object,
    updateAccount: PropTypes.func,
    profile: PropTypes.object,
    loadingState: PropTypes.string,
    addAccount: PropTypes.func,
  }

  constructor (props) {
    super(props)
    this.state = {
      isSent: false,
      accountId: null,
    }
  }

  validationSchema = Yup.object().shape({
    mnemonic: Yup.mixed()
      .test(
        'mnemonic',
        'Recovery phrase is required',
        (value = '') => {
          return !!value
        })
      .test(
        'mnemonic',
        'Incorrect recovery phrase or wallet with this recovery phrase does not exist',
        (value = '') => bip39.validateMnemonic(value.toLowerCase().trim()),
      )
      .test(
        'mnemonic',
        'Incorrect recovery phrase or wallet with this recovery phrase does not exist',
        (value = '') => {
          if (this.props.account.address) {
            return this.props.account.address === getAddressFromMnemonic(this.props.account, value.toLowerCase().trim())
          }
          return true
        }),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Minimum 8 symbols'),
    confirmPassword: Yup.mixed()
      .required('Confirm of password is required')
      .oneOf(
        [Yup.ref('password', null)],
        'Confirm of password must be equal with new password',
      )
      .test('confirmPassword', 'Minimum 8 symbols', (value) => value && value.length >= 8),
  })

  render () {

    const handleSubmit = async (values: FormValues) => {
      let accountId = null
      if (!this.props.account.address) {
        accountId = await this.props.addAccount(this.props.account, values.mnemonic, values.password)
      } else {
        this.props.updateAccount(new AccountModel({
          ...this.props.account,
          type: ACCOUNT_TYPE_HOT,
          hash: CryptoJS.MD5(this.props.profile.id).toString(),
          encryptedKey: CryptoJS.AES.encrypt(values.mnemonic, values.password).toString(),
        }))
      }
      this.setState({
        isSent: true,
        accountId,
      })
    }

    return (
      <Formik
        validateOnChange={false}
        initialValues={{
          password: '',
          confirmPassword: '',
          mnemonic: '',
        }}
        validationSchema={this.validationSchema}
        onSubmit={handleSubmit}
      >
        {(formProps) => {
          const {
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue,
          } = formProps

          return (
            <div className={globalStyles.messageContainer}>
              <div className={globalStyles.messageHeader}>
                <button onClick={this.props.onClose}><Icon scale='1.5' type='close' colorType='gray-50' /></button>
                <div className='h4 gray-70'>Recover wallet from backup</div>
              </div>
              {!this.state.isSent && (
                <div className={styles.content}>
                  <Form className={styles.form} name='recoverForm' onSubmit={handleSubmit}>
                    <div className={styles.title + ' capture gray-70'}>
                      Please type 12 words of recovery phrase for this<br />wallet. Use space to separate words
                    </div>
                    <div className={styles.input}>
                      <InputText
                        name='mnemonic'
                        textarea
                        onChange={handleChange}
                        onBlur={(e) => {
                          const trimmed = (e.target.value || '').toLowerCase().trim()
                          setFieldValue('mnemonic', trimmed)
                        }}
                        error={!!errors.mnemonic && touched.mnemonic}
                        value={values.mnemonic}
                        errorText={errors.mnemonic}
                      />
                    </div>
                    <div className={styles.input}>
                      <InputText
                        name='password'
                        type='password'
                        placeholder='Enter wallet password'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.password && touched.password}
                        value={values.password}
                      />
                    </div>
                    <div className={styles.input}>
                      <InputText
                        name='confirmPassword'
                        type='password'
                        placeholder='Enter password confimration'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.confirmPassword && touched.confirmPassword}
                        value={values.confirmPassword}
                      />
                    </div>

                    <div className={styles.action}>
                      <Button
                        colorType='new-border'
                        disabled={!values.mnemonic && !values.password && !values.confirmPassword}
                        type='submit'
                      >Recover my wallet</Button>
                    </div>
                  </Form>
                </div>
              )}

              {this.state.isSent && this.props.loadingState === LOADING_STATE_LOADING && (
                <LoadingState loadingState='loading' />
              )}

              {this.state.isSent && this.props.loadingState === LOADING_STATE_ERROR && (
                <div className={globalStyles.messageContent}>
                  <Icon type='attention' colorType='red' />
                  <div className='h5 gray-30'>Failed</div>
                  <div className='capture gray-30'>Unable to recover wallet.<br />
                    Please try again. Contact support if the issue still persists.
                  </div>
                  <div className={globalStyles.messageActions}>
                    <Button
                      type='button'
                      onClick={this.props.onClose}
                      colorType='new-border'>CLOSE</Button>
                  </div>
                </div>
              )}

              {this.state.isSent && this.props.loadingState === LOADING_STATE_LOADED && (
                <div className={globalStyles.messageContent}>
                  <Icon type='checkmark-circle' colorType='green' />
                  <div className='h5 gray-30'>Wallet successfully recovered</div>
                  <div className='capture gray-30'>You can send payments</div>
                  <div className={globalStyles.messageActions}>
                    <Button
                      type='button'
                      onClick={this.props.onClose}
                      colorType='new-border'>CLOSE</Button>
                  </div>
                </div>
              )}
            </div>
          )
        }}
      </Formik>
    )
  }
}

function mapStateToProps (state) {
  return {
    loadingState: accountLoadingStateSelector(state),
    profile: profileInfoSelector(state),
  }
}

function mapDispatchToProps (dispatch) {
  return {
    updateAccount: (accountData) => dispatch(updateAccount(accountData)),
    addAccount: (accountData: any, mnemonic: string, passPhrase: string) => dispatch(addAccount(accountData, mnemonic, passPhrase)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecoverForm)
