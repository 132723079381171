//@flow
/*eslint-disable */
import EventEmitter from 'events'
import WS from '@adonisjs/websocket-client'
import config from '../config'
import {EVENT_INVOICE_CREATE, EVENT_INVOICE_UPDATE, EVENT_INVOICE_EDIT} from '../constants'
import type { AnyWallet } from '../ducks/accounts/models'
import Logger from './Logger'

class InsightSocketServiceClass extends EventEmitter {

  socket: WS
  isSocketConnected: boolean
  token: string | null
  initSubscriptionsArray: []
  subscriptions: { [supsriptionId: string]: any }

  static getSubscriptionKey (account: AnyWallet) {
    return `${account.blockchain}:address:${account.address}`
  }

  constructor () {
    super()
    this.isSocketConnected = false
    this.subscriptions = {}
    this.initSubscriptionsArray = []
    this.token = null
  }

  getSubscriptionKey = (account: AnyWallet) => {
    return InsightSocketServiceClass.getSubscriptionKey(account)
  }

  connect = (token?: string | null) => {
    try {
      if (token) {
        this.setToken(token)
      }
      if (!this.getToken()) {
        return
      }
      this.socket = new WS(config.urls.insightSocketService)

      this.socket.on('open', () => {
        this.setSocketConnectedStatus(true)
        this.runSubscriptionInit()
      })
      this.socket.on('error', () => {
        this.setSocketConnectedStatus(false)
        this.clearSubscriptions()
      })
      this.socket.on('close', () => {
        this.setSocketConnectedStatus(false)
        this.clearSubscriptions()
      })

      this.socket.withApiToken(this.getToken()).connect()
    } catch (e) {
      Logger.error(e)
    }
  }

  disconnect (clearSubscriptionsArray: boolean = false ) {
    try {
      if (clearSubscriptionsArray) {
        this.initSubscriptionsArray = []
      }

      Object.entries(this.subscriptions)
        .forEach(([, subscription]) => {
          if (subscription && typeof subscription.close === 'function') {
            // $flow-disable-line
            subscription.close()
          }
        })
      this.subscriptions = {}
      this.setToken(null)
      if (this.socket && this.socket._connectionState === 'open') {
        this.socket.close()
      }
    } catch (e) {
      Logger.error(e)
    }
  }

  setToken = (token: string | null) => {
    this.token = token
  }

  getToken = () => {
    return this.token
  }

  setSocketConnectedStatus (status: boolean) {
    this.isSocketConnected = status
  }

  clearSubscriptions = () => {
    this.subscriptions = {}
  }

  removeSubscription = (subscriptionKey: string) => {
    // $flow-disable-line
    this.initSubscriptionsArray = this.initSubscriptionsArray.filter(({ key }) => key !== subscriptionKey)
  }

  addSubscription = (subscriptionKey: string, initFunction: () => void) => {
    if (typeof initFunction !== 'function') {
      //eslint-disable-next-line
      console.warn('addSubscription not a function: ', typeof initFunction)
      return null
    }

    // $flow-disable-line
    this.initSubscriptionsArray.push({
      key: subscriptionKey,
      init: initFunction
    })

    if (this.isSocketConnected) {
      initFunction()
    }
  }

  runSubscriptionInit = () => {
    if (!this.initSubscriptionsArray.length) {
      return null
    }

    this.initSubscriptionsArray.forEach(({ init }) => {
      init()
    })
  }

  subscribeAccount (account: AnyWallet, txCallback: (tx: any) => Promise<any>, confirmationCallback: (data: any) => void) {
    const subscribeKey = InsightSocketServiceClass.getSubscriptionKey(account)

    if (this.subscriptions[subscribeKey]) {
      //eslint-disable-next-line
      console.warn('Account is already subscribed to WS: ', account, this.subscriptions[subscribeKey])
      return null
    }

    this.subscriptions[subscribeKey] = this.socket.subscribe(subscribeKey)
    this.subscriptions[subscribeKey].on('tx', (tx) => {
      //eslint-disable-next-line
      console.log('%c Socket Tx received', 'background: green; color: #fff', tx)
      txCallback(tx)
    })
    this.subscriptions[subscribeKey].on('confirmation', (data) => {
      //eslint-disable-next-line
      console.log('%c Socket Tx confirmation received: ', 'background: blue; color: #fff', account, data)
      confirmationCallback(data)
    })
  }

  unsubscribeAccount (account: AnyWallet) {
    const subscribeKey = InsightSocketServiceClass.getSubscriptionKey(account)
    Object.entries(this.subscriptions)
      .forEach(([key, subscription]) => {
        if (subscription && typeof subscription.close === 'function' && subscribeKey === key) {
          // $flow-disable-line
          subscription.close()
          delete this.subscriptions[subscribeKey]
        }
      })
    this.removeSubscription(subscribeKey)
  }

  subscribeProfileId (profileId: string, txCallback: (data: any) => Promise<any> => void, invoiceEditCallback: (data: any) => Promise<any> => void) {
    const subscribeKey = `events:${profileId}`
    if (this.subscriptions[subscribeKey]) {
      //eslint-disable-next-line
      console.warn('Profile is already subscribed to WS: ', profileId, this.subscriptions)
      return null
    }

    this.subscriptions[subscribeKey] = this.socket.subscribe(subscribeKey)
    this.subscriptions[subscribeKey].on(EVENT_INVOICE_CREATE, async (data) => {
      //eslint-disable-next-line
      console.log('%c WS Invoice created', 'background: green; color: #fff', data)
      await txCallback(data)
    })
    this.subscriptions[subscribeKey].on(EVENT_INVOICE_UPDATE, async (data) => {
      //eslint-disable-next-line
      console.log('%c WS Invoice updated', 'background: green; color: #fff', data)
      await txCallback(data)
    })
    this.subscriptions[subscribeKey].on(EVENT_INVOICE_EDIT, async (data) => {
      //eslint-disable-next-line
      console.log('%c WS Invoice edited', 'background: green; color: #fff', data)
      await invoiceEditCallback(data)
    })
  }

  unsubscribeProfileId (profileId: string) {
    const subscribeKey = `events:${profileId}`
    Object.entries(this.subscriptions)
      .forEach(([key, subscription]) => {
        if (subscription && typeof subscription.close === 'function' && subscribeKey === key) {
          // $flow-disable-line
          subscription.close()
          delete this.subscriptions[subscribeKey]
        }
      })
  }
}

export const InsightSocketService = new InsightSocketServiceClass()
