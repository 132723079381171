// @flow
import moment from 'moment'
import BigNumber from 'bignumber.js'
import CryptoJS from 'crypto-js'
import AddressValidation from 'wallet-address-validator'
import coinselect from 'coinselect/accumulative'
import coinselectSplit from 'coinselect/split'
import bitcoin from 'bitcoinjs-lib'
import get from 'lodash.get'
import {
  BITCOIN_DECIMAL_LENGTH,
  BITCOIN_SYMBOL,
  BLOCKCHAIN_BITCOIN,
  BLOCKCHAIN_BITCOIN_TESTNET,
  BITCOIN_DECIMALS,
} from './constants'
import {
  TRANSACTION_ICON_NAME_IN,
  TRANSACTION_ICON_NAME_OUT,
  TRANSACTION_ICON_NAME_PENDING,
  UNKNOWN_ADDRESS,
} from '../accounts/constants'

import type { AnyWallet, HotWallet, Transaction } from '../accounts/models'
import type { TransactionSend, TransactionTo, Utxo } from './models'
import Logger from '../../services/Logger'
import config from '../../config/getConfig'

/**
 * Get unused exits to create new transaction
 * @param {array} to - recipient's address
 * @param {Object} utxos - unused exits from current block which will be used
 * @param {Number} feeRate - fee rate
 * @param {boolean} isSendAll - send all tokens or not. accumulative coinselect mode can't do it.
 */
export const selectCoins = (to: Array<TransactionTo>, utxos: Array<Utxo>, feeRate: number, isSendAll: boolean) => {
  const targets = to.map(({ value, address }) => {
    const target: TransactionTo = {
      address,
    }
    if (!isSendAll && value) {
      target.value = value.toNumber()
    }
    return target
  })
  // An unspent transaction output (UTXO) selection
  const { inputs, outputs, fee } = isSendAll ? coinselectSplit(utxos, targets, feeRate) : coinselect(utxos, targets, feeRate)
  // TODO: need to process a case, if some of inputs, outputs or fee is undefined... Here or outside
  return { inputs, outputs, fee }
}

export const describeBitcoinTransaction = (tx: TransactionSend, utxos: Array<Utxo>, account: HotWallet, feeRate: number, isSendAll: boolean) => {
  const { to, from } = tx
  const bitcoinNetwork = account.blockchain === BLOCKCHAIN_BITCOIN ? bitcoin.networks.bitcoin : bitcoin.networks.testnet

  const { inputs, outputs, fee } = selectCoins(to, utxos, feeRate, isSendAll)
  if (!inputs || !outputs) {
    const err = new Error(`Cannot describe ${account.blockchain} transaction. Bad transaction data.`)
    Logger.error(err, {
      tx,
      utxos,
      account: { ...account, encryptedKey: null },
      feeRate,
      isSendAll,
      inputs,
      outputs,
      fee,
    })
    throw err
  }

  const txb = new bitcoin.TransactionBuilder(bitcoinNetwork)

  for (const input of inputs) {
    txb.addInput(input.txId, input.vout)
  }

  for (const output of outputs) {
    if (!output.address) {
      output.address = from || get(inputs, `[${inputs.length - 1}].address`, null)
    }
    txb.addOutput(output.address, output.value)
  }

  return {
    txb,
    fee,
    inputs,
    outputs,
    // $flow-disable-line
    value: to.reduce((acc, to) => acc.plus(to.value), new BigNumber(0)),
  }
}

export const checkPassOfAccount = (account?: HotWallet, password?: string) => {
  try {
    if (!account || !password) {
      return false
    }
    const mnemonic = CryptoJS.AES.decrypt(account.encryptedKey, password)
    const mnemonicString = CryptoJS.enc.Utf8.stringify(mnemonic)
    return mnemonicString.length > 0
  } catch (e) {
    return false
  }
}

export const checkNetworkOfAddress = (address: string) => {
  // https://en.bitcoin.it/wiki/List_of_address_prefixes
  if (!address) {
    return 'unknown network'
  }

  if (AddressValidation.validate(address, BLOCKCHAIN_BITCOIN, 'prod')) {
    return BLOCKCHAIN_BITCOIN
  } else if (AddressValidation.validate(address, BLOCKCHAIN_BITCOIN, 'testnet')) {
    return BLOCKCHAIN_BITCOIN_TESTNET
  } else {
    return 'unknown network'
  }
}

export const createTx = (from: string, to: string, value: BigNumber) => {
  return {
    from: from,
    to: [
      { address: to, value: value.multipliedBy(BITCOIN_DECIMALS) },
    ],
  }
}

export const getBtcFee = (to: Array<TransactionTo>, utxos: Array<Utxo>, feeRate: number, isSendAll: boolean) => {
  const { fee } = selectCoins(to, utxos, parseInt(feeRate), isSendAll)
  return convertToBigNumber(fee)
}

export const convertToBigNumber = (amount: string | number) => {
  return new BigNumber(amount).dividedBy(BITCOIN_DECIMALS)
}

export const trimZeros = (string: string) => {
  return string.replace(/[0]+$/, '')
}

/**
 * For Chronobank node format
 * @param tx
 * @param address
 * @returns {{symbol: string, fees, amount: BigNumber, blockchain: string, txId: string, block: *, from: *, time: moment.Moment, to: *}}
 */
export const formatBlockExplorerTx = (tx: any, address: string): Transaction => {
  const negative = tx.inputs
    .filter((input) => input.address === address)
    .reduce((acc, cur) => acc.plus(cur.value), new BigNumber(0))

  const positive = tx.outputs
    .filter((output) => output.address === address)
    .reduce((acc, cur) => acc.plus(cur.value), new BigNumber(0))

  const difference = positive.minus(negative)
  const isPositive = difference.toString() > 0
  const isPending = !tx.blockTime || tx.confirmations <= 0
  const feeBn = convertToBigNumber(tx.fee)

  const fromAddress = []
  const inputs = tx.inputs.map((vin) => {
    // false for Segwit empty address
    if (vin.address !== 'false') {
      fromAddress.push(vin.address)
    }
    return {
      txId: vin.spentTxid,
      address: vin.address,
      value: trimZeros(convertToBigNumber(vin.value).toString()),
    }
  })

  const toAddress = []

  const outputs = tx.outputs.map((output) => {
    toAddress.push(output.address)
    return {
      txId: output.spentTxid,
      address: output.address,
      value: convertToBigNumber(output.value).toString(),
    }
  })

  return {
    txId: tx.txid,
    time: tx.blockTime ? moment(tx.blockTime) : null,
    block: tx.blockHeight,
    fees: trimZeros(feeBn.toString()),
    feeBn: feeBn,
    amount: convertToBigNumber(difference),
    symbol: BITCOIN_SYMBOL,
    blockchain: BLOCKCHAIN_BITCOIN,
    confirmations: tx.blockTime ? tx.confirmations : 0,
    isPending,
    isPositive,
    fromAddress,
    fromAddressText: fromAddress.length ? fromAddress.join(', ') : UNKNOWN_ADDRESS,
    type: isPending ? TRANSACTION_ICON_NAME_PENDING : isPositive ? TRANSACTION_ICON_NAME_IN : TRANSACTION_ICON_NAME_OUT,
    from: inputs,
    to: outputs,
    toAddress,
    toAddressText: toAddress.length ? toAddress.join(', ') : UNKNOWN_ADDRESS,
  }
}

export const formatBalance = (unformattedBalance: string | number | null = null): { balance: BigNumber | null, formattedBalance: BigNumber | null } => {
  if (unformattedBalance === null || unformattedBalance === 'undefined') {
    return {
      balance: null,
      formattedBalance: null,
    }
  }
  const balance = new BigNumber(unformattedBalance)
  return {
    balance,
    formattedBalance: new BigNumber(balance.dividedBy(BITCOIN_DECIMALS).toFixed(config.getConfig('bitcoinDecimalLength'))),
  }
}

export const getExplorerLink = (txId: string, account: AnyWallet) => {
  const mainnetExplorer = 'https://www.blockchain.com/btc/tx/'
  const testnetExplorer = 'https://www.blockchain.com/btctest/tx/'

  // `https://live.blockcypher.com/btc/tx/${txId}/`
  // `https://live.blockcypher.com/btc-testnet/tx/${txId}/`
  switch (account.blockchain) {
    case BLOCKCHAIN_BITCOIN:
      return `${mainnetExplorer}${txId}`
    case BLOCKCHAIN_BITCOIN_TESTNET:
      return `${testnetExplorer}${txId}`
    default:
      break
  }
  return null
}

export const getDecimals = () => {
  return BITCOIN_DECIMALS
}

export const getDecimalLength = () => {
  return BITCOIN_DECIMAL_LENGTH
}

export const isTestnetAccount = (account: { blockchain: string }) => {
  return [BLOCKCHAIN_BITCOIN_TESTNET]
    .includes(account.blockchain)
}

export const createPaymentLink = (accountAddress: string, value: string, message: string): string => {
  return `bitcoin:${accountAddress}?amount=${value}${message}`
}
