import { RestService } from '../../services/RestService'
import get from 'lodash.get'
import {
  setPaymentRequestsList,
  setPaymentRequestsLoadingState,
  updateFormLoadingState,
  updatePaymentRequest,
  updateUsersHint,
} from './actions'
import { PaymentRequestModel } from '../../models/PaymentRequests/PaymentRequestModel'
import { LOADING_STATE_ERROR, LOADING_STATE_LOADED, LOADING_STATE_LOADING } from '../../constants'
import { formatRawRequest, formatValues } from './utils'
import { profileInfoSelector } from '../profile/selectors'
import { getProfile } from '../profile/thunks'
import { accountByIdSelector } from '../accounts/selectors'
import { downloadCsvFile, downloadCustomFile, getMainSymbolForBlockchain, isResponseValid } from '../../utils/general'
import { requestByIdSelector, requestsPreparedToExportToScvSelector, requestsMappedByIdSelector } from './selectors'
import {
  PAYMENT_REQUEST_STATUS_CANCELLED,
  PAYMENT_REQUEST_STATUS_DELETED,
  PAYMENT_REQUEST_STATUS_DRAFT,
} from './constants'
import json2csv from 'json2csv'
import { setCustomLoadingState } from "../ui/actions"
import type { Dispatch } from 'redux'
import type { getStateType } from "../accounts/models"
import Logger from '../../services/Logger'

const sortByDate = (a, b) => {
  if ((a.createdAt || a) > (b.createdAt || b)) {
    return -1
  }
  if ((a.createdAt || a) < (b.createdAt || b)) {
    return 1
  }
  return 0
}

export const downloadFile = (attachmentId, requestId) => async () => {
  try {
    const { data } = await RestService.request('paymentRequestsService::getRequestAttachment', attachmentId, requestId)
    const { code, result } = data
    if (isResponseValid(code) && result[0]) {
      downloadCustomFile(result[0].data, result[0].name)
    }
  } catch (e) {
    Logger.error(e, { attachmentId, requestId })
  }
}

export const getRequestLogo = (requestId) => async (dispatch, getState) => {
  try {
    const request = requestByIdSelector(requestId)(getState())
    if (!request || !request.invoice.logo) {
      return null
    }
    const { data } = await RestService.request(
      'paymentRequestsService::getRequestAttachment',
      request.invoice.logo.attachmentId,
      request.requestId,
    )
    const { code, result } = data
    if (isResponseValid(code) && result[0]) {
      const request = requestByIdSelector(requestId)(getState()) // get new data from store
      const loadedRequest = new PaymentRequestModel({
        ...request,
        invoice: {
          ...request.invoice,
          logo: {
            ...result[0],
            loadingState: LOADING_STATE_LOADED,
          },
        },
      })
      await dispatch(updatePaymentRequest(formatRawRequest(loadedRequest)))
      return result[0]
    }
  } catch (e) {
    const request = requestByIdSelector(requestId)(getState())
    const loadedRequest = new PaymentRequestModel({
      ...request,
      invoice: {
        ...request.invoice,
        logo: {
          ...request.invoice.logo,
          loadingState: LOADING_STATE_ERROR,
        },
      },
    })
    await dispatch(updatePaymentRequest(formatRawRequest(loadedRequest)))
  }
}

export const getPaymentRequests = () => async (dispatch: Dispatch, getState: getStateType) => {
  dispatch(setPaymentRequestsLoadingState({
    loadingState: LOADING_STATE_LOADING,
  }))

  try {
    const requestsMap = requestsMappedByIdSelector(getState())
    const { data } = await RestService.request('paymentRequestsService::getPaymentRequests')
    const { code, result, error } = data
    if (isResponseValid(code)) {
      dispatch(setPaymentRequestsList({
        loadingState: LOADING_STATE_LOADED,
        limit: result.limit,
        offset: result.offset,
        requestList: result.requests
          .sort(sortByDate)
          .map((request) => {
            try {
              return formatRawRequest(request, requestsMap)
            } catch (e) {
              Logger.error(e, { request })
            }

            return null
          })
          // filter null values in case of error
          .filter((r) => r),
      }))
    } else {
      Logger.error(new Error('paymentRequestsService::getPaymentRequests'), error)
      dispatch(setPaymentRequestsLoadingState({
        loadingState: LOADING_STATE_ERROR,
      }))
    }
  } catch (e) {
    Logger.error(e)
    dispatch(setPaymentRequestsLoadingState({
      loadingState: LOADING_STATE_ERROR,
    }))
  }
}

export const createInvoice = (values) => async (dispatch, getState) => {
  try {
    const state = getState()
    dispatch(updateFormLoadingState('newInvoiceForm', LOADING_STATE_LOADING))
    await dispatch(getProfile())
    const account = values.account
      ? accountByIdSelector(get(values, 'account.id', null))(state) || {
        address: values.account.value || values.account.address,
        blockchain: values.blockchain,
        symbol: getMainSymbolForBlockchain(values.blockchain),
      } : {}

    const profile = profileInfoSelector(state)
    const oldRequest = values.requestId ? requestByIdSelector(values.requestId)(getState()) : null
    const request = await formatValues(values, profile, account, oldRequest)
    let methodName = values.requestId ? 'updatePaymentRequest' : 'createPaymentRequest'

    if (values.requestId && request.fromDraftId) {
      methodName = 'createPaymentRequest'
    }

    const { data } = await RestService.request('paymentRequestsService::' + methodName, request, values.requestId, request.status === PAYMENT_REQUEST_STATUS_DRAFT)

    if (isResponseValid(data.code)) {
      dispatch(updateFormLoadingState('newInvoiceForm', LOADING_STATE_LOADED))
      await dispatch(getPaymentRequests())
      dispatch(getUsersHint())
      return { success: true }
    } else {
      throw new Error('Bad response')
    }
  } catch (e) {
    Logger.error(e, values)

    const validation = get(e, 'response.data.validation', null)
    dispatch(updateFormLoadingState('newInvoiceForm', LOADING_STATE_ERROR))
    return { success: false, validation }
  }
}

/**
 * Cancel requests
 * @param {string} requestId
 */
export const cancelRequest = (requestId) => async (dispatch, getState) => {
  let request
  try {
    request = requestByIdSelector(requestId)(getState())
    const loadedRequest = new PaymentRequestModel({
      ...request,
      loadingState: LOADING_STATE_LOADING,
    })
    dispatch(updatePaymentRequest(formatRawRequest(loadedRequest)))

    const { data } = await RestService.request('paymentRequestsService::cancelRequests', requestId)
    const { code } = data
    if (!isResponseValid(code)) {
      throw new Error('Error in cancelRequest: ', data)
    }

    const { data: requestData } = await RestService.request('paymentRequestsService::getRequestById', requestId)
    if (!isResponseValid(requestData.code)) {
      throw new Error('Error in cancelRequest: ', requestData)
    }
    if (requestData.result.isPaid) {
      requestData.result.isPaid = false
    }
    const logo = request.invoice.logo ? {
      ...request.invoice.logo, // save loaded logo
    } : null

    dispatch(updatePaymentRequest(formatRawRequest({
      ...requestData.result,
      invoice: {
        ...requestData.result.invoice,
        logo,
      },
    })))

  } catch (e) {
    Logger.error(e, requestId)
    if (request) {
      const loadedRequest = new PaymentRequestModel({
        ...request,
        loadingState: LOADING_STATE_LOADED,
      })
      dispatch(updatePaymentRequest(loadedRequest))
    }
  }
}

/**
 * Load and update requests
 * @param {string} requestId
 * @param {object} data to update
 */
export const updateRequestLocal = (requestId: string, data: any = {}) => async (dispatch, getState) => {
  try {
    const state = getState()
    const request = requestByIdSelector(requestId)(state)
    const updatedRequest = new PaymentRequestModel({
      ...request,
      ...data,
    })

    dispatch(updatePaymentRequest(updatedRequest))
  } catch (e) {
    Logger.error(e, { requestId, data })
  }
}

/**
 * Load and update requests
 * @param {string} requestId
 */
export const loadAndUpdateRequest = (requestId: string) => async (dispatch, getState) => {
  try {
    const state = getState()
    const profile = profileInfoSelector(state)

    const { data } = await RestService.request('paymentRequestsService::getRequestById', requestId)
    if (!isResponseValid(data.code)) {
      throw new Error('Error in cancelRequest: ', data)
    }
    if (data.result.userId === profile.id && data.result.status === PAYMENT_REQUEST_STATUS_CANCELLED) {
      dispatch(updateRequestLocal(data.result.requestId, { status: PAYMENT_REQUEST_STATUS_CANCELLED }))
    } else {
      dispatch(updatePaymentRequest(formatRawRequest(data.result)))
    }
  } catch (e) {
    Logger.error(e, { requestId })
  }
}

export const loadTxByIdForRequest = (requestId: string) => async (dispatch: Dispatch, getState): Promise<void> => {
  try {
    let request = new PaymentRequestModel({
      ...requestByIdSelector(requestId)(getState()),
      loadingState: LOADING_STATE_LOADING,
    })

    dispatch(updatePaymentRequest(request))
    const promises = []
    request.invoice.transactions.forEach(({ hash }) => {
      promises.push(new Promise((resolve) => {
        RestService.request('insightService::getTx', request.paymentBlockchain, hash)
          .then(({ data }) => {
            resolve({
              ...data,
              hash,
            })
          })
          .catch(() => {
            resolve({ hash })
          })
      }))
    })
    const transactions = await Promise.all(promises)

    // get new request data from state
    request = requestByIdSelector(requestId)(getState())
    dispatch(updatePaymentRequest(new PaymentRequestModel({
      ...request,
      loadingState: LOADING_STATE_LOADED,
      invoice: {
        ...request.invoice,
        transactions: request.invoice.transactions.map((transaction) => {
          const loadedtransaction = transactions.find((t) => t.hash === transaction.hash)
          if (loadedtransaction) {
            return {
              ...loadedtransaction,
              ...transaction,
            }
          }

          return transaction
        }),
      },
    })))

  } catch (e) {
    Logger.error(e, { requestId })
    dispatch(updatePaymentRequest(new PaymentRequestModel({
      ...requestByIdSelector(requestId)(getState()),
      loadingState: LOADING_STATE_LOADED, // ignore error
    })))
  }
}

export const getUsersHint = () => async (dispatch) => {
  const { data } = await RestService.request('paymentRequestsService::getHints')
  const hint = get(data, 'result.requests', null)
  if (isResponseValid(data.code) && hint) {
    dispatch(updateUsersHint(hint
      .sort((a, b) => {
        const aName = a.isPaid ? (a.name || a.email) : a.email
        const bName = b.isPaid ? (b.name || b.email) : b.email
        if (aName > bName) {
          return 1
        }
        if (aName < bName) {
          return -1
        }
        return 0
      })
      .reduce((acc, item) => {
        if (!get(item, 'email', false)) {
          return acc
        }
        if (get(item, 'avatar.url', false)) {
          item.avatar.url = item.avatar.url.replace(/upload/i, 'upload/w_36,h_36')
        }

        return {
          ...acc,
          [item.email]: item,
        }
      }, {})))
  }
}

export const removePaymentRequest = (requestId) => async (dispatch, getState) => {
  let request
  try {
    request = requestByIdSelector(requestId)(getState())
    await dispatch(updatePaymentRequest(new PaymentRequestModel({
      ...request,
      loadingState: LOADING_STATE_LOADING,
    })))

    await RestService.request('paymentRequestsService::deletePaymentRequest', requestId)

    await dispatch(updatePaymentRequest(new PaymentRequestModel({
      ...request,
      status: PAYMENT_REQUEST_STATUS_DELETED,
      loadingState: LOADING_STATE_LOADED,
    })))

  } catch (e) {
    Logger.error(e, { requestId })
    if (request) {
      const loadedRequest = new PaymentRequestModel({
        ...request,
        loadingState: LOADING_STATE_ERROR,
      })
      await dispatch(updatePaymentRequest(loadedRequest))
    }
  }
}

export const downloadInvoiceAsPdf = (requestId) => async () => {
  const { data } = await RestService.request('paymentRequestsService::getPaymentRequestPDF', requestId)
  const { code, result } = data
  if (isResponseValid(code) && result) {
    downloadCustomFile(result.data, result.name)
  }
}

export const sendInvoiceAsPdf = (requestId, email) => async () => {
  const { data } = await RestService.request('paymentRequestsService::sendPaymentRequestToEmailAsPDF', requestId, email)
  const { code } = data
  if (!isResponseValid(code)) {
    throw new Error('Error')
  }
}

export const exportPaymentRequestsToCSV = () => async (dispatch, getState) => {
  dispatch(setCustomLoadingState({ exportCSVLoadingState: LOADING_STATE_LOADING }))
  return new Promise((resolve, reject) => {
    try {
      const items = requestsPreparedToExportToScvSelector(getState())
      downloadCsvFile(json2csv.parse(items), 'invoices')
      dispatch(setCustomLoadingState({ exportCSVLoadingState: LOADING_STATE_LOADED }))
      resolve()
    } catch (e) {
      dispatch(setCustomLoadingState({ exportCSVLoadingState: LOADING_STATE_ERROR }))
      reject()
    }
  })
}
