import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import styles from './Login.module.scss'
import { LOADING_STATE_LOADING } from '../../constants'

function RecallConfirmationCodeTimer ({ confirmCodeSendTime, handleRecallCode, loadingState }) {

  const now = moment.utc().valueOf()
  const [time, setTime] = useState(3 * 60)

  //Replaces componentDidMount and componentWillUnmount
  useEffect(() => {
    const timerID = setTimeout(() => {
      const time = (3 * 60) - Math.round((now - confirmCodeSendTime) / 1000, 0)
      setTime(time > 0 ? time - 1 : 0)
    }, 1000)

    return function cleanup () {
      clearInterval(timerID)
    }
  })
  const handleClick = (e) => {
    setTime(3 * 60)
    handleRecallCode(e)
  }

  const date = new Date()
  date.setMinutes(Math.floor(time / 60, 0))
  date.setSeconds(Math.floor(time % 60, 0))

  return (
    <button
      className={styles.goBack}
      type='button'
      disabled={time > 0 || loadingState === LOADING_STATE_LOADING}
      onClick={handleClick}>
      {time > 0
        ? (
          <span className='capture'>Code can be resent in {moment(date).format('mm:ss')}</span>
        ) : (
          <span className='capture blue-bk'>Send the code again</span>
        )}
    </button>
  )
}

RecallConfirmationCodeTimer.propTypes = {
  confirmCodeSendTime: PropTypes.number,
  handleRecallCode: PropTypes.func,
  loadingState: PropTypes.string,
}

export default RecallConfirmationCodeTimer
