//@flow
import get from 'lodash.get'
import React from 'react'
import styles from './AccountTransactionsList.module.scss'
import PropTypes from 'prop-types'
import Button from '../Button/Button'
import LoadingState from '../LoadingState'
import { LOADING_STATE_LOADED, LOADING_STATE_LOADING } from '../../constants/index'
import TransactionLine from './components/TransactionLine'
import { getTransactionList } from '../../ducks/accounts/utils'
import type { AnyWallet, Transaction } from '../../ducks/accounts/models'
import { AccountModel } from '../../models/Accounts/AccountModel'

type Props = {
  handleLoadMore: () => void,
  account: AnyWallet,
  lastBlock: {
    number: number,
  },
}

class AccountTransactionsListComponent extends React.PureComponent<Props> {
  static propTypes = {
    account: PropTypes.instanceOf(AccountModel),
    lastBlock: PropTypes.shape({
      number: PropTypes.number,
    }),
  }

  getTransactionList = (transactionList: Array<Transaction>) => {
    const confirmedTxs = []
    const pendingTxs = []

    if (!transactionList) {
      return [pendingTxs, confirmedTxs]
    }

    transactionList.sort((a: Transaction, b: Transaction) => {
      if (!a.time) { // for unconfirmed tx
        return -1
      }
      if (!b.time) { // for unconfirmed tx
        return 1
      }
      return b.time.format('X') - a.time.format('X')
    })
      .forEach((tx: Transaction) => {
        const confirmations = Object.prototype.hasOwnProperty.call(tx, 'confirmations')
          ? tx.confirmations
          : this.props.lastBlock.number - parseInt(tx.block)
        if (confirmations > 0) {
          confirmedTxs.push({
            ...tx,
            confirmations,
          })
        } else {
          pendingTxs.push({
            ...tx,
            confirmations,
          })
        }
      })

    return [pendingTxs, confirmedTxs]
  }

  render () {
    const { account, handleLoadMore } = this.props

    const transactions = get(this.props, 'account.transactions', {})
    const txList = get(this.props, 'account.transactions.list', null)
    const isEmptyList = !txList || (txList && Object.values(txList).length === 0)

    const [pendingList, confirmedList] = this.getTransactionList(getTransactionList(txList))

    if (isEmptyList && transactions.loadingState !== LOADING_STATE_LOADING) {
      return (
        <div className={styles.container}>
          <div className={styles.noTransactionsText}>There are no transactions yet</div>
        </div>
      )
    }

    let currentTxDate, currentTxDatePrev = null

    return (
      <div className={styles.container}>
        <div className={styles.tabTitle + ' h4 gray-70'}>Last Operations</div>

        <div className={styles.list}>
          {pendingList.length > 0 && <>
            <div className={styles.headerWithoutShadow + ' h5 gray-70'}>
              {'Unconfirmed'}
            </div>

            {pendingList.map((tx) => {
              return <TransactionLine
                account={this.props.account}
                transaction={tx}
                key={tx.txId}
              />
            })
            }
          </>}

          {!isEmptyList && confirmedList.length > 0 && confirmedList.map((tx, i) => {
            currentTxDate = currentTxDatePrev
            currentTxDatePrev = tx.time.format('MMM DD')

            return (<div key={'container_' + tx.txId}>
              {tx.time.format('MMM DD') !== currentTxDate &&
              <div
                className={i === 0 ? styles.headerWithoutShadow : styles.header + ' h5 gray-70'}
                key={tx.time.toString()}>
                {tx.time.format('MMM DD')}
              </div>}
              <TransactionLine
                account={this.props.account}
                transaction={tx}
                key={tx.txId}
              />
            </div>)
          })
          }
        </div>
        <div className={styles.loaderWrapper}>
          <LoadingState loadingState={transactions.loadingState} reloadData={handleLoadMore} />
        </div>
        {transactions.loadingState === LOADING_STATE_LOADED && account.transactions.loadMore &&
        <div className={styles.loaderWrapper}>
          <Button colorType='new-filled' handleClick={handleLoadMore}>Load More</Button>
        </div>}
      </div>
    )
  }
}

export default AccountTransactionsListComponent
