import {
  PAYROLL_PERIOD_EVERY_TWO_WEEK,
  PAYROLL_PERIOD_MONTHLY,
  PAYROLL_PERIOD_ONCE_PER_WEEK,
} from './constants'
import moment from 'moment'
import get from 'lodash.get'
import BigNumber from 'bignumber.js'
import { BLOCKCHAIN_ETHEREUM, BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY } from '../ethereum/constants'
import { getDecimals } from '../accounts/utils'

export const formatRequestsListForTx = (list, rates) => {
  const res = list.reduce((accumulator, request) => {
    let item = accumulator[request.paymentWallet]
    if (!item) {
      item = {
        value: new BigNumber(0),
        requests: [],
      }
    }

    const amount = new BigNumber(request.settlementAmount)

    const rate = get(rates, request.paymentAsset + '.' + request.settlementAsset, null)
    const updatedAt = get(rates, request.paymentAsset + '.updatedAt', null)
    const source = get(rates, request.paymentAsset + '.source', null)

    if (rate) {
      const paymentAmount = amount
        .dividedBy(rate)
        .multipliedBy(getDecimals({ blockchain: request.paymentBlockchain }))
        .decimalPlaces(0)

      item = {
        value: item.value
          .plus(paymentAmount),
        address: request.paymentWallet,
        requests: [
          ...item.requests,
          {
            requestId: request.requestId,
            asset: request.paymentAsset,
            blockchain: request.paymentBlockchain,
            xrate: rate,
            amount: paymentAmount,
            amountFormatted: new BigNumber(paymentAmount)
              .dividedBy(getDecimals({ blockchain: request.paymentBlockchain }))
              .toString(),
            xrateSource: source || "Cryptocompare.com",
            xrateDate: new Date(updatedAt).getTime(),
          },
        ],
      }
    }

    return { ...accumulator, [request.paymentWallet]: item }
  }, {})

  return Object.values(res)
}

/**
 *
 * @param firstDate
 * @param period
 */
export const getPayrollDateRange = (firstDate, period) => {
  let day

  switch (period) {
    case PAYROLL_PERIOD_ONCE_PER_WEEK.value:
    case PAYROLL_PERIOD_EVERY_TWO_WEEK.value:
      day = moment(firstDate).isoWeekday()
      break

    case PAYROLL_PERIOD_MONTHLY.value:
      day = moment(firstDate).format('DD')
      break

    default:
      throw new Error('Unknown period: ' + period)
  }

  return [+day]
}

export const canPaySeparateTransactionsUtil = (canPaySeparateTransactions, blockchainRequestsCount, blockchain) => {
  // if count of request more than 1 and the user can pay one by one 
  canPaySeparateTransactions = blockchainRequestsCount > 1 ? !!canPaySeparateTransactions : false
  // Ethereum supported only one to one transactions
  if ([BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY, BLOCKCHAIN_ETHEREUM].includes(blockchain) && blockchainRequestsCount > 1) {
    canPaySeparateTransactions = true
  }
  return canPaySeparateTransactions
}
