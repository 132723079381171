//@flow
import get from 'lodash.get'
import bitcoin from 'bitcoinjs-lib'
import CryptoJS from 'crypto-js'
import BigNumber from 'bignumber.js'
import { Dispatch } from 'redux'
import { AccountModel } from '../../models/Accounts/AccountModel'
import * as actions from './actions'
import { updateLoadingState, updateSendFormSetting } from './actions'
import { RestService } from '../../services/RestService'
import { CacheService } from '../../services/CacheService'
import {
  ACCOUNT_KEY_MAINNET,
  ACCOUNT_TYPE_COLD,
  ACCOUNT_TYPE_HOT,
  ACCOUNT_TYPE_TREZOR,
  ACCOUNTS_STORAGE_KEY,
  TRANSACTION_ICON_NAME_PENDING,
  TRANSACTIONS_LIMIT,
} from './constants'
import {
  BITCOIN_DECIMALS,
  BITCOIN_SYMBOL,
  BLOCKCHAIN_BITCOIN,
  BLOCKCHAIN_BITCOIN_TESTNET,
  COIN_TYPE_BTC_MAINNET,
  COIN_TYPE_BTC_TESTNET,
} from '../bitcoin/constants'
import { InsightSocketService } from '../../services/InsightSocketService'
import {
  accountByIdSelector,
  accountsSelector,
  getAccountNetwork,
  getSigner,
  transactionByIdSelector,
} from './selectors'
import {
  convertToBigNumber,
  getMnemonicPhrase,
  getUtils,
  isTestnetAccount,
  transformRemoteAccountToLocal,
} from './utils'
import { profileInfoSelector } from '../profile/selectors'
import * as Btc from '../bitcoin/thunks'
import { LOADING_STATE_ERROR, LOADING_STATE_LOADED, LOADING_STATE_LOADING } from '../../constants'
import { getMainSymbolForBlockchain, isResponseValid } from '../../utils/general'

import type { AnyWallet, ColdWallet, getStateType, HotWallet, Transaction } from './models'
import type { TransactionSend, TransactionTo } from '../bitcoin/models.js'
import moment from 'moment'
import { PAYROLL_STEPS } from '../payroll/constants'
import Logger from '../../services/Logger'
import * as Eth from '../ethereum/thunks'
import {
  BLOCKCHAIN_ETHEREUM,
  BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY,
} from '../ethereum/constants'
import { amplitudeSendEvent } from "../../utils/analytics"

export const getMethods = (account: { blockchain: string }) => {
  switch (account.blockchain) {
    case BLOCKCHAIN_ETHEREUM:
    case BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY:
      return Eth
    case BLOCKCHAIN_BITCOIN:
    case BLOCKCHAIN_BITCOIN_TESTNET:
    default:
      return Btc
  }
}

export const checkIsSimilarAccountExisted = (accountData: any, mnemonic: string) => (dispatch: Dispatch, getState: getStateType) => {
  dispatch(updateLoadingState(LOADING_STATE_LOADING))
  const network = accountData.blockchain === BLOCKCHAIN_BITCOIN ? bitcoin.networks.bitcoin : bitcoin.networks.testnet
  const coinType = accountData.blockchain === BLOCKCHAIN_BITCOIN ? COIN_TYPE_BTC_MAINNET : COIN_TYPE_BTC_TESTNET
  const address = accountData.address || getSigner(accountData.blockchain, mnemonic, network, coinType).getAddress()
  const accounts = accountsSelector(getState())

  const existedAccounts = accounts.filter((account) => {
    return account.blockchain === accountData.blockchain && account.address === address.trim()
  })

  dispatch(updateLoadingState(LOADING_STATE_LOADED))
  return existedAccounts.length > 0 ? address : null
}

export const addAccount = (accountData: any, mnemonic: string, passPhrase: string) => async (dispatch: Dispatch, getState: getStateType) => {
  try {
    dispatch(updateLoadingState(LOADING_STATE_LOADING))
    const profile = profileInfoSelector(getState())
    const network = accountData.blockchain === BLOCKCHAIN_BITCOIN ? bitcoin.networks.bitcoin : bitcoin.networks.testnet
    const coinType = accountData.blockchain === BLOCKCHAIN_BITCOIN ? COIN_TYPE_BTC_MAINNET : COIN_TYPE_BTC_TESTNET
    const signer = getSigner(accountData.blockchain, mnemonic, network, coinType)
    const accountNetwork = getAccountNetwork(accountData.blockchain)

    const account = new AccountModel({
      ...accountData,
      symbol: getMainSymbolForBlockchain(accountData.blockchain),
      type: ACCOUNT_TYPE_HOT,
      hash: CryptoJS.MD5(profile.id).toString(),
      encryptedKey: CryptoJS.AES.encrypt(mnemonic, passPhrase).toString(),
      address: signer.getAddress(),
      network: accountNetwork,
      meta: {
        symbol: getMainSymbolForBlockchain(accountData.blockchain),
        payDefault: false,
        recieveDefault: false,
        network: accountNetwork,
        isTestnet: accountNetwork !== ACCOUNT_KEY_MAINNET,
      },
    })

    const payload = {
      wallets: [{
        name: account.name,
        address: account.address,
        namespace: 'paymentX',
        blockchain: account.symbol,
        meta: {
          symbol: getMainSymbolForBlockchain(accountData.blockchain),
          payDefault: false,
          recieveDefault: false,
          network: account.network,
        },
      }],
    }

    const result = await RestService.request('walletService::saveAccount', payload)
    if (result.data.error) {
      const err = new Error('saveAccount error ')
      Logger.error(err, result.data)
      throw err
    }

    const wallet = result.data.result.wallets.find((wallet) => wallet.name === account.name)
    const hotWallet: HotWallet = new AccountModel({
      ...account,
      id: wallet.id || null,
    })

    let cryptocurrency = ''
    if (wallet.meta.network === 'mainnet' && wallet.meta.symbol === 'BTC') {
      cryptocurrency = 'Bitcoin'
    }
    if (wallet.meta.network === 'testnet' && wallet.meta.symbol === 'BTC') {
      cryptocurrency = 'Bitcoin Test'
    }
    if (wallet.meta.network === 'mainnet' && wallet.meta.symbol === 'ETH') {
      cryptocurrency = 'Ether'
    }
    if (wallet.meta.network === 'rinkeby' && wallet.meta.symbol === 'ETH') {
      cryptocurrency = 'Ether Test'
    }
    amplitudeSendEvent('Wallet added', { Type: 'Hot', Cryptocurrency: cryptocurrency })
    await dispatch(actions.addAccount(hotWallet))
    await saveAccountToLocalStorage(hotWallet)
    await dispatch(subscribeToAccount(hotWallet))
    await dispatch(updateBalanceForAccount(hotWallet))

    dispatch(updateLoadingState(LOADING_STATE_LOADED))

    return wallet.id
  } catch (e) {
    Logger.error(e, { ...accountData, encryptedKey: null })
    dispatch(updateLoadingState(LOADING_STATE_ERROR))
    return false
  }
}

export const updateAccount = (account: AccountModel) => async (dispatch: Dispatch) => {
  try {
    dispatch(updateLoadingState(LOADING_STATE_LOADING))

    const payload = {
      name: account.name,
      address: account.address,
      namespace: 'paymentX',
      blockchain: account.symbol,
      meta: {
        symbol: account.symbol,
        payDefault: false,
        recieveDefault: false,
        network: account.network,
      },
    }

    const res = await RestService.request('walletService::updateAccount', account.id, payload)

    // {"code":200,"status":"Ok","result":{"success":true}}
    if (!isResponseValid(res.data.code)) {
      throw new Error('Update account error: ' + JSON.stringify(res))
    }

    const hotWallet: HotWallet = new AccountModel({
      ...account,
    })

    await dispatch(actions.updateAccount(hotWallet))
    await saveAccountToLocalStorage(hotWallet)
    await dispatch(updateLoadingState(LOADING_STATE_LOADED))
    // return wallet.id
  } catch (e) {
    Logger.error(e, { ...account, encryptedKey: null })
    dispatch(updateLoadingState(LOADING_STATE_ERROR))

    return false
  }
}

export const addPublicAddress = (accountData: AccountModel) => async (dispatch: Dispatch) => {
  try {
    dispatch(updateLoadingState(LOADING_STATE_LOADING))
    const accountNetwork = getAccountNetwork(accountData.blockchain)
    const symbol = getMainSymbolForBlockchain(accountData.blockchain)
    const account = new AccountModel({
      ...accountData,
      symbol,
      type: ACCOUNT_TYPE_COLD,
      network: accountNetwork,
      meta: {
        symbol,
        payDefault: false,
        recieveDefault: false,
        network: accountNetwork,
        isTestnet: accountNetwork !== ACCOUNT_KEY_MAINNET,
      },
    })

    const payload = {
      wallets: [{
        name: account.name,
        address: account.address,
        namespace: 'paymentX',
        blockchain: account.symbol, // the backend needs to put BTC
        meta: {
          symbol,
          payDefault: false,
          recieveDefault: false,
          network: account.network,
        },
      }],
    }

    const result = await RestService.request('walletService::saveAccount', payload)

    // {"code":200,"status":"Ok","result":{"success":true}}
    if (!isResponseValid(result.data.code)) {
      throw new Error('Save account error: ' + JSON.stringify(result))
    }

    const wallet = result.data.result.wallets.find((wallet) => wallet.name === account.name)
    const coldWallet = new AccountModel({
      ...account,
      id: wallet.id || null,
    })

    let cryptocurrency = ''
    if (wallet.meta.network === 'mainnet' && wallet.meta.symbol === 'BTC') {
      cryptocurrency = 'Bitcoin'
    }
    if (wallet.meta.network === 'testnet' && wallet.meta.symbol === 'BTC') {
      cryptocurrency = 'Bitcoin Test'
    }
    if (wallet.meta.network === 'mainnet' && wallet.meta.symbol === 'ETH') {
      cryptocurrency = 'Ether'
    }
    if (wallet.meta.network === 'rinkeby' && wallet.meta.symbol === 'ETH') {
      cryptocurrency = 'Ether Test'
    }
    amplitudeSendEvent('Wallet added', { Type: 'Cold', Cryptocurrency: cryptocurrency })
    await dispatch(actions.addAccount(coldWallet))
    await dispatch(subscribeToAccount(coldWallet))
    await dispatch(updateBalanceForAccount(coldWallet))
    await dispatch(updateLoadingState(LOADING_STATE_LOADED))

    return wallet.id
  } catch (e) {
    Logger.error(e, accountData)
    dispatch(updateLoadingState(LOADING_STATE_ERROR))

    return false
  }
}

export const updatePassword = (account: AccountModel, oldPassword: string, newPassword: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(updateLoadingState(LOADING_STATE_LOADING))
    const mnemonic = getMnemonicPhrase(account, oldPassword)
    if (!mnemonic) {
      throw new Error()
    }
    const hotWallet: HotWallet = new AccountModel({
      ...account,
      encryptedKey: CryptoJS.AES.encrypt(mnemonic, newPassword).toString(),
    })

    await dispatch(actions.updateAccount(hotWallet))
    await saveAccountToLocalStorage(hotWallet)
    await dispatch(updateLoadingState(LOADING_STATE_LOADED))

  } catch (e) {
    Logger.error(e, { ...account, encryptedKey: null })
    dispatch(updateLoadingState(LOADING_STATE_ERROR))

    return false
  }
}

export const removeAccount = (accountId: string) => async (dispatch: Dispatch, getState: getStateType) => {
  try {
    dispatch(updateLoadingState(LOADING_STATE_LOADING))
    const state = getState()
    const allAccountsString: string | void | null = localStorage.getItem(ACCOUNTS_STORAGE_KEY)

    const accountList: Array<AnyWallet> = allAccountsString ? JSON.parse(allAccountsString) : []
    const account = accountByIdSelector(accountId)(state)
    const newList = accountList.filter((account) => account.id !== accountId)

    localStorage.setItem(ACCOUNTS_STORAGE_KEY, JSON.stringify(newList))

    const result = await RestService.request('walletService::deleteAccount', accountId)
    if (result.data.error) {
      const err = new Error('Delete account error ')
      Logger.error(err, result.data)
      throw err
    }

    await InsightSocketService.unsubscribeAccount(account)
    await dispatch(updateLoadingState(LOADING_STATE_LOADED))

    return true
  } catch (e) {
    dispatch(updateLoadingState(LOADING_STATE_ERROR))
    Logger.error(e, { accountId })
    return false
  }
}

export const saveAccountToLocalStorage = (account: HotWallet) => {
  try {
    const allAccountsString: string | void | null = localStorage.getItem(ACCOUNTS_STORAGE_KEY)
    const accountModelObject = { ...account }
    delete accountModelObject.balance
    delete accountModelObject.formattedBalance
    delete accountModelObject.transactions
    delete accountModelObject.balanceLoadingState
    const accountsMap = allAccountsString
      ? JSON.parse(allAccountsString)
        .reduce((acc, item) => ({
          ...acc,
          [item.id]: item,
        }), {})
      : {}
    accountsMap[accountModelObject.id] = accountModelObject
    localStorage.setItem(ACCOUNTS_STORAGE_KEY, JSON.stringify(Object.values(accountsMap)))

    return true
  } catch (e) {
    Logger.error(e, { ...account, encryptedKey: null })
    return false
  }
}

export const getBalanceForAccount = async (account: AnyWallet): Promise<any> => {
  const methods = getMethods(account)
  return methods.getBalance(account)
}

export const updateBalanceForAccount = (account: AnyWallet) => async (dispatch: Dispatch) => {
  try {
    const methods = getMethods(account)
    const { balance, formattedBalance } = await methods.getBalance(account)

    if (balance) {
      dispatch(actions.updateAccountBalance(account.id, balance, formattedBalance))
    }
  } catch (e) {
    dispatch(actions.updateAccountBalanceError(account.id))
  }
}

export const loadTxById = (account: AnyWallet, txId: string, reThrowError: boolean = false) => async (dispatch: Dispatch): Promise<void> => {
  try {
    const { data } = await RestService.request('insightService::getTx', account.blockchain, txId)
    if (!data) {
      return
    }

    const utils = getUtils(account)
    dispatch(addTransactionToAccount(account.id, utils.formatBlockExplorerTx(data, account.address)))
  } catch (e) {
    Logger.error(e, {
      account: { ...account, encryptedKey: null },
      txId,
      reThrowError,
    })
    if (reThrowError) {
      throw e
    }
  }
}

const subscribeToAccount = (account: AnyWallet) => async (dispatch, getState: getStateType) => {

  const subscribeTxCallback = async (data): Promise<any> => {
    const { tx } = data
    const methods = getMethods(account)
    const formattedTx = methods.formatBlockExplorerTx(tx, account.address)
    dispatch(addTransactionToAccount(account.id, formattedTx))
    const payrollStep = get(getState(), 'payroll.currentStep', null)
    if (![
      PAYROLL_STEPS.payrollRun,
      PAYROLL_STEPS.transactionsSend,
      PAYROLL_STEPS.transactionsTie,
    ].includes(payrollStep)) {
      CacheService.clearCache(account.address)
    }

    if (!transactionByIdSelector(account.id, tx.txid)(getState()) && tx.confirmations === 0) {
      // if tx not in account transaction list, add tx and increase balance
      dispatch(actions.increaseAccountBalance(
        account.id,
        formattedTx.amount.multipliedBy(methods.decimals),
        formattedTx.amount,
      ))
    } else {
      dispatch(updateBalanceForAccount(account))
    }

  }

  /**
   * {tx: "cc9bee78d442b2988f915ca92041f2be888ce72f5390325e01982d4706ac877f", confirmation: 1}
   * @param data
   */
  const subscribeConfirmationCallback = (data) => {
    const { confirmation, hash, tx } = data
    const payrollStep = get(getState(), 'payroll.currentStep', null)
    if (![
      PAYROLL_STEPS.payrollRun,
      PAYROLL_STEPS.transactionsSend,
      PAYROLL_STEPS.transactionsTie,
    ].includes(payrollStep) && confirmation >= 1) {
      CacheService.clearCache(account.address)
    }

    dispatch(loadTxById(account, hash || tx))
    dispatch(updateBalanceForAccount(account))
  }

  const methods = getMethods(account)
  dispatch(methods.subscribeAccount(account, subscribeTxCallback, subscribeConfirmationCallback))
}

export const getAccountFromLocalStorage = (profileId: string) => {

  const storageAccounts: string | null | void = localStorage.getItem(ACCOUNTS_STORAGE_KEY)
  if (!storageAccounts || !JSON.parse(storageAccounts)) {
    return []
  }

  const accountList = JSON.parse(storageAccounts) || []
  const profileIdHash = CryptoJS.MD5(profileId).toString()

  return accountList
    .filter((account) => account.hash === profileIdHash)
    .map((account) => new AccountModel({
      ...account,
      balance: new BigNumber(0),
      formattedBalance: new BigNumber(0),
      balanceLoadingState: LOADING_STATE_LOADING,
    }))
}

export const loadAccounts = (profileId: string) => async (dispatch: Dispatch) => {
  const accountList = getAccountFromLocalStorage(profileId)

  const remoteAccounts = await dispatch(loadAccountsFromService())
  remoteAccounts.forEach((remoteAccount) => {
    const localAccount = accountList.find((a) => a.id === remoteAccount.id)

    const account = localAccount
      ? new AccountModel({
        ...localAccount,
        ...remoteAccount,
        type: localAccount.type,
      })
      : new AccountModel({
        ...remoteAccount,
        type: remoteAccount.type === ACCOUNT_TYPE_TREZOR ? ACCOUNT_TYPE_TREZOR : ACCOUNT_TYPE_COLD,
      })
    dispatch(actions.addAccount(account))
    dispatch(updateBalanceForAccount(account))
    dispatch(subscribeToAccount(account))
  })
}

export const loadAccountsFromService = () => async (): Promise<Array<ColdWallet>> => {
  try {
    const result = await RestService.request('walletService::loadAccounts')
    if (result.data.error) {
      const err = new Error('Load accounts error ')
      Logger.error(err, result.data)
      throw err
    }
    const accounts = result.data.result.wallets

    return accounts.map((account) => transformRemoteAccountToLocal(account))

  } catch (e) {
    Logger.error(e)
    return []
  }
}

export const addTransactionToAccount = (accountId: string, transaction: Transaction) => async (dispatch: Dispatch, getState: getStateType) => {
  const account = accountByIdSelector(accountId)(getState())
  if (!account) {
    return null
  }

  const totalTxList = {
    ...get(account, 'transactions.list', {}),
    [transaction.txId]: transaction,
  }

  dispatch(actions.updateAccount(new AccountModel({
    ...account,
    transactions: {
      ...account.transactions,
      list: totalTxList,
    },
  })))
}

const TRANSACTION_LOADING_CACHE = 60000 // seconds

export const getAccountTransactions = (accountId: string, isStartFromFirst: boolean = true, useCacheIfPossible: boolean = true) => async (dispatch: Dispatch, getState: getStateType) => {
  const account = accountByIdSelector(accountId)(getState())
  if (!account) {
    return null
  }

  const transactionsLoadedAt = get(account, 'transactions.loadedAt', null)
  const lastCacheAt = +new Date() - transactionsLoadedAt
  if (useCacheIfPossible && transactionsLoadedAt && lastCacheAt < TRANSACTION_LOADING_CACHE) {
    // eslint-disable-next-line
    console.log('Transaction cache seconds ago: ', lastCacheAt)
    return null
  }


  try {
    dispatch(actions.updateAccount(new AccountModel({
      ...account,
      transactions: {
        ...account.transactions,
        list: isStartFromFirst ? {} : account.transactions.list,
        loadingState: LOADING_STATE_LOADING,
      },
    })))
    let txList
    let hasMore
    let lastBlock

    const prevLastBlock = isStartFromFirst ? 0 : get(account, 'transactions.lastBlock', 0)

    const methods = getMethods(account)
    const result = await methods.getAddressTransactions(
      account,
      prevLastBlock,
      TRANSACTIONS_LIMIT,
    )
    txList = { ...result.items }
    hasMore = result.hasMore
    lastBlock = result.lastBlock

    const totalTxList = {
      ...(isStartFromFirst ? {} : get(account, 'transactions.list', {})),
      ...txList,
    }

    dispatch(actions.updateAccount(new AccountModel({
      ...account,
      transactions: {
        ...account.transactions,
        loadMore: hasMore,
        loadingState: LOADING_STATE_LOADED,
        lastBlock,
        list: totalTxList,
        loadedAt: +(new Date()),
      },
    })))
  } catch (e) {
    Logger.error('getAccountTransactions', e, { accountId, isStartFromFirst, useCacheIfPossible })
    dispatch(actions.updateAccount(new AccountModel({
      ...account,
      transactions: {
        ...account.transactions,
        loadingState: LOADING_STATE_ERROR,
      },
    })))
  }
}

export const calculateFeeForAccount = (
  account: AnyWallet,
  to: Array<TransactionTo> | string,
  isSendAll: boolean = false,
) => async (dispatch: Dispatch) => {
  await dispatch(updateSendFormSetting({ loadingState: LOADING_STATE_LOADING }))

  const methods = getMethods(account)
  const multipliers = [1, 2, 3.5]

  const feesList = await dispatch(methods.calculateFee(
    account,
    to,
    multipliers,
    isSendAll,
  ))

  const fees = multipliers.reduce((acc, multiplier, key) => {
    acc[multiplier] = feesList[key]
    return acc
  }, {})

  await dispatch(updateSendFormSetting({ fees, loadingState: LOADING_STATE_LOADED }))
  return fees
}

type sendTransactionParamsType = {
  account: HotWallet,
  tx: TransactionSend,
  password: string,
  multiplier: number,
  isSendAll: boolean,
  returnValues?: boolean,
  reThrowError?: boolean,
}
export const sendTransaction = (params: sendTransactionParamsType) => async (dispatch: Dispatch, getState: getStateType) => {
  const {
    account,
    tx,
    password,
    multiplier,
    isSendAll,
    returnValues,
    reThrowError,
  } = params

  const methods = getMethods(account)

  try {
    const { hash, value: valueTx, fee } = await dispatch(methods.sendTransaction({
      account,
      tx,
      password: password,
      multiplier,
      isSendAll,
      returnValues: true,
      reThrowError: !!reThrowError,
    }))

    if ([BLOCKCHAIN_BITCOIN, BLOCKCHAIN_BITCOIN_TESTNET].includes(account.blockchain) && !transactionByIdSelector(account.id, hash)(getState())) {
      dispatch(increaseAccountBalanceAndAddEmptyTx(account, {
        value: valueTx,
        fee: new BigNumber(fee).dividedBy(BITCOIN_DECIMALS),
        amount: valueTx.plus(fee).multipliedBy(-1), // value + fee
        to: tx.to,
        hash,
      }))
    }
    if (returnValues) {
      return {
        hash,
        value: valueTx,
        fee,
      }
    }
    return hash
  } catch (e) {
    Logger.error(e, {
      account: { ...account, encryptedKey: null },
      tx,
      isSendAll,
      returnValues,
      reThrowError,
    })
    if (reThrowError) throw new Error(e)
  }
}

type simpleTxType = {
  value: BigNumber,
  fee: BigNumber,
  amount: BigNumber, // value + fee
  to: Array<TransactionTo>,
  hash: string,
}
export const increaseAccountBalanceAndAddEmptyTx = (account: AnyWallet, simpleTx: simpleTxType) => (dispatch: Dispatch) => {
  dispatch(actions.increaseAccountBalance(
    account.id,
    simpleTx.amount,
    simpleTx.amount.dividedBy(BITCOIN_DECIMALS),
  ))
  const toArray = simpleTx.to.map((to: { address: string }) => to.address)
  dispatch(addTransactionToAccount(account.id, {
    txId: simpleTx.hash,
    time: moment(),
    block: null,
    fees: simpleTx.fee.toString(),
    feeBn: simpleTx.fee,
    symbol: BITCOIN_SYMBOL,
    blockchain: BLOCKCHAIN_BITCOIN,
    confirmations: 0,
    amount: convertToBigNumber(account, simpleTx.amount),
    type: TRANSACTION_ICON_NAME_PENDING,
    isPositive: simpleTx.amount.gt(0),
    isPending: true,
    fromAddress: [account.address],
    fromAddressText: account.address,
    toAddress: toArray,
    toAddressText: toArray.join(', '),
    from: [{ address: account.address }],
    to: toArray.map((address) => ({ address })),
  }))
}

type valuesType = {
  cryptoAmount: string,
  email: string,
  message: string,
  blockchain: string
}
export const sendReceiveMessageToEmail = (values: valuesType, address: string) => () => {
  return RestService.request('paymentRequestsService::sendReceiveMessageToEmail', {
    'amount': values.cryptoAmount,
    'recipientWalletAddress': address,
    'paymentSenderEmail': values.email,
    'blockchain': values.blockchain,
    'message': values.message,
  })
}

export const recoverAccountPassword = ({ mnemonic, password }: { mnemonic: string, password: string }, account: HotWallet) => async (dispatch: Dispatch) => {
  try {
    dispatch(updateLoadingState(LOADING_STATE_LOADING))
    const hotWallet: HotWallet = new AccountModel({
      ...account,
      encryptedKey: CryptoJS.AES.encrypt(mnemonic, password).toString(),
    })

    await dispatch(actions.updateAccount(hotWallet))
    await saveAccountToLocalStorage(hotWallet)
    await dispatch(updateLoadingState(LOADING_STATE_LOADED))
    return true
  } catch (e) {
    Logger.error(e)
    dispatch(updateLoadingState(LOADING_STATE_ERROR))
    return false
  }
}

export type defaultAccountType = { blockchain: string, account: string }
export const setDefaultAccounts = (accounts: Array<defaultAccountType>) => async (dispatch: Dispatch, getState: getStateType) => {
  const result = await RestService.request('walletService::setDefaultAccounts', accounts.map(({ account }) => account))
  if (isResponseValid(get(result, 'data.code', null))) {
    const profile = profileInfoSelector(getState())
    const accountList = getAccountFromLocalStorage(profile.id)
    const remoteAccounts = await dispatch(loadAccountsFromService())
    remoteAccounts.forEach((remoteAccount) => {
      const localAccount = accountList.find((a) => a.id === remoteAccount.id)
      const account = localAccount
        ? new AccountModel({
          ...localAccount,
          ...remoteAccount,
          type: localAccount.type,
        })
        : remoteAccount
      dispatch(actions.addAccount(account))
      dispatch(updateBalanceForAccount(account))
    })
  }
}

export const getTrezorWalletInfo = async (blockchain: string) => {
  try {
    const methods = getMethods({ blockchain })
    const address = await methods.getTrezorWalletAddress(blockchain)
    return {
      address,
      isTestnet: isTestnetAccount({ blockchain }),
      blockchain: blockchain,
    }

  } catch (e) {
    Logger.error(e, { blockchain })
  }
}

type sendTransactionType = {
  account: HotWallet,
  tx: TransactionSend,
  password: string,
  multiplier?: number,
  isSendAll: boolean,
  reThrowError: boolean,
  returnValues?: boolean,
}

export const signTransaction = ({ account, tx, password, multiplier, isSendAll, reThrowError, returnValues }: sendTransactionType) => {
  const methods = getMethods(account)
  return methods.signTransaction({
    account,
    tx,
    password,
    multiplier: multiplier || 1,
    isSendAll,
    reThrowError,
    returnValues,
  })
}
