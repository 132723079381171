export const PAYMENT_REQUESTS_DUCK = 'paymentRequests'
export const PAYMENT_REQUESTS_SET_LIST = 'paymentRequests/setList'
export const PAYMENT_REQUESTS_SET_LOADING_STATE = 'paymentRequests/setLoadingState'
export const PAYMENT_REQUESTS_UPDATE = 'paymentRequests/update' // not add
export const PAYMENT_REQUESTS_DELETE = 'paymentRequests/delete'
export const PAYMENT_REQUESTS_MULTIPLE_UPDATE = 'paymentRequests/multipleUpdate'
export const PAYMENT_REQUESTS_REMOVE = 'paymentRequests/remove'
export const PAYMENT_REQUESTS_SORT_LIST = 'paymentRequests/sortList'
export const PAYMENT_REQUESTS_UPDATE_FORM_LOADING_STATE = 'paymentRequests/updateFormLoadingState'
export const PAYMENT_REQUESTS_UPDATE_USERS_HINT = 'paymentRequests/updateUsersHins'

export const PAYMENT_REQUEST_STATUS_DRAFT = 'Draft'
export const PAYMENT_REQUEST_STATUS_PENDING_APPROVAL = 'PendingApproval'
export const PAYMENT_REQUEST_STATUS_PENDING_APPROVAL_FORMAT = 'Pending'
export const PAYMENT_REQUEST_STATUS_DECLINED = 'Declined'
export const PAYMENT_REQUEST_STATUS_UNPAID = 'Unpaid'
export const PAYMENT_REQUEST_STATUS_IN_PROGRESS = 'InProgress'
export const PAYMENT_REQUEST_STATUS_IN_PROGRESS_FORMATTED = 'In Progress'
export const PAYMENT_REQUEST_STATUS_PAID = 'Paid'
export const PAYMENT_REQUEST_STATUS_CANCELLED = 'Cancelled'
export const PAYMENT_REQUEST_STATUS_FAILED = 'Failed'
export const PAYMENT_REQUEST_STATUS_APPROVED = 'Approved'
export const PAYMENT_REQUEST_STATUS_CAPTURED = 'CAPTURED'
export const PAYMENT_REQUEST_STATUS_DELETED = 'DELETED'

export const PAYMENT_REQUEST_COLLECTION_PENDING = 'Pending'
export const PAYMENT_REQUEST_COLLECTION_DRAFT = 'Draft'
export const PAYMENT_REQUEST_COLLECTION_PAID = 'Paid'

export const PAYMENT_REQUEST_MAINNET_NETWORK_KEY = 'mainnet'
export const PAYMENT_REQUEST_TESTNET_NETWORK_KEY = 'testnet'
export const PAYMENT_REQUEST_RINKEBY_NETWORK_KEY = 'rinkeby'
