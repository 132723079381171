import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash.get'
import PropTypes from 'prop-types'
import MobileMessage, { isNeedToWarnMobileUser } from '../../containers/PageContainer/MobileMessage'
import {
  checkEmail,
  confirmCode,
  goToMainPage,
  isAuthenticated,
  resendConfirmationCode,
  resetLogin, resetPassword,
  signIn,
  signUp,
  updateResetPassword,
} from '../../ducks/auth/thunks'
import {
  authSelector,
  checkEmailLoadingStateSelector,
  loginEmailSelector,
} from '../../ducks/auth/selectors'
import { LOADING_STATE_LOADED } from '../../constants'
import { updateAuth, updateAuthStatus } from '../../ducks/auth/actions'
import styles from './Login.module.scss'
import SignInStep from './SignInStep'
import CreatePasswordStep from './CreatePasswordStep'
import EmailStep from './EmailStep'
import ConfirmCodeStep from './ConfirmCodeStep'
import { AUTH_FLOW_RESET_PASSWORD, AUTH_FLOW_SIGN_IN, AUTH_FLOW_SIGN_UP } from '../../ducks/auth/constants'

const STEP_EMAIL = 'emailStep'
const STEP_SIGN_IN = 'signInStep'
const STEP_SIGN_UP = 'signUpStep'
const STEP_CONFIRM_CODE = 'confirmCodeStep'

class Login extends React.PureComponent {
  static propTypes = {
    handleUpdateResetPassword: PropTypes.func,
    handleRecallCode: PropTypes.func,
    handleResetPassword: PropTypes.func,
    signIn: PropTypes.func,
    signUp: PropTypes.func,
    confirmCode: PropTypes.func,
    checkEmail: PropTypes.func,
    resetLogin: PropTypes.func,
    resetEmailData: PropTypes.func,
    auth: PropTypes.shape({
      loadingState: PropTypes.string,
      error: PropTypes.string,
      result: PropTypes.number,
      email: PropTypes.string,
      authFLow: PropTypes.string,
    }),
    isAuthenticated: PropTypes.bool,
    checkEmailLoadingState: PropTypes.string,
    loginEmail: PropTypes.string,
    dropErrors: PropTypes.func,
    goToMainPage: PropTypes.func,
    hideConfirmCodeMessage: PropTypes.func,
  }

  constructor (props) {
    super(props)
    this.state = {
      step: STEP_EMAIL,
    }
  }

  static getDerivedStateFromProps (nextProps) {
    const isCodeConfirmed = get(nextProps, 'auth.isCodeConfirmed', false)
    const isProfileConfirmed = get(nextProps, 'auth.isProfileConfirmed', false)
    const confirmCodeSendTime = get(nextProps, 'auth.confirmCodeSendTime', false)
    const authFlow = get(nextProps, 'auth.authFlow', false)

    switch (true) {
      case [AUTH_FLOW_SIGN_UP, AUTH_FLOW_RESET_PASSWORD].includes(authFlow) && confirmCodeSendTime > 0:
        return {
          step: STEP_CONFIRM_CODE,
        }
      case [AUTH_FLOW_SIGN_UP, AUTH_FLOW_RESET_PASSWORD].includes(authFlow) && isCodeConfirmed === true:
        return {
          step: STEP_SIGN_UP,
        }
      case authFlow === AUTH_FLOW_SIGN_IN && isProfileConfirmed === true:
        return {
          step: STEP_SIGN_IN,
        }
      default:
        return {
          step: STEP_EMAIL,
        }
    }
  }


  componentDidMount () {
    if (this.props.isAuthenticated) {
      this.props.goToMainPage()
    }
  }

  componentWillUnmount () {
    this.props.resetEmailData()
  }

  handleRecallCode = () => {
    this.props.handleRecallCode(this.props.auth.email)
  }

  renderSignInMessage = (type) => {
    switch (type) {
      case 400:
        return 'Either email or password is incorrect'
      case 401:
        return 'E-mail is not confirmed'
      case 'error':
        return 'Something went wrong'
      default:
        return this.props.auth.error
    }
  }

  renderSignUpMessage (auth, { email }) {
    switch (auth.result) {
      case 400:
        return 'User with the provided email already exists'
      case 201:
        return `An email has been sent to ${email || auth.email}. Please check your email and follow the instructions to confirm this address and sign in`
      case 'error':
        return 'Something went wrong'
      default:
        return auth.error
    }
  }

  handleResetLogin = () => {
    this.props.resetLogin()
  }

  renderForms = () => {
    switch (this.state.step) {
      case STEP_SIGN_UP: {
        return <CreatePasswordStep
          auth={this.props.auth}
          signUp={this.props.signUp}
          renderSignUpMessage={this.renderSignUpMessage}
          handleResetLogin={this.handleResetLogin}
          handleUpdateResetPassword={this.props.handleUpdateResetPassword}
        />
      }
      case STEP_SIGN_IN: {
        return <SignInStep
          auth={this.props.auth}
          signIn={this.props.signIn}
          renderSignInMessage={this.renderSignInMessage}
          handleResetLogin={this.handleResetLogin}
          handleResetPassword={this.props.handleResetPassword}
        />
      }
      case STEP_CONFIRM_CODE: {
        return <ConfirmCodeStep
          auth={this.props.auth}
          confirmCode={this.props.confirmCode}
          signIn={this.props.signIn}
          renderSignUpMessage={this.renderSignUpMessage}
          handleResetLogin={this.handleResetLogin}
          handleRecallCode={this.handleRecallCode}
        />
      }
      default: {
        return <EmailStep
          auth={this.props.auth}
          checkEmailLoadingState={this.props.checkEmailLoadingState}
          checkEmail={this.props.checkEmail}
          renderSignInMessage={this.renderSignInMessage}
        />
      }
    }
  }

  render () {
    if (isNeedToWarnMobileUser()) {
      return <MobileMessage />
    }
    const { step } = this.state
    return (
      <div className={styles.container}>
        <div className={styles.loginWrapper}>
          <div className={styles.logo} />
        </div>
        <div className={styles.formContainer}>
          <div className={styles.persons + ' hide-mobile'}>
            <div className={`${styles.checkMail} ${step === STEP_EMAIL ? styles.show : ''}`} />
            <div className={`${styles.signIn} ${step === STEP_SIGN_IN ? styles.show : ''}`} />
            <div className={`${styles.signUp} ${step === STEP_SIGN_UP ? styles.show : ''}`} />
            <div className={`${styles.checkCode} ${step === STEP_CONFIRM_CODE ? styles.show : ''}`} />
          </div>
          {this.renderForms()}
        </div>
        <div className={styles.footer + ' capture gray-70'}>© PaymentX 2020</div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const auth = authSelector(state)
  return {
    auth,
    isAuthenticated: isAuthenticated(),
    checkEmailLoadingState: checkEmailLoadingStateSelector(state),
    loginEmail: loginEmailSelector(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  signIn: (values) => dispatch(signIn(values)),
  signUp: (values) => dispatch(signUp(values)),
  resetLogin: () => dispatch(resetLogin()),
  checkEmail: (email) => dispatch(checkEmail(email)),
  confirmCode: (email: string, code: string) => dispatch(confirmCode(email, code)),
  resetEmailData: () => dispatch(updateAuth({
    isCodeConfirmed: null,
    isProfileConfirmed: null,
    confirmCodeSendTime: null,
    email: null,
    secretCode: null,
    authFlow: AUTH_FLOW_SIGN_IN,
  })),
  dropErrors: () => dispatch(updateAuthStatus(LOADING_STATE_LOADED, null, null)),
  goToMainPage: () => dispatch(goToMainPage()),
  handleRecallCode: (email) => dispatch(resendConfirmationCode(email)),
  handleResetPassword: () => dispatch(resetPassword()),
  handleUpdateResetPassword: (values) => dispatch(updateResetPassword(values)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
