import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styles from './ErrorPage.module.scss'
import { withRouter } from 'react-router-dom'
import Button from '../../components/Button/Button'
import { errorPageDataSelector } from '../../ducks/ui/selectors'

class ErrorPage extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
  }

  render () {
    return (
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.image} />
          <div className='h1 gray-50'>{this.props.title || 'Oooops!'}</div>
          <div className='h5 gray-50'>{this.props.subTitle || 'Something went wrong.'}</div>
          <Button colorType='new-filled' onClick={() => {
            window.location = '/'
          }}>Try again</Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...errorPageDataSelector(state),
  }
}

export default connect(mapStateToProps, null)(withRouter(ErrorPage))
