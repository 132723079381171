import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

export default class BigPopup extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    children: PropTypes.node,
    smallMode: PropTypes.bool,
  }

  constructor (props) {
    super(props)
    this.state = {
      loaded: false,
    }
  }

  componentDidMount () {
    setTimeout(() => {
      this.setState({
        loaded: true,
      })
    }, 100)
  }

  handleClose = () => {
    this.setState({
      loaded: false,
    }, () => setTimeout(this.props.onClose, 600))
  }

  render () {
    let className = ''
    if (this.state.loaded) {
      className = ' opened '
    }

    const childrenWithProps = React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, { onClose: this.handleClose }),
    )

    if (this.props.smallMode) {
      return (
        <div className={styles.smallPanelContainer}>
          <div className={styles.smallPanelBackground + className} />
          <div className={styles.smallPanel + className}>
            {childrenWithProps}
          </div>
        </div>
      )
    }
    return (
      <div className={styles.panel + className}>
        {childrenWithProps}
      </div>
    )
  }
}
