import { connect } from 'react-redux'
import cn from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import styles from './PaymentAssetsTable.module.scss'
import CustomSelect from '../../../components/Select/CustomSelect'
import { ACCOUNTS_ACTIVE_BLOCKCHAINS } from '../../../ducks/accounts/constants'
import AccountSelect from '../../../components/Select/AccountSelect'
import { accountsSelector } from '../../../ducks/accounts/selectors'
import { isTestnetAccount } from '../../../ducks/accounts/utils'
import Icon from '../../../components/Icon/Icon'
import { getMainSymbolForBlockchain } from "../../../utils/general"

const PaymentAssetsTable = ({ accounts, formikProps }) => {
  const selectStyles = {
    control: (defaultStyles) => {
      return {
        ...defaultStyles,
        borderColor: 'transparent !important',
        boxShadow: 'none !important',
        backgroundColor: '#ffffff',
        borderRadius: '0px',
        minHeight: '50px',
      }
    },
  }
  const {
    values,
    setFieldValue,
  } = formikProps

  const usedBlockchaines = [...values.defaultAccounts
    .reduce((acc, account) => {
      if (account.blockchain) {
        acc.add(account.blockchain)
      }
      return acc
    }, new Set())]
  return (
    <div className={styles.paymentAssetsTableWrapper}>
      <div className={cn(styles.titles, 'capture-small', 'gray-50')}>
        <div>Asset</div>
        <div>Default wallet</div>
      </div>
      <div className={styles.table}>
        <div className={styles.content}>
          {values.defaultAccounts.map(({ blockchain, account }, i) => {
            const accountValue = accounts.find((option) => option.id === account)
            return (
              <React.Fragment key={`${blockchain}-${account}-${i}`}>
                <div>
                  <CustomSelect
                    onChange={(value) => {
                      setFieldValue(`defaultAccounts[${i}]`, {
                        'blockchain': value.value,
                        'account': !value || (accountValue && accountValue.blockchain === value.value) ? account : null,
                      })
                    }}
                    placeholder='Select blockchain'
                    name='itemsType'
                    value={ACCOUNTS_ACTIVE_BLOCKCHAINS.find((option) => option.value === blockchain)}
                    options={ACCOUNTS_ACTIVE_BLOCKCHAINS.filter((option) => !usedBlockchaines.includes(option.value))}
                    styles={selectStyles}
                  />
                </div>
                <div>
                  <AccountSelect
                    formikProps={formikProps}
                    selectProps={{
                      isCreatableSelect: false,
                      options: accounts.filter((account) => {
                        return blockchain ? account.blockchain === blockchain : true
                      }),
                      onChange: (value) => {
                        setFieldValue(`defaultAccounts[${i}].account`, value.id)
                      },
                      value: accountValue,
                    }}
                    selectStyles={selectStyles}
                  />
                </div>
              </React.Fragment>
            )
          })
          }
        </div>
        <div className={styles.removedActions}>
          {values.defaultAccounts.map(({ blockchain, account }, i) => {
            return <button
              type='button'
              onClick={() => {
                if (values.defaultAccounts.length <= 1) {
                  setFieldValue('defaultAccounts', [{
                    blockchain: null,
                    account: null,
                  }])
                } else {
                  setFieldValue('defaultAccounts', values.defaultAccounts.filter((account, j) => {
                    return j !== i
                  }))
                }
              }}
              key={`${blockchain}-${account}-${i}`}
            >
              <Icon type='close' colorType='gray-50' />
            </button>
          })}
        </div>
      </div>
      {values.defaultAccounts.length !== ACCOUNTS_ACTIVE_BLOCKCHAINS.length
        ? (
          <div className={styles.actionWrapper}>
            <button
              type='button'
              onClick={() => {
                setFieldValue('defaultAccounts', [
                  ...values.defaultAccounts,
                  {
                    blockchain: null,
                    account: null,
                  },
                ])
              }}>
              <Icon type='plus-circle' colorType='blue' />
              <span className='capture blue-bk'>Add another asset</span>
            </button>
          </div>
        ) : null}
    </div>
  )
}

PaymentAssetsTable.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({
    address: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    blockchain: PropTypes.string,
    symbol: PropTypes.string,
  })),
  formikProps: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    values: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    submitForm: PropTypes.func,
    setFieldValue: PropTypes.func,
    submitCount: PropTypes.number,
  }),
}

const mapStateToProps = (state) => {
  const accounts = accountsSelector(state)
    .map((account) => {
      return {
        id: account.id,
        name: account.name,
        address: account.address,
        isTestnet: isTestnetAccount(account),
        blockchain: account.blockchain,
        symbol: account.symbol || getMainSymbolForBlockchain(account.blockchain),
      }
    })
  return {
    accounts,
  }
}

export default connect(mapStateToProps)(PaymentAssetsTable)
