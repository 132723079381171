//@flow
import { connect } from 'react-redux'
import React from 'react'
import PropTypes from 'prop-types'
import AccountTransactionsListComponent from './AccountTransactionsListComponent'
import { getAccountTransactions } from '../../ducks/accounts/thunks'
import { accountByIdSelector, lastBlockSelector } from '../../ducks/accounts/selectors'
import { AccountModel } from '../../models/Accounts/AccountModel'

import type { AnyWallet } from '../../ducks/accounts/models'

type Props = {
  account: AnyWallet,
  getTransactions: (account: AnyWallet, isStartFromFirst?: boolean, userCache?: boolean) => void,
  lastBlock: {
    number: number,
  }
}

class AccountTransactionsListContainer extends React.PureComponent<Props> {
  static propTypes = {
    account: PropTypes.instanceOf(AccountModel),
    lastBlock: PropTypes.object,
    getTransactions: PropTypes.func,
  }

  componentDidMount () {
    this.props.getTransactions(this.props.account)
  }

  handleLoadMore = () => {
    this.props.getTransactions(this.props.account, false, false)
  }

  render () {
    return (
      <AccountTransactionsListComponent
        {...this.props}
        lastBlock={this.props.lastBlock}
        handleLoadMore={this.handleLoadMore}
      />
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    account: accountByIdSelector(props.account.id)(state),
    lastBlock: lastBlockSelector(props.account.blockchain)(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTransactions: (account, isStartFromFirst, useCache = true) => dispatch(getAccountTransactions(account.id, isStartFromFirst, useCache)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountTransactionsListContainer)
