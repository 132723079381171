import React from 'react'
import ItemsTableTitles from './ItemsTableTitles'
import PropTypes from 'prop-types'
import ItemsTableInputs from './ItemsTableInputs'
import Icon from '../../../components/Icon/Icon'
import styles from '../AddInvoiceCard.module.scss'
import cn from 'classnames'

const ItemsTable = ({ formikProps, handleClickEnter, handleRemoveItem, handleAddItem, totalValue, isShowRequireError, requiredFieldsErrors }) => {
  const {
    values,
  } = formikProps

  return (
    <>
      <div
        className={cn(
          styles.itemTableTitle,
          styles.itemTable,
          'capture',
          'gray-50',
          { [styles.itemTableBodyAmountType]: values.itemsType === 'amount' },
        )}
      >
        {<ItemsTableTitles {...values} />}
      </div>

      <div className={styles.itemTableWrapper}>
        <div className={cn(
          styles.itemTable,
          styles.itemTableBody,
          { [styles.itemTableBodyAmountType]: values.itemsType === 'amount' },
          'capture',
          'gray-70',
        )}>
          {values.items.map((item, key) => {
            return <ItemsTableInputs
              isShowRequireError={isShowRequireError}
              requiredFieldsErrors={requiredFieldsErrors}
              formikProps={formikProps}
              item={item}
              key={key}
              itemKey={key}
              handleClickEnter={handleClickEnter} />
          })}
        </div>
        <div className={styles.itemActions}>
          {values.items.map((item, key) => {
            return <button
              type='button'
              key={key}
              onClick={handleRemoveItem(formikProps, key)}
            ><Icon type='close' colorType='gray-30' /></button>
          })}
        </div>
      </div>
      <div className={styles.itemTotalWrapper}>
        <button
          type='button'
          className='blue-bk capture'
          onClick={handleAddItem}
        ><Icon type='plus' colorType='blue' /> Add another item
        </button>
        <div>
          <span className='capture gray-30'>Total</span>
          <span className='capture gray-70'>{totalValue} {values.currency}</span>
        </div>
      </div>
    </>
  )
}

ItemsTable.propTypes = {
  handleClickEnter: PropTypes.func,
  handleRemoveItem: PropTypes.func,
  handleAddItem: PropTypes.func,
  totalValue: PropTypes.string,
  formikProps: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    values: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    submitForm: PropTypes.func,
    setFieldValue: PropTypes.func,
    submitCount: PropTypes.number,
  }),
  isShowRequireError: PropTypes.bool,
  requiredFieldsErrors: PropTypes.object,
}

export default ItemsTable
