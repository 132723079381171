import PropTypes from 'prop-types'
import React from 'react'
import { getTrezorWalletInfo } from "../../ducks/accounts/thunks"
import { LOADING_STATE_LOADED, LOADING_STATE_LOADING } from "../../constants"
import LoadingState from "../LoadingState"
import styles from './TrezorConnectButton.module.scss'
import cn from 'classnames'
import Icon from "../Icon/Icon"
import Logger from '../../services/Logger'

function TrezorConnectButton ({ className, account, callback, children, label }) {

  const loadingState = account.loadingState

  function handleClick (event) {
    event.stopPropagation()
    event.preventDefault()
    callback({
      ...account,
      loadingState: LOADING_STATE_LOADING,
    })
    getTrezorWalletInfo(account.blockchain)
      .then((walletData) => {
        callback({
          ...account,
          ...walletData,
          loadingState: LOADING_STATE_LOADED,
        })
      })
      .catch((err) => {
        callback({
          ...account,
          loadingState: null,
        })
        Logger.error(err)
      })
  }

  return (
    <button
      className={cn(className, styles.button, { [styles.loading]: loadingState === LOADING_STATE_LOADING })}
      onClick={handleClick}
      disabled={loadingState === LOADING_STATE_LOADING}
    >{children
    || (
      <div className={styles.buttonContent}>
        {loadingState === LOADING_STATE_LOADING
          ? (<LoadingState
            loadingState={loadingState}
            className={styles.loaderWrapper}
            loaderClassName={styles.loader} />)
          : <Icon type='trezor' colorType='blue' />}
        <div className='capture blue-bk'>{label || 'Connect'}</div>
      </div>
    )}</button>
  )
}

TrezorConnectButton.propTypes = {
  account: PropTypes.shape({
    blockchain: PropTypes.string.isRequired,
    loadingState: PropTypes.string,
  }),
  className: PropTypes.string,
  callback: PropTypes.func.isRequired,
  children: PropTypes.node,
  label: PropTypes.string,
}

export default TrezorConnectButton
