import get from 'lodash.get'
import { connect } from 'react-redux'
import React from 'react'
import PropTypes from 'prop-types'
import { accountsSelector } from '../../ducks/accounts/selectors'
import { createInvoice, getRequestLogo, removePaymentRequest } from '../../ducks/paymentRequests/thunks'
import {
  formLoadingStateSelector,
  requestByIdSelector,
  usersHintSelector,
} from '../../ducks/paymentRequests/selectors'
import AddInvoiceCardComponent from './AddInvoiceCardComponent'
import validate from './validate'
import { removePaymentRequestLocal, updateFormLoadingState } from '../../ducks/paymentRequests/actions'
import { TYPE_AMOUNT } from './constants'
import { isTestnetAccount } from '../../ducks/accounts/utils'
import BigNumber from 'bignumber.js'
import { PaymentRequestModel } from '../../models/PaymentRequests/PaymentRequestModel'
import { BLOCKCHAIN_BITCOIN } from '../../ducks/bitcoin/constants'
import { currentCurrencySelector, profileInfoSelector } from '../../ducks/profile/selectors'
import { checkNetworkOfAddress } from '../../ducks/bitcoin/utils'
import { PAYMENT_REQUEST_STATUS_DELETED, PAYMENT_REQUEST_STATUS_DRAFT } from '../../ducks/paymentRequests/constants'
import { getMainSymbolForBlockchain } from "../../utils/general"

class AddInvoiceCardContainer extends React.PureComponent {
  static propTypes = {
    templateRequestId: PropTypes.string,
    removePaymentRequestLocal: PropTypes.func,
    removePaymentRequest: PropTypes.func,
    profile: PropTypes.object,
    billToSearchList: PropTypes.object,
    request: PropTypes.instanceOf(PaymentRequestModel),
    templateRequest: PropTypes.instanceOf(PaymentRequestModel),
    openInvoiceCard: PropTypes.func,
    currentCurrency: PropTypes.string,
    onClose: PropTypes.func,
    accounts: PropTypes.arrayOf(PropTypes.shape({
      address: PropTypes.string,
      type: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      symbol: PropTypes.string,
    })),
    handleCreateInvoice: PropTypes.func,
  }

  constructor (props) {
    super(props)
    this.state = {
      isSent: false,
      isDeleteSent: false,
      initialValues: null,
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.request) {
      return {
        request: props.request,
      }
    }
    return {
      request: state.request,
    }
  }

  componentDidMount () {
    const initialValues = this.getInitialValues()
    this.setState({ initialValues })
  }

  getInitialValues = (templateRequest) => {

    const { accounts, currentCurrency } = this.props
    let request
    if (templateRequest || this.props.templateRequest || this.props.request) {
      request = new PaymentRequestModel({ ...(templateRequest || this.props.templateRequest || this.props.request) })
    }

    const invoiceNumber = get(request, 'invoice.number', null)
    const version = get(request, 'invoice.version', null)
    const requestStatus = get(request, 'status', null)

    let totalNumber = invoiceNumber + '.' + (version + 1)
    if (requestStatus === PAYMENT_REQUEST_STATUS_DRAFT || templateRequest || this.props.templateRequest) {
      totalNumber = invoiceNumber
    }

    const blockchain = get(request, 'paymentBlockchain', false)
      || (
        get(request, 'paymentWallet', false)
          ? checkNetworkOfAddress(request.paymentWallet)
          : BLOCKCHAIN_BITCOIN
      )

    let initialAccount = null

    if (get(request, 'paymentWallet', false)) {
      initialAccount = accounts.find((a) => a.address === request.paymentWallet && blockchain === a.blockchain)
        || {
          value: request.paymentWallet,
          label: request.paymentWallet,
          __isNew__: true,
        }
    } else {
      initialAccount = accounts.find((account) => blockchain === account.blockchain && account.defaultAccount)
        || accounts.find((account) => blockchain === account.blockchain)
    }

    const due = new Date()
    due.setDate(due.getDate() + 14)

    let items = [
      this.emptyItems[TYPE_AMOUNT].reduce((acc, field) => {
        return {
          ...acc,
          [field]: '',
        }
      }, {}),
    ]

    if (get(request, 'invoice.items', false)) {
      items = get(request, 'invoice.items', [])
        .map((item) => {
          if (Object.prototype.hasOwnProperty.call(item, 'amount')) {
            item.amount = item.amount ? new BigNumber(item.amount).toFixed(2) : ''
          }
          if (Object.prototype.hasOwnProperty.call(item, 'rate')) {
            item.rate = item.rate ? new BigNumber(item.rate).toFixed(2) : ''
          }
          return item
        })
    }
    const logo = get(request, 'invoice.logo', null)
    // remove attachmentId if request copied from another
    if ((templateRequest || this.props.templateRequest) && logo) {
      delete logo.attachmentId
    }

    return {
      requestId: this.props.request ? get(request, 'requestId', null) : null,
      invoiceNumber: invoiceNumber ? totalNumber : '',
      subtitle: get(request, 'invoice.title', ''),
      billTo: get(request, 'userEmail', ''),
      billFrom: get(request, 'invoice.billFrom', this.props.profile.fromDescription),
      currency: get(request, 'settlementAsset', currentCurrency),
      items,
      statusText: null,
      due: (requestStatus === PAYMENT_REQUEST_STATUS_DRAFT || templateRequest || this.props.templateRequest)
        ? due
        : new Date(get(this.props.request, 'invoice.due', due)), // due must be today + 2 weeks or filled from this.props.request in edit mode.
      comment: get(request, 'notes', ''),
      blockchain,
      account: initialAccount,
      attachments: this.props.request ? get(request, 'invoice.attachments', []) : [],
      logo,
      itemsType: get(request, 'invoice.items[0].type', TYPE_AMOUNT),
    }
  }

  emptyItems = {
    'amount': [
      'description',
      'amount',
    ],
    'hours': [
      'description',
      'rate',
      'count',
    ],
    'quantity': [
      'description',
      'rate',
      'count',
    ],
  }

  handleSubmit = async (pureValues, formikProps) => {
    const values = { ...pureValues }
    this.setState({
      isSent: true,
    })
    if (values.statusText !== PAYMENT_REQUEST_STATUS_DRAFT) {
      values.items = values.items.filter((item) => {
        return !Object.keys(item).some((field) => !item[field])
      })
    }
    values.attachments = values.attachments.filter((file) => {
      return !file.isErrored
    })
    values.billTo = values.billTo ? values.billTo.trim().toLowerCase() : ''

    const { success, validation } = await this.props.handleCreateInvoice(values)
    if (!success && validation) {
      const errors = {}
      validation.forEach((error) => {
        if (error.field === 'invoice.number') {
          errors.invoiceNumber = error.errors.join(', ')
        }
      })
      if (Object.values(errors).length > 0) {
        this.setState({
          isSent: false,
        })
        formikProps.setErrors(errors)
      }
    }
  }

  handleDelete = (requestId) => () => {
    this.setState({
      isDeleteSent: true,
    })
    this.props.removePaymentRequest(requestId)
  }

  handleResetDeleteFlag = () => {
    this.setState({
      isDeleteSent: false,
    })
  }


  handleClose = () => {
    this.props.onClose()
    setTimeout(() => {
      if (get(this, 'props.request.status', null) === PAYMENT_REQUEST_STATUS_DELETED) {
        this.props.removePaymentRequestLocal(this.props.request.requestId)
      }
    }, 600)
  }

  render () {
    return (
      <div>
        {this.state.initialValues && (
          <AddInvoiceCardComponent
            {...this.props}
            request={this.state.request}
            onClose={this.handleClose}
            handleSubmit={this.handleSubmit}
            handleResetDeleteFlag={this.handleResetDeleteFlag}
            validate={validate}
            emptyItems={this.emptyItems}
            isSent={this.state.isSent}
            isDeleteSent={this.state.isDeleteSent}
            handleDelete={this.handleDelete}
            getInitialValues={this.getInitialValues}
            initialValues={this.state.initialValues}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const request = requestByIdSelector(props.requestId)(state)
  const profile = profileInfoSelector(state)
  const templateRequest = requestByIdSelector(props.templateRequestId)(state)

  const accounts = accountsSelector(state)
    .map((account) => {
      return {
        id: account.id,
        type: account.type,
        name: account.name,
        address: account.address,
        isTestnet: isTestnetAccount(account),
        blockchain: account.blockchain,
        defaultAccount: account.defaultAccount,
        symbol: account.symbol || getMainSymbolForBlockchain(account.blockchain),
      }
    })
  return {
    profile,
    request,
    templateRequest,
    accounts,
    loadingState: formLoadingStateSelector('newInvoiceForm')(state),
    billToSearchList: usersHintSelector(state),
    currentCurrency: currentCurrencySelector(state),
  }
}

function mapDispatchToProps (dispatch) {
  return {
    handleCreateInvoice: (values) => dispatch(createInvoice({ ...values })),
    handleResetFormLoadingState: () => dispatch(updateFormLoadingState('newInvoiceForm', null)),
    removePaymentRequest: (requestId) => dispatch(removePaymentRequest(requestId)),
    removePaymentRequestLocal: (requestId) => dispatch(removePaymentRequestLocal(requestId)),
    getRequestLogo: (requestId) => dispatch(getRequestLogo(requestId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddInvoiceCardContainer)
