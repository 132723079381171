import BigNumber from 'bignumber.js'
import { PaymentRequestModel } from '../../../models/PaymentRequests/PaymentRequestModel'
import * as c from '../constants'
import get from 'lodash.get'
import { RestService } from '../../../services/RestService'
import {
  updatePayrollStep,
  selectRequestUpdateList,
  selectRequestItem,
  updatePayrollSettingsAction,
  updatePayrollSettingsBlockchain,
  removeRequestItem,
  updatePayrollSettingsLoadingState,
  selectRequestPreviewItem,
} from '../actions'
import { allRequestsSelector, requestsByIdsSelector } from '../../paymentRequests/selectors'
import {
  PAYMENT_REQUEST_STATUS_CAPTURED,
  PAYMENT_REQUEST_STATUS_FAILED,
  PAYMENT_REQUEST_STATUS_IN_PROGRESS,
  PAYMENT_REQUEST_STATUS_UNPAID,
} from '../../paymentRequests/constants'
import { accountByIdSelector, anyHotAccountsSelector, defaultAccountSelector } from '../../accounts/selectors'
import {
  fixedRatesSelector,
  payrollStepStatusSelector,
  blockchainsSettingsSelector,
  payrollSettingsSelector,
  selectedRequestListByTypeSelector,
  selectedRequestsTypeSelector,
} from '../selectors'
import { formatRequestsListForTx, canPaySeparateTransactionsUtil } from '../utils'
import { isResponseValid } from '../../../utils/general'
import { updatePaymentRequests } from '../../paymentRequests/actions'
import { LOADING_STATE_LOADING } from '../../../constants'
import { getPaymentRequests } from '../../paymentRequests/thunks'

import type { Dispatch } from 'redux'
import type { HotWallet } from '../../accounts/models'
import { PAYROLL_SELECTED_ITEMS_TYPE_MAIN_LIST } from '../constants'
import { PAYROLL_SELECTED_ITEMS_TYPE_PREVIEW_INVOICE } from '../constants'
import { profileInfoSelector } from '../../profile/selectors'
import { ACCOUNT_TYPE_HOT, ACCOUNT_TYPE_TREZOR } from '../../accounts/constants'
import Logger from '../../../services/Logger'
import { getMethods, signTransaction } from '../../accounts/thunks'
import { getDecimals, getDecimalLength } from '../../accounts/utils'
import EthereumTrezorDevice from "../../../signers/EthereumTrezorDevice"
import { BLOCKCHAIN_ETHEREUM, BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY } from "../../ethereum/constants"
import { getNetwork } from "../../ethereum/selectors"
import { getBalance } from "../../ethereum/thunks"
import { amplitudeSendEvent } from "../../../utils/analytics"

/**
 * Check day
 * If it pay day, run payroll in cycle
 * If not run pyaroll off cycle
 * @param {object} params
 * @param {string} type
 */
const captureRequests = (params, type = PAYROLL_SELECTED_ITEMS_TYPE_MAIN_LIST) => async (dispatch, getState) => {
  try {
    const requests = allRequestsSelector(getState())
    const selectedRequestIds = [...selectedRequestListByTypeSelector(type)(getState())]

    const { data } = await RestService.request('payrollService::capture', {
      requestsIds: selectedRequestIds.filter(
        (id) => requests.find((r) => r.requestId === id).paymentBlockchain === params.blockchain,
      ),
    })

    const { status, result } = data
    if (isResponseValid(status)) {
      const requestsForUpdate = {}
      params.capturedRequestsIds = get(result, 'payload.requests', [])
        .filter(({ status }) => status === PAYMENT_REQUEST_STATUS_CAPTURED)
        .map(({ requestId }) => {
          requestsForUpdate[requestId] = new PaymentRequestModel({
            ...requests.find((r) => r.requestId === requestId),
            status: PAYMENT_REQUEST_STATUS_IN_PROGRESS,
          })
          return requestId
        })
      amplitudeSendEvent('Payment confirmed', { Succesfully: 'Yes' })
      dispatch(updatePaymentRequests(requestsForUpdate))
      dispatch(updatePayrollStep(c.PAYROLL_STEPS.transactionsSend))
    } else {
      amplitudeSendEvent('Payment confirmed', { Succesfully: `Error: Capture request is invalid` })
      throw new Error('Capture request is invalid: ', data)
    }
  } catch (e) {
    amplitudeSendEvent('Payment confirmed', { Succesfully: `Error: ${e.message}` })
    Logger.error(e, {
      // params included password, don't push all the object to logger
      blockchain: params.blockchain,
      capturedRequestsIds: params.capturedRequestsIds,
      type,
    })
    dispatch(updatePayrollStep(c.PAYROLL_STEPS.payrollErrored))
  }
  dispatch(runPayroll(params, type))
}

/**
 * main method for run payroll process
 * @param {object} params - map of passwords
 * @param {object} type -
 */
export const runPayroll = (params, type = PAYROLL_SELECTED_ITEMS_TYPE_MAIN_LIST) => async (dispatch, getState) => {
  const steps = {
    [c.PAYROLL_STEPS.payrollRun]: () => {
      dispatch(captureRequests(params, type))
    },
    [c.PAYROLL_STEPS.transactionsSend]: () => {
      const settings = blockchainsSettingsSelector(getState())
      const { isSeparateTransactions } = settings[params.blockchain]
      dispatch(payRequestsSeparatelyAndTie({ ...params, isSeparateTransactions }, type))
    },
    [c.PAYROLL_STEPS.payrollErrored]: () => {
      dispatch(getPaymentRequests())
    },
    [c.PAYROLL_STEPS.payrollCompleted]: () => {
      dispatch(getPaymentRequests())
    },
  }

  const step = payrollStepStatusSelector(getState())

  if (steps[step]) {
    steps[step]()
  } else {
    dispatch(updatePayrollStep(c.PAYROLL_STEPS.payrollRun))
    dispatch(runPayroll(params, type))
  }
}

export const selectRequestItemThunk = (
  requestId: string,
  selectedType = PAYROLL_SELECTED_ITEMS_TYPE_MAIN_LIST,
) => async (dispatch: Dispatch): Promise<void> => {
  switch (selectedType) {
    case PAYROLL_SELECTED_ITEMS_TYPE_MAIN_LIST:
      await dispatch(selectRequestItem(requestId))
      break
    case PAYROLL_SELECTED_ITEMS_TYPE_PREVIEW_INVOICE:
      await dispatch(selectRequestPreviewItem(requestId))
      break
    default:
      throw new Error('unknown error')
  }

  dispatch(updatePayrollSettings(false, selectedType))
}

export const selectRequestsListThunk = (requests: Array<string>) => async (dispatch: Dispatch): Promise<void> => {
  await dispatch(selectRequestUpdateList(requests))
  dispatch(updatePayrollSettings())
}

export const removeRequestItemThunk = (requestId: string) => async (dispatch: Dispatch): Promise<void> => {
  await dispatch(removeRequestItem(requestId))
  dispatch(updatePayrollSettings())
}

const getPayrollSettingsForAccount = (account: HotWallet, multiplier: string = 1, paymentType: string) => async (dispatch: Dispatch, getState): Promise<void> => {
  const state = getState()

  if (!multiplier) {
    multiplier = 1
  }
  if ([ACCOUNT_TYPE_TREZOR].includes(paymentType)) {
    const oldPayrollSettings = payrollSettingsSelector(state)
    const selectedRequestsType = selectedRequestsTypeSelector(state)
    const selectedRequestSet = selectedRequestListByTypeSelector(selectedRequestsType)(state)
    const requestList = requestsByIdsSelector([...selectedRequestSet])(state)
    const rates = fixedRatesSelector(state)
    const blockchainRequestsList = requestList.filter((r) => r.paymentBlockchain === account.blockchain)

    let totalCryptoValue = new BigNumber(0)
    if (requestList.length) {
      requestList
        .filter((request) => request.paymentBlockchain === account.blockchain)
        .forEach((request) => {
          const fiatSummary = new BigNumber(request.settlementAmount)
          const summaryRate = get(rates, `${request.paymentAsset}.${request.settlementAsset}`, 0)
          totalCryptoValue = totalCryptoValue.plus(fiatSummary.dividedBy(summaryRate))
        })
    }

    const outputList = formatRequestsListForTx(blockchainRequestsList, rates)
    const multipliersList = [1, 2, 3.5]
    let feeList = null

    let ethTrezorAccountAddress = get(oldPayrollSettings, `${account.blockchain}.ethTrezorAccountAddress`, null)
    let ethTrezorAccountBalance = get(oldPayrollSettings, `${account.blockchain}.ethTrezorAccountBalance`, null)
    if ([BLOCKCHAIN_ETHEREUM, BLOCKCHAIN_ETHEREUM_TESTNET_RINKEBY].includes(account.blockchain)) {
      const accountEth = {
        ...account,
        address: ethTrezorAccountAddress,
        meta: {
          network: getNetwork(account.blockchain),
        },
      }
      if (!ethTrezorAccountAddress) {
        const signer = new EthereumTrezorDevice()
        ethTrezorAccountAddress = await signer.getAddress()
        ethTrezorAccountBalance = await getBalance({ ...accountEth, address: ethTrezorAccountAddress })
      }
      const methods = getMethods(accountEth)
      feeList = await dispatch(methods.calculateFee(
        accountEth,
        outputList,
        multipliersList,
      ))
      feeList = feeList.reduce((acc, fee, key) => {
        return {
          ...acc,
          [multipliersList[key]]: fee,
        }
      }, {})

    }

    const { canPaySeparateTransactions } = profileInfoSelector(getState())

    return {
      accountId: null,
      ethTrezorAccountAddress,
      ethTrezorAccountBalance,
      paymentType,
      blockchain: account.blockchain,
      separateTransactionsPaid: 0,
      separateTransactionsFailed: 0,
      separateTransactionsAll: blockchainRequestsList.length,
      totalDebited: new BigNumber(0),
      fee: feeList && feeList[multiplier],
      baseFee: feeList && feeList[1],
      multiplier,
      feeList,
      isSeparateTransactions: canPaySeparateTransactionsUtil(
        canPaySeparateTransactions,
        blockchainRequestsList.length,
        account.blockchain,
      ),
      enoughMoney:
        ethTrezorAccountBalance.formattedBalance &&
        ethTrezorAccountAddress &&
        ethTrezorAccountBalance.formattedBalance.comparedTo(totalCryptoValue.plus(new BigNumber(feeList[multiplier]))) >= 0,
    }
  } else if (account) {
    const selectedRequestsType = selectedRequestsTypeSelector(state)
    const selectedRequestSet = selectedRequestListByTypeSelector(selectedRequestsType)(state)
    const requestList = requestsByIdsSelector([...selectedRequestSet])(state)
    const rates = fixedRatesSelector(state)
    const blockchainRequestsList = requestList.filter((r) => r.paymentBlockchain === account.blockchain)
    const outputList = formatRequestsListForTx(blockchainRequestsList, rates)
    const multipliersList = [1, 2, 3.5]
    let feeList = null
    if (account.address) {
      const methods = getMethods(account)
      feeList = await dispatch(methods.calculateFee(account, outputList, multipliersList))
      feeList = feeList.reduce((acc, fee, key) => {
        return {
          ...acc,
          [multipliersList[key]]: fee,
        }
      }, {})
    }

    let totalCryptoValue = new BigNumber(0)
    if (requestList.length) {
      requestList
        .filter((request) => request.paymentBlockchain === account.blockchain)
        .forEach((request) => {
          const fiatSummary = new BigNumber(request.settlementAmount)
          const summaryRate = get(rates, `${request.paymentAsset}.${request.settlementAsset}`, 0)
          // the crypto currency can't has decimals places more than getDecimalLength(request.paymentBlockchain)
          totalCryptoValue = totalCryptoValue.plus(
            fiatSummary
              .dividedBy(summaryRate)
              .decimalPlaces(getDecimalLength(request.paymentBlockchain), BigNumber.ROUND_UP),
          )
        })
    }

    const { canPaySeparateTransactions } = profileInfoSelector(getState())

    return {
      accountId: account.id,
      paymentType: null,
      blockchain: account.blockchain,
      multiplier,
      enoughMoney:
        account.formattedBalance &&
        account.address &&
        account.formattedBalance.comparedTo(totalCryptoValue.plus(new BigNumber(feeList[multiplier]))) >= 0,
      fee: feeList && feeList[multiplier],
      baseFee: feeList && feeList[1],
      feeList,
      separateTransactionsPaid: 0,
      separateTransactionsFailed: 0,
      separateTransactionsAll: blockchainRequestsList.length,
      totalDebited: new BigNumber(0),
      isSeparateTransactions: canPaySeparateTransactionsUtil(
        canPaySeparateTransactions,
        blockchainRequestsList.length,
        account.blockchain,
      ),
    }
  }
}

type paramsType = {
  accountId: string,
  multiplier: string,
  isSeparateTransactions: boolean,
  paymentType: string,
  blockchain: string,
}
export const updatePayrollSettingsParams = (params: paramsType) => async (
  dispatch: Dispatch,
  getState,
): Promise<void> => {
  const { accountId, multiplier, isSeparateTransactions, paymentType, blockchain } = params
  const account = accountId ? accountByIdSelector(accountId)(getState()) : { blockchain }
  const settings = await dispatch(getPayrollSettingsForAccount(account, multiplier, paymentType))
  settings.isSeparateTransactions = isSeparateTransactions
  dispatch(updatePayrollSettingsBlockchain(settings))
}

export const updatePayrollSettings = (
  isNeedToUnmark = false,
  selectedType = PAYROLL_SELECTED_ITEMS_TYPE_MAIN_LIST,
  paymentType,
) => async (dispatch: Dispatch, getState): Promise<void> => {
  const state = getState()
  const selectedRequestSet = selectedRequestListByTypeSelector(selectedType)(state)
  const requestList = requestsByIdsSelector([...selectedRequestSet])(state)
  const oldPayrollSettings = payrollSettingsSelector(state)
  const hotWallets = anyHotAccountsSelector(state)

  const requestsToUnmark = []
  const payrollSettings = {}
  for (let i = 0; i < requestList.length; i++) {
    const r = requestList[i]
    const isRightStatus = [PAYMENT_REQUEST_STATUS_FAILED, PAYMENT_REQUEST_STATUS_UNPAID].includes(r.status)
    if (isRightStatus) {
      if (!payrollSettings[r.paymentBlockchain]) {
        if (!oldPayrollSettings[r.paymentBlockchain]) {
          await dispatch(updatePayrollSettingsLoadingState(LOADING_STATE_LOADING))
        }
        const accountFromState = accountByIdSelector(get(oldPayrollSettings, r.paymentBlockchain + '.accountId'), null)(
          getState(),
        )
        const defaultAccount = defaultAccountSelector(r.paymentBlockchain)(getState())
        const account =
          accountFromState ||
          (defaultAccount && defaultAccount.type === ACCOUNT_TYPE_HOT
            ? defaultAccount
            : hotWallets[r.paymentBlockchain])
        payrollSettings[r.paymentBlockchain] = await dispatch(
          getPayrollSettingsForAccount(
            account || {
              blockchain: r.paymentBlockchain,
            },
            null,
            paymentType),
        )
      }
    } else {
      requestsToUnmark.push(r.requestId)
    }
  }

  dispatch(updatePayrollSettingsAction(payrollSettings))
  if (isNeedToUnmark) {
    dispatch(
      selectRequestUpdateList(
        [...selectedRequestSet].filter((id) => {
          return !requestsToUnmark.includes(id)
        }),
      ),
    )
  }
}

const payRequestsSeparatelyAndTie = (params, type = PAYROLL_SELECTED_ITEMS_TYPE_MAIN_LIST) => async (dispatch, getState) => {
  try {
    const { password, capturedRequestsIds, blockchain, isSeparateTransactions } = params
    const requests = allRequestsSelector(getState())
    const selectedAccounts = blockchainsSettingsSelector(getState())
    let account = accountByIdSelector(selectedAccounts[blockchain].accountId)(getState())

    const rates = fixedRatesSelector(getState())
    const settings = blockchainsSettingsSelector(getState())[blockchain]

    const requestsForBlockchain = capturedRequestsIds.reduce((accumulator, requestId) => {
      const request = requests.find((r) => r.requestId === requestId)
      const blockchain = request.paymentBlockchain
      if (!accumulator[blockchain]) {
        accumulator[blockchain] = []
      }
      accumulator[blockchain].push(request)
      return accumulator
    }, {})[blockchain]

    settings.separateTransactionsAll = requestsForBlockchain.length
    settings.separateTransactionsPaid = 0
    settings.separateTransactionsFailed = 0
    dispatch(updatePayrollSettingsBlockchain(settings))

    const sendRequests = async (requestList) => {
      /**
       * SEND TRANSACTION
       */
      const settings = blockchainsSettingsSelector(getState())[blockchain]
      const outputList = formatRequestsListForTx(requestList, rates)

      const { tx, value, fee } = await dispatch(
        signTransaction({
          account: account || {
            address: settings.ethTrezorAccountAddress,
            blockchain: settings.blockchain,
            type: settings.paymentType,
          },
          multiplier: settings.multiplier,
          tx: {
            from: account ? account.address : null,
            to: outputList,
          },
          password,
        }),
      )
      settings.totalDebited = new BigNumber(
        value || outputList.reduce((acc, { value }) => acc.plus(value), new BigNumber(0)),
      )
        .plus(fee || 0)
        .dividedBy(getDecimals({ blockchain: settings.blockchain }))
        .plus(settings.totalDebited)

      dispatch(updatePayrollSettingsBlockchain(settings))
      const payload = []
      outputList.forEach((outputRow) => {
        const requestListInfo = get(outputRow, 'requests', null)
        if (!requestListInfo) {
          throw new Error('requestListInfo is empty')
        }
        requestListInfo.forEach((request) => {
          payload.push({
            requestId: request.requestId,
            rawtx: tx,
            asset: request.asset,
            blockchain: request.blockchain,
            amount: request.amountFormatted,
            xrate: request.xrate,
            xrateSource: request.xrateSource,
            xrateDate: request.xrateDate,
          })
        })
      })
      const result = await RestService.request('paymentRequestsService::payRequest', payload)
      // eslint-disable-next-line
      console.debug('paymentRequestsService::payRequest result: ', result.data)
      return result
    }

    if (isSeparateTransactions) {
      for (const request of requestsForBlockchain) {
        try {
          const result = await sendRequests([request])
          if (result.data.status !== 'Ok') {
            throw new Error('Status is not Ok')
          }
          const requestResult = result.data.result[request.requestId] || {}
          if (!requestResult.success) {
            throw new Error(`${request.requestId} has failed. ${requestResult.error}`)
          }
          const settings = blockchainsSettingsSelector(getState())[blockchain]
          settings.separateTransactionsPaid = settings.separateTransactionsPaid + 1
          dispatch(updatePayrollSettingsBlockchain(settings))
        } catch (e) {
          const settings = blockchainsSettingsSelector(getState())[blockchain]
          settings.separateTransactionsFailed = settings.separateTransactionsFailed + 1
          dispatch(updatePayrollSettingsBlockchain(settings))
        }
      }
      const { separateTransactionsFailed, separateTransactionsAll } = blockchainsSettingsSelector(getState())[
        blockchain
        ]
      if (separateTransactionsFailed === separateTransactionsAll) {
        dispatch(updatePayrollStep(c.PAYROLL_STEPS.payrollErrored))
      } else {
        dispatch(updatePayrollStep(c.PAYROLL_STEPS.payrollCompleted))
      }
    } else {
      const result = await sendRequests(requestsForBlockchain)
      if (result.data.status !== 'Ok') {
        throw new Error('Status is not Ok')
      }
      Object.entries(result.data.result).forEach(([requestId, requestData]) => {
        if (!requestData.success) {
          throw new Error(`${requestId} has failed. ${requestData.error}`)
        }
      })
      dispatch(updatePayrollStep(c.PAYROLL_STEPS.payrollCompleted))
    }
  } catch (e) {
    // eslint-disable-next-line
    console.log('%c payRequests', 'background: #222; color: #fff', e)
    dispatch(updatePayrollStep(c.PAYROLL_STEPS.payrollErrored))
  }
  dispatch(runPayroll(params, type))
}
