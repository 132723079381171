import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../components/Button'
import Icon from '../../components/Icon/Icon'
import styles from './CancelMessage.module.scss'

const CancelMessage = ({ onClose, onCancel }) => {

  const handleCancel = () => {
    onClose()
    onCancel()
  }
  return (
    <div className={styles.messageContainer}>
      <div className={styles.messageHeader}>
        <button onClick={onClose}><Icon scale='1.5' type='close' colorType='gray-50' /></button>
        <div className='h4 gray-70'>Cancel Invoice</div>
      </div>
      <div className={styles.messageContent}>
        <Icon type='info-circle' colorType='gray-30' />
        <div className='h5 gray-30'>Are you sure you want to cancel the invoice?</div>
        <div className='capture gray-30'>
          The invoice will remain in the system,<br />
          but it is status will be changed to Canceled.
        </div>
        <div className={styles.messageActions}>
          <Button
            type='button'
            onClick={handleCancel}
            colorType='new-border'>Cancel Invoice</Button>
          <Button
            type='button'
            onClick={onClose}
            colorType='new-filled'>Return to invoice preview </Button>
        </div>
      </div>
    </div>
  )
}

CancelMessage.propTypes = {
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
}

export default CancelMessage
