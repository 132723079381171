export const PAYROLL_DUCK = 'payroll'

export const PAYROLL_STEP_REQUEST_SETTINGS = 'payrollStep/requestSettings'
export const PAYROLL_STEP_SEND = 'payrollStep/send'

export const REQUEST_ITEM_UPDATE_LIST = 'request/updateList'
export const REQUEST_ITEM_SELECT = 'request/select'
export const REQUEST_ITEM_SELECT_PREVIEW_INVOICE = 'request/selectPreviewInvoice'
export const REQUEST_ITEM_REMOVE = 'request/remove'
export const REQUEST_ITEM_CLEAR_SET = 'request/clearSet'
export const REQUEST_ITEM_CLEAR_PREVIEW_INVOICE = 'payroll/selectedItemsClearMainList'
export const UPDATE_PAYROLL_TRANSACTIONS = 'payroll/transactions'
export const RESET_PAYROLL_SETTINGS = 'payroll/resetSettings'

export const PAYROLL_CREATE_SET_LOADING_STATE = 'payroll/setCreateLoadingState'
export const PAYROLL_SET_LOADING_STATE = 'payroll/setLoadingState'
export const PAYROLL_SET_STEP = 'payroll/setStep'
export const PAYROLL_ADD_TRANSACTION = 'payroll/addTransaction'
export const PAYROLL_REMOVE_PAYROLL_INFO = 'payroll/removePayrollInfo'
export const PAYROLL_UPDATE_PAYROLL_INFO = 'payroll/updatePayrollInfo'
export const PAYROLL_UPDATE_PAYROLL_ITEM_REQUEST = 'payroll/updatePayrollItemRequest'
export const PAYROLL_UPDATE_PAYROLL_ITEMS_LIST = 'payroll/updatePayrollItemsList'
export const PAYROLL_UPDATE_PAYROLL_ITEMS_LOADING_STATE = 'payroll/updatePayrollItemsLoadingState'
export const PAYROLL_FIX_RATES = 'payroll/fixRates'
export const PAYROLL_UPDATE_FIXED_RATE = 'payroll/updateFixedRate'
export const PAYROLL_UPDATE_SETTINGS_ITEM = 'payroll/updateSettingsItem'
export const PAYROLL_UPDATE_FEE = 'payroll/updateFee'
export const PAYROLL_UPDATE_RUN_PAYROLL_SETTINGS = 'payroll/updateRunPayrollSettings'

export const PAYROLL_UPDATE_STEP = 'payroll/updateStep'
export const PAYROLL_UPDATE_SETTINGS = 'payroll/updateSettings'
export const PAYROLL_UPDATE_SETTINGS_LOADING_STATE = 'payroll/updateSettingsLoadingState'
export const PAYROLL_UPDATE_SETTINGS_BLOCKCHAIN = 'payroll/updateSettingsBlockchain'

export const PAYROLL_SELECTED_ITEMS_TYPE_PREVIEW_INVOICE = 'payroll/selectedItemsPreviewInvoice'
export const PAYROLL_SELECTED_ITEMS_TYPE_MAIN_LIST = 'payroll/selectedItemsMainList'

export const PAYROLL_STEPS = {
  payrollRun: 'payrollRun',
  payrollRunFormatted: 'Payroll running',

  transactionsSend: 'transactionsSend',
  transactionsSendFormatted: 'Transactions sending',

  transactionsTie: 'transactionsTie',
  transactionsTieFormatted: 'Transactions tying',

  payrollCompleted: 'payrollCompleted',
  payrollErrored: 'payrollErrored',
}

export const PAYROLL_PERIOD_ONCE_PER_WEEK_VALUE = 'WEEKLY'
export const PAYROLL_PERIOD_ONCE_PER_WEEK = {
  title: 'Once per week',
  value: PAYROLL_PERIOD_ONCE_PER_WEEK_VALUE,
}

export const PAYROLL_PERIOD_EVERY_TWO_WEEK_VALUE = 'BIWEEKLY'
export const PAYROLL_PERIOD_EVERY_TWO_WEEK = {
  title: 'Every two weeks',
  value: PAYROLL_PERIOD_EVERY_TWO_WEEK_VALUE,
}

export const PAYROLL_PERIOD_MONTHLY_VALUE = 'MONTHLY'
export const PAYROLL_PERIOD_MONTHLY = {
  title: 'Monthly',
  value: PAYROLL_PERIOD_MONTHLY_VALUE,
}
