import React from 'react'
import PropTypes from 'prop-types'
import NavigationBar from '../../components/NavigationBar'
import { Scrollbars } from 'react-custom-scrollbars'
import ModalStack from '../../components/ModalStack/ModalStack'
import SwipePanel from '../../components/SwipePanel/index'
import styles from './index.module.scss'
import { isDeviceWidth } from '../../utils/general'

class PageContainer extends React.PureComponent {
  static propTypes = {
    mainPart: PropTypes.element,
    additionalPart: PropTypes.element,
  }

  render () {
    return (
      <>
        <div className={styles.wrapper}>
          <NavigationBar />
          <div className={styles.contentPadding} />
          <div className={styles.content}>
            <Scrollbars className={styles.mainScrollbarContainer}>
              <div className={styles.main}>{this.props.mainPart}</div>
            </Scrollbars>
            {this.props.additionalPart && isDeviceWidth(1024) && (
              <SwipePanel containerId='walletsContainer'>
                <div className={styles.additional}>{this.props.additionalPart}</div>
              </SwipePanel>
            )}
            {this.props.additionalPart && !isDeviceWidth(1024) && (
              <div className={styles.additionalContainer}>
                <div className={styles.additional}>{this.props.additionalPart}</div>
              </div>
            )}
          </div>
        </div>
        <ModalStack />
      </>
    )
  }
}

export default PageContainer
