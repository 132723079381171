//@flow
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import AccountsCard from '../AccountsCard'
import { selectedRequestIdsSelector } from '../../ducks/payroll/selectors'
import RequestPayCard from '../RequestPayCard'
import styles from './index.module.scss'

import {PAYROLL_SELECTED_ITEMS_TYPE_MAIN_LIST} from '../../ducks/payroll/constants'

type Props = {
  selectedRequestSet: any,
}

class RightPanel extends React.PureComponent<Props> {
  static propTypes = {
    selectedRequestSet: PropTypes.instanceOf(Set),
  }

  renderCards () {
    if (this.props.selectedRequestSet.size > 0) {
      return <RequestPayCard
        type={PAYROLL_SELECTED_ITEMS_TYPE_MAIN_LIST}
      />
    }

    return <AccountsCard />
  }

  render () {
    return (
      <div className={styles.wrapper}>
        {this.renderCards()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const selectedRequestSet = selectedRequestIdsSelector(state)

  return {
    selectedRequestSet,
  }
}

export default connect(mapStateToProps)(RightPanel)
