import * as c from './constants'

const getInitialState = () => ({
  payrollPageCurrentTab: 'payroll',
  sidePanels: {
    addInvoiceSidePanel: {
      isOpen: false,
      requestId: null,
      templateRequestId: null,
    },
    addPaymentRequestSidePanel: { // just request to email, NOT invoice
      isOpen: false,
      accountId: null,
    },
    invoiceSidePanel: {
      isOpen: false,
      requestId: null,
    },
    addAccountSidePanel: {
      isOpen: false,
    },
    accountSidePanel: {
      isOpen: false,
      accountId: null,
    },
    requestsSidePanel: {
      requestIds: [],
    },
    recoverAccountPasswordForm: {
      isOpen: false,
      accountId: null,
    },
    qrAddressPopup: {
      isOpen: false,
      account: {
        address: null,
        name: null,
      },
    },
  },
  ConfirmPasswordCard: {
    isShownFeeWarning: false,
  },
  errorPage: {
    title: null,
    subTitle: null,
  },
  notifications: {
    // TODO remove this notification in future release
    warningPopup: {
      isOpen: true,
    },
  },
  customLoadingStates: {
    exportCSVLoadingState: null,
  },
  sendTransactionForm: {
    transactionSendingState: null,
  },
})

const mutations = {
  [c.UI_SET_CONFIRM_PASSWORD_CARD_PARAMS]: (state, { params }) => {
    return {
      ...state,
      ConfirmPasswordCard: {
        ...state.ConfirmPasswordCard,
        ...params,
      },
    }
  },
  [c.UI_SET_CUSTOM_LOADING_STATE]: (state, { params }) => {
    return {
      ...state,
      customLoadingStates: {
        ...state.customLoadingStates,
        ...params,
      },
    }
  },
  [c.UI_SET_PAYROLL_PAGE_CURRENT_TAB]: (state, { tab }) => {
    return {
      ...state,
      payrollPageCurrentTab: tab,
    }
  },
  [c.UI_SET_SIDE_PANEL]: (state, { panelName, panelProps }) => {
    return {
      ...state,
      sidePanels: {
        ...state.sidePanels,
        [panelName]: panelProps,
      },
    }
  },
  [c.UI_SET_NOTIFICATIONS]: (state, { notificationKey, notificationParam }) => {
    return {
      ...state,
      notifications: {
        ...state.notifications,
        [notificationKey]: notificationParam,
      },
    }
  },
  [c.UI_SET_ERROR_PAGE_DATA]: (state, { title, subTitle }) => {
    return {
      ...state,
      errorPage: {
        ...state.errorPage,
        title,
        subTitle,
      },
    }
  },
  [c.UI_UPDATE_TRANSACTION_FORM_STATE]: (state, { sendingState }) => {
    return {
      ...state,
      sendTransactionForm: {
        transactionSendingState: sendingState,
      },
    }
  },
}

export default (state = getInitialState(), { type, ...payload }) => {
  return (type in mutations)
    ? mutations[type](state, payload)
    : state
}
