import get from 'lodash.get'
import { createSelector } from 'reselect'
import {
  PAYROLL_DUCK,
  PAYROLL_SELECTED_ITEMS_TYPE_MAIN_LIST,
  PAYROLL_SELECTED_ITEMS_TYPE_PREVIEW_INVOICE,
} from './constants'
import BigNumber from 'bignumber.js'
import { ratesSymbolMappedListSelector } from '../rates/selectors'
import { allRequestsSelector } from '../paymentRequests/selectors'
import { currentCurrencySelector } from '../profile/selectors'

export const payrollDuckSelector = (state) => {
  return state[PAYROLL_DUCK]
}

export const payrollCurrentStepSelector = createSelector(
  payrollDuckSelector,
  ({ currentStep }) => currentStep,
)

export const payrollCurrentPanelStepSelector = createSelector(
  payrollDuckSelector,
  ({ currentPanelStep }) => currentPanelStep,
)

export const selectedRequestIdsSelector = createSelector(
  payrollDuckSelector,
  ({ selectedRequestIds }) => selectedRequestIds,
)
export const selectedRequestPreviewInvoiceSelector = createSelector(
  payrollDuckSelector,
  ({ selectedRequestPreviewId }) => selectedRequestPreviewId,
)
export const selectedRequestListByTypeSelector = (type = PAYROLL_SELECTED_ITEMS_TYPE_MAIN_LIST) =>
  createSelector(
    payrollDuckSelector,
    ({ selectedRequestPreviewId, selectedRequestIds }) => {
      switch (type) {
        case PAYROLL_SELECTED_ITEMS_TYPE_MAIN_LIST:
          return selectedRequestIds
        case PAYROLL_SELECTED_ITEMS_TYPE_PREVIEW_INVOICE:
          return selectedRequestPreviewId
        default:
          throw new Error('unknown type ' + type)
      }
    },
  )

export const blockchainsSettingsSelector = createSelector(
  payrollDuckSelector,
  ({ blockchainsSettings }) => blockchainsSettings,
)

export const blockchainsSettingsLoadingStateSelector = createSelector(
  payrollDuckSelector,
  ({ blockchainsSettingsLoadingState }) => blockchainsSettingsLoadingState,
)

export const payrollCreateLoadingStateSelector = createSelector(
  payrollDuckSelector,
  ({ createPayrollLoadingState }) => createPayrollLoadingState,
)

export const payrollCreateErrorMessageSelector = createSelector(
  payrollDuckSelector,
  ({ createPayrollErrorMessage }) => createPayrollErrorMessage,
)

export const payrollTransactionsListSelector = createSelector(
  payrollDuckSelector,
  ({ transactions }) => transactions,
)

export const payrollSettingsSelector = createSelector(
  payrollDuckSelector,
  ({ blockchainsSettings }) => blockchainsSettings,
)

export const currentFeeRateSettingsForBlockchain = (blockchain) =>
  createSelector(
    payrollDuckSelector,
    ({ blockchainsSettings }) => {
      return blockchainsSettings[blockchain] ? blockchainsSettings[blockchain].feeRate : null
    },
  )

export const payrollStepStatusSelector = createSelector(
  payrollDuckSelector,
  ({ currentStep }) => currentStep,
)

export const fixedRatesSelector = createSelector(
  payrollDuckSelector,
  ratesSymbolMappedListSelector,
  ({ fixedRates }, rates) => {
    return fixedRates || rates
  },
)

export const cryptoPaymentSelector = (type = PAYROLL_SELECTED_ITEMS_TYPE_MAIN_LIST) =>
  createSelector(
    selectedRequestIdsSelector,
    selectedRequestPreviewInvoiceSelector,
    allRequestsSelector,
    blockchainsSettingsSelector,
    fixedRatesSelector,
    currentCurrencySelector,
    (selectedRequestIds, selectedPreviewInvoiceIds, requests, blockchainsSettings, rates, currentCurrency) => {
      let selectedIds = null
      if (type === PAYROLL_SELECTED_ITEMS_TYPE_PREVIEW_INVOICE) {
        selectedIds = selectedPreviewInvoiceIds
      } else {
        selectedIds = selectedRequestIds
      }

      return [...selectedIds].reduce((acc, id) => {
        const request = requests.find((r) => r.requestId === id)
        if (!request) {
          return acc
        }
        if (!acc[request.paymentBlockchain]) {
          acc[request.paymentBlockchain] = {
            count: 0,
            amount: new BigNumber(0),
            fiatAmount: new BigNumber(0),
          }
        }
        let fiatSummary = new BigNumber(request.settlementAmount)
        if (currentCurrency !== request.settlementAsset) {
          const rate = get(rates, currentCurrency + '.' + request.settlementAsset, 0)
          fiatSummary = new BigNumber(request.settlementAmount).dividedBy(rate)
        }

        const summaryRate = get(rates, `${request.paymentAsset}.${currentCurrency}`, 0)
        const cryptoSummary = fiatSummary.dividedBy(summaryRate)

        acc[request.paymentBlockchain].fiatAmount = acc[request.paymentBlockchain].fiatAmount.plus(fiatSummary)
        acc[request.paymentBlockchain].amount = acc[request.paymentBlockchain].amount.plus(cryptoSummary)
        acc[request.paymentBlockchain].count++
        return acc
      }, {})
    },
  )

export const selectedRequestsTypeSelector = createSelector(
  payrollDuckSelector,
  ({ selectedRequestsType }) => {
    return selectedRequestsType
  },
)
