import bip32 from 'bip32'
import bip39 from 'bip39'
import bitcoin from 'bitcoinjs-lib'

export default class BitcoinMemoryDevice {
  constructor (mnemonic, network, coinType) {
    this.mnemonic = mnemonic
    this.network = network
    this.coinType = coinType
  }

  getAddress () {
    const root = bip32.fromSeed(bip39.mnemonicToSeed(this.mnemonic))
    const node = root.derivePath(`m/44'/${this.coinType}'/0'/0/0`)

    return bitcoin.payments.p2pkh({ pubkey: node.publicKey, network: this.network }).address
  }

  async signTransaction (unsignedTx) {
    const txb = new bitcoin.TransactionBuilder.fromTransaction(unsignedTx, this.network)

    const keyPair = this.getKeyPair()

    for (let i = 0; i < txb.__inputs.length; i++) {
      txb.sign(i, keyPair)
    }

    return txb.build().toHex()
  }

  getKeyPair () {
    const root = bip32.fromSeed(bip39.mnemonicToSeed(this.mnemonic))
    const node = root.derivePath(`m/44'/${this.coinType}'/0'/0/0`)
    return new bitcoin.ECPair.fromPrivateKey(node.privateKey, { network: this.network })
  }
}
