// @flow
import get from 'lodash.get'
import { InsightSocketService } from '../../services/InsightSocketService'
import CryptoJS from 'crypto-js'
import bitcoin from 'bitcoinjs-lib'
import config from '../../config/getConfig'
import { describeBitcoinTransaction, getBtcFee, formatBlockExplorerTx, formatBalance } from './utils'
import { RestService } from '../../services/RestService'
import { CacheService } from '../../services/CacheService'
import { BLOCKCHAIN_BITCOIN, BLOCKCHAIN_BITCOIN_TESTNET } from '../bitcoin/constants'
import { COIN_TYPE_BTC_MAINNET, COIN_TYPE_BTC_TESTNET } from './constants'
import { feeRateSelector, getBitcoinSigner } from './selectors'
import { updateBitcoinFeeRate, updateTransactionSendingState } from './actions'
import { TRANSACTION_STATE_SENDING, TRANSACTION_STATE_SENT, TRANSACTION_STATE_ERROR } from '../../constants'
import type { AnyWallet, HotWallet } from '../accounts/models'
import type { TransactionTo, TransactionSend } from './models'
import type { Dispatch, GetState } from 'redux'
import { ACCOUNT_TYPE_TREZOR } from "../accounts/constants"
import Logger from '../../services/Logger'
import BitcoinTrezorDevice from "../../signers/BitcoinTrezorDevice"

export { formatBlockExplorerTx } from './utils'
export { BITCOIN_DECIMALS as decimals } from './constants'

type sendBitcoinTransactionType = {
  account: HotWallet,
  tx: TransactionSend,
  password: string,
  multiplier: number,
  isSendAll: boolean,
  reThrowError: boolean,
  returnValues?: boolean,
}

export const sendTransaction = ({ account, tx, password, multiplier, isSendAll, reThrowError, returnValues }: sendBitcoinTransactionType) => async (dispatch: Dispatch, getState: GetState): Promise<any | void> => {
  try {
    console.log('sendTransaction bitcoin multiplier', multiplier)
    dispatch(updateTransactionSendingState(TRANSACTION_STATE_SENDING))

    const utxos = await CacheService.getFromCache(account.address, 'utxo', getUtxoByAddress.bind(this, account.address, account.blockchain))
    const feeRates = feeRateSelector(getState())
    const baseFeeRate = feeRates[account.blockchain]
    const feeRate = parseInt(baseFeeRate * multiplier) // must be an int


    const { txb, fee, value, inputs, outputs } = describeBitcoinTransaction(tx, utxos, account, feeRate, isSendAll)

    const network = account.blockchain === BLOCKCHAIN_BITCOIN ? bitcoin.networks.bitcoin : bitcoin.networks.testnet
    const coinType = account.blockchain === BLOCKCHAIN_BITCOIN ? COIN_TYPE_BTC_MAINNET : COIN_TYPE_BTC_TESTNET
    const mnemonic = account.encryptedKey ? CryptoJS.AES.decrypt(account.encryptedKey, password) : null
    const signer = getBitcoinSigner(mnemonic ? CryptoJS.enc.Utf8.stringify(mnemonic) : null, network, coinType, account.type)

    const bitcoinTransaction = await signer.signTransaction(txb.buildIncomplete(), utxos)
    const { data: sendData } = await RestService.request('insightService::sendTx', account.blockchain, bitcoinTransaction)
    const hash = sendData.txid || sendData.hash
    if (hash) {
      // eslint-disable-next-line
      console.log('%c Transaction sent', 'background: green; color: #fff', hash)
      dispatch(updateTransactionSendingState(TRANSACTION_STATE_SENT))
      /**
       * remove used utxo
       */
      const inputsHashes = inputs.map((input) => input.txId)
      const newUtxos = utxos.filter((utxo) => {
        return !inputsHashes.includes(utxo.txId)
      })
      /**
       * add new utxo
       */
      outputs.forEach((out, key) => {
        if (out.address === account.address) {
          newUtxos.push({
            txId: hash,
            value: out.value,
            vout: key,
          })
        }
      })
      CacheService.addToCache(account.address, 'utxo', [...newUtxos])

      if (returnValues) {
        return {
          hash,
          value,
          fee,
        }
      }
      return hash
    } else {
      // eslint-disable-next-line
      console.log('%c Transaction failed', 'background: red; color: #fff', sendData)
      Logger.error(new Error('Transaction failed'), sendData)
      dispatch(updateTransactionSendingState(TRANSACTION_STATE_ERROR))
    }
  } catch (e) {
    Logger.error(e, {
      account: { ...account, encryptedKey: null },
      tx,
      multiplier,
      isSendAll,
      reThrowError,
      returnValues,
    })
    dispatch(updateTransactionSendingState(TRANSACTION_STATE_ERROR))
    if (reThrowError) throw new Error(e)
  }
}

export const signTransaction = ({ account, tx, password, multiplier, isSendAll }: sendBitcoinTransactionType) => async (dispatch: Dispatch, getState: GetState): Promise<any | void> => {
  console.log('signTransaction bitcoin multiplier', multiplier)
  const coinType = account.blockchain === BLOCKCHAIN_BITCOIN ? COIN_TYPE_BTC_MAINNET : COIN_TYPE_BTC_TESTNET
  const network = account.blockchain === BLOCKCHAIN_BITCOIN ? bitcoin.networks.bitcoin : bitcoin.networks.testnet
  const mnemonic = account.encryptedKey ? CryptoJS.AES.decrypt(account.encryptedKey, password) : null
  const signer = getBitcoinSigner(mnemonic ? CryptoJS.enc.Utf8.stringify(mnemonic) : null, network, coinType, account.type)

  if ([ACCOUNT_TYPE_TREZOR].includes(account.type)) {
    const { payload, success } = await signer.composeTransaction(tx.to)
    if (!success) {
      throw new Error(payload.error)
    }
    return {
      tx: payload.serializedTx,
      // fee: described.fee,
      // value: described.fee,
    }
  } else {
    const feeRates = feeRateSelector(getState())
    const baseFeeRate = feeRates[account.blockchain]
    const utxos = await CacheService.getFromCache(account.address, 'utxo', getUtxoByAddress.bind(this, account.address, account.blockchain), true)
    const described = describeBitcoinTransaction(tx, utxos, account, parseInt(multiplier * baseFeeRate), isSendAll)
    return {
      tx: await signer.signTransaction(described.txb.buildIncomplete(), utxos),
      fee: described.fee,
      value: described.fee,
    }
  }
}

export const calculateFee = (account: AnyWallet, to: Array<TransactionTo> | string, multipliers: number | Array<string | number>, isSendAll: boolean) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    if (!Array.isArray(to)) {
      throw new Error('To must be an Array')
    }
    const from = account.address
    const feeRates = feeRateSelector(getState())
    const baseFeeRate = feeRates[account.blockchain]

    const utxos = await CacheService.getFromCache(from, 'utxo', getUtxoByAddress.bind(this, from, account.blockchain))

    if (!utxos) {
      throw new Error('utxos is empty')
    }

    if (Array.isArray(multipliers)) {
      const rateResult = []
      multipliers.forEach((multiplier) => {
        // $flow-disable-line
        rateResult.push(getBtcFee(to, utxos, parseFloat(baseFeeRate * multiplier), isSendAll))
      })
      return rateResult
    }

    return getBtcFee(to, utxos, baseFeeRate * multipliers, isSendAll)
  } catch (e) {
    Logger.error(e, { from: account.address, to, blockchain: account.blockchain, multipliers, isSendAll })
    throw e
  }
}

export const getUtxoByAddress = async (address: string, blockchain: string) => {
  try {
    const { data } = await RestService.request('insightService::getUtxo', blockchain, address)
    return data
  } catch (e) {
    Logger.error(e, { address, blockchain })
    throw e
  }
}

export const getBitcoinFeeRate = () => async (dispatch: Dispatch) => {
  [
    BLOCKCHAIN_BITCOIN,
    BLOCKCHAIN_BITCOIN_TESTNET,
  ].forEach((blockchain) => {
    RestService.request('insightService::getFeeRate', blockchain)
      .then((feeRate) => {
        if (feeRate) {
          dispatch(updateBitcoinFeeRate(blockchain, feeRate))
        } else {
          throw new Error()
        }
      })
      .catch(() => {
        dispatch(updateBitcoinFeeRate(blockchain, config.getConfig('defaultBitcoinFeeRate')))
      })
  })
}

export const getAddressTransactions = async (account: AnyWallet, prevLastBlock: number, limit: number) => {
  const { blockchain, address } = account
  const result = await RestService.request('insightService::getTransactionsForMultipleAddresses', blockchain, [address], prevLastBlock, limit)
  const txs = get(result, 'data.txs', [])
  const hasMore = get(result, 'data.hasMore', [])
  const lastBlock = get(result, 'data.lastBlock', [])

  const items = {}

  txs.forEach((tx) => {
    items[tx.txid] = formatBlockExplorerTx(tx, address)
  })

  return {
    items,
    hasMore,
    lastBlock,
  }
}

export const getBalance = async (account: AnyWallet): Promise<any> => {
  try {
    const { data: balanceData } = await RestService.request('insightService::getBalance', account.blockchain, account.address)
    return formatBalance(balanceData.balance)
  } catch (e) {
    return { error: e }
  }
}

export const subscribeAccount = (account: AnyWallet, subscribeTxCallback: (data: any)=>Promise<any>, subscribeConfirmationCallback: (data: any)=> void) => () => {
  InsightSocketService.addSubscription(InsightSocketService.getSubscriptionKey(account), () => {
    InsightSocketService.subscribeAccount(account, subscribeTxCallback, subscribeConfirmationCallback)
  })
}

export const getTrezorWalletAddress = async (blockchain: string) => {
  try {
    const isTestent = blockchain !== BLOCKCHAIN_BITCOIN
    const network = blockchain === BLOCKCHAIN_BITCOIN ? bitcoin.networks.bitcoin : bitcoin.networks.testnet
    const signer = new BitcoinTrezorDevice(network, isTestent)

    const { success, payload: accountInfo } = await signer.getAccount()
    if (!success) {
      throw new Error(accountInfo.error)
    }

    return accountInfo.unusedAddresses[0]

  } catch (e) {
    Logger.error(e, { blockchain })
  }
}
