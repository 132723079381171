import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './Button.module.scss'

export default class Button extends React.PureComponent {
  static defaultProps = {
    type: 'button',
    colorType: 'new-filled',
  }
  static propTypes = {
    handleClick: PropTypes.func,
    onClick: PropTypes.func,
    children: PropTypes.node,
    colorType: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    'data-selenium-id': PropTypes.string,
    id: PropTypes.string,
    onMouseOver: PropTypes.func,
    onMouseLeave: PropTypes.func,
  }

  render () {
    const className = [styles.styledButton]
    if (this.props.className) {
      className.push(this.props.className)
    }
    if (this.props.colorType) {
      className.push(styles[this.props.colorType])

      if (!styles[this.props.colorType]) {
        //eslint-disable-next-line
        console.error('No color type for ' + this.props.colorType)
      }
    }

    return (
      <button
        onMouseOver={this.props.onMouseOver}
        onMouseLeave={this.props.onMouseLeave}
        id={this.props.id}
        type={this.props.type}
        onClick={this.props.handleClick || this.props.onClick}
        className={className.join(' ')}
        disabled={this.props.disabled}
        data-selenium-id={this.props['data-selenium-id']}
      >
        {this.props.children}
      </button>
    )
  }
}
