import React from 'react'
import PropTypes from 'prop-types'
import Avatar from 'react-avatar-edit'
import Icon from '../../../components/Icon/Icon'
import styles from './AvatarSettings.module.scss'
import Button from '../../../components/Button/Button'
import { validate } from './validate'
import DEFAULT_AVATAR from '../../../images/default_avatar.svg'


class AvatarSettings extends React.Component {
  static propTypes = {
    avatar: PropTypes.string,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
  }

  constructor (props) {
    super(props)
    const src = props.avatar

    this.state = {
      preview: null,
      error: null,
      src,
    }
    this.onCrop = this.onCrop.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this)
  }

  useDefaultAvatar = () => {
    this.props.onSave(null)
  }

  onClose () {
    this.setState({ preview: null })
  }

  onCrop (preview) {
    this.setState({ preview })
  }

  onBeforeFileLoad (elem) {
    const error = validate(elem.target.files[0])
    this.setState({
      error,
    })
  }

  handleSave = () => {
    this.props.onSave(this.state.preview)
    this.props.onClose()
  }

  renderImage = () => {
    if (this.state.preview) {
      return <img src={this.state.preview} alt='Preview' />
    }
    if (this.props.avatar) {
      return <img src={this.props.avatar} alt='Preview' />
    }
    return <img src={DEFAULT_AVATAR} alt='Preview' />
  }

  render () {
    return (
      <div data-selenium-id='avatarUploadModal'>
        <div className={styles.messageHeader}>
          <button type='button' onClick={this.props.onClose}><Icon scale='1.5' type='close' colorType='gray-50' /></button>
          <div className='h4 gray-70'>Set your avatar</div>
        </div>
        <div className={styles.previewWrapper}>
          <div className={styles.title + ' capture gray-70'}>Preview:</div>
          {this.renderImage()}
          <button
            type='button'
            onClick={this.useDefaultAvatar}
            data-selenium-id='defaultAvatarButton'
            className={styles.useDefaultButton + ' capture blue-bk'}>Use default
          </button>
        </div>
        <div className={styles.editorWrapper} data-selenium-id='avatarInput'>
          <Avatar
            label={<div className={styles.avatarText + ' capture blue-bk'}>
              <Icon type='image-circle' colorType='blue' />
              <span>Choose photo</span>
            </div>}
            labelStyle={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
            borderStyle={{
              border: '2px dashed #F0F2F9',
              borderRadius: '6px',
            }}
            width={324}
            height={230}
            onCrop={this.onCrop}
            onClose={this.onClose}
            onBeforeFileLoad={this.onBeforeFileLoad}
            src={null}
          />
        </div>
        {this.state.error
          ? (
            <div className={styles.errorWrapper}>
              <Icon type='attention' colorType='red' />
              <span className='capture red'>{this.state.error}</span>
            </div>
          ) : null}
        <div className={styles.actionsWrapper}>
          <Button
            disabled={this.state.error || (this.props.avatar !== DEFAULT_AVATAR && this.state.preview === null)}
            onClick={this.handleSave}
            colorType='new-filled'
            data-selenium-id='setAvatarButton'
            type='button'>Set Avatar</Button>
        </div>
      </div>
    )
  }
}


export default AvatarSettings
