import React from 'react'
import PageContainer from '../../containers/PageContainer'
import PaymentRequestsContainer from './PaymentRequestsContainer'
import RightPanel from '../../partials/RightPanel'

export default class Payments extends React.PureComponent {
  render () {
    return (
      <div data-selenium-id='paymentsPage' style={{height: '100%'}}>
        <PageContainer
          mainPart={<PaymentRequestsContainer />}
          additionalPart={<RightPanel />}
        />
      </div>
    )
  }
}
