//@flow
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import {
  totalFiatBalanceSelector,
  isAllBalancesLoadedSelector,
  accountsSelector,
} from '../../ducks/accounts/selectors'
import BigNumber from 'bignumber.js'
import AccountCardComponent from './AccountCardComponent'
import type { ColdWallet, HotWallet } from '../../ducks/accounts/models'
import { currentCurrencySelector } from '../../ducks/profile/selectors'

type Props = {
  accounts: Array<HotWallet | ColdWallet>,
  handleSubmit: () => {},
  totalFiatBalance: BigNumber,
  primaryAsset: string,
  isBalanceLoaded: boolean
}

class AccountCardContainer extends React.PureComponent<Props> {
  static propTypes = {
    accounts: PropTypes.arrayOf(PropTypes.object),
    totalFiatBalance: PropTypes.instanceOf(BigNumber),
    primaryAsset: PropTypes.string,
    rates: PropTypes.object,
    isBalanceLoaded: PropTypes.bool,
  }

  render () {
    return (
      <AccountCardComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state) => {
  const { totalBalance } = totalFiatBalanceSelector(state)

  return {
    isBalanceLoaded: isAllBalancesLoadedSelector(state),
    totalFiatBalance: totalBalance,
    accounts: accountsSelector(state),
    primaryAsset: currentCurrencySelector(state),
  }
}

export default connect(mapStateToProps)(AccountCardContainer)
