import React, { useEffect, useRef } from 'react'
import get from 'lodash.get'
import PropTypes from 'prop-types'
import DragAndDrop from '../../../components/DragAndDrop/DragAndDrop'
import Icon from '../../../components/Icon/Icon'
import styles from '../AddInvoiceCard.module.scss'
import { PaymentRequestModel } from '../../../models/PaymentRequests/PaymentRequestModel'

const LogoArea = ({ formikProps, handleAddLogo, handleGetRequestLogo, request }) => {
  const {
    errors,
    values,
    setFieldValue,
  } = formikProps

  const inputEl = useRef(null)

  //Replaces componentDidMount
  useEffect(() => {
    if (!get(values, 'logo.data', null) && get(values, 'logo.attachmentId', null)) {
      handleGetRequestLogo(formikProps, request)
    }
  }, [values, handleGetRequestLogo, formikProps, request])

  return (
    <>
      <input
        className={styles.fileInput}
        name='logo'
        id='logo'
        ref={inputEl}
        type='file'
        onChange={(event) => {
          if (event.currentTarget.files.length > 0) {
            handleAddLogo(event.currentTarget.files[0])
          }
        }}
      />
      {!values.logo || errors.logo ? (
        <DragAndDrop handleDrop={handleAddLogo}>
          <div className={styles.attachmentArea + ' logo'}>
            <label htmlFor='logo'>
              <Icon type='image-circle' colorType='blue' />
              <span className='capture blue-bk'>Choose a logo or drag it here</span>
            </label>
          </div>
        </DragAndDrop>
      ) : null}
      {values.logo ? (
        <div className={styles.logoWrapper}>
          {values.logo.isLoading
            ? <div className={styles.logoLoader} />
            : errors.logo
              ? (
                <>
                  <Icon type='attention' colorType='red' />
                  <span className='capture red'>{errors.logo}</span>
                  <button onClick={() => {
                    setFieldValue('logo', null)
                    inputEl.current.value = null
                  }}>
                    <Icon type='close' colorType='gray-30' />
                  </button>
                </>
              ) : (
                <>
                  <img className={styles.logoImage} alt={values.logo.name} src={values.logo.data} />
                  <button onClick={() => {
                    setFieldValue('logo', null)
                    inputEl.current.value = null
                  }}>
                    <Icon type='close' colorType='gray-30' />
                  </button>
                </>
              )
          }
        </div>
      ) : null}
    </>
  )
}


LogoArea.propTypes = {
  handleAddLogo: PropTypes.func,
  formikProps: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    values: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    submitForm: PropTypes.func,
    setFieldValue: PropTypes.func,
    submitCount: PropTypes.number,
  }),
  handleGetRequestLogo: PropTypes.func,
  request: PropTypes.instanceOf(PaymentRequestModel),
}

export default LogoArea
