import { setConfirmPasswordCardParams } from './actions'
import { setCookie, getCookie } from '../../utils/auth'

export const initUIState = () => (dispatch) => {
  const isShownFeeWarning = !!getCookie('isShownFeeWarning')
  dispatch(setConfirmPasswordCardParams({
    isShownFeeWarning,
  }))
}

export const hideFeeWarning = () => (dispatch) => {
  setCookie('isShownFeeWarning', true)
  dispatch(setConfirmPasswordCardParams({
    isShownFeeWarning: true,
  }))
}
