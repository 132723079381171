import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { Button } from '../../../components/Button'
import { shuffle } from '../../../utils/general'

import styles from './CreateWallet.module.scss'
import Icon from '../../../components/Icon/Icon'

const numberEndings = {
  1: '1st',
  2: '2nd',
  3: '3rd',
  4: '4th',
  5: '5th',
  6: '6th',
  7: '7th',
  8: '8th',
  9: '9th',
  10: '10th',
  11: '11th',
  12: '12th',
}

class ConfirmMnemonic extends React.PureComponent {

  static propTypes = {
    mnemonic: PropTypes.string,
    handleSubmit: PropTypes.func,
    onBack: PropTypes.func,
  }

  constructor (props) {
    super(props)

    this.state = {
      wordsNumbers: this.generateRandomNumbers(),
      mnemonicArray: props.mnemonic ? shuffle(props.mnemonic.split(' ')) : [],
      selectedWords: [],
    }
  }

  selectWord = (word) => () => {
    if (this.state.selectedWords.length >= 3) {
      return null
    }

    this.setState((prevState) => {
      prevState.selectedWords.push(word)
      return {
        prevState: [...prevState.selectedWords],
      }
    })
  }

  isMnemonicValid = () => {
    const mnemonicArray = this.props.mnemonic.split(' ')
    const userArray = []
    for (const number of this.state.wordsNumbers) {
      userArray.push(mnemonicArray[number - 1])
    }

    return userArray.toString() === this.state.selectedWords.toString()
  }

  generateRandomNumbers = () => {
    const numberSet = new Set()
    while (numberSet.size < 3) {
      numberSet.add(Math.floor(Math.random() * (12) + 1))
    }

    return [...numberSet].sort((a, b) => a - b)
  }

  resetWords = () => {
    this.setState({
      selectedWords: [],
    })
  }

  getEnding = (number) => {
    return numberEndings[number]
  }

  handleConfirm = () => {
    if (this.isMnemonicValid()) {
      this.props.handleSubmit()
    }
  }

  render () {
    const { wordsNumbers } = this.state
    if (!this.state.mnemonicArray.length) {
      return null
    }

    return (
      <div className={styles.formWrapperMnemonic}>

        <div className={styles.formTitle + ' h5 gray-70'}>Backup wallet</div>
        <div className={styles.subTitleWrapper}>
          <div className='capture gray-70'>
            Please choose {`${this.getEnding(wordsNumbers[0])}, ${this.getEnding(wordsNumbers[1])} and
            ${this.getEnding(wordsNumbers[2])}`} words from the<br /> recovery phrase
            in the exact order.
          </div>
          <button className={styles.resetBlock} onClick={this.resetWords}>
            <Icon type='refresh-left' colorType='blue' />
            <span className='capture blue-bk'>Reset</span>
          </button>
        </div>


        <div className={styles.confirmedBlocksWrapper}>
          {this.state.wordsNumbers.map((number, index) => {
            const selectedWord = this.state.selectedWords[index] ? this.state.selectedWords[index] : ''
            return (
              <div className={styles.confirmedBlock} key={number}>
                <span className='h5 gray'>{`${number}. ${selectedWord}`}</span>
              </div>
            )
          })}
        </div>

        <div className={styles.wordsGrid}>
          {this.state.mnemonicArray.map((word, key) => {
            return (
              <div
                className={cn(styles.word, { 'active': this.state.selectedWords.includes(word) })}
                onClick={!this.state.selectedWords.includes(word) ? this.selectWord(word) : null}
                key={key + word}
              >{word}</div>
            )
          })}
        </div>
        {!this.isMnemonicValid() && this.state.selectedWords.length >= 3 && (
          <div className={styles.confirmError}>
            <Icon type='attention' colorType='red' />
            <span className='capture-small red'> Words do not match. <br />
                   Please check your recovery phrase and try again
            </span>
          </div>
        )}
        <div className={styles.actionsMnemonic}>
          <Button
            type='button'
            onClick={this.props.onBack}
            colorType='new-border'
          >back</Button>
          <Button
            disabled={!this.isMnemonicValid()}
            onClick={this.handleConfirm}
            type='submit'
            colorType='new-filled'>NEXT</Button>
        </div>
      </div>
    )
  }
}

export default ConfirmMnemonic
