// @flow
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button } from '../../components/Button'
import type { HotWallet } from '../../ducks/accounts/models'
import { accountLoadingStateSelector } from '../../ducks/accounts/selectors'
import { removeAccount } from '../../ducks/accounts/thunks'
import { LOADING_STATE_LOADED, LOADING_STATE_ERROR } from '../../constants'
import { removeAccount as removeAccountFromState } from '../../ducks/accounts/actions'
import globalStyles from './styles.module.scss'
import Icon from '../../components/Icon/Icon'

const UNLINK_FIRST = 'unlink-first'
const UNLINK_SECOND = 'unlink-second'
const UNLINK_SUCCESS = 'unlink-success'
const UNLINK_ERROR = 'unlink-error'

type Props = {
  step: string,
  onCancel: () => void,
  onUnlink: () => void,
  removeAccount: (accountId: string) => void,
  account: HotWallet,
  loadingState: string,
  removeAccountFromState: (accountId: string) => void,
  onClose: () => void,
  onUnlinkFromState: () => void,
}

type State = {
  isSent: boolean,
  step: string,
}

class UnlinkForm extends React.PureComponent<Props, State> {
  static propTypes = {
    step: PropTypes.oneOf(['unlink-first', 'unlink-second']),
    onCancel: PropTypes.func,
    onUnlink: PropTypes.func,
    removeAccount: PropTypes.func,
    account: PropTypes.object,
    loadingState: PropTypes.string,
    onClose: PropTypes.func,
    removeAccountFromState: PropTypes.func,
    onUnlinkFromState: PropTypes.func,
  }

  constructor (props) {
    super(props)
    this.state = {
      step: UNLINK_FIRST,
      isSent: false,
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (prevState.isSent && nextProps.loadingState === LOADING_STATE_ERROR) {
      return {
        step: UNLINK_ERROR,
      }
    }
    if (prevState.isSent && nextProps.loadingState === LOADING_STATE_LOADED) {
      return {
        step: UNLINK_SUCCESS,
      }
    }
    return {}
  }

  handleUnlink = () => {
    this.props.removeAccount(this.props.account.id)
    this.setState({
      isSent: true,
    })
  }

  handleSetStep = (step) => () => {
    this.setState({
      step,
    })
  }

  render () {

    const info = {
      [UNLINK_FIRST]: {
        title: <>This operation can’t be undone.<br />Please make sure you have the backup phrase.</>,
        subTitle: 'Unlink the wallet from PaymentX?',
        closeButtonTitle: 'CANCEL',
      },
      [UNLINK_SECOND]: {
        title: 'Unlink the wallet from PaymentX?',
        closeButtonTitle: 'CANCEL',
      },
      [UNLINK_SUCCESS]: {
        icon: 'checkmark-circle',
        iconColor: 'green',
        title: 'Wallet successfully unlinked',
        closeButtonTitle: 'CLOSE',
        closeButtonAction: () => {
          this.props.onClose()
          this.props.onUnlinkFromState()
        },
      },
      [UNLINK_ERROR]: {
        icon: 'attention',
        iconColor: 'red',
        title: 'Failed',
        closeButtonTitle: 'CLOSE',
        subTitle: <>Unable to unlink wallet.<br /> Please try again. Contact support if the issue still persists.</>,
      },
    }

    const { step } = this.state

    return (
      <div className={globalStyles.messageContainer}>
        <div className={globalStyles.messageHeader}>
          <button
            onClick={info[step].closeButtonAction || this.props.onClose}
          ><Icon scale='1.5' type='close' colorType='gray-50' />
          </button>
          <div className='h4 gray-70'>Unlink wallet from account</div>
        </div>
        <div className={globalStyles.messageContent}>
          <Icon type={info[step].icon || 'info-circle'} colorType={info[step].iconColor || 'gray-30'} />
          <div className='h5 gray-30'>{info[step].title}</div>
          {info[step].subTitle && (
            <div className='capture gray-30'>{info[step].subTitle}</div>
          )}
          <div className={globalStyles.messageActions}>
            <Button
              type='button'
              onClick={info[step].closeButtonAction || this.props.onClose}
              colorType='new-border'>{info[step].closeButtonTitle}</Button>
            {step === UNLINK_FIRST && (
              <Button type='button' onClick={this.handleSetStep(UNLINK_SECOND)} colorType='new-filled'>YES,
                UNLINK</Button>
            )}
            {step === UNLINK_SECOND && (
              <Button type='button' onClick={this.handleUnlink} colorType='new-filled'>YES, UNLINK</Button>
            )}
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    loadingState: accountLoadingStateSelector(state),
  }
}

function mapDispatchToProps (dispatch) {
  return {
    removeAccount: (accountId) => dispatch(removeAccount(accountId)),
    removeAccountFromState: (accountId) => dispatch(removeAccountFromState(accountId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnlinkForm)
