import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './newSelect.module.scss'

class Select extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    children: PropTypes.node,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    name: PropTypes.string,
    prefix: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }

  render () {
    return (
      <>
        {this.props.label && <div className={styles.label}>{this.props.label}</div>}
        <div className={styles.wrapper}>
          <div className={styles.iconWrapper} htmlFor={this.props.name}>
            <select className={styles.styledSelect + ' ' + (this.props.error?styles.error:'')}
              id={this.props.name}
              name={this.props.name}
              value={this.props.value}
              disabled={this.props.disabled}
              onChange={this.props.onChange}
              onBlur={this.props.onBlur}
              prefix={this.props.prefix}
            >
              {this.props.children}
            </select>
            {this.props.prefix ? <div className={styles.prefix}>{this.props.prefix}</div> : ''}
          </div>
        </div>
      </>
    )
  }
}

export default Select
