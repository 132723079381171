import React from 'react'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import InputText from '../../../components/InputText'
import LoadingState from '../../../components/LoadingState'
import { LOADING_STATE_LOADING } from '../../../constants'
import styles from './ChangePasswordForm.module.scss'
import Button from '../../../components/Button/Button'
import BigPopup from '../../../components/BigPopup'
import PopupMessage from '../../../components/PopupMessage/PopupMessage'

class ChangePasswordForm extends React.PureComponent {

  constructor (props) {
    super(props)
    this.state = {
      isResetPasswordMessageShow: false,
    }
  }

  static propTypes = {
    onUpdatePassword: PropTypes.func,
    passwordLoadingState: PropTypes.object,
    isPasswordSent: PropTypes.bool,
    passwordValidationSchema: PropTypes.object,
    passwordInitialValues: PropTypes.object,
    handleResetPasswordFromProfile: PropTypes.func,
    authLoadingState: PropTypes.string,
  }

  handleToggleResetPasswordMessage = () => {
    this.setState((prevState) => {
      return {
        isResetPasswordMessageShow: !prevState.isResetPasswordMessageShow,
      }
    })
  }

  renderPasswordBlock = (formikProps) => {
    const {
      errors,
      touched,
      values,
      handleChange,
      handleBlur,
    } = formikProps

    return (
      <Form>
        <div className={styles.tabTitle + ' h4 gray-70'}>Change Password</div>
        {this.props.passwordLoadingState.loadingState === LOADING_STATE_LOADING
          ? (
            <div className={styles.loaderWrapper}>
              <LoadingState loadingState={this.props.passwordLoadingState.loadingState} />
            </div>
          )
          :
          <div className={styles.block}>
            <div className={styles.rowWrapper}>
              <div className={styles.line}>
                <span className='capture gray-70'>Old password</span>
                <InputText
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.oldPassword && touched.oldPassword}
                  value={values.oldPassword}
                  name='oldPassword'
                  type='password'
                  data-selenium-id='oldPasswordInput'
                />
              </div>
              <div className={styles.line}>
                <span className='capture gray-70'>New password</span>
                <InputText
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.password && touched.password}
                  value={values.password}
                  name='password'
                  type='password'
                  data-selenium-id='passwordInput'
                />
              </div>
              <div className={styles.line}>
                <span className='capture gray-70'>Confirm password</span>
                <InputText
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.confirmPassword && touched.confirmPassword}
                  value={values.confirmPassword}
                  name='confirmPassword'
                  type='password'
                  data-selenium-id='confirmPasswordInput'
                />
              </div>
              <div className={styles.line}>
                <span />
                <Button
                  className='change-btn'
                  colorType='new-border'
                  type='submit'
                  data-selenium-id='changePasswordButton'
                >Change password</Button>
              </div>
              <div className={styles.line}>
                <span />
                <button
                  className={styles.forgotPasswordButton + ' capture gray-70'}
                  onClick={this.handleToggleResetPasswordMessage}
                  type='button'
                >Forgot password
                </button>
              </div>
            </div>
          </div>
        }
      </Form>
    )
  }

  render () {
    return (
      <>
        <Formik
          initialValues={this.props.passwordInitialValues}
          validationSchema={this.props.passwordValidationSchema}
          onSubmit={this.props.onUpdatePassword}
          render={this.renderPasswordBlock}
        />
        {this.state.isResetPasswordMessageShow && (
          <BigPopup onClose={this.handleToggleResetPasswordMessage} smallMode>
            <PopupMessage
              headerTitle='Reset password'
              icon={{
                type: 'info-circle',
                colorType: 'gray-30',
              }}
              title='Reset password'
              subTitle={<>Do you want to reset your password?<br />
                To set a new password, you will be logged out of the
                system and will receive a letter with a confirmation code that you can use to set a new password.
                Proceed?</>}
              actions={[
                {
                  title: 'Cancel',
                },
                {
                  title: this.props.authLoadingState === LOADING_STATE_LOADING ?
                    <div className={styles.buttonLoader} /> : 'Reset password',
                  onClick: this.props.handleResetPasswordFromProfile,
                  disabled: this.props.authLoadingState === LOADING_STATE_LOADING,
                  colorType: 'new-filled',
                },
              ]}
            />
          </BigPopup>
        )}
      </>
    )
  }
}

export default ChangePasswordForm
