import React from 'react'
import styles from '../AddInvoiceCard.module.scss'
import PropTypes from 'prop-types'
import CustomSelect from '../../../components/Select/CustomSelect'
import { ACCOUNTS_ACTIVE_BLOCKCHAINS } from '../../../ducks/accounts/constants'
import cn from 'classnames'
import AccountSelect from '../../../components/Select/AccountSelect'

const InvoiceAccountSelect = ({ formikProps, accounts, isShowRequireError, handleSetTrezorWallet }) => {
  const {
    values,
    setFieldValue,
  } = formikProps

  return (
    <div className={cn(styles.line, styles.accountLine)}>
      <label className='capture'>Receive payment to</label>
      <AccountSelect
        handleSetTrezorWallet={handleSetTrezorWallet}
        isShowRequireError={isShowRequireError}
        formikProps={formikProps}
        accounts={accounts}
        selectProps={{
          placeholder: 'Select a wallet or type a new address',
        }}
      />
      <CustomSelect
        onChange={(option) => {
          setFieldValue('blockchain', option.value)
          const defaultAccount = accounts.find((account) => account.blockchain === option.value && account.defaultAccount)
          setFieldValue('account', defaultAccount || null)
        }}
        defaultValue={ACCOUNTS_ACTIVE_BLOCKCHAINS.find((blockchain) => blockchain.value === values.blockchain) || ACCOUNTS_ACTIVE_BLOCKCHAINS[0]}
        value={ACCOUNTS_ACTIVE_BLOCKCHAINS.find((blockchain) => blockchain.value === values.blockchain) || ACCOUNTS_ACTIVE_BLOCKCHAINS[0]}
        name='blockchain'
        options={ACCOUNTS_ACTIVE_BLOCKCHAINS}
      />
    </div>
  )
}

InvoiceAccountSelect.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({
    address: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    blockchain: PropTypes.string,
  })),
  formikProps: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    values: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    submitForm: PropTypes.func,
    setFieldValue: PropTypes.func,
    submitCount: PropTypes.number,
  }),
  isShowRequireError: PropTypes.bool,
  handleSetTrezorWallet: PropTypes.func,
}

export default InvoiceAccountSelect

