import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import React from 'react'
import PropTypes from 'prop-types'
import { teamsListSelector } from '../../ducks/teams/selectors'
import {
  passwordLoadingStateSelector,
  profileInfoSelector,
  profileLoadingStateSelector,
} from '../../ducks/profile/selectors'
import { passwordValidationSchema, profileValidationSchema } from './validate'
import { updatePassword, updateProfile } from '../../ducks/profile/thunks'
import ProfileCardView from './ProfileCardView'
import { Scrollbars } from 'react-custom-scrollbars'
import styles from './ProfileCardContainer.module.scss'
import {
  TAB_GENERAL_SETTINGS,
  TAB_CHANGE_PASSWORD,
} from './constants'
import Button from '../../components/Button/Button'
import Icon from '../../components/Icon/Icon'
import ChangePasswordForm from './ChangePasswordForm'
import {
  LOADING_STATE_LOADED,
  LOADING_STATE_ERROR,
  LOADING_STATE_LOADING,
  CURRENCY_USD,
} from '../../constants'
import Notification from '../../components/Notification/'
import { resetPasswordFromProfile } from '../../ducks/auth/thunks'
import { authSelector } from '../../ducks/auth/selectors'
import { defaultAccountsSelector } from '../../ducks/accounts/selectors'
import { AccountModel } from '../../models/Accounts/AccountModel'

class ProfileCardContainer extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    teams: PropTypes.array,
    profile: PropTypes.object,
    updateProfile: PropTypes.func,
    updatePassword: PropTypes.func,
    onLogout: PropTypes.func,
    handleResetPasswordFromProfile: PropTypes.func,
    authLoadingState: PropTypes.string,
    loadingState: PropTypes.shape({
      loadingState: PropTypes.string,
      error: PropTypes.string,
    }),
    passwordLoadingState: PropTypes.object,
    accounts: PropTypes.arrayOf(PropTypes.instanceOf(AccountModel)),
  }

  constructor (props) {
    super(props)

    this.state = {
      avatar: null,
      avatarLoading: false,
      isProfileSent: false,
      isPasswordSent: false,
      activeTab: TAB_GENERAL_SETTINGS,
    }

    const { profile, accounts } = this.props
    const defaultAccounts = accounts.length > 0
      ? accounts.map((account) => ({
        blockchain: account.blockchain,
        account: account.id,
      }))
      : [{
        blockchain: null,
        account: null,
      }]
    this.profileInitialValues = {
      firstName: profile.firstName || '',
      lastName: profile.lastName || '',
      email: profile.email || '',
      receivePdf: typeof profile.receivePdf === 'boolean' ? profile.receivePdf : true,
      fromDescription: profile.fromDescription || '',
      primaryAsset: profile.primaryAsset || CURRENCY_USD,
      defaultAccounts,
    }
    this.passwordInitialValues = {
      oldPassword: '',
      password: '',
      confirmPassword: '',
    }
  }

  handleUpdateProfile = (values) => {
    this.props.updateProfile({
      ...values,
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      defaultAccounts: values.defaultAccounts.filter((value) => {
        return !!value.blockchain && !!value.account
      }),
    })
    this.setState({ isProfileSent: true })
  }

  handleUpdatePassword = async (values, formProps) => {
    this.setState({ isPasswordSent: true })
    const res = await this.props.updatePassword(values)
    if (res) {
      formProps.resetForm()
    }
  }

  handleFormatImage = (file) => {
    this.setState(() => ({
      avatar: file,
    }))
  }

  toggleTab = (tabName) => () => {
    this.setState({
      activeTab: tabName,
    })
  }

  toggleIsSend = (form) => () => {
    this.setState({
      [form]: false,
    })
  }

  bindSubmitForm = (submitForm) => {
    this.handleSaveProfile = submitForm
  }

  renderTabs = () => {
    const activeTab = this.state.activeTab
    switch (activeTab) {
      case TAB_GENERAL_SETTINGS:
        return (
          <ProfileCardView
            {...this.props}
            bindSubmitForm={this.bindSubmitForm}
            isProfileSent={this.state.isProfileSent}
            profileInitialValues={this.profileInitialValues}
            profileValidationSchema={profileValidationSchema}
            onUpdateProfile={this.handleUpdateProfile}
            avatar={this.state.avatar}
            avatarLoading={this.state.avatarLoading}
            onFormatImage={this.handleFormatImage}
          />
        )
      case TAB_CHANGE_PASSWORD:
        return (
          <ChangePasswordForm
            {...this.props}
            isPasswordSent={this.state.isPasswordSent}
            passwordInitialValues={this.passwordInitialValues}
            passwordValidationSchema={passwordValidationSchema}
            onUpdatePassword={this.handleUpdatePassword}
          />
        )
      default:
        return null
    }
  }

  render () {
    const { activeTab } = this.state
    const { onClose } = this.props
    return (
      <div className={styles.wrapper} data-selenium-id='profileSettings'>
        <div className={styles.header}>
          <button
            className={styles.closeButton}
            onClick={onClose}
          ><Icon scale='1.5' type='close' colorType='gray-50' /></button>
          <div className='title h3 gray-70'>Settings</div>
        </div>
        <div className={styles.panel}>
          <div className={styles.sideContent}>
            <div className={styles.sidePanelContent}>
              <div className={styles.saveButton}>
                <Button
                  data-selenium-id='saveSettingsButton'
                  disabled={activeTab !== TAB_GENERAL_SETTINGS || this.props.loadingState.loadingState === LOADING_STATE_LOADING}
                  colorType='new-filled'
                  onClick={() => this.handleSaveProfile()}>SAVE SETTINGS</Button>
              </div>
              <div className={styles.tabs + ' capture gray-70'}>
                <button data-selenium-id='generalSettingsButton' className={styles.tab}
                  onClick={this.toggleTab(TAB_GENERAL_SETTINGS)}>
                  <Icon type='settings' colorType={activeTab === TAB_GENERAL_SETTINGS ? 'blue' : 'gray-70'} />
                  <span className={activeTab === TAB_GENERAL_SETTINGS ? 'blue-bk' : 'gray-70'}>General settings</span>
                </button>
                <button data-selenium-id='changePasswordTabButton' className={styles.tab}
                  onClick={this.toggleTab(TAB_CHANGE_PASSWORD)}>
                  <Icon type='password-2' colorType={activeTab === TAB_CHANGE_PASSWORD ? 'blue' : 'gray-70'} />
                  <span className={activeTab === TAB_CHANGE_PASSWORD ? 'blue-bk' : 'gray-70'}>Change password</span>
                </button>
                <button data-selenium-id='logoutButton' className={styles.tab} onClick={this.props.onLogout}>
                  <Icon type='sign-out' colorType='gray-70' />
                  <span className='gray-70'>Logout</span>
                </button>
              </div>
            </div>
          </div>
          <div className={styles.mainContent}>
            <Scrollbars style={{ height: 'calc(100vh - 72px)' }}>
              {this.renderTabs()}
              <div className={styles.notification}>
                {this.state.isProfileSent && [LOADING_STATE_LOADED, LOADING_STATE_ERROR].includes(this.props.loadingState.loadingState) &&
                <Notification
                  data-selenium-id={this.props.loadingState.error ? 'failedNotificaction' : 'successNotification'}
                  type={this.props.loadingState.loadingState === LOADING_STATE_ERROR ? 'error' : 'success'}
                  message={this.props.loadingState.error ? 'Failed to save settings' : 'Settings saved successfully'}
                  callback={this.toggleIsSend('isProfileSent')}
                />}

                {this.state.isPasswordSent && [LOADING_STATE_LOADED, LOADING_STATE_ERROR].includes(this.props.passwordLoadingState.loadingState) &&
                <Notification
                  data-selenium-id={this.props.loadingState.error ? 'failedNotificaction' : 'successNotification'}
                  type={this.props.passwordLoadingState.loadingState === LOADING_STATE_ERROR ? 'error' : 'success'}
                  message={this.props.passwordLoadingState.loadingState === LOADING_STATE_ERROR
                    ? (this.props.passwordLoadingState.error || 'Failed to change password')
                    : 'Password changed successfully'}
                  callback={this.toggleIsSend('isPasswordSent')}
                />}
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
    )
  }
}


function mapStateToProps (state) {
  const { loadingState } = authSelector(state)
  return {
    profile: profileInfoSelector(state),
    teams: teamsListSelector(state),
    loadingState: profileLoadingStateSelector(state),
    passwordLoadingState: passwordLoadingStateSelector(state),
    authLoadingState: loadingState,
    accounts: defaultAccountsSelector(state),
  }
}

function mapDispatchToProps (dispatch) {
  return {
    updateProfile: (values) => dispatch(updateProfile(values)),
    updatePassword: (values) => dispatch(updatePassword(values)),
    onLogout: () => dispatch(push('/logout')),
    handleResetPasswordFromProfile: () => dispatch(resetPasswordFromProfile()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCardContainer)
