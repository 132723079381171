// @flow
import AbstractRESTService from './AbstractRESTService'

const URL_WALLETS = '/wallets'
const URL_WALLET_SET_DEFAULT = '/wallets/setDefault'

export default class WalletService extends AbstractRESTService {

  /**
   * Save account to wallet service
   */
  saveAccount (payload: any) {
    return this.restService.request({
      method: 'POST',
      url: URL_WALLETS,
      headers: this.getBearerHeaders(),
      data: { payload },
    })
  }

  /**
   * Save account to wallet service
   */
  updateAccount (accountId: string, payload: any) {
    return this.restService.request({
      method: 'PUT',
      url: URL_WALLETS + '/' + accountId,
      headers: this.getBearerHeaders(),
      data: { payload },
    })
  }

  /**
   * Delete account from wallet service
   */
  deleteAccount (walletId: string) {
    return this.restService.request({
      method: 'DELETE',
      url: URL_WALLETS + '/' + walletId,
      headers: this.getBearerHeaders(),
    })
  }

  /**
   * Load accounts from wallets service
   */
  loadAccounts () {
    return this.restService.request({
      method: 'GET',
      url: URL_WALLETS,
      headers: this.getBearerHeaders(),
    })
  }

  setDefaultAccounts (walletList: any) {
    return this.restService.request({
      method: 'PUT',
      url: URL_WALLET_SET_DEFAULT,
      headers: this.getBearerHeaders(),
      data: {
        payload: {
          walletList,
        },
      },
    })
  }

}
