import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../../components/Button'
import Icon from '../../../components/Icon/Icon'
import styles from './DeleteMessage.module.scss'

const DeleteMessage = ({ onClose, onDelete }) => {

  const handleDelete = () => {
    onClose()
    setTimeout(() => {
      onDelete()
    }, 0)
  }
  return (
    <div className={styles.messageContainer}>
      <div className={styles.messageHeader}>
        <button onClick={onClose}><Icon scale='1.5' type='close' colorType='gray-50' /></button>
        <div className='h4 gray-70'>Delete Invoice</div>
      </div>
      <div className={styles.messageContent}>
        <Icon type='info-circle' colorType='gray-30' />
        <div className='h5 gray-30'>Are you sure you want to delete the invoice?</div>
        <div className='capture gray-30'>
          The invoice will be removed from the system permanently.
        </div>
        <div className={styles.messageActions}>
          <Button
            type='button'
            onClick={handleDelete}
            colorType='new-border'>Delete Invoice</Button>
          <Button
            type='button'
            onClick={onClose}
            colorType='new-filled'>Return to invoice preview </Button>
        </div>
      </div>
    </div>
  )
}

DeleteMessage.propTypes = {
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
}

export default DeleteMessage
